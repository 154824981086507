/* eslint-disable linebreak-style */
import axios from 'axios';

export const validateAddress = async (zipCode, state, city, country) => {
  let url = `/api/core/address_validation/?`;
  if (zipCode) url += `zip_code=${zipCode}&`;
  if (country) url += `country=${country}&`;
  if (state) url += `state=${state}&`;
  if (city) url += `city=${city}`;
  try {
    return await axios.get(url);
  } catch (error) {
    return error
  }
};
