/* eslint-disable linebreak-style */
import axios from 'axios';
import {
  PARTNER_FETCH_REQUEST,
  PARTNER_FETCH_SUCCESS,
  PARTNER_FETCH_ERROR,
} from './actionTypes';
import { updateSpinner } from "./spinnerActions";


export const fetchPartnerDetail = (partnerUid) => (dispatch) => {
  dispatch(updateSpinner(true));
  dispatch({ type: PARTNER_FETCH_REQUEST , request: true});
  let url;
  if (partnerUid) {
    url = `/api/partner/partners/${partnerUid}/`;
  } else {
    url = 'api/partner/partners/get_partner_detail/';
  }
  axios.get(url)
  .then(({ data }) => {
    if (data) {
      dispatch({ type: PARTNER_FETCH_SUCCESS, data });
    } else {
      dispatch({ type: PARTNER_FETCH_ERROR, error: { mes: "" }});
    }
    dispatch(updateSpinner(false));
  })
  .catch((error) => {
    dispatch({ type: PARTNER_FETCH_ERROR, error });
    dispatch(updateSpinner(false));
  });
};
