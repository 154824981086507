import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  Card,
  CardBody,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import RangeSlider from "react-bootstrap-range-slider";
import FuelBiImg from "../../assets/img/fuel-bi.png";
import defaultAvatar from "../../assets/img/placeholder.jpg";
import classnames from "classnames";
import Cropper from "react-easy-crop";
import getCroppedImg from "../../utils/cropImage";

class ImageUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      imagePreviewUrl: this.props.previewImage
        ? this.props.previewImage
        : this.props.avatar
        ? defaultAvatar
        : null,
      error: "",
      size: 0,
      showModal: false,
      crop: { x: 0, y: 0 },
      zoom: 1,
      aspect: 1,
      rotation: 0,
      croppedAreaPixels: null,
    };
    this.handleImageChange = this.handleImageChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.onZoomChange = this.onZoomChange.bind(this);
  }

  componentDidMount() {
    if (this.props.previewImage) {
      this.setState({
        imagePreviewUrl: this.props.previewImage,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.previewImage !== this.props.previewImage) {
      this.setState({
        imagePreviewUrl: this.props.previewImage,
      });
    }
  }

  handleImageChange(e) {
    e.preventDefault();
    const { setFilesSize } = this.props;
    if (e.target.files.length === 0) {
      return;
    }

    const availableFileTypes = [
      "image/gif",
      "image/jpeg",
      "image/png",
      "image/tiff",
    ];
    if (!e.target.files[0].size) {
      this.setState({ error: "Empty files cannot be uploaded" });
    } else if (!availableFileTypes.includes(e.target.files[0].type)) {
      this.setState({
        error: `The file type (${e.target.files[0].type}) is not supported`,
      });
    } else {
      try {
        setFilesSize(e.target.files[0].size - this.state.size);
        this.setState({ error: "" });

        let reader = new FileReader();
        let file = e.target.files[0];
        const { setLogo } = this.props;
        reader.onloadend = () => {
          this.setState({
            file: file,
            imagePreviewUrl: reader.result,
            size: file.size,
          });
          if (setLogo) {
            setLogo(reader.result);
          }
        };
        reader.readAsDataURL(file);

        // Make value properly propagated to Redux Form:
        // https://github.com/redux-form/redux-form/issues/71#issuecomment-251365333
        const {
          input: { onChange },
        } = this.props;
        onChange(file);
        this.showModal();
      } catch (e) {
        this.setState({ error: e.toString() });
      }
    }
  }

  handleSubmit(e) {
    e.preventDefault();
  }

  handleClick() {
    this.refs.fileInput.click();
  }

  handleRemove() {
    this.setState({
      file: null,
      size: 0,
      imagePreviewUrl: this.props.previewImage
        ? this.props.previewImage
        : this.props.avatar
        ? defaultAvatar
        : FuelBiImg,
    });
    this.refs.fileInput.value = null;
  }

  showModal() {
    this.setState({
      showModal: true,
    });
  }

  hideModal() {
    this.setState({
      showModal: false,
    });
  }

  onCropChange = (crop) => {
    this.setState({ crop });
  };

  onCropComplete = (croppedArea, croppedAreaPixels) => {
    this.setState({ croppedAreaPixels });
  };

  onZoomChange = (zoom) => {
    this.setState({ zoom });
  };

  onRotationChange = (rotation) => {
    this.setState({ rotation });
  };

  cancelCrop = () => {
    this.hideModal();
    this.setState({ file: null });
  };

  saveCroppedImage = async () => {
    const { setLogo } = this.props;

    try {
      const croppedImage = await getCroppedImg(
        this.state.imagePreviewUrl,
        this.state.croppedAreaPixels,
        this.state.rotation
      );
      this.hideModal();

      this.setState({ imagePreviewUrl: croppedImage, file: croppedImage });

      if (setLogo) {
        setLogo(croppedImage);
      }
    } catch (e) {
      console.error(e);
    }
  };

  render() {
    const { error, touched, visited } = this.props.meta;

    let statusClass = null;
    let errorEl = null;

    if (visited || touched) {
      if (!error) {
        statusClass = "has-success";
      } else {
        statusClass = "has-danger";
        errorEl = <Label className="error mt-1">{error}</Label>;
      }
    }

    return (
      <div className={classnames("fileinput", "text-center", statusClass)}>
        <input
          type="file"
          onChange={this.handleImageChange}
          ref="fileInput"
          accept="image/gif, image/jpeg, image/png, image/tiff"
          onClick={(event) => {
            event.target.value = null;
          }}
        />
        <div className={"thumbnail" + (this.props.avatar ? " img-circle" : "")}>
          {this.state.imagePreviewUrl && (
            <img src={this.state.imagePreviewUrl} alt="..." />
          )}
        </div>
        <div>
          {this.state.file === null ? (
            <Button
              color="outline-primary px-4"
              onClick={() => this.handleClick()}
            >
              {this.state.imagePreviewUrl ? "EDIT IMAGE" : "ADD IMAGE"}
            </Button>
          ) : (
            <span>
              <Button
                color="outline-primary px-4"
                onClick={() => this.handleClick()}
              >
                {this.state.imagePreviewUrl ? "EDIT IMAGE" : "ADD IMAGE"}
              </Button>
            </span>
          )}
        </div>
        {errorEl}
        {this.state.error && (
          <div>
            <Label className="error mt-1">{this.state.error}</Label>
          </div>
        )}

        <Modal size="lg" isOpen={this.state.showModal}>
          <ModalHeader>Edit Image</ModalHeader>
          <ModalBody className="mt-4 p-3">
            <Card>
              <CardBody>
                <div className="crop-container">
                  <Cropper
                    image={this.state.imagePreviewUrl}
                    crop={this.state.crop}
                    zoom={this.state.zoom}
                    aspect={this.state.aspect}
                    onCropChange={this.onCropChange}
                    onCropComplete={this.onCropComplete}
                    onZoomChange={this.onZoomChange}
                    onRotationChange={this.onRotationChange}
                  />
                </div>
              </CardBody>
            </Card>

            <div className="w-50 ml-auto mr-auto">
              <RangeSlider
                value={this.state.zoom}
                step={0.1}
                min={1}
                max={3}
                onChange={(e) => this.onZoomChange(e.target.value)}
              />
            </div>
          </ModalBody>
          <ModalFooter className="d-flex justify-content-center">
            <Button color="secondary" onClick={this.cancelCrop}>
              Cancel
            </Button>
            <Button color="primary" onClick={this.saveCroppedImage}>
              Save
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

ImageUpload.propTypes = {
  avatar: PropTypes.bool,
  previewImage: PropTypes.string,
};

export default ImageUpload;
