import React from "react";
import {Button, Col, Row, Input, FormGroup, Label} from "reactstrap";
import Slider from "react-slick";
import { getYouTubeId } from "../../services/youtube";
import YouTube from "../YouTube";
import {urlValidation} from "../../views/forms/validation";

class SolutionMediaResourcesUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filesData: [],
      uploadedFilesData: [],
      newUrl: '',
      newUrlError: '',
      error: ""
    };
    this.handleFilesChange = this.handleFilesChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    if (this.props.solutionMediaResources) {
      this.setState({
        uploadedFilesData: this.props.solutionMediaResources
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.solutionMediaResources &&
      this.props.solutionMediaResources
    ) {
      this.setState({
        uploadedFilesData: this.props.solutionMediaResources
      });
    }
  }

  readFile(files, filesData, idx) {
    let reader = new FileReader();
    let file = files[idx];
    const { setAddedMediaResources } = this.props;
    reader.onloadend = () => {
      filesData.push({
        name: files[idx].name,
        data: reader.result,
        size: files[idx].size,
      });

      if (idx + 1 < files.length) {
        this.readFile(files, filesData, idx + 1)
      } else {
        this.setState({ filesData });
        this.refs.fileInput.value = null;
        if (setAddedMediaResources) setAddedMediaResources(filesData);
      }
    };
    reader.readAsDataURL(file);
  }

  handleFilesChange(e) {
    e.preventDefault();
    const {setFilesSize} = this.props;
    const availableFileTypes = ["image/gif", "image/jpeg", "image/png", "image/tiff"];
    if (!e.target.files.length) {
      return;
    }
    if (!e.target.files[0].size) {
      this.setState({error: "Empty files cannot be uploaded"});
    }
    else if (!availableFileTypes.includes(e.target.files[0].type)) {
      this.setState({error: `The file type (${e.target.files[0].type}) is not supported`});
    }
    else {
      try {
        setFilesSize(e.target.files[0].size)
        this.setState({error: ""});
        this.readFile(e.target.files, this.state.filesData, 0)
      } catch (e) {
        this.setState({error: e.toString()});
      }
    }
  }

  removeNewFile(idx) {
    const {setFilesSize, removeAddedMediaResource} = this.props;
    let newFilesData = [
      ...this.state.filesData
    ];
    const deletedFileSize = newFilesData[idx].size;
    newFilesData.splice(idx, 1);
    this.setState({
      filesData: newFilesData
    });
    if (removeAddedMediaResource) {
      removeAddedMediaResource(idx);
    }

    try {
      setFilesSize(deletedFileSize, "minus")
      this.setState({error: ""});
    } catch (e) {
      this.setState({error: e.toString()});
    }
  }

  removeUploadedFile(idx) {
    const {
      addRemovedMediaResource
    } = this.props;
    let newUploadedFilesData = [
      ...this.state.uploadedFilesData
    ];
    const {
      id
    } = newUploadedFilesData[idx];
    newUploadedFilesData.splice(idx, 1);
    this.setState({
      uploadedFilesData: newUploadedFilesData
    });
    addRemovedMediaResource(id);
  }

  handleClick() {
    this.refs.fileInput.click();
  }

  onAddMediaUrl() {

    const error = urlValidation(this.state.newUrl)
    if (error) {
      this.setState({ newUrlError: error });
      return;
    }

    const youTubeId = getYouTubeId(this.state.newUrl);
    if (youTubeId) {
      const filesData = this.state.filesData.concat([{
        resourceLink: this.state.newUrl,
      }]);
      this.setState({ newUrl: '', newUrlError: '' , filesData});
      this.props.setAddedMediaResources(filesData)
    } else {
      this.setState({ newUrlError: 'Not a valid YouTube link' });
    }
  }

  render() {
    const carouselSettings = {
      // infinite: true,
      autoplay: false,
      slidesToShow: 3,
      slidesToScroll: 1,
      // speed: 350,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 800,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          }
        }
      ]
    };
    const { filesData, uploadedFilesData } = this.state;
    const totalFilesLen = uploadedFilesData.length + filesData.length;
    const { submitFailed } = this.props;

    const onChangeYouTubeLink = (e) => {
      this.setState({newUrl: e.target.value})
    }

    return (
      <div>
        <Row className={!totalFilesLen && submitFailed ? "media-upload-error": null}>
          <Col>
            <Row>
              <Col>
                <h4>Media</h4>
                <p>Upload images or link to videos about your solution</p>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <FormGroup>
                  <h4 className="mb-0">Add Images</h4>
                  <Button
                    id="addImages"
                    className="has-danger"
                    color="outline-primary"
                    onClick={() => this.handleClick()}
                  >
                    SELECT
                  </Button>
                </FormGroup>
              </Col>
              <Col md={8}>
                <h4 className="mb-0">Add YouTube video link</h4>
                <FormGroup inline check>
                  <Input
                    type="url"
                    value={this.state.newUrl}
                    onChange={onChangeYouTubeLink}
                    size="50"
                    placeholder="https://www.youtube.com/watch?v=xxxxxxxxx"
                    className="p-2"
                    color="outline-primary"
                  />
                  <Button
                    color="outline-primary"
                    className="ml-2"
                    onClick={() => this.onAddMediaUrl()}
                  >
                    Add
                  </Button>
                </FormGroup>
                <div className="fileinput text-center">
                  <input
                    type="file" onChange={this.handleFilesChange} ref="fileInput" multiple
                    accept="image/gif, image/jpeg, image/png, image/tiff"
                  />
                </div>
                {this.state.newUrlError && <label className="error mb-3">{this.state.newUrlError}</label>}
              </Col>
            </Row>
          </Col>
        </Row>
        {!totalFilesLen && submitFailed && <label className="error">Please add one or more files</label>}

        {totalFilesLen > 3 &&
          <Slider {...carouselSettings} className="m-4 media-carousel">
            {
              uploadedFilesData.map(
                ({ resourceLink, resourceFileUrl, data }, idx) => {
                  const youTubeId = getYouTubeId(resourceLink);
                  return (
                  <div key={idx} className="pr-3 pt-3">
                    <div className="position-relative media-item-wrapper">
                      <div className="media-item-front-cover">
                      </div>
                      <div className="position-absolute media-item-remove-icon">
                        <i className="fa fa-times fa-lg" onClick={() => this.removeUploadedFile(idx)} />
                      </div>
                      {youTubeId ? <YouTube youTubeId={youTubeId} title={idx} />
                        : <img className="media-carousel-img" alt="" src={resourceFileUrl || data} />
                      }
                    </div>
                  </div>
                )}
              )
            }
            {
              filesData.map(
                ({ resourceLink, data}, idx) => {
                  const youTubeId = getYouTubeId(resourceLink);
                  return (
                  <div key={idx} className="pr-3 pt-3">
                    <div className="position-relative media-item-wrapper">
                      <div className="media-item-front-cover">
                      </div>
                      <div className="position-absolute media-item-remove-icon">
                        <i className="fa fa-times fa-lg" onClick={() => this.removeNewFile(idx)} />
                      </div>
                      {youTubeId ? <YouTube youTubeId={youTubeId} title={idx} />
                        : <img className="media-carousel-img" alt="" src={data} />
                      }
                    </div>
                  </div>
                )}
              )
            }
          </Slider>
        }
        {totalFilesLen <= 3 &&
          <div className="d-flex media-carousel">
            {
              uploadedFilesData.map(
                ({ resourceLink, resourceFileUrl, data }, idx) => {
                  const youTubeId = getYouTubeId(resourceLink);
                  return (
                  <div key={idx} className="pr-3 pt-3">
                    <div className="position-relative media-item-wrapper">
                      <div className="media-item-front-cover">
                      </div>
                      <div className="position-absolute media-item-remove-icon">
                        <i className="fa fa-times fa-lg" onClick={() => this.removeUploadedFile(idx)} />
                      </div>
                      {youTubeId ? <YouTube youTubeId={youTubeId} title={idx} />
                       : <img className="media-img" alt="" src={resourceFileUrl || data} />
                      }
                    </div>
                  </div>
                )}
              )
            }
            {
              filesData.map(
                ({resourceLink,  data}, idx) => {
                  const youTubeId = getYouTubeId(resourceLink);
                  return (
                  <div key={idx} className="pr-3 pt-3">
                    <div className="position-relative media-item-wrapper">
                      <div className="media-item-front-cover">
                      </div>
                      <div className="position-absolute media-item-remove-icon">
                        <i className="fa fa-times fa-lg" onClick={() => this.removeNewFile(idx)} />
                      </div>
                      {youTubeId ? <YouTube youTubeId={youTubeId} title={idx} />
                        : <img className="media-img" alt="" src={data} />
                      }
                    </div>
                  </div>
                )}
              )
            }
          </div>
        }
        {this.state.error &&
        <div>
          <Label className="error mt-1">{this.state.error}</Label>
        </div>
        }
      </div>
    );
  }
}

export default SolutionMediaResourcesUpload;
