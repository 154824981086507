import { updateSpinner } from "./spinnerActions";
import {
  MARKETPLACE_RESOURCE_ADD_REQUEST,
  MARKETPLACE_RESOURCE_ADD_SUCCESS,
  MARKETPLACE_RESOURCE_ADD_ERROR,
  MARKETPLACE_RESOURCE_EDIT_REQUEST,
  MARKETPLACE_RESOURCE_EDIT_SUCCESS,
  MARKETPLACE_RESOURCE_EDIT_ERROR,
  MARKETPLACE_RESOURCE_DELETE_ERROR,
  MARKETPLACE_RESOURCE_DELETE_SUCCESS,
  MARKETPLACE_RESOURCE_DELETE_REQUEST,
  MARKETPLACE_RESOURCE_FETCH_REQUEST,
  MARKETPLACE_RESOURCE_FETCH_SUCCESS,
  MARKETPLACE_RESOURCE_FETCH_ERROR,
  MARKETPLACE_RESOURCE_CLEAR_DETAIL,
  MARKETPLACE_RESOURCE_PUBLISH_SUCCESS,
  MARKETPLACE_RESOURCE_PUBLISH_REQUEST,
  MARKETPLACE_RESOURCE_PUBLISH_ERROR,
} from "./actionTypes";
import axios from "axios";
import { addMessage } from "./messageActions";

export const fetchMarketplaceResourceDetail =
  (resourceId) => async (dispatch, state) => {
    dispatch(updateSpinner(true));
    dispatch({ type: MARKETPLACE_RESOURCE_FETCH_REQUEST, request: true });
    let url = `/api/marketplace/marketplace_resources/${resourceId}/`;
    const { mode } = state().account;
    url += `?mode=${mode}`;
    try {
      const res = await axios.get(url);
      dispatch({ type: MARKETPLACE_RESOURCE_FETCH_SUCCESS, data: res.data });
      dispatch(updateSpinner(false));
    } catch (error) {
      dispatch({ type: MARKETPLACE_RESOURCE_FETCH_ERROR, error });
      dispatch(updateSpinner(false));
      return error;
    }
  };

export const addMarketplaceResourceDetail =
  (data) => async (dispatch, state) => {
    dispatch(updateSpinner(true));
    dispatch({ type: MARKETPLACE_RESOURCE_ADD_REQUEST, request: true });
    let url = `/api/marketplace/marketplace_resources/`;
    const { mode } = state().account;
    url += `?mode=${mode}`;
    try {
      const res = await axios.post(url, data);
      dispatch({ type: MARKETPLACE_RESOURCE_ADD_SUCCESS, data: res.data });
      dispatch(updateSpinner(false));
    } catch (error) {
      dispatch({ type: MARKETPLACE_RESOURCE_ADD_ERROR, error });
      dispatch(updateSpinner(false));
      return error;
    }
  };

export const updateMarketplaceResourceDetail =
  (marketplaceResourceId, data) => async (dispatch, state) => {
    dispatch(updateSpinner(true));
    dispatch({ type: MARKETPLACE_RESOURCE_EDIT_REQUEST, request: true });
    let url = `/api/marketplace/marketplace_resources/${marketplaceResourceId}/`;
    const { mode } = state().account;
    url += `?mode=${mode}`;
    try {
      const res = await axios.patch(url, data);
      dispatch({ type: MARKETPLACE_RESOURCE_EDIT_SUCCESS, data: res.data });
      dispatch(updateSpinner(false));
    } catch (error) {
      dispatch({ type: MARKETPLACE_RESOURCE_EDIT_ERROR, error });
      dispatch(updateSpinner(false));
      return error;
    }
  };

export const deleteMarketplaceResource =
  (marketplaceResourceId) => async (dispatch, state) => {
    dispatch(updateSpinner(true));
    dispatch({ type: MARKETPLACE_RESOURCE_DELETE_REQUEST, request: true });
    let url = `/api/marketplace/marketplace_resources/${marketplaceResourceId}/delete/`;
    const { mode } = state().account;
    url += `?mode=${mode}`;

    try {
      const res = await axios.post(url);
      dispatch({ type: MARKETPLACE_RESOURCE_DELETE_SUCCESS, data: res.data });
      dispatch(updateSpinner(false));
    } catch (error) {
      dispatch({ type: MARKETPLACE_RESOURCE_DELETE_ERROR, error });
      dispatch(updateSpinner(false));
      return error;
    }
  };

export const publishMarketplaceResource =
  (marketplaceResourceId) => async (dispatch, state) => {
    dispatch(updateSpinner(true));
    dispatch({ type: MARKETPLACE_RESOURCE_PUBLISH_REQUEST, request: true });
    let url = `/api/marketplace/marketplace_resources/${marketplaceResourceId}/publish/`;
    const { mode } = state().account;
    url += `?mode=${mode}`;

    try {
      const res = await axios.post(url);
      dispatch({ type: MARKETPLACE_RESOURCE_PUBLISH_SUCCESS, data: res.data });
      dispatch(updateSpinner(false));
      if (res.data && res.data.delete_requested) {
        dispatch(addMessage("Resource deleted successfully."));
      } else {
        dispatch(addMessage("Resource synced successfully."));
      }
    } catch (error) {
      dispatch({ type: MARKETPLACE_RESOURCE_PUBLISH_ERROR, error });
      dispatch(updateSpinner(false));
      return error;
    }
  };

export const clearMarketplaceResource = () => (dispatch) => {
  dispatch({ type: MARKETPLACE_RESOURCE_CLEAR_DETAIL });
};
