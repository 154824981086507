import {updateSpinner} from "./spinnerActions";
import {
  SUBSCRIPTION_UPDATE_ADD_ERROR,
  SUBSCRIPTION_UPDATE_ADD_REQUEST,
  SUBSCRIPTION_UPDATE_ADD_SUCCESS,
  SUBSCRIPTION_UPDATE_LIST_FETCH_ERROR,
  SUBSCRIPTION_UPDATE_LIST_FETCH_REQUEST,
  SUBSCRIPTION_UPDATE_LIST_FETCH_SUCCESS,
  SUBSCRIPTION_UPDATE_LIST_CLEAR
} from "./actionTypes";
import axios from "axios";


export const addSubscriptionUpdate = (subscriptionUid, data) => async (dispatch) => {
  dispatch(updateSpinner(true));
  dispatch({ type: SUBSCRIPTION_UPDATE_ADD_REQUEST , request: true});
  const url = `/api/workflow/buyflows/${subscriptionUid}/request_subscription_update/`;
  try {
    const res = await axios.post(url, data);
    dispatch({ type: SUBSCRIPTION_UPDATE_ADD_SUCCESS, data: res.data });
    dispatch(updateSpinner(false));
  } catch (error) {
    dispatch({ type: SUBSCRIPTION_UPDATE_ADD_ERROR, error });
    dispatch(updateSpinner(false));
    return error;
  }
};

export const fetchSubscriptionUpdateBySubscription = (subscription_id) => async (dispatch) => {
  dispatch(updateSpinner(true));
  dispatch({ type: SUBSCRIPTION_UPDATE_LIST_FETCH_REQUEST , request: true});
  let url = `/api/core/subscription_updates/?subscription__id=${subscription_id}&ordering=-created&limit=1&no_approval_date=True`;
  try {
    const res = await axios.get(url);
    dispatch(updateSpinner(false));
    dispatch({ type: SUBSCRIPTION_UPDATE_LIST_FETCH_SUCCESS, data: res.data });
    return res;
  } catch (error) {
    dispatch({ type: SUBSCRIPTION_UPDATE_LIST_FETCH_ERROR, error });
    dispatch(updateSpinner(false));
    return error;
  }
};

export const clearSubscriptionUpdateList = () => (dispatch) => {
  dispatch({type: SUBSCRIPTION_UPDATE_LIST_CLEAR});
};
