import React from 'react';
import PropTypes from 'prop-types';
import {Button} from "reactstrap";
import {useHistory} from 'react-router-dom';
import {formatDisplayDatetime} from "../../../utils/datetime";
import {SOLUTION_VERSION_STATUS_READY} from "../../../config/solutionVersion";

const VersionState = (props) => {
    const history = useHistory();
    const {solutionVersion, solutionId, latestSolutionVersion} = props;

    if (!solutionId) {
        return <></>;
    }

    return (
        <div className="float-right pt-4 pr-4 d-flex align-items-center">
            <div className="pr-3">
                {!solutionVersion && latestSolutionVersion && !latestSolutionVersion.published ?
                    <>
                        Version created at {formatDisplayDatetime(latestSolutionVersion.created)}
                        {latestSolutionVersion.status === SOLUTION_VERSION_STATUS_READY &&
                        <div><b>(Pending publishing)</b></div>
                        }
                    </> : !solutionVersion && latestSolutionVersion && latestSolutionVersion.published ?
                        <>
                            Version published at {formatDisplayDatetime(latestSolutionVersion.published)}
                        </>
                        :
                        null
                }

                {solutionVersion && solutionVersion.published
                    ?
                    <>
                        Version published at {formatDisplayDatetime(solutionVersion.published)}
                    </>
                    : solutionVersion && !solutionVersion.published
                        ?
                        <>
                            Version created at {formatDisplayDatetime(solutionVersion.created)}
                        </>
                        :
                        null
                }

            </div>
            <Button
                color="outline-primary"
                onClick={() => history.push(`/admin/solutions/${solutionId}/versions/`)}
            >
                Versions
            </Button>
        </div>
    )
}

export default VersionState;

VersionState.propTypes = {
    solutionVersion: PropTypes.object,
    latestSolutionVersion: PropTypes.object,
    solutionId: PropTypes.any,
};
