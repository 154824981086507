import camelcaseKeys from "camelcase-keys";
import {
  MARKETPLACE_RESOURCE_LIST_FETCH_REQUEST,
  MARKETPLACE_RESOURCE_LIST_FETCH_SUCCESS,
  MARKETPLACE_RESOURCE_LIST_FETCH_ERROR,
  MARKETPLACE_RESOURCE_CHANGE_ORDER_ERROR,
  MARKETPLACE_RESOURCE_CHANGE_ORDER_REQUEST,
  MARKETPLACE_RESOURCE_CHANGE_ORDER_SUCCESS,
  MARKETPLACE_RESOURCE_ADD_REQUEST,
  MARKETPLACE_RESOURCE_ADD_ERROR,
  MARKETPLACE_RESOURCE_ADD_SUCCESS,
  MARKETPLACE_RESOURCE_EDIT_ERROR,
  MARKETPLACE_RESOURCE_EDIT_REQUEST,
  MARKETPLACE_RESOURCE_EDIT_SUCCESS,
  MARKETPLACE_RESOURCE_DELETE_ERROR,
  MARKETPLACE_RESOURCE_DELETE_REQUEST,
  MARKETPLACE_RESOURCE_DELETE_SUCCESS,
  MARKETPLACE_RESOURCE_FETCH_SUCCESS,
  MARKETPLACE_RESOURCE_FETCH_ERROR,
  MARKETPLACE_RESOURCE_FETCH_REQUEST,
  MARKETPLACE_RESOURCE_CLEAR_DETAIL,
  MARKETPLACE_RESOURCE_PUBLISH_REQUEST,
  MARKETPLACE_RESOURCE_PUBLISH_ERROR,
  MARKETPLACE_RESOURCE_PUBLISH_SUCCESS,
  MARKETPLACE_RESOURCE_STATIC_FILES_FETCH_REQUEST,
  MARKETPLACE_RESOURCE_STATIC_FILES_LIST_FETCH_SUCCESS, MARKETPLACE_RESOURCE_STATIC_FILES_LIST_FETCH_ERROR,
} from "../actions/actionTypes";

const initialState = {
  marketplaceResource: null,
  marketplaceResources: [],
  isLoading: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case MARKETPLACE_RESOURCE_ADD_REQUEST:
    case MARKETPLACE_RESOURCE_EDIT_REQUEST:
    case MARKETPLACE_RESOURCE_LIST_FETCH_REQUEST:
    case MARKETPLACE_RESOURCE_CHANGE_ORDER_REQUEST:
    case MARKETPLACE_RESOURCE_DELETE_REQUEST:
    case MARKETPLACE_RESOURCE_FETCH_REQUEST:
    case MARKETPLACE_RESOURCE_PUBLISH_REQUEST:
    case MARKETPLACE_RESOURCE_STATIC_FILES_FETCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };

    case MARKETPLACE_RESOURCE_LIST_FETCH_SUCCESS:
    case MARKETPLACE_RESOURCE_CHANGE_ORDER_SUCCESS:
      const data = camelcaseKeys(action.data, { deep: true });
      return {
        ...state,
        marketplaceResources: data,
        isLoading: false,
        error: action.error,
      };

    case MARKETPLACE_RESOURCE_LIST_FETCH_ERROR:
    case MARKETPLACE_RESOURCE_CHANGE_ORDER_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    case MARKETPLACE_RESOURCE_EDIT_ERROR:
    case MARKETPLACE_RESOURCE_ADD_ERROR:
    case MARKETPLACE_RESOURCE_DELETE_ERROR:
    case MARKETPLACE_RESOURCE_FETCH_ERROR:
    case MARKETPLACE_RESOURCE_PUBLISH_ERROR:
    case MARKETPLACE_RESOURCE_STATIC_FILES_LIST_FETCH_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    case MARKETPLACE_RESOURCE_STATIC_FILES_LIST_FETCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
      };

    case MARKETPLACE_RESOURCE_ADD_SUCCESS:
      return {
        ...state,
        marketplaceResources: [
          ...state.marketplaceResources,
          camelcaseKeys(action.data, { deep: true }),
        ],
        marketplaceResource: action.data
          ? camelcaseKeys(action.data, { deep: true })
          : null,
        isLoading: false,
        error: null,
      };

    case MARKETPLACE_RESOURCE_DELETE_SUCCESS:
    case MARKETPLACE_RESOURCE_EDIT_SUCCESS:
      return {
        ...state,
        marketplaceResources: state.marketplaceResources
          ? state.marketplaceResources.map((marketplaceResource) =>
              marketplaceResource.id === action.data.id
                ? camelcaseKeys(action.data, { deep: true })
                : marketplaceResource
            )
          : null,
        marketplaceResource: action.data
          ? camelcaseKeys(action.data, { deep: true })
          : null,
        isLoading: false,
        error: null,
      };

    case MARKETPLACE_RESOURCE_FETCH_SUCCESS:
      return {
        ...state,
        marketplaceResource: action.data
          ? camelcaseKeys(action.data, { deep: true })
          : null,
        isLoading: false,
        error: null,
      };

    case MARKETPLACE_RESOURCE_CLEAR_DETAIL:
      return {
        ...state,
        marketplaceResource: null,
        isLoading: false,
        error: null,
      };

    case MARKETPLACE_RESOURCE_PUBLISH_SUCCESS:
      if (action.data.delete_requested) {
        return {
          ...state,
          marketplaceResources: state.marketplaceResources
            ? state.marketplaceResources.filter(
                (marketplaceResource) =>
                  marketplaceResource.id !== action.data.id
              )
            : null,
          isLoading: false,
          error: null,
        };
      }
      return {
        ...state,
        marketplaceResources: state.marketplaceResources
          ? state.marketplaceResources.map((marketplaceResource) =>
              marketplaceResource.id === action.data.id
                ? camelcaseKeys(action.data, { deep: true })
                : marketplaceResource
            )
          : null,
        isLoading: false,
        error: null,
      };

      
    default:
      return state;
  }
};
