import React from 'react';
import { Switch, Route } from 'react-router-dom';

import CustomerDetail from './CustomerDetail';
import CustomerEditNew from './CustomerEditNew'
import CustomerEntities from './CustomerEntities';
import CustomerList from './CustomerList';
import FleetEditNew from './fleets/FleetEditNew';


const Customer = () => {
    return (
        <Switch>
            <Route key="1" path="/admin/customers" exact component={CustomerList} />
            <Route key="2" path="/admin/customers/new" exact component={CustomerEditNew} />
            <Route key="3" path="/admin/customers/fleet/new/" exact component={FleetEditNew} />
            <Route key="4" path="/admin/customers/fleet/:fleetId/edit/" exact component={FleetEditNew} />
            <Route key="5" path="/admin/customers/:subscriptionId/" exact component={CustomerDetail} />
            <Route key="6" path="/admin/customers/:subscriptionId/edit" exact component={CustomerEditNew} />
            <Route key="7" path="/admin/customers/:subscriptionId/entities" exact component={CustomerEntities} />
            <Route key="8" path="/admin/customers/:subscriptionId/fleet/:fleetId/edit/" exact component={FleetEditNew} />
        </Switch>
    )
};

export default Customer;
