import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Slider from "react-slick";
import Lightbox from "react-18-image-lightbox";
import Pluralize from "pluralize";
import { HiDotsVertical } from 'react-icons/hi';

import {
  clearSolutionDetail,
  cloneSolution,
  deleteSolutionDetail,
  fetchSolutionDetail
} from "../../store/actions/solutionDetailActions";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  NavItem,
  NavLink,
  Nav,
  Row,
  TabContent,
  TabPane, Label, FormGroup, UncontrolledTooltip, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem,
} from "reactstrap";
import MultilineText from "../../components/MultilineText";
import EmbeddedAlert from "../../components/EmbeddedAlert";
import PropTypes from "prop-types";
import {getYouTubeId} from "../../services/youtube";
import {
  ENTITY_TYPE_OPTIONS,
  SOLUTION_1_OTC_SHIPPING_TYPE_NO_SHIPPING_VALUE,
  SOLUTION_2_OTC_SHIPPING_TYPE_NO_SHIPPING_VALUE,
  SOLUTION_TYPE_PAID_VALUE
} from "../../config/solution";
import YouTube from "../../components/YouTube";
import {SUBSCRIPTION_PAYMENT_METHOD_INTERVALS} from "../../config/subscription";
import Currency from "../../components/Currency";
import VersionState from "./version/VersionState";
import {
  clearSolutionVersionDetail,
  fetchSolutionVersionDetail
} from "../../store/actions/solutionVersionActions";
import RestoreButton from "./components/restoreButton";
import CircleBorder from "../../components/CircleBorder";
import FleetIcon from "../../assets/icons/icon-fleet-size.svg";
import FillIcon from "../../assets/icons/fill-1.svg";
import VehiclesIcon from "../../assets/icons/icon-vehicles.svg";
import {addMessage} from "../../store/actions/messageActions";
import {Auth0Context} from "../../services/auth/react-auth0-wrapper";

class SolutionDetail extends React.Component {
  static contextType = Auth0Context;

  constructor(props) {
    super(props);
    this.state = {
      tab: "listing",
      currentImg: 0,
      isOpenGallery: false,
      showModal: false
    };
  }

  componentDidMount() {
    const {
      match: {
        params: {
          solutionId,
          versionId
        }
      }
    } = this.props;
    this.props.fetchSolutionDetail(solutionId, versionId);
    if (versionId) {
      this.props.fetchSolutionVersionDetail(versionId);
    }
  }

  componentWillUnmount() {
    const {
      match: {
        params: {
          solutionId,
        }
      },
      clearSolutionVersionDetail,
    } = this.props;

    clearSolutionVersionDetail()
  }


  toggleModal() {
    this.setState(prevState => ({
      showModal: !prevState.showModal
    }));
  }

  render() {
    const { account, settings } = this.props;
    const { user } = this.context;

    const carouselSettings = {
      infinite: true,
      centerMode: true,
      centerPadding: '60px',
      slidesToShow: 3,
      slidesToScroll: 1,
      speed: 350,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 800,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          }
        }
      ]
    };
    const {
      currentImg: photoIndex,
      isOpenGallery: isOpen
    } = this.state;
    const {
      match: {
        params: {
          solutionId,
          versionId
        }
      }
    } = this.props;
    const {
      solutionDetail: {
        solution: {
          name,
          description,
          fleetSegments,
          vehicleClasses,
          companyDescription,
          companyName,
          customerSizeFrom,
          customerSizeTo,
          firstOneTimePrice,
          firstOneTimeSubDescription,
          secondOneTimePrice,
          secondOneTimeSubDescription,
          summary,
          supportEmail,
          supportPhoneNumber,
          internalName,
          leadsContactFirstName,
          leadsContactSecondName,
          leadsContactEmail,
          accessUrl,
          marketplaces,
          startSubscriptionPrice,
          unit,
          solutionLogo,
          solutionAdditionalResources,
          solutionMediaResources,
          solutionMarketingResources,
          firstOneTimeShippingType,
          secondOneTimeShippingType,
          featureAndBenefits,
          // solutionDataPermissions,
          isTrialEnabled,
          isDirectCheckoutEnabled,
          legalTerms,
          showSupportPhoneNumber,
          paymentInterval,
          primaryDiscount,
          subscriptionDiscounts,
          otc1Discounts,
          otc2Discounts,
          nonMonetaryDiscounts,
          promotion,
          trialPeriodDays,
          otc1DiscountAmount,
          otc2DiscountAmount,
          solutionType,
          currency,
          latestSolutionVersion,
          tags,
        }
      },
      solutionVersionDetail: {solutionVersion}
      // dataTypeClasses
    } = this.props;

    // const solutionDataPermissionIds = solutionDataPermissions ? solutionDataPermissions.map(item => item.id) : [];

    // let dataPermissionObjects = [];
    // if (solutionDataPermissions) {
    //   dataPermissionObjects = dataTypeClasses.filter(item => solutionDataPermissionIds.includes(item.id));
    // }

    const readOnly = !!versionId;

    return (
        <>


          <div className="breadcrumb-nav">
            <Row>
              <Col>
                <ol className="breadcrumb-container">
                  <li className="breadcrumb-container__item">
                    <h5>
                      <Link to="/admin/solutions">Solutions</Link>
                    </h5>
                  </li>
                  <li className="breadcrumb-container__item">
                    <h5>/</h5>
                  </li>
                  <li className="breadcrumb-container__item">
                    <h5>{name}</h5>
                  </li>
                </ol>
              </Col>
            </Row>
            <VersionState
                solutionVersion={solutionVersion}
                solutionId={solutionId}
                latestSolutionVersion={latestSolutionVersion}
            />
          </div>
          <div className="content solution-detail-page">
            <div className="pt-3 c-solution-alert">
              <EmbeddedAlert />
            </div>
            <div>
              <div className="nav-tabs-navigation">
                <div className="nav-tabs-wrapper">
                  {user.scope.includes('SuperAdmin') &&
                    <div className='float-right pt-3 mr-3'>
                      <UncontrolledDropdown group>
                        <DropdownToggle tag="div" className="nav-link">
                          <span role="button">
                            <HiDotsVertical size={25} />
                          </span>
                        </DropdownToggle>
                        <DropdownMenu right className='dropdown-menu'>
                            <DropdownItem
                                onClick={() => {
                                    this.props.clearSolutionDetail();
                                    this.props.history.push(`/admin/solutions/listing-new`)
                                }}
                            >
                                Add new
                            </DropdownItem>
                          <DropdownItem
                              onClick={() => {
                                this.props.cloneSolution(solutionId).then(() => {
                                  this.props.history.push(`/admin/solutions/`)
                                  this.props.addMessage("Your solution has been successfully cloned.");
                                });
                              }}
                          >
                            Clone
                          </DropdownItem>
                          <DropdownItem onClick={() => {
                            this.props.deleteSolutionDetail(solutionId).then((res) => {
                              if (res) {
                                this.props.history.push(`/admin/solutions/`)
                                this.props.addMessage("Your solution has been deleted successfully.");
                              }
                            })
                          }}>
                            Delete
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  }
                  <Nav id="tabs" role="tablist" tabs>
                    <NavItem>
                      <NavLink
                          aria-expanded={this.state.tab === "listing"}
                          data-toggle="tab"
                          href="#listing"
                          role="tab"
                          className={
                            this.state.tab === "listing"
                                ? "active"
                                : ""
                          }
                          onClick={() =>
                              this.setState({ tab: "listing" })
                          }
                      >
                        Listing
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                          aria-expanded={
                            this.state.tab === "info"
                          }
                          data-toggle="tab"
                          href="#info"
                          role="tab"
                          className={
                            this.state.tab === "info"
                                ? "active"
                                : ""
                          }
                          onClick={() =>
                              this.setState({ tab: "info" })
                          }
                      >
                        Info
                      </NavLink>
                    </NavItem>
                  </Nav>
                </div>
              </div>
              <TabContent
                  id="my-tab-content"
                  activeTab={this.state.tab}
              >
                <TabPane tabId="listing" role="tabpanel">
                  <div className="page-content-header">
                    <Row>
                      <Col>
                        <h3>View and Edit Listing</h3>
                      </Col>
                      <Col>
                        {!readOnly && account.permissions.includes("write:solutions") &&
                        <Button
                            className="pull-right"
                            color="primary"
                            onClick={() => this.props.history.push(`/admin/solutions/${solutionId}/listing-edit`)}
                        >
                          Edit Listing
                        </Button>
                        }
                        <RestoreButton solutionId={solutionId} versionId={versionId} />
                      </Col>
                    </Row>
                  </div>
                  <div className="solution-intro section">
                    <Row>
                      <Col md="8" xl="8" className="short-description">
                        <div className="d-flex flex-row">
                          <div style={{minWidth: 206}} className="mr-3">
                            {solutionLogo &&
                            <img src={solutionLogo} alt="Solution Logo" />
                            }
                          </div>
                          <div>
                            <h5><strong>{name}</strong></h5>
                            <MultilineText text={summary} />
                          </div>
                        </div>
                      </Col>
                      <Col md="4" xl="4">
                        <h6 className="text-capitalize">
                          Category
                        </h6>
                        <p>
                          Per marketplace
                        </p>
                        <h6 className="text-capitalize">
                          Company
                        </h6>
                        <p>
                          {companyName}
                        </p>

                        {startSubscriptionPrice &&
                        <div className="d-flex">
                          <div className="bg-white px-4 py-3">
                            <h6>Our Price</h6>
                            {solutionType === SOLUTION_TYPE_PAID_VALUE ?
                                <>
                                  <div>
                                    Starting at
                                  </div>
                                  <div className="d-flex mt-1">
                                    <div>
                                      <h4 className="font-weight-bold m-0">
                                        {startSubscriptionPrice && (<Currency currency={currency} value={startSubscriptionPrice - (primaryDiscount ? primaryDiscount.amountCalculated : 0)} />)}
                                      </h4>
                                    </div>
                                    {primaryDiscount && primaryDiscount.amountFormatted &&
                                    <>
                                      <div className="pl-2">
                                        <h4 className="m-0" style={{textDecoration: "line-through", color: "#999999"}}>
                                          <Currency currency={currency} value={startSubscriptionPrice} />
                                        </h4>
                                      </div>
                                      <div>
                                        <div className="arrow-left ml-2" />
                                        <div className="arrow-body text-capitalize text-white pl-2" id='entity-tooltip-percentage'>
                                          Save {primaryDiscount.percentageFormatted}%
                                        </div>
                                        <UncontrolledTooltip placement="bottom" target={`entity-tooltip-percentage`}>
                                          {primaryDiscount.name}
                                        </UncontrolledTooltip>
                                      </div>
                                    </>
                                    }
                                  </div>
                                  <div className="text-lowercase font-weight-bold mb-2">
                                    per {ENTITY_TYPE_OPTIONS[unit]}, {SUBSCRIPTION_PAYMENT_METHOD_INTERVALS[paymentInterval]}
                                  </div>
                                </>
                                : <h4 className='mb-0 mt-3 text-capitalize'>Free</h4>}

                            {settings.promotions && promotion && promotion.name &&
                            <>
                              <div id='promotion-tooltip' className='price-promotion'>
                                {promotion.name}
                                <i className="fa fa-angle-down fa-lg ml-4" />
                              </div>
                              <UncontrolledTooltip placement="bottom" target={`promotion-tooltip`}>
                                <div className='p-2 text-left'>
                                  <div
                                      dangerouslySetInnerHTML={{
                                        __html: promotion.description
                                      }} />
                                </div>
                              </UncontrolledTooltip>
                            </>
                            }

                            {solutionType === SOLUTION_TYPE_PAID_VALUE &&
                            <a
                                href="#section"
                                className="join-btn text-secondary"
                                onClick={this.toggleModal.bind(this)}
                            >
                              <u>Price details</u>
                            </a>
                            }
                          </div>
                        </div>
                        }
                      </Col>
                    </Row>
                  </div>

                  <div className="solution-description section">
                    <Row>
                      <Col md="2" className="d-flex align-items-center">
                        <h2>
                          Who It's For
                        </h2>
                      </Col>
                      <Col md="1" className="d-flex align-items-center">
                        <div className="divider" />
                      </Col>
                      <Col md="3">
                        <div className="d-flex align-items-center py-3">
                          <CircleBorder size={35} backgroundColor={"#000000"}>
                            <img alt="Fleet Size" src={FleetIcon} />
                          </CircleBorder>
                          <h4 className="m-0" style={{paddingLeft: "10px"}}>
                            Fleet Size
                          </h4>
                        </div>
                        <p className="pl-5">
                          {unit && customerSizeFrom &&
                          <>
                            {Pluralize(ENTITY_TYPE_OPTIONS[unit], customerSizeFrom, true)} and Larger
                          </>
                          }
                        </p>
                      </Col>
                      <Col md="3">
                        <div className="d-flex align-items-center py-3">
                          <CircleBorder size={35} backgroundColor={"#000000"}>
                            <img alt="Fleet Segments" src={FillIcon} />
                          </CircleBorder>
                          <h4 className="m-0" style={{paddingLeft: "10px"}}>
                            Fleet Segments
                          </h4>
                        </div>
                        <div className="pl-5">
                          {fleetSegments}
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="d-flex align-items-center py-3">
                          <CircleBorder size={35} backgroundColor={"#000000"}>
                            <img alt="Vehicle Classes" src={VehiclesIcon} />
                          </CircleBorder>
                          <h4 className="m-0" style={{paddingLeft: "10px"}}>
                            Vehicle Classes
                          </h4>
                        </div>
                        <div className="pl-5">
                          {vehicleClasses}
                        </div>
                      </Col>
                    </Row>
                  </div>

                  <Row className="solution-description section">
                    <Col>
                      <h2>Description</h2>
                      <MultilineText text={description} className="text" />
                    </Col>
                  </Row>
                  {isOpen && solutionMediaResources && solutionMediaResources.length > 0 && (
                      <Lightbox
                          mainSrc={solutionMediaResources[photoIndex].resourceFileUrl || "https://img.youtube.com/vi/" + getYouTubeId(solutionMediaResources[photoIndex].resourceLink) + "/0.jpg"}
                          nextSrc={solutionMediaResources[(photoIndex + 1) % solutionMediaResources.length].resourceFileUrl || "https://img.youtube.com/vi/" + getYouTubeId(solutionMediaResources[photoIndex].resourceLink) + "/0.jpg"}
                          prevSrc={solutionMediaResources[(photoIndex + solutionMediaResources.length - 1) % solutionMediaResources.length].resourceFileUrl || "https://img.youtube.com/vi/" + getYouTubeId(solutionMediaResources[photoIndex].resourceLink) + "/0.jpg"}
                          onCloseRequest={() => this.setState({ isOpenGallery: false })}
                          onMovePrevRequest={() =>
                              this.setState({
                                currentImg: (photoIndex + solutionMediaResources.length - 1) % solutionMediaResources.length
                              })
                          }
                          onMoveNextRequest={() =>
                              this.setState({
                                currentImg: (photoIndex + 1) % solutionMediaResources.length
                              })
                          }
                      />
                  )}
                  <Row className="solution-carousel section">
                    {
                      solutionMediaResources && solutionMediaResources.length <= 3 &&
                      solutionMediaResources.map(
                          ({ resourceLink, resourceFileUrl }, index) => {
                            const youTubeId = getYouTubeId(resourceLink);
                            return (
                                <Col md="3" key={index} className="pr-3">
                                  {youTubeId ? <YouTube youTubeId={youTubeId} title={index} />
                                      : (
                                          <img
                                              style={{cursor: "pointer"}}
                                              className="solution-carousel-img"
                                              src={resourceFileUrl}
                                              alt=""
                                              onClick={
                                                (event) => {
                                                  this.setState({
                                                    currentImg: index,
                                                    isOpenGallery: true
                                                  });
                                                  event.stopPropagation();
                                                }
                                              }
                                          />
                                      )
                                  }
                                </Col>
                            );
                          }
                      )
                    }
                    {
                      solutionMediaResources && solutionMediaResources.length > 3 &&
                      (
                          <Col>
                            <Slider {...carouselSettings} className="m-4">
                              {
                                solutionMediaResources.map(
                                    ({ resourceLink, resourceFileUrl }, index) => {
                                      const youTubeId = getYouTubeId(resourceLink);
                                      return (
                                          <div className="pr-3" key={index}>
                                            {youTubeId ? <YouTube youTubeId={youTubeId} title={`${name} (${index})`} />
                                                : (
                                                    <img src={resourceFileUrl}
                                                         style={{cursor: "pointer"}}
                                                         className="solution-carousel-img"
                                                         key={index}
                                                         alt=""
                                                         onClick={
                                                           (event) => {
                                                             this.setState({
                                                               currentImg: index,
                                                               isOpenGallery: true
                                                             });
                                                             event.stopPropagation();
                                                           }
                                                         }
                                                    />
                                                )
                                            }
                                          </div>
                                      );
                                    }
                                )
                              }
                            </Slider>
                          </Col>
                      )
                    }
                  </Row>
                  <div className="solution-features-benefits section">
                    <Row>
                      <Col>
                        <h2>Features and Benefits</h2>
                      </Col>
                    </Row>
                    <Row>
                      {featureAndBenefits && featureAndBenefits.map(
                          (featureAndBenefit, idx) => (
                              <Col md="6" key={idx}>
                                <div className="solution-features-benefits__item">
                                  <i className="ti-check-box" />
                                  <p>
                                    {featureAndBenefit}
                                  </p>
                                </div>
                              </Col>
                          )
                      )}

                    </Row>
                  </div>
                  <div className="solution-about-resource section">
                    <Row>
                      <Col md={6}>
                        <h2>About {companyName}</h2>
                        <MultilineText text={companyDescription} />
                      </Col>
                      {solutionAdditionalResources && solutionAdditionalResources.length > 0 &&
                      <Col md={6} className='mt-sm-3'>
                        <h2>Additional Resources</h2>
                        Download documents
                        <ul>
                          {
                            solutionAdditionalResources.map(
                                ({ resourceFileName, resourceFileUrl, resourceTitle, resourceLink }, idx) => (
                                    <li key={idx} className='text-truncate'>
                                      {resourceFileUrl ? (
                                          <a href={resourceFileUrl} download={resourceFileName} rel="noopener noreferrer" target="_blank">
                                            {resourceFileName}
                                          </a>
                                      ) : (
                                          <a href={resourceLink} rel="noopener noreferrer" target="_blank">
                                            {resourceTitle}
                                          </a>
                                      )}
                                    </li>
                                ))
                          }
                        </ul>
                      </Col>
                      }
                    </Row>
                  </div>
                </TabPane>
                <TabPane tabId="info" role="tabpanel">
                  <div className="page-content-header">
                    <Row>
                      <Col>
                        <h3>
                          View and Edit Info
                        </h3>
                      </Col>
                      <Col>
                        {!readOnly && account.permissions.includes("write:solutions") &&
                        <Button
                            className="pull-right"
                            color="primary"
                            onClick={() => this.props.history.push(`/admin/solutions/${solutionId}/info-edit`)}
                        >
                          Edit Info
                        </Button>
                        }

                        <RestoreButton solutionId={solutionId} versionId={versionId} />

                      </Col>
                    </Row>
                  </div>
                  <Row className="info-detail">
                    <Col>
                      <Card>
                        <CardHeader>
                          <h6>Summary</h6>
                        </CardHeader>
                        <CardBody>
                          <h6>Internal name</h6>
                          <p>{internalName}</p>
                          <h6>Access URL</h6>
                          <p>{accessUrl}</p>
                        </CardBody>
                      </Card>
                      <Card>
                        <CardHeader>
                          <h6>Leads Contact</h6>
                        </CardHeader>
                        <CardBody>
                          <p>{leadsContactFirstName} {leadsContactSecondName}</p>
                          <p>{leadsContactEmail}</p>
                        </CardBody>
                      </Card>
                      <Card>
                        <CardHeader>
                          <h6>Sales Enablement Materials</h6>
                        </CardHeader>
                        <CardBody>
                          <ul>
                            {
                              solutionMarketingResources && solutionMarketingResources.map(
                                  ({resourceFileName, resourceFileUrl}, idx) => (
                                      <li key={idx}>
                                        <a href={`${resourceFileUrl}`}>
                                          {resourceFileName}
                                        </a>
                                      </li>
                                  )
                              )
                            }
                          </ul>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col>
                      <Card>
                        <CardHeader>
                          <h6>Marketplace Categories</h6>
                        </CardHeader>
                        <CardBody>
                          {
                            marketplaces && marketplaces.map(({
                                                                marketplace: {
                                                                  name: marketplaceName,
                                                                  host: {
                                                                    name: hostName
                                                                  }
                                                                }, categories
                                                              }, idx) => (
                                <p key={idx}>
                                  <b>{hostName} - {marketplaceName}: </b>
                                  {
                                    categories &&
                                    categories.map(
                                        (category) => category.name
                                    ).join(', ')
                                  }
                                </p>
                            ))
                          }
                        </CardBody>
                      </Card>
                      <Card>
                        <CardHeader>
                          <h6>Support Access</h6>
                        </CardHeader>
                        <CardBody>
                          <p>{supportEmail ? supportEmail : 'Email not specified'}</p>
                          <p>
                            {supportPhoneNumber ? supportPhoneNumber : 'Phone not specified'}

                            {showSupportPhoneNumber ?
                                <span className="pl-3"><i><b>Visible to customers</b></i></span> :
                                <span className="pl-3"><i><b>Invisible to customers</b></i></span>
                            }

                          </p>



                        </CardBody>
                      </Card>
                      {/*TODO Just removing the data related UI, it needs to be implemented later */}
                      {/* <Card>
                      <CardHeader>
                        <h6>Data Access</h6>
                      </CardHeader>
                      <CardBody>
                        <p>Data Access Required:</p>
                        <h6>Drivers</h6>
                        <ul>
                          {
                            dataPermissionObjects.filter(item => item.category === "Drivers").map(
                              ({name}) => (
                                <li>{name}</li>
                              )
                            )
                          }
                        </ul>
                        <h6>Assets</h6>
                        <ul>
                          {
                            dataPermissionObjects.filter(item => item.category === "Assets").map(
                              ({name}) => (
                                <li>{name}</li>
                              )
                            )
                          }
                        </ul>
                        <h6>Vehicles</h6>
                        <ul>
                          {
                            dataPermissionObjects.filter(item => item.category === "Vehicles").map(
                              ({name}, i) => (
                                <li key={i}>{name}</li>
                              )
                            )
                          }
                        </ul>
                      </CardBody>
                    </Card> */}
                      <Card>
                        <CardHeader>
                          <h6>Marketplace</h6>
                        </CardHeader>
                        <CardBody>
                          <Checkbox
                              isChecked={!!isTrialEnabled}
                              labelText="Show Free Trial and enable Request for Free Trial"
                          />
                          {settings.freeTrialLength &&
                          <p className="mb-4"><b>Trial:</b> {Pluralize('day', parseInt(trialPeriodDays), true)}</p>
                          }
                          <Checkbox
                              isChecked={!!isDirectCheckoutEnabled}
                              labelText="Show Buy option and enable direct checkout"
                          />
                          <h6>Subscription Terms & Conditions</h6>
                          <Input
                              className="p-2"
                              readOnly
                              type="textarea"
                              value={legalTerms ? legalTerms : ""}
                          />
                          <h6 className='pt-3'>Search Tags</h6>
                          {tags && tags.map((tag, idx) => {
                            return <div className='react-tagsinput-tag no-pointer' key={idx}>{tag}</div>
                          })}

                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </TabPane>
              </TabContent>
              <Modal size="lg" isOpen={this.state.showModal}>
                <ModalHeader>
                  <h4 className="m-0">Price</h4>
                </ModalHeader>
                <ModalBody className="mt-4 p-3">
                  <p>
                    Starting prices apply to fleets with {customerSizeFrom} to {customerSizeTo} {ENTITY_TYPE_OPTIONS[unit]}s. <br />
                    For volume pricing contact us.
                  </p>
                  <Card>
                    <CardBody>
                      <Row>
                        <Col>
                          <h5 className="font-weight-bold">
                            Subscription
                          </h5>
                        </Col>
                        <Col className="mb-4">
                          <h5 className="mb-2">
                            Starting at
                          </h5>
                          {paymentInterval &&
                          <h4 className="text-nowrap mt-0">
                            {startSubscriptionPrice && (<Currency currency={currency} value={startSubscriptionPrice - (primaryDiscount ? primaryDiscount.amountCalculated : 0)} />)} / {ENTITY_TYPE_OPTIONS[unit]}, {SUBSCRIPTION_PAYMENT_METHOD_INTERVALS[paymentInterval].toLowerCase()}
                          </h4>
                          }

                          {subscriptionDiscounts && subscriptionDiscounts.map((discount, index) => {
                            return (
                                <div className="mb-1" key={index}>
                                  <span className="mr-1">{discount.name}</span>
                                  <span className="mr-1">{discount.percentage && <>{discount.percentageFormatted}%</>}</span>
                                  {discount.amount && <Currency currency={currency} value={discount.amountFormatted} />}
                                </div>
                            )
                          })}

                        </Col>
                      </Row>
                      {firstOneTimePrice ?
                          (<Row>
                            <Col>
                              <h5 className="font-weight-bold">
                                One-time costs
                              </h5>
                            </Col>
                            <Col className="mb-2">
                              {firstOneTimeSubDescription &&
                              <h5 className="mb-2">
                                {firstOneTimeSubDescription}
                              </h5>
                              }
                              {firstOneTimePrice &&

                              <h4 className="text-nowrap mt-0">
                                <Currency currency={currency} value={firstOneTimePrice - (otc1DiscountAmount || 0)} /> / {ENTITY_TYPE_OPTIONS[unit]}
                                {firstOneTimeShippingType !== SOLUTION_1_OTC_SHIPPING_TYPE_NO_SHIPPING_VALUE && (
                                    <span> + shipping</span>
                                )}
                              </h4>

                              }

                              {otc1Discounts && otc1Discounts.map((discount, index) => {
                                return (
                                    <div className="mb-1" key={index}>
                                      <span className="mr-1">{discount.name}</span>
                                      <span className="mr-1">{discount.percentage && <>{discount.percentageFormatted}%</>}</span>
                                      {discount.amount &&  <Currency currency={currency} value={discount.amountFormatted} />}
                                    </div>
                                )
                              })}

                              {secondOneTimeSubDescription &&
                              <h5 className="mt-4 mb-2">
                                {secondOneTimeSubDescription}
                              </h5>
                              }
                              {secondOneTimePrice ?
                                  (
                                      <>
                                        <h4 className="text-nowrap mt-0 mb-2">
                                          <Currency currency={currency} value={secondOneTimePrice - (otc2DiscountAmount || 0)} /> / {ENTITY_TYPE_OPTIONS[unit]}
                                          {secondOneTimeShippingType !== SOLUTION_2_OTC_SHIPPING_TYPE_NO_SHIPPING_VALUE && (
                                              <span> + shipping</span>
                                          )}
                                        </h4>

                                        {otc2Discounts && otc2Discounts.map((discount, index) => {
                                          return (
                                              <div className="mb-1" key={index}>
                                                <span className="mr-1">{discount.name}</span>
                                                <span className="mr-1">{discount.percentage && <>{discount.percentageFormatted}%</>}</span>
                                                {discount.amount &&  <Currency currency={currency} value={discount.amountFormatted} />}
                                              </div>
                                          )
                                        })}
                                      </>
                                  ) :
                                  (null)
                              }
                            </Col>
                          </Row>) : null
                      }

                      {nonMonetaryDiscounts && nonMonetaryDiscounts.length > 0 ?
                          (
                              <>
                                <Row>
                                  <Col className="mb-2" />
                                  <Col>
                                    <hr />
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <h5 className="font-weight-bold">
                                      Included with your <br />subscription
                                    </h5>
                                  </Col>
                                  <Col className="mb-2">
                                    {nonMonetaryDiscounts && nonMonetaryDiscounts.map((discount, index) => {
                                      return (
                                          <div className="mb-1" key={index}>
                                            <span className="mr-1">{discount.name}</span>
                                            <span className="mr-1">{discount.percentage && <>{discount.percentageFormatted}%</>}</span>
                                            {discount.amount &&  <Currency currency={currency} value={discount.amountFormatted} />}
                                          </div>
                                      )
                                    })}
                                  </Col>
                                </Row>
                              </>) : null
                      }

                    </CardBody>
                  </Card>
                </ModalBody>
                <ModalFooter className="d-flex justify-content-center">
                  <Button color="primary" onClick={this.toggleModal.bind(this)}>
                    Close
                  </Button>
                </ModalFooter>
              </Modal>
            </div>
          </div>
        </>
    );
  }
}

const checkboxPropTypes = {
  isChecked: PropTypes.bool.isRequired,
  labelText: PropTypes.string.isRequired,
};

function Checkbox(props) {
  return (
      <FormGroup check>
        <Label check>
          <Input
              disabled={true}
              type="checkbox"
              checked={props.isChecked}
          />
          <span className="form-check-sign"/>
          <p style={{fontSize: "14px", color: "#252422"}}>
            {props.labelText}
          </p>
        </Label>
      </FormGroup>
  )
}

Checkbox.propTypes = checkboxPropTypes;

const mapStateToProps = state => ({
  solutionDetail: state.solutionDetail,
  solutionVersionDetail: state.solutionVersionDetail,
  dataTypeClasses: state.dataList.dataTypeClasses,
  account: state.account,
  settings: state.settings
});

const mapDispatchToProps = {
  fetchSolutionDetail,
  fetchSolutionVersionDetail,
  clearSolutionVersionDetail,
  cloneSolution,
  addMessage,
  deleteSolutionDetail,
  clearSolutionDetail,
};

export default connect(mapStateToProps, mapDispatchToProps)(SolutionDetail);

// Exports for easy testing
export {Checkbox};
