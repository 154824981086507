import React from "react";
import PropTypes from "prop-types";

class YouTube extends React.Component {
  render() {
    const { youTubeId, title } = this.props;
    return (
      <iframe
        title={title} frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" width="240" height="130"
        src={`https://www.youtube.com/embed/${youTubeId}?autoplay=0&fs=0&iv_load_policy=3&showinfo=0&rel=0&cc_load_policy=0&start=0&end=0`}>
      </iframe>
    );
  }
}

YouTube.propTypes = {
  youTubeId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

export default YouTube;
