import React from "react";
import { connect } from "react-redux";
import { Button, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";

class FleetSubscriptionDetail extends React.Component {
  render() {
    const { subscription, account } = this.props;
    const { fleet } = subscription;

    return (
      <>
        <div className="d-flex align-items-center">
          <div className="flex-grow-1"><h6>Company contact info</h6></div>
          <div>
            <Link to={`/admin/customers/${subscription.id}/fleet/${fleet.id}/edit/`}>
              <Button
                className="float-right mb-3"
                color="outline-primary"
                type="button"
              >
                {account.mode === 'Host' ? "View info" : "Edit info"}
              </Button>
            </Link>
          </div>
        </div>
        <Card>
          <CardBody>
            {fleet && fleet.id &&
              <>
                <div className="d-flex justify-content-between">
                  <p>{`${fleet.customerFirstName} ${fleet.customerLastName}`}</p>
                </div>
                <p>{fleet.customerEmail}</p>
                <p>{fleet.customerPhoneNumber}</p>
                <p>
                  {fleet.companyAddress} {fleet.companyAddress2}
                </p>
                <p>
                  {fleet.companyCity && `${fleet.companyCity}`}
                  {fleet.companyState && `, ${fleet.companyState}`}
                  {fleet.companyZipCode && ` ${fleet.companyZipCode}`}
                </p>
              </>
            }
          </CardBody>
        </Card>
      </>
    );
  }
}

const mapStateToProps = state => ({
  subscription: state.subscriptionDetail.subscription,
  account: state.account,
});

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(FleetSubscriptionDetail);
