/* eslint-disable linebreak-style */
import axios from 'axios';
import {
  DATA_TYPE_CLASS_LIST_FETCH_REQUEST,
  DATA_TYPE_CLASS_LIST_FETCH_SUCCESS,
  DATA_TYPE_CLASS_LIST_FETCH_ERROR,
} from './actionTypes';
import { updateSpinner } from "./spinnerActions";


export const fetchDataTypeClassList = () => (dispatch) => {
  dispatch(updateSpinner(true));
  dispatch({ type: DATA_TYPE_CLASS_LIST_FETCH_REQUEST });
  let url = `/api/data/data-type-classes/`;

  axios.get(url)
  .then(({ data }) => {
    if (data) {
      dispatch({ type: DATA_TYPE_CLASS_LIST_FETCH_SUCCESS, data });
    } else {
      dispatch({ type: DATA_TYPE_CLASS_LIST_FETCH_ERROR, error: { mes: "" }});
    }
    dispatch(updateSpinner(false));
  })
  .catch((error) => {
    dispatch({ type: DATA_TYPE_CLASS_LIST_FETCH_ERROR, error });
    dispatch(updateSpinner(false));
  });
};
