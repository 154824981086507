/* eslint-disable linebreak-style */
import axios from 'axios';
import {
  METRICS_FETCH_REQUEST,
  METRICS_FETCH_SUCCESS,
  METRICS_FETCH_ERROR,
} from './actionTypes';
import { updateSpinner } from "./spinnerActions";


export const fetchMetrics = () => (dispatch, state) => {
  dispatch(updateSpinner(true));
  dispatch({ type: METRICS_FETCH_REQUEST , request: true});

  const { mode } = state().account;
  const url = `/api/core/metrics/?mode=${mode}`;

  axios.get(url)
  .then(({ data }) => {
    if (data) {
      dispatch({ type: METRICS_FETCH_SUCCESS, data });
    } else {
      dispatch({ type: METRICS_FETCH_ERROR, error: { mes: "" }});
    }
    dispatch(updateSpinner(false));
  })
  .catch((error) => {
    dispatch({ type: METRICS_FETCH_ERROR, error });
    dispatch(updateSpinner(false));
  });
};
