import moment from "moment";

export const formatDisplayDatetime = (dateTime) => {
  return moment(dateTime).format('MM/DD/YY h:mm A');
};

export const formatDisplayDatetimeShort = (dateTime) => {
  return moment(dateTime).format('MM/DD/YY');
};

export const formatDatetimeAsLocalDate = (dateTime) => {
  return moment(dateTime).format('MMMM DD, YYYY');
};
