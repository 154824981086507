import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Row, Col } from "reactstrap";
import snakeCaseKeys from "snakecase-keys";

import SolutionInfoEditNewForm from "../forms/SolutionInfoEditNewForm";

import {
  addSolutionDetail,
  fetchSolutionDetail,
  updateSolutionDetail
} from "../../store/actions/solutionDetailActions";
import { addMessage } from "../../store/actions/messageActions";
import {SOLUTION_STATUS_OPEN, SOLUTION_STATUS_READY} from "../../config/solution";
import {clearSolutionVersionDetail, fetchSolutionVersionDetail} from "../../store/actions/solutionVersionActions";
import VersionState from "./version/VersionState";


class SolutionInfoEditNew extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      draft: false,
      addedMarketingResources: [],
      removedMarketingResources: [],
      filesSize: 0,
      tags: [],
    };
    this.form = React.createRef();
    this.submitInfoForm.bind(this);
  }

  setAddedMarketingResources(data) {
    this.setState({addedMarketingResources: [...data]});
  }

  removeAddedMarketingResource(idx) {
    const addedMarketingResources = [...this.state.addedMarketingResources];
    addedMarketingResources.splice(idx, 1);
    this.setState({ addedMarketingResources });
  }

  addRemovedMarketingResource(resourceId) {
    const removedMarketingResources = [...this.state.removedMarketingResources];
    removedMarketingResources.push(resourceId);
    this.setState({ removedMarketingResources });
  }

  componentDidMount() {
    const {
      match: {
        params: {
          versionId
        }
      }
    } = this.props;
    const { solution } = this.props.solutionDetail;
    const solutionId = this.getSolutionId();
    if (solutionId) {
      this.props.fetchSolutionDetail(solutionId);
    }
    if (versionId) {
      this.props.fetchSolutionVersionDetail(versionId);
    }
    if (solution) {
      this.setState({tags: solution.tags || []});
    }
  }

  componentWillUnmount() {
    this.props.clearSolutionVersionDetail();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.solutionDetail && !prevProps.solutionDetail.solution.tags && this.props.solutionDetail.solution.tags) {
      this.setState({tags: this.props.solutionDetail.solution.tags});
    }
  }

  handleTagsChange(tags) {
    this.setState({tags})
  }

  onSubmit(values) {
    const { solution } = this.props.solutionDetail;
    const { updateSolutionDetail } = this.props;
    const { addedMarketingResources, removedMarketingResources, draft } = this.state;
    const { state } = this.props.location;
    const solutionId = this.getSolutionId();

    const { dataPermissions } = values;
    delete values.dataPermissions;
    let newDataPermissions = [];
    for (let key in dataPermissions) {
      if (dataPermissions[key]) {
        const id = parseInt(key.split('-')[1]);
        newDataPermissions.push(id);
      }
    }
    values.dataPermissions = newDataPermissions;

    values.trialPeriodDays = values.trialPeriodDays || 0

    const data = snakeCaseKeys({...values}, { deep: true });

    if (!draft) {
      if (!solution.status || solution.status === SOLUTION_STATUS_OPEN) {
        data["status"] = SOLUTION_STATUS_READY;
      }
    }

    if (addedMarketingResources.length > 0) {
      data["added_marketing_resources"] = addedMarketingResources;
    }
    if (removedMarketingResources.length > 0) {
      data["removed_marketing_resources"] = removedMarketingResources;
    }

    data["draft"] = draft;
    data["tags"] = this.state.tags;

    updateSolutionDetail(solutionId, data).then((err) => {
      if (!err) {
        if (draft) {
          this.props.history.push("/admin/solutions/listing-new", {solutionId: state.solutionId});
        } else {
          this.props.addMessage("Solution saved");
          this.props.history.push(`/admin/solutions/${solutionId}/`);
        }
      }
    });
  }

  submitInfoForm(event) {
    this.form.current.submit();
    event.preventDefault();
  }

  goBackToListing() {
    const { values, syncErrors } = this.props.solutionInfoForm;
    const data = {};
    Object.keys(values).forEach((key) => {
      if (!syncErrors || !(key in syncErrors)) {
        data[key] = values[key]
      }
    });
    this.setState({draft: true}, () => {this.onSubmit(data);});
  }

  getSolutionId() {
    const { state } = this.props.location;
    let { solutionId } = this.props.match.params;
    if (!solutionId && state) {
      solutionId = state.solutionId
    }
    return solutionId
  }

  setFilesSize(filesSize, action="plus") {
    let newFilesSize;
    if (action === "minus") {
      newFilesSize = this.state.filesSize - filesSize
      this.setState({filesSize: newFilesSize})
    } else { // plus
      newFilesSize = this.state.filesSize + filesSize
    }

    if (newFilesSize >= (50 * 1024 * 1024)) {
      throw new Error("The size of all uploaded files cannot exceed 50 MB.")
    }

    if (action === "plus") {
      this.setState({filesSize: newFilesSize})
    }

    return newFilesSize
  }

  render() {
    const { solution } = this.props.solutionDetail;
    const { dataTypeClasses } = this.props;
    const { name, internalName, solutionMarketingResources, solutionDataPermissions } = solution;
    const { solutionId } = this.props.match.params;
    const { solutionVersion } = this.props.solutionVersionDetail;

    const solutionInfo = {
      internalName: internalName ? internalName : name,
      accessUrl: solution.accessUrl,
      supportEmail: solution.supportEmail,
      supportPhoneNumber: solution.supportPhoneNumber,
      leadsContactFirstName: solution.leadsContactFirstName,
      leadsContactSecondName: solution.leadsContactSecondName,
      leadsContactEmail: solution.leadsContactEmail,
      isTrialEnabled: solution.isTrialEnabled,
      isDirectCheckoutEnabled: solution.isDirectCheckoutEnabled,
      legalTerms: solution.legalTerms,
      showSupportPhoneNumber: solution.showSupportPhoneNumber,
      trialPeriodDays: solution.trialPeriodDays
    };

    return (
      <>
        <div className="breadcrumb-nav">
          <Row>
            <Col>
              <ol className="breadcrumb-container">
                <li className="breadcrumb-container__item">
                  <h5>
                    <Link to="/admin/solutions">Solutions</Link>
                   </h5>
                </li>
                <li className="breadcrumb-container__item">
                  <h5>/</h5>
                </li>
                <li className="breadcrumb-container__item">
                  <h5>{solutionId ? `Edit ${name}` : "Add New"}</h5>
                </li>
              </ol>
            </Col>
          </Row>
          <VersionState
              solutionVersion={solutionVersion}
              solutionId={solutionId}
              latestSolutionVersion={solution.latestSolutionVersion}
          />
        </div>
        <div className="content solution-new-info-page">
          <div className="page-content-header">
            <Row>
              <Col>
                <h3>
                  {
                    solutionId ? "Customize Your Solution Info" :  "Add Info About This Solution"
                  }
                </h3>              
              </Col>
              {
                !solutionId &&
                (<Col>
                  <Button
                    className="pull-right"
                    color="primary"
                    onClick={(event) => this.submitInfoForm(event)}
                  >
                    Submit
                  </Button>
                  <Button
                    className="pull-right mr-3"
                    color="outline-primary"
                    onClick={() => this.goBackToListing()}
                  >
                    Back
                  </Button>
                </Col>)
              }
              {
                solutionId &&
                (
                  <Col>
                    <div className="d-flex justify-content-end">
                      <Link to={`/admin/solutions/${solutionId}`}>
                        <Button
                          className="mr-3"
                          color="outline-primary"
                        >
                          Cancel
                        </Button>
                      </Link>
                      <Button
                        className="mr-3"
                        color="primary"
                        onClick={(event) => this.submitInfoForm(event)}
                      >
                        Submit Info
                      </Button>
                    </div>
                  </Col>
                )
              }
            </Row>
          </div>
          <SolutionInfoEditNewForm
            ref={this.form}
            solutionInfo={solutionInfo}
            isEditing={!!solutionId}
            solutionId={this.getSolutionId()}
            onSubmit={this.onSubmit.bind(this)}
            solutionMarketingResources={solutionMarketingResources}
            setAddedMarketingResources={this.setAddedMarketingResources.bind(this)}
            addRemovedMarketingResource={this.addRemovedMarketingResource.bind(this)}
            removeAddedMarketingResource={this.removeAddedMarketingResource.bind(this)}
            dataTypeClasses={dataTypeClasses}
            solutionDataPermissions={solutionDataPermissions}
            setFilesSize={this.setFilesSize.bind(this)}
            handleTagsChange={this.handleTagsChange.bind(this)}
            tags={this.state.tags}
          />
          {
            !solutionId &&
            (<div className="page-content-footer d-flex justify-content-end">
              <Button
                className="mr-3"
                color="outline-primary"
                onClick={() => this.goBackToListing()}
              >
                Back
              </Button>    
              <Button
                color="primary"
                onClick={(event) => this.submitInfoForm(event,)}
              >
                Submit
              </Button>          
            </div>)
          }
          {
            solutionId &&
            (<div className="page-content-footer d-flex justify-content-end">
              <Link to={`/admin/solutions/${solutionId}`}>
                <Button
                  className="mr-3"
                  color="outline-primary"
                >
                  Cancel
                </Button>
              </Link>
              <Button
                color="primary"
                 onClick={(event) => this.submitInfoForm(event)}
              >
                Submit Info
              </Button>          
            </div>)
          }
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  solutionDetail: state.solutionDetail,
  solutionVersionDetail: state.solutionVersionDetail,
  dataTypeClasses: state.dataList.dataTypeClasses,
  solutionInfoForm: state.form.SolutionInfo
});

const mapDispatchToProps = {
  addSolutionDetail,
  fetchSolutionDetail,
  updateSolutionDetail,
  addMessage,
  fetchSolutionVersionDetail,
  clearSolutionVersionDetail
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  SolutionInfoEditNew
);
