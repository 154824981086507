import axios from 'axios';
import {
  SOLUTION_RESOURCE_DETAIL_FETCH_REQUEST,
  SOLUTION_RESOURCE_DETAIL_FETCH_SUCCESS,
  SOLUTION_RESOURCE_DETAIL_FETCH_ERROR,
  SOLUTION_RESOURCE_LIST_FETCH_ERROR,
  SOLUTION_RESOURCE_LIST_FETCH_SUCCESS,
  SOLUTION_RESOURCE_LIST_FETCH_REQUEST,
} from './actionTypes';

import { updateSpinner } from  "./spinnerActions"

export const fetchSolutionResourceDetail = (solutionResourceId) => async (dispatch, state) => {
  dispatch(updateSpinner(true));
  dispatch({ type: SOLUTION_RESOURCE_DETAIL_FETCH_REQUEST , request: true});
  let url = `/api/solution/solution_resources/${solutionResourceId}/`;

  const { mode } = state().account;
  url += `?mode=${mode}`;

  try {
    const res = await axios.get(url);
    dispatch({ type: SOLUTION_RESOURCE_DETAIL_FETCH_SUCCESS, data: res.data });
    dispatch(updateSpinner(false));
    return res
  } catch (error) {
    dispatch({ type: SOLUTION_RESOURCE_DETAIL_FETCH_ERROR, error });
    dispatch(updateSpinner(false));
    return error
  }
};


export const fetchSolutionResourceList = (solution) => (dispatch, state) => {
  dispatch(updateSpinner(true));
  dispatch({ type: SOLUTION_RESOURCE_LIST_FETCH_REQUEST , request: true});
  let url = `/api/solution/solution_resources/`;

  const { mode } = state().account;
  url += `?mode=${mode}`;

  if (solution) url += `&solution__pk=${solution}&resource_type=ADDITIONAL&resource_type=MEDIA&resource_type=VIDEO&external=0`;
  axios.get(url)
    .then(({ data }) => {
      if (data) {
        dispatch({ type: SOLUTION_RESOURCE_LIST_FETCH_SUCCESS, data });
      } else {
        dispatch({ type: SOLUTION_RESOURCE_LIST_FETCH_ERROR, error: { mes: "" }});
      }
      dispatch(updateSpinner(false));
    })
    .catch((error) => {
      dispatch({ type: SOLUTION_RESOURCE_LIST_FETCH_ERROR, error });
      dispatch(updateSpinner(false));
    });
};
