import React from "react";
import { NumericFormat } from "react-number-format";
import { connect } from "react-redux";

class Currency extends React.Component {
  render() {
    const { value, currency, settings } = this.props;
    if (!settings.defaultCurrency) {
      return "";
    }
    let currencySymbol = settings.defaultCurrency.symbol;
    if (currency) {
      currencySymbol = settings.currencies[currency].symbol;
    }
    return (
      <NumericFormat
        value={value}
        displayType={"text"}
        thousandSeparator={true}
        prefix={currencySymbol}
        fixedDecimalScale={true}
        decimalScale={2}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  settings: state.settings,
});

export default connect(mapStateToProps)(Currency);

// <Currency value={} />
