import React, { useEffect, useState, useRef, useCallback } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import snakeCaseKeys from "snakecase-keys";

import { sendContactForm } from "../../store/actions/contactFormActions";
import logo from "../../assets/img/roadz-logo.svg";
import ContactForm from "../../views/forms/ContactForm";
import { useAuth0 } from "../../services/auth/react-auth0-wrapper";
import EmbeddedAlert from "../../components/EmbeddedAlert";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { addMessage } from "../../store/actions/messageActions";

const Login = () => {
  const [showModal, setShowModal] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [contactRecaptchaToken, setContactRecaptchaToken] = useState(null);
  const contactFormRef = useRef();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const dispatch = useDispatch();

  useEffect(() => {
    document.body.classList.toggle("login-page");

    return () => {
      document.body.classList.toggle("login-page");
    };
  }, []);

  const toggleModal = () => {
    setShowModal((prevShowModal) => !prevShowModal);
    setIsSubmitDisabled(false);
    handleReCaptchaVerify();
  };

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log("Execute recaptcha not yet available");
      return;
    }

    const token = await executeRecaptcha("contact");
    setContactRecaptchaToken(token);
    // Do whatever you want with the token
  }, [executeRecaptcha]);

  // Trigger the verification as soon as the component being loaded.
  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  const saveContactForm = (values) => {
    setIsSubmitDisabled(true);
    values["recaptchaToken"] = contactRecaptchaToken;
    const data = snakeCaseKeys(values, { deep: true });
    dispatch(sendContactForm(data))
      .then((res) => {
        toggleModal();
        dispatch(
          addMessage("Your information has been submitted successfully.")
        );
      })
      .catch(() => {
        toggleModal();
        dispatch(
          addMessage("Failed to verify that you are not a robot.", "danger")
        );
        setIsSubmitDisabled(false);
      });
  };

  const submitContactForm = () => {
    contactFormRef.current.submit();
  };

  return (
    <div className="login-page">
      <div className="header row">
        <div className="col-8">
          <Link to="/">
            <img src={logo} alt="Connecting the Smart Fleet Ecosystem" />
          </Link>
        </div>
        <div className="col-4">
          <div className="text-right">
            {!isAuthenticated && (
              <Button
                id="login-button"
                color="primary"
                className="m-0"
                onClick={() => {
                  loginWithRedirect({});
                }}
              >
                Sign In
              </Button>
            )}
          </div>
        </div>
      </div>
      <div className="position-relative">
        <div className="login-bkg-image" />
        <div className="login-section-overlay" />
        <div className="login-section p-background d-flex justify-content-between flex-column flex-sm-row">
          <div className="header-text">
            <div className="m-0">
              Connecting the <br />
              Smart Fleet Ecosystem
            </div>
          </div>
        </div>
      </div>
      <div className="description-section">
        <div className="pt-3">
          <EmbeddedAlert />
        </div>
        <p className="h5">Solution provider for fleets and mobile workers?</p>
        <p className="h5">
          You can now increase your market reach exponentially with just one
          integration!
        </p>
        <p className="h5"></p>
        <p className="h5 join-btn" onClick={toggleModal}>
          Join the ecosystem
        </p>
      </div>
      <Modal size="md" isOpen={showModal}>
        <ModalHeader>
          <p className="h4 m-0">Get in Touch</p>
        </ModalHeader>
        <ModalBody className="mt-4 p-3">
          <ContactForm ref={contactFormRef} onSubmit={saveContactForm} />
        </ModalBody>
        <ModalFooter className="contact-form p-3">
          <Button color="outline-primary" onClick={toggleModal}>
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={submitContactForm}
            disabled={isSubmitDisabled}
          >
            Submit
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default Login;
