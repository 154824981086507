import {
  SOLUTION_DISCOUNT_LIST_FETCH_REQUEST,
  SOLUTION_DISCOUNT_LIST_FETCH_SUCCESS,
  SOLUTION_DISCOUNT_LIST_FETCH_ERROR,
  SOLUTION_DISCOUNT_LIST_CLEAR,
  SOLUTION_DISCOUNT_ADD_REQUEST,
  SOLUTION_DISCOUNT_ADD_SUCCESS,
  SOLUTION_DISCOUNT_ADD_ERROR,
  SOLUTION_DISCOUNT_UPDATE_REQUEST,
  SOLUTION_DISCOUNT_UPDATE_SUCCESS,
  SOLUTION_DISCOUNT_UPDATE_ERROR,
  SOLUTION_DISCOUNT_DELETE_REQUEST,
  SOLUTION_DISCOUNT_DELETE_SUCCESS,
  SOLUTION_DISCOUNT_DELETE_ERROR,
  SOLUTION_DISCOUNTS_ADD_REQUEST,
  SOLUTION_DISCOUNTS_ADD_ERROR,
  SOLUTION_DISCOUNT_UPDATE_CLEAR_IS_PRIMARY
} from "../actions/actionTypes";

const initialState = {
  solutionDiscounts: [],
  isLoading: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SOLUTION_DISCOUNT_LIST_FETCH_REQUEST:
    case SOLUTION_DISCOUNT_ADD_REQUEST:
    case SOLUTION_DISCOUNT_UPDATE_REQUEST:
    case SOLUTION_DISCOUNT_DELETE_REQUEST:
    case SOLUTION_DISCOUNTS_ADD_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: action.error,
      };

    case SOLUTION_DISCOUNT_LIST_FETCH_ERROR:
    case SOLUTION_DISCOUNT_ADD_ERROR:
    case SOLUTION_DISCOUNT_UPDATE_ERROR:
    case SOLUTION_DISCOUNT_DELETE_ERROR:
    case SOLUTION_DISCOUNTS_ADD_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    case SOLUTION_DISCOUNT_LIST_FETCH_SUCCESS:
      return {
        ...state,
        solutionDiscounts: action.data,
        isLoading: false,
        error: action.error
      };

    case SOLUTION_DISCOUNT_LIST_CLEAR:
      return {
        ...state,
        solutionDiscounts: [],
      };

    case SOLUTION_DISCOUNT_ADD_SUCCESS:
      return {
        ...state,
        solutionDiscounts: [...state.solutionDiscounts, action.data]
      };

    case SOLUTION_DISCOUNT_UPDATE_CLEAR_IS_PRIMARY:
      return {
        ...state,
        solutionDiscounts: state.solutionDiscounts.map((discount) => {
           return  {...discount, isPrimary: false}
          }
        )
      };
    case SOLUTION_DISCOUNT_UPDATE_SUCCESS:
      return {
        ...state,
        solutionDiscounts: state.solutionDiscounts.map((discount) =>
            discount.id === action.data.id ? action.data : discount
          )
      };

    case SOLUTION_DISCOUNT_DELETE_SUCCESS: {
      return {
        ...state,
        solutionDiscounts: state.solutionDiscounts.filter(
          (discount) => action.data.id !== discount.id
        )
      };
    }

    default:
      return state;
  }
};
