import { updateSpinner } from "./spinnerActions";
import {
  MARKETPLACE_DETAIL_FETCH_ERROR,
  MARKETPLACE_DETAIL_FETCH_REQUEST,
  MARKETPLACE_DETAIL_FETCH_SUCCESS,
} from "./actionTypes";
import axios from "axios";

export const fetchMarketplaceDetail =
  (marketplaceId) => async (dispatch, state) => {
    dispatch(updateSpinner(true));
    dispatch({ type: MARKETPLACE_DETAIL_FETCH_REQUEST, request: true });
    let url = `/api/marketplace/marketplaces/${marketplaceId}/`;
    const { mode } = state().account;
    url += `?mode=${mode}`;
    try {
      const res = await axios.get(url);
      dispatch({ type: MARKETPLACE_DETAIL_FETCH_SUCCESS, data: res.data });
      dispatch(updateSpinner(false));
    } catch (error) {
      dispatch({ type: MARKETPLACE_DETAIL_FETCH_ERROR, error });
      dispatch(updateSpinner(false));
      return error;
    }
  };
