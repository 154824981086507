import React from "react";
import { useTable, useSortBy } from "react-table";
import { Button } from "reactstrap";
import { formatDisplayDatetime } from "../../../../utils/datetime";

const Table = ({ columns, data, history, solutionId }) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
        initialState: {
          sortBy: [
            {
              id: "Created",
              desc: true,
            },
          ],
        },
      },
      useSortBy
    );

  return (
    <div className="ReactTable7">
      <table {...getTableProps()} className="rt-table">
        <thead className="rt-thead">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()} className="rt-rh rt-tr">
              {headerGroup.headers.map((column) => {
                const sortClass =
                  column.isSorted && !column.isSortedDesc
                    ? "-sort-asc"
                    : column.isSorted && column.isSortedDesc
                    ? "-sort-desc"
                    : "no-sort";
                return (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    <div className={"rt-header rt-td " + sortClass}>
                      {column.render("Header")}
                    </div>
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows && rows.length > 0
            ? rows.map((row, i) => {
                prepareRow(row);
                // console.log(row)
                return (
                  <tr
                    {...row.getRowProps()}
                    className="rt-tr"
                    onClick={() => {
                      if (row.index === 0) {
                        return history.push(`/admin/solutions/${solutionId}/`);
                      } else {
                        return history.push(
                          `/admin/solutions/${solutionId}/version/${row.original.id}`
                        );
                      }
                    }}
                  >
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()} className="rt-td">
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })
            : [...Array(10).keys()].map((val) => (
                <tr className="rt-tr" key={val}>
                  <td colSpan={10} className="rt-td empty-rows">
                    {val === 4 ? "No rows found" : ""}
                  </td>
                </tr>
              ))}
        </tbody>
      </table>
    </div>
  );
};

const VersionListTable = ({ data, history, solutionId, onRestore }) => {
  const columns = React.useMemo(
    () => [
      {
        Header: "Created",
        accessor: (row) => formatDisplayDatetime(row.created),
      },
      {
        Header: "Published",
        accessor: (row) =>
          row.published ? formatDisplayDatetime(row.published) : "",
      },
      {
        id: "id",
        Header: "",
        accessor: (row, i) => {
          return (
            <div className=" d-flex justify-content-end">
              {i === 0 ? (
                <Button
                  className="mt-0"
                  color="primary"
                  onClick={(event) => {
                    event.stopPropagation();
                    history.push(`/admin/solutions/${solutionId}/`);
                  }}
                >
                  View
                </Button>
              ) : (
                <>
                  <Button
                    className="mt-0"
                    color="outline-primary"
                    onClick={(event) => {
                      event.stopPropagation();
                      onRestore(row.value);
                    }}
                  >
                    Restore
                  </Button>

                  <Button
                    className="mt-0 ml-3"
                    color="primary"
                    onClick={(event) => {
                      event.stopPropagation();
                      history.push(
                        `/admin/solutions/${solutionId}/version/${row.value}`
                      );
                    }}
                  >
                    View
                  </Button>
                </>
              )}
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <Table
      columns={columns}
      data={data}
      history={history}
      solutionId={solutionId}
    />
  );
};

export default VersionListTable;
