import React from "react";
import { connect } from "react-redux";
import {Card, CardBody, Row, Col, Button} from "reactstrap";
import ClipLoader from "react-spinners/ClipLoader";

import { fetchMetrics } from "../../store/actions/metricsActions";
import { Auth0Context } from '../../services/auth/react-auth0-wrapper';
import LeadsAndSubscriptions from "./LeadsAndSubscriptions";
import { Link } from "react-router-dom";
import { clearSubscriptionData } from "../../store/actions/subscriptionDetailActions";

class PartnerDashboard extends React.Component {
  static contextType = Auth0Context;

  componentDidMount() {
    const { account } = this.props;
    this.props.fetchMetrics();
    if (account.mode === 'Host') this.props.history.push("/admin/host/dashboard");
  }

  onClickSetUpACustomer(){
    this.props.clearSubscriptionData().then(() => {
      this.props.history.push('/admin/customers/new')
    });
  }

  render() {
    const { user } = this.context;

    const {
      metrics: {
        marketplaceCount,
        viewCount,
        leadCount
      }
    } = this.props;

    return (
      <>
        <div className="breadcrumb-nav">
          <Row>
            <Col>
              <ol className="breadcrumb-container">
                <li className="breadcrumb-container__item">
                  <h5>
                    Welcome, {user.name ? user.name : user.email}
                  </h5>
                </li>
              </ol>
            </Col>
          </Row>
        </div>
        <div className="content home-page">
          <Card className="card-tasks d-inline-block">
            <CardBody className="d-flex metrics flex-column flex-sm-row">
              <div className="pl-5 pr-5 d-flex pb-3 pt-3">
                <div className="mr-2 pt-2">
                  <i className="ti-icon ti-shopping-cart-full"/>
                </div>
                <div>
                  <h3 className="font-weight-bold m-0">
                    <ClipLoader
                      size={20}
                      color={"#000000"}
                      loading={marketplaceCount === undefined}
                    />
                    {marketplaceCount && marketplaceCount}
                  </h3>
                  <h5>
                    Marketplaces
                  </h5>
                </div>
              </div>
              <div className="pl-5 pr-5 d-flex pb-3 pt-3">
                <div className="mr-2">
                  <i className="ti-icon ti-eye"/>
                </div>
                <div>
                  <h3 className="font-weight-bold m-0">
                    <ClipLoader
                      size={20}
                      color={"#000000"}
                      loading={viewCount === undefined}
                    />
                    {viewCount && viewCount}
                  </h3>
                  <h5>
                    Views
                  </h5>
                </div>
              </div>
              <div className="pl-5 pr-5 d-flex pb-3 pt-3">
                <div className="mr-2 pt-2">
                  <i className="ti-icon ti-flag"/>
                </div>
                <div>
                  <h3 className="font-weight-bold m-0">
                    <ClipLoader
                      size={20}
                      color={"#000000"}
                      loading={leadCount === undefined}
                    />
                    {leadCount && leadCount}
                  </h3>
                  <h5>
                    Leads
                  </h5>
                </div>
              </div>
            </CardBody>
          </Card>
          <Row>
            <Col>
              <h4>Open Leads and Subscriptions</h4>
            </Col>
          </Row>
          <Row>
            <Col md="8">
              <LeadsAndSubscriptions {...this.props} />
            </Col>
            <Col md="4">
              <Card>
                <CardBody>
                  <h4 className="text-center font-weight-bold">
                    Onboard a customer
                  </h4>
                  <h5 className="text-center mt-4">
                    Get your new fleet started
                  </h5>
                </CardBody>
                <Button
                  color="primary"
                  className="m-5"
                  onClick={this.onClickSetUpACustomer.bind(this)}
                >
                  Set up a customer
                </Button>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md="8">
              <Link to={"/admin/leads/"}>View All leads ></Link>
              <Link className={"ml-4"} to={"/admin/customers/"}>View All subscriptions ></Link>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  metrics: state.metrics,
  account: state.account
});

const mapDispatchToProps = {
  fetchMetrics,
  clearSubscriptionData
};

export default connect(mapStateToProps, mapDispatchToProps)(PartnerDashboard);
