import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  Card,
  CardBody,
  Row,
  Col,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import EmbeddedAlert from "../../../components/EmbeddedAlert";
import {
  fetchSolutionVersionList,
  restoreSolutionVersion,
} from "../../../store/actions/solutionVersionActions";
import {
  clearSolutionDetail,
  fetchSolutionDetail,
} from "../../../store/actions/solutionDetailActions";
import VersionListTable from "./components/VersionListTable";

class VersionList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalConfirmation: false,
      selectedVersionId: null,
    };
    this.toggleModalConfirmation = this.toggleModalConfirmation.bind(this);
    this.onRestore = this.onRestore.bind(this);
  }

  componentDidMount() {
    const {
      match: {
        params: { solutionId },
      },
    } = this.props;
    this.props.fetchSolutionVersionList(solutionId);
    this.props.fetchSolutionDetail(solutionId);
  }

  componentWillUnmount() {
    const {
      match: {
        params: { solutionId },
      },
      clearSolutionDetail,
    } = this.props;

    if (solutionId) {
      clearSolutionDetail();
    }
  }

  toggleModalConfirmation() {
    this.setState({
      modalConfirmation: !this.state.modalConfirmation,
    });
  }

  onConfirmRestore() {
    const {
      match: {
        params: { solutionId },
      },
    } = this.props;
    this.toggleModalConfirmation();
    this.props.restoreSolutionVersion(solutionId, this.state.selectedVersionId);
  }

  onRestore(versionId) {
    this.setState({
      selectedVersionId: versionId,
    });
    this.toggleModalConfirmation();
  }

  render() {
    const {
      match: {
        params: { solutionId },
      },
    } = this.props;

    const { solutionVersions } = this.props.solutionVersionList;
    const { solution } = this.props.solutionDetail;

    const data = solutionVersions.map((solutionVersion) => {
      return {
        id: solutionVersion.id,
        status: solutionVersion.status,
        created: solutionVersion.created,
        published: solutionVersion.published,
      };
    });

    return (
      <>
        <div className="breadcrumb-nav">
          <Row>
            <Col>
              <ol className="breadcrumb-container">
                <li className="breadcrumb-container__item">
                  <h5>
                    <Link to="/admin/solutions">Solutions</Link>
                  </h5>
                </li>
                <li className="breadcrumb-container__item">
                  <h5>/</h5>
                </li>
                <li className="breadcrumb-container__item">
                  <h5>
                    <Link to={`/admin/solutions/${solution.id}`}>
                      {solution.internalName}
                    </Link>
                  </h5>
                </li>
                <li className="breadcrumb-container__item">
                  <h5>/</h5>
                </li>
                <li className="breadcrumb-container__item">
                  <h5>Solution Versions</h5>
                </li>
              </ol>
            </Col>
          </Row>
        </div>
        <div className="content lead-list-page">
          <div className="page-content-header">
            <div className="pt-3">
              <EmbeddedAlert />
            </div>
            <Row>
              <Col>
                <h3>Solution Versions</h3>
              </Col>
            </Row>
          </div>
          <Card className="custom-react-table">
            <CardBody>
              <VersionListTable data={data} history={this.props.history} solutionId={solutionId} onRestore={this.onRestore} />
            </CardBody>
          </Card>
        </div>

        <Modal
          isOpen={this.state.modalConfirmation}
          fade={false}
          toggle={this.toggleModalConfirmation}
          className="modal-dialog-centered"
        >
          <div className="modal-header justify-content-center">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={this.toggleModalDeleteConfirmation}
            >
              <span aria-hidden="true">×</span>
            </button>
            <h5 className="modal-title">Restore Solution Version</h5>
          </div>
          <ModalBody>
            Are you sure, you want to restore the solution version?
          </ModalBody>
          <ModalFooter className="mr-3">
            <Button
              color="outline-primary"
              onClick={this.toggleModalConfirmation}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              onClick={() => {
                this.onConfirmRestore();
              }}
            >
              Restore
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  solutionVersionList: state.solutionVersionList,
  solutionDetail: state.solutionDetail,
});

const mapDispatchToProps = {
  fetchSolutionVersionList,
  fetchSolutionDetail,
  clearSolutionDetail,
  restoreSolutionVersion,
};

export default connect(mapStateToProps, mapDispatchToProps)(VersionList);
