import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import snakeCaseKeys from "snakecase-keys";

const { updateSyncErrors } = require("redux-form/lib/actions").default;

import FleetEditNewForm from "./FleetEditNewForm";
import {
  addFleetDetail,
  updateFleetDetail,
} from "../../../store/actions/customerDetailActions";
import { fetchSubscriptionDetail } from "../../../store/actions/subscriptionDetailActions";
import { history } from "../../../store";
import { addMessage } from "../../../store/actions/messageActions";
import camelcaseKeys from "camelcase-keys";

class FleetEditNew extends React.Component {
  constructor(props) {
    super(props);
    this.form = React.createRef();
  }

  componentDidMount() {
    const { subscriptionId } = this.props.match.params;
    if (subscriptionId) this.props.fetchSubscriptionDetail(subscriptionId);
  }

  submitFleetEditNewForm(event) {
    this.form.current.submit();
    event.preventDefault();
  }

  onSubmit(values, dispatch) {
    const { subscriptionId } = this.props.match.params;
    const { fleetId } = this.props.match.params;
    const { subscription } = this.props.subscriptionDetail;
    const { lead } = this.props.leadDetail;

    let fleetData = snakeCaseKeys(
      {
        country: values.country,
        customerFirstName: values.customerFirstName,
        customerEmail: values.customerEmail,
        companyAddress: values.companyAddress,
        companyState: values.companyState,
        customerLastName: values.customerLastName,
        customerPhoneNumber: values.customerPhoneNumber,
        companyCity: values.companyCity,
        companyZipCode: values.companyZipCode,
        companyCountry: "US",
        name: values.name,
        size: values.size,
        companyAddress2: values.companyAddress2,
      },
      { deep: true }
    );

    if (lead && lead.marketplace) {
      fleetData.marketplacePk = lead.marketplace;
      fleetData.formVariant = lead.formVariant;
    }

    // Edit Customer
    if (fleetId) {
      fleetData.hostFleetId = subscription.fleet.hostFleetId;
      fleetData = snakeCaseKeys(fleetData, { deep: true });
      this.props.updateFleetDetail(fleetId, fleetData).then((err) => {
        if (err) {
          try {
            const fieldErrors = camelcaseKeys(err.response.data, {
              deep: true,
            });
            if (fieldErrors) {
              for (const [key, value] of Object.entries(fieldErrors)) {
                dispatch(
                  updateSyncErrors("FleetEditNewForm", {
                    [key]: value,
                  })
                );
              }
            }
          } catch (e) {}
        } else {
          this.props.addMessage("The customer info has been saved");
          if (subscriptionId) {
            history.goBack();
            // history.push(`/admin/customers/${subscriptionId}/`);
          } else {
            history.push(`/admin/customers/new`);
          }
        }
      });
    }
    // Add new Customer
    else {
      fleetData.hostFleetId = lead.hostFleetId;
      fleetData = snakeCaseKeys(fleetData, { deep: true });
      this.props.addFleetDetail(fleetData).then((res) => {

        if (res.err) {
          try {
            const fieldErrors = camelcaseKeys(res.err.response.data, {
              deep: true,
            });
            if (fieldErrors) {
              for (const [key, value] of Object.entries(fieldErrors)) {
                dispatch(
                    updateSyncErrors("FleetEditNewForm", {
                      [key]: value,
                    })
                );
              }
            }
          } catch (e) {}
        }

        else {
          this.props.addMessage("The customer info has been saved");
          history.push(`/admin/customers/new`);
        }
      });
    }
  }

  render() {
    const { account } = this.props;
    const { subscriptionId } = this.props.match.params;
    const { subscription } = this.props.subscriptionDetail;
    const { lead } = this.props.leadDetail;

    let fleet;
    if (subscription && subscription.fleet && subscription.fleet.name)
      fleet = subscription.fleet;
    else fleet = this.props.fleetDetail.fleet;

    let initialValues = {};

    if (lead && lead.company) {
      initialValues = {
        country: lead.country,
        customerFirstName: lead.firstName,
        customerEmail: lead.email,
        companyAddress: lead.address,
        companyState: lead.state,
        customerLastName: lead.lastName,
        customerPhoneNumber: lead.phoneNumber,
        companyCity: lead.city,
        companyZipCode: lead.zipCode,
        name: lead.company,
        size: lead.fleetSize,
        formVariant: lead.formVariant,
        companyAddress2: lead.address2,
      };
    }

    if (fleet && fleet.name) {
      initialValues = {
        country: fleet.country,
        customerFirstName: fleet.customerFirstName,
        customerEmail: fleet.customerEmail,
        companyAddress: fleet.companyAddress,
        companyState: fleet.companyState,
        customerLastName: fleet.customerLastName,
        customerPhoneNumber: fleet.customerPhoneNumber,
        companyCity: fleet.companyCity,
        companyZipCode: fleet.companyZipCode,
        name: fleet.name,
        size: fleet.size,
        companyAddress2: fleet.companyAddress2,
        formVariant: fleet.formVariant,
      };
    }
    if (!initialValues.country) {
      initialValues.country = "US";
    }
    return (
      <>
        <div className="breadcrumb-nav">
          <div>
            <ol className="breadcrumb-container">
              <li className="breadcrumb-container__item">
                <h5>
                  <Link to="/admin/customers">Subscriptions</Link>
                </h5>
              </li>
              <li className="breadcrumb-container__item">
                <h5>/</h5>
              </li>
              {subscriptionId && (
                <>
                  <li className="breadcrumb-container__item">
                    <h5>
                      <Link to={`/admin/customers/${subscriptionId}`}>
                        {fleet.name}
                      </Link>
                    </h5>
                  </li>
                  <li className="breadcrumb-container__item">
                    <h5>/</h5>
                  </li>
                  <li className="breadcrumb-container__item">
                    <h5>Edit Customer</h5>
                  </li>
                </>
              )}
              {!subscriptionId && (
                <li className="breadcrumb-container__item">
                  <h5>New Customer</h5>
                </li>
              )}
            </ol>
          </div>
        </div>

        <div className="content customer-new-page">
          <FleetEditNewForm
            initialValues={initialValues}
            ref={this.form}
            enableReinitialize={true}
            keepDirtyOnReinitialize={true}
            onSubmit={this.onSubmit.bind(this)}
          />
          <div className="page-content-footer">
            <div className="d-flex justify-content-end">
              <Link
                to={
                  subscriptionId
                    ? `/admin/customers/${subscriptionId}/`
                    : "/admin/customers/new"
                }
              >
                <Button color="outline-primary" className="mr-3">
                  Cancel
                </Button>
              </Link>
              {account.permissions.includes("write:customers") && (
                <Button
                  color="primary"
                  onClick={this.submitFleetEditNewForm.bind(this)}
                >
                  Save
                </Button>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  account: state.account,
  subscriptionDetail: state.subscriptionDetail,
  leadDetail: state.leadDetail,
  fleetDetail: state.fleetDetail,
});

const mapDispatchToProps = {
  fetchSubscriptionDetail,
  addFleetDetail,
  updateFleetDetail,
  addMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(FleetEditNew);
