import React from "react";
import { connect } from "react-redux";
import {Col, Row, Table} from "reactstrap";
import {clearSubscriptionDiscountList, fetchSubscriptionDiscountList} from "../../../store/actions/subscriptionDiscountActions";
import {clearSolutionDiscountList, fetchSolutionDiscountList} from "../../../store/actions/solutionDiscountActions";
import {
  SOLUTION_DISCOUNT_ENTITY_TYPE_NON_MONETARY_LABEL, SOLUTION_DISCOUNT_ENTITY_TYPE_NON_MONETARY_VALUE,
  SOLUTION_DISCOUNT_ENTITY_TYPE_OTC1_LABEL,
  SOLUTION_DISCOUNT_ENTITY_TYPE_OTC1_VALUE,
  SOLUTION_DISCOUNT_ENTITY_TYPE_OTC2_LABEL,
  SOLUTION_DISCOUNT_ENTITY_TYPE_OTC2_VALUE,
  SOLUTION_DISCOUNT_ENTITY_TYPE_SUBSCRIPTION_LABEL,
  SOLUTION_DISCOUNT_ENTITY_TYPE_SUBSCRIPTION_VALUE
} from "../../../config/solutionDiscount";
import Currency from "../../../components/Currency";

class SubscriptionDiscount extends React.Component {
  componentDidMount() {
    const {subscriptionId, solution, fetchSubscriptionDiscountList, fetchSolutionDiscountList} = this.props;
    if (subscriptionId) {
      fetchSubscriptionDiscountList(subscriptionId)
    } else if (solution) {
      fetchSolutionDiscountList(solution)
    }
  }

  componentWillUnmount() {
    this.props.clearSubscriptionDiscountList();
    this.props.clearSolutionDiscountList();
  }

  render() {
    const {subscriptionDiscounts, currency, solutionDiscounts, firstOneTimeSubDescription, secondOneTimeSubDescription} = this.props;

    let discounts = [];
    if (subscriptionDiscounts && subscriptionDiscounts.length > 0) {
      discounts = subscriptionDiscounts;
    } else if (solutionDiscounts && solutionDiscounts.length > 0) {
      discounts = solutionDiscounts;
    }

    if (!discounts || discounts.length === 0) {
      return null;
    }

    return (
      <div className="mb-2 cost-options-panel">
        <Row className="mt-2">
          <Col>
            <h5 className="font-weight-bold mb-2">
              Discounts
            </h5>
          </Col>
        </Row>
        {discounts && discounts.length > 0 &&
        <Row className="mt-2">
          <Col>
            <Table responsive className="table-nonfluid mb-0" style={{minWidth: 500}}>
              <thead>
              <tr>
                <th className="text-center">#</th>
                <th>Name</th>
                <th>
                  <div className="mx-5">Product</div>
                </th>
                <th>Amount</th>
                <th>
                  <div className="mx-5">Is primary</div>
                </th>
              </tr>
              </thead>
              <tbody>
              {discounts.map((discount, index) => {
                return (
                  <tr key={index}>
                    <td className="text-center">{index + 1}</td>
                    <td>{discount.name}</td>
                    <td>
                      <div className="mx-5">
                        {discount.entityType === SOLUTION_DISCOUNT_ENTITY_TYPE_SUBSCRIPTION_VALUE
                        && SOLUTION_DISCOUNT_ENTITY_TYPE_SUBSCRIPTION_LABEL}
                        {discount.entityType === SOLUTION_DISCOUNT_ENTITY_TYPE_NON_MONETARY_VALUE
                        && SOLUTION_DISCOUNT_ENTITY_TYPE_NON_MONETARY_LABEL}
                        {discount.entityType === SOLUTION_DISCOUNT_ENTITY_TYPE_OTC1_VALUE
                        && (firstOneTimeSubDescription
                          ? firstOneTimeSubDescription
                          : SOLUTION_DISCOUNT_ENTITY_TYPE_OTC1_LABEL)}
                        {discount.entityType === SOLUTION_DISCOUNT_ENTITY_TYPE_OTC2_VALUE
                        && (secondOneTimeSubDescription
                          ? secondOneTimeSubDescription
                          : SOLUTION_DISCOUNT_ENTITY_TYPE_OTC2_LABEL)}
                      </div>
                    </td>
                    {discount.percentage && <td>{discount.percentage}%</td>}
                    {discount.amount && <td><Currency currency={currency} value={discount.amount} /></td>}
                    {discount.entityType === SOLUTION_DISCOUNT_ENTITY_TYPE_NON_MONETARY_VALUE && <td />}
                    <td>
                      <div className="mx-5">{discount.isPrimary && <i className="fa fa-check" />}</div>
                    </td>
                  </tr>
                )
              })}
              </tbody>
            </Table>
          </Col>
        </Row>
        }
      </div>
    );
  }
}

const mapStateToProps = state => ({
  subscriptionDiscounts: state.subscriptionDiscount.subscriptionDiscounts,
  solutionDiscounts: state.solutionDiscount.solutionDiscounts
});

const mapDispatchToProps = {
  fetchSubscriptionDiscountList,
  clearSubscriptionDiscountList,
  fetchSolutionDiscountList,
  clearSolutionDiscountList,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  SubscriptionDiscount
);
