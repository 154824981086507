/* eslint-disable linebreak-style */
import axios from 'axios';
import {
  MARKETPLACE_LIST_FETCH_REQUEST,
  MARKETPLACE_LIST_FETCH_SUCCESS,
  MARKETPLACE_LIST_FETCH_ERROR,
} from './actionTypes';
import { updateSpinner } from "./spinnerActions";

export const fetchMarketplaceList = (limit) => async (dispatch, state) => {
  dispatch(updateSpinner(true));
  dispatch({ type: MARKETPLACE_LIST_FETCH_REQUEST , request: true});
  let url = `/api/marketplace/marketplaces/`;

  const { mode } = state().account;
  url += `?mode=${mode}`;

  if (limit) url += `&limit=${limit}`;

  try {
    const res = await axios.get(url);
    dispatch({ type: MARKETPLACE_LIST_FETCH_SUCCESS, data: res.data });
    dispatch(updateSpinner(false));
    return {err: null, data: res.data}
  } catch (error) {
    dispatch({ type: MARKETPLACE_LIST_FETCH_ERROR, error });
    dispatch(updateSpinner(false));
    return {err: error};
  }
};
