/* eslint-disable linebreak-style */
import camelcaseKeys from 'camelcase-keys';
import {
  SUBSCRIPTION_LIST_FETCH_ERROR,
  SUBSCRIPTION_LIST_FETCH_REQUEST,
  SUBSCRIPTION_LIST_FETCH_SUCCESS,
} from "../actions/actionTypes";

const initialState = {
  subscriptions: [],
  isLoading: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SUBSCRIPTION_LIST_FETCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };

    case SUBSCRIPTION_LIST_FETCH_SUCCESS:
      const data = camelcaseKeys(action.data, {deep: true});
      return {
        ...state,
        subscriptions: data,
        isLoading: false,
        error: null
      };

    case SUBSCRIPTION_LIST_FETCH_ERROR:
      return {
        ...state,
        subscriptions: [],
        isLoading: false,
        error: action.error,
      };

    default:
      return state;
  }
};
