/* eslint-disable linebreak-style */
import camelcaseKeys from 'camelcase-keys';
import {
  LEAD_FETCH_REQUEST,
  LEAD_FETCH_SUCCESS,
  LEAD_FETCH_ERROR,
  LEAD_ADD_REQUEST,
  LEAD_ADD_SUCCESS,
  LEAD_ADD_ERROR,
  LEAD_UPDATE_REQUEST,
  LEAD_UPDATE_SUCCESS,
  LEAD_UPDATE_ERROR,
  LEAD_CLEAR,
} from "../actions/actionTypes";

const initialState = {
  lead: {
    type: ''
  }, // lead detail returned from the api
  isLoading: false, // boolean variable to indicate fetching api is in progress
  isAdding: false, // boolean variable to indicate creating api is in progress
  isUpdating: false, // boolean variable to indicate updating api is in progress
  error: null, // error
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LEAD_ADD_REQUEST:
      return {
        ...state,
        isAdding: true,
        error: null,
      };

    case LEAD_FETCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };

    case LEAD_UPDATE_REQUEST:
      return {
        ...state,
        isUpdating: true,
        error: null,
      };

    case LEAD_ADD_SUCCESS:
      {
        const data = camelcaseKeys(action.data, {deep: true});
        return {
          ...state,
          lead: data,
          isAdding: false,
          error: null
        };
      }

    case LEAD_FETCH_SUCCESS:
      {
        const data = camelcaseKeys(action.data, {deep: true});
        return {
          ...state,
          lead: data,
          isLoading: false,
          error: null
        };
      }

    case LEAD_UPDATE_SUCCESS:
      {
        const data = camelcaseKeys(action.data, {deep: true});
        return {
          ...state,
          lead: data,
          isUpdating: false,
          error: null
        };
      }

    case LEAD_ADD_ERROR:
      return {
        ...state,
        isAdding: false,
        error: action.error,
      };

    case LEAD_FETCH_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    case LEAD_UPDATE_ERROR:
      return {
        ...state,
        isUpdating: false,
        error: action.error,
      };
    
    case LEAD_CLEAR:
      return  {
        lead: {},
        isLoading: false,
        isAdding: false,
        isUpdating: false,
        error: null,
      };      

    default:
      return state;
  }
};
