import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Alert } from "reactstrap";
import {
  removeMessages,
  removeAllMessages,
} from "../store/actions/messageActions";

const EmbeddedAlert = () => {
  const dispatch = useDispatch();
  const messages = useSelector(({ notifications }) => notifications.messages);

  useEffect(() => {
    return () => {
      dispatch(removeAllMessages());
    };
  }, [dispatch]);

  const onDismiss = (message) => {
    dispatch(removeMessages(message));
  };

  return (
    <>
      {messages.map((message, idx) => {
        return (
          <Alert
            key={idx}
            className={`rounded-0 ${
              message.color === "primary" ? "text-dark c-alert-green" : ""
            }`}
            isOpen={true}
            toggle={() => onDismiss(message)}
            color={message.color}
          >
            <div className="d-flex flex-row">
              {message.color === "primary" && (
                <div>
                  <i className="fa fa-check fa-2x mx-3" />
                </div>
              )}
              <div className="my-1">{message.mes}</div>
            </div>
          </Alert>
        );
      })}
    </>
  );
};

export default EmbeddedAlert;
