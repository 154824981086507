import { SWITCH_ACCOUNT_MODE, TOGGLE_ACCOUNT_MODE, ASSIGN_PERMISSIONS } from "../actions/actionTypes";

const initialState = {
  mode: null,
  permissions: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_ACCOUNT_MODE:
      let mode = state.mode;
      if (state.mode === 'Partner') mode = 'Host';
      else mode = 'Partner';

      return {
        ...state,
        mode
      };

    case SWITCH_ACCOUNT_MODE:
      return {
        ...state,
        mode: action.data
      };

    case ASSIGN_PERMISSIONS:
      return {
        ...state,
        permissions: action.data
      };

    default:
      return state;
  }
};
