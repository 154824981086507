import React from "react";
import { connect } from "react-redux";
import { formValueSelector, Field, reduxForm, untouch, change, touch } from "redux-form";
import {Button, Card, CardBody, Col, FormGroup, Label, Row, UncontrolledTooltip} from "reactstrap"
import { Link } from "react-router-dom";
import Pluralize from 'pluralize';

import {
  requiredValidation,
  positiveNumberValidation,
  integerValidation,
  checkedValidation,
  notZeroValidation,
  maxNumber100000Validation,
  minLengthValidation5,
  maxLengthValidation120,
  solutionSearchFieldValidation,
  customerValidation,
  isNotPastDateValidation,
  isDateValidationMMDDYYYY,
  freeTrialLengthValidation,
  maxNumber1000Validation, minNumber1Validation
} from "./validation";

import {
  renderFloatNumberField,
  renderSelectField,
  renderInputField,
  renderCheckboxField,
  nonNegativeNumbers,
  renderDropdownSelectSearchField,
  renderDatetimeMinMaxField,
  renderDatetimeField,
} from "./utils";

import {
  ENTITY_TYPE_OPTIONS,
  SOLUTION_1_OTC_SHIPPING_TYPE_NO_SHIPPING_VALUE,
  SOLUTION_2_OTC_SHIPPING_TYPE_NO_SHIPPING_VALUE, SOLUTION_TYPE_FREE_VALUE
} from "../../config/solution"
import { ONBOARD_TYPE } from "../../config/customer";

import {
  CUSTOMER_TYPE_ECOMMERCE_SUBSCRIBER_LABEL,
  SUBSCRIPTION_PAYMENT_METHOD_HOST_CREDIT_VALUE,
  SUBSCRIPTION_1_OTC_SHIPPING_TYPE_FLAT_RATE_VALUE,
  SUBSCRIPTION_1_OTC_SHIPPING_TYPE_PER_UNIT_VALUE,
  SUBSCRIPTION_2_OTC_SHIPPING_TYPE_FLAT_RATE_VALUE,
  SUBSCRIPTION_2_OTC_SHIPPING_TYPE_PER_UNIT_VALUE,
  SUBSCRIPTION_1_OTC_SHIPPING_TYPE_NO_SHIPPING_VALUE,
  SUBSCRIPTION_2_OTC_SHIPPING_TYPE_NO_SHIPPING_VALUE,
  SUBSCRIPTION_1_OTC_SHIPPING_TYPE_OPTIONS,
  SUBSCRIPTION_2_OTC_SHIPPING_TYPE_OPTIONS,
  SUBSCRIPTION_PAYMENT_METHOD_PAY_ON_TERMS_VALUE,
  SUBSCRIPTION_PAYMENT_METHOD_PAY_ON_TERMS_LABEL,
  SUBSCRIPTION_PAYMENT_METHOD_CREDIT_CARD_VALUE,
  SUBSCRIPTION_PAYMENT_METHOD_CREDIT_CARD_LABEL,
  SUBSCRIPTION_PAYMENT_METHOD_HOST_CREDIT_LABEL,
  SUBSCRIPTION_NET_TERMS_OPTIONS,
  SUBSCRIPTION_NET_TERMS_30_DAYS_VALUE, SUBSCRIPTION_PAYMENT_METHOD_INTERVALS
} from "../../config/subscription";

import WarningIcon from "../../assets/icons/warning-icon.svg";
import HelpIcon from "../../assets/icons/help-icon.svg";
import Currency from "../../components/Currency";
import EmbeddedAlert from "../../components/EmbeddedAlert";
import {activeSubscriptionsByFleetEmail} from "../../store/actions/subscriptionListActions";
import SubscriptionDiscount from "../customers/discounts/SubscriptionDiscount";

const { updateSyncErrors } = require('redux-form/lib/actions').default;

class CustomerSubscriptionEditNewForm extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      clearSolutionName: false,
    };
  }

  resetForm = (fields) => {
    Object.keys(fields).forEach(field => {
      this.props.dispatch(change("CustomerSubscriptionEditNew", field, fields[field]));
      this.props.dispatch(untouch("CustomerSubscriptionEditNew", field));
    });
  };

  resetSolutionFields = () => {
    this.resetForm({
      solution: null,
      quantity: null,
      startSubscriptionPrice: null,
      firstOneTimeQuantity: null,
      firstOneTimePrice: null,
      firstOneTimeSubDescription: null,
      firstOneTimeShippingType: null,
      firstOneTimeShippingPrice: null,
      secondOneTimeQuantity: null,
      secondOneTimePrice: null,
      secondOneTimeSubDescription: null,
      secondOneTimeShippingType: null,
      secondOneTimeShippingPrice: null
    });
  };

  activeSubscriptionsByFleetEmailValidate = email => solutionId => {
    if (!solutionId || !email) {
      return null;
    }
    activeSubscriptionsByFleetEmail(email, solutionId).then(res => {
      if (res.data && res.data.length > 0) {
        if (res.data[0] && res.data[0].solution && res.data[0].solution.id !== solutionId) {
          this.props.dispatch(touch('CustomerSubscriptionEditNew', 'solution'));
          this.props.dispatch(updateSyncErrors('CustomerSubscriptionEditNew', {
            solution: 'There is already an active Subscription for this Customer and Solution'
          }));
        }
      }
    });
    return null
  };

  activeSubscriptionsByFleetEmailValidation = this.activeSubscriptionsByFleetEmailValidate(
      this.props.fleet.customerEmail || this.props.lead.email);

  quantityValidation = value => {
    const {subscriptionEntities} = this.props;
    if (!subscriptionEntities) {
      return undefined;
    }
    let activeEntities = 0;
    for( let i = 0; i < subscriptionEntities.length; i++ ){
      if (subscriptionEntities[i].isActive) {
        activeEntities += 1
      }
    }
    if (parseInt(value) < activeEntities) {
      return 'The number must be greater than or equal to active entities.'
    }
    return undefined
  };

  render() {
    const {
      customerName,
      // dataTypeClasses,
      isEditing,
      initialCustomerName,
      initialQuantity,
      initialStartSubscriptionPrice,
      marketplaces,
      marketplace,
      customerType,
      quantity,
      paymentMethod,
      startSubscriptionPrice,
      partnerName,
      onboardType,
      unit,
      submitFailed,
      invalid,
      solutionFirstOneTimePrice,
      solutionSecondOneTimePrice,
      subscriptionId,
      fleetId,
      firstOneTimePrice,
      firstOneTimeShippingType,
      secondOneTimePrice,
      secondOneTimeShippingType,
      firstOneTimeQuantity,
      secondOneTimeQuantity,
      showReviewSubscriptionChanges,
      firstOneTimeSubDescription,
      secondOneTimeSubDescription,
      firstOneTimeShippingPrice,
      secondOneTimeShippingPrice,
      billingTax,
      settings,
      account,
      paymentInterval,
      solution,
      subscriptionDiscounts,
      otc1Discounts,
      otc2Discounts,
      anchorDayRadio,
      trialPeriodDays,
      currency
    } = this.props;

    let solutions = [];
    const marketplaceObj = marketplaces.find(marketplaceObj => marketplaceObj.id === parseInt(marketplace));

    if (marketplaceObj) {
      solutions = marketplaceObj.solutions;
      solutions = solutions.filter((solution) => solution.solutionType !== SOLUTION_TYPE_FREE_VALUE)
    }

    let shippingTotal = 0;
    if (parseFloat(firstOneTimeShippingPrice) && parseFloat(firstOneTimeQuantity)) {
      if (firstOneTimeShippingType === SUBSCRIPTION_1_OTC_SHIPPING_TYPE_FLAT_RATE_VALUE) {
        shippingTotal += parseFloat(firstOneTimeShippingPrice)
      } else if (firstOneTimeShippingType === SUBSCRIPTION_1_OTC_SHIPPING_TYPE_PER_UNIT_VALUE) {
        shippingTotal += parseFloat(firstOneTimeShippingPrice) * parseFloat(firstOneTimeQuantity)
      }
    }
    if (parseFloat(secondOneTimeShippingPrice) && parseFloat(secondOneTimeQuantity)) {
      if (secondOneTimeShippingType === SUBSCRIPTION_2_OTC_SHIPPING_TYPE_FLAT_RATE_VALUE) {
        shippingTotal += parseFloat(secondOneTimeShippingPrice)
      } else if (secondOneTimeShippingType === SUBSCRIPTION_2_OTC_SHIPPING_TYPE_PER_UNIT_VALUE) {
        shippingTotal += parseFloat(secondOneTimeShippingPrice) * parseFloat(secondOneTimeQuantity)
      }
    }

    let oneTimeChargesSubtotal = (parseFloat(firstOneTimeQuantity) && parseFloat(firstOneTimePrice) ?
            (parseFloat(firstOneTimeQuantity) * parseFloat(firstOneTimePrice)) : 0)
        + (parseFloat(secondOneTimeQuantity) && parseFloat(secondOneTimePrice) ?
            (parseFloat(secondOneTimeQuantity) * parseFloat(secondOneTimePrice)) : 0);

    let taxAmountToCollect;
    if (!billingTax.isLoading) taxAmountToCollect = billingTax.tax.amountToCollect;
    if (!taxAmountToCollect) taxAmountToCollect = 0;

    let fields;
    const { CustomerSubscriptionEditNew } = this.props;
    if (CustomerSubscriptionEditNew && "fields" in CustomerSubscriptionEditNew) {
      fields = CustomerSubscriptionEditNew.fields;
    }

    let subscriptionSubtotal = 0;
    if (quantity && startSubscriptionPrice) {
      subscriptionSubtotal = parseFloat(quantity) * parseFloat(startSubscriptionPrice)
    }

    return (
        <form>
          <Card>
            <fieldset disabled={account.mode === 'Host'}>
              <div className="section">
                <div className="pt-3">
                  <EmbeddedAlert />
                </div>
                <Row>
                  <Col md="2" xl={{size: 2}}>
                    <h4 className="m-0">
                      Summary
                    </h4>
                  </Col>
                  <Col md="5" xl={{size: 4}}>
                    <FormGroup>
                      <Label>
                        <h5 className="font-weight-bold">Customer</h5>
                      </Label>
                      <div className="d-flex">

                        <div className={initialCustomerName || isEditing ? "flex-fill mr-2" : "hidden"}>
                          <Field
                              type='text'
                              name="customerName"
                              component={renderInputField}
                              disabled={true}
                              validate={[requiredValidation, customerValidation]}
                              onChange={
                                (event) => {
                                  this.props.onChangeFleetName(event.target.value);
                                }
                              }
                          />

                        </div>

                        <div>
                          {
                            // We came from the lead view page. We have a lead but don't have a fleet.
                            // We will populate fleet form from the lead.
                            initialCustomerName && !fleetId ?
                                <Link to={'/admin/customers/fleet/new'}>
                                  <Button className="mt-0 mb-0" color="primary">Edit</Button>
                                </Link>
                                :
                                // Edit subscription
                                initialCustomerName && subscriptionId && fleetId ?
                                    <Link to={`/admin/customers/${subscriptionId}/fleet/${fleetId}/edit/`}>
                                      <Button className="mt-0 mb-0" color="primary">Edit</Button>
                                    </Link>
                                    :
                                    // Add new subscription
                                    // We created a fleet and continue creating a subscription
                                    initialCustomerName && fleetId ?
                                        <Link to={`/admin/customers/fleet/${fleetId}/edit/`}>
                                          <Button className="mt-0 mb-0" color="primary">Edit</Button>
                                        </Link>
                                        :
                                        <Link to={'/admin/customers/fleet/new'}>
                                          <Button className="mt-0 mb-0" color="primary">Add Customer Info</Button>
                                        </Link>
                          }
                          {submitFailed &&
                          <Label className="error mt-2">
                            {requiredValidation(customerName)}
                          </Label>
                          }
                        </div>
                      </div>
                    </FormGroup>
                  </Col>
                  <Col md={{size: 5}} xl={{offset: 1}}>
                    <FormGroup>
                      <Label>
                        <h5 className="font-weight-bold">
                          Customer type
                        </h5>
                      </Label>
                      <p>
                        {customerType}
                      </p>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="2" xl={{size: 2}}>
                    {" "}
                  </Col>
                  <Col md="5" xl={{size: 3}}>
                    <FormGroup>
                      <Label>
                        <h5 className="font-weight-bold">
                          Marketplace
                        </h5>
                      </Label>
                      <Field
                          type='select'
                          name="marketplace"
                          component={renderSelectField}
                          validate={[requiredValidation]}
                          disabled={onboardType === ONBOARD_TYPE.LEAD}
                          defaultValue={""}
                          onChange={
                            () => {
                              this.props.change("solution", "");
                              this.resetSolutionFields();
                              this.setState({clearSolutionName: true});
                            }
                          }
                      >
                        <option value="" disabled hidden>Select marketplace</option>
                        {
                          marketplaces && marketplaces.map(marketplace => (
                              <option key={marketplace.id} value={marketplace.id}>
                                {marketplace.name}
                              </option>
                          ))
                        }
                      </Field>
                    </FormGroup>
                  </Col>

                  <Col md="5" xl={{size: 3, offset: 2}}>
                    {solutions && (!isEditing || solutions.length > 0) &&
                    <FormGroup>
                      <Label>
                        <h5 className="font-weight-bold">
                          Solution
                        </h5>
                      </Label>
                      <Field
                          type="text"
                          name="solution"
                          component={renderDropdownSelectSearchField}
                          validate={[solutionSearchFieldValidation, this.activeSubscriptionsByFleetEmailValidation]}
                          disabled={customerType === CUSTOMER_TYPE_ECOMMERCE_SUBSCRIBER_LABEL || isEditing}
                          onItemClick={value => {
                            this.props.onChangeSolution(value);
                          }}
                          clearSolutionName={this.state.clearSolutionName}
                          toggleClearSolutionName={() => {this.setState({clearSolutionName: false});}}
                          clearSolutionField={() => {
                            this.resetSolutionFields();
                          }
                          }
                          items={solutions ? solutions.map(s => {
                            return {name: s.name, value: s.id}
                          }) : []}
                      >
                      </Field>

                    </FormGroup>
                    }
                  </Col>
                </Row>
              </div>

              {showReviewSubscriptionChanges &&
              <div className="section">
                <Row>
                  <Col md={{offset: 2, size: 10}} className="mb-3">
                    <h5 className="font-weight-bold">Review Subscription Changes</h5>

                    {/*Remove assets*/}
                    {initialQuantity - quantity > 0 &&
                    <p className="mb-1">The change will remove {initialQuantity - quantity} {' '}
                      {unit ?
                          Pluralize(ENTITY_TYPE_OPTIONS[unit], initialQuantity - quantity) :
                          Pluralize('asset', initialQuantity - quantity)
                      }{' '} from this subscription.
                    </p>
                    }

                    {/*Add assets*/}
                    {initialQuantity - quantity < 0 &&
                    <p className="mb-1">The change will add {quantity - initialQuantity} {' '}
                      {unit ?
                          Pluralize(ENTITY_TYPE_OPTIONS[unit], quantity - initialQuantity) :
                          Pluralize('asset', quantity - initialQuantity)
                      }{' '} to this subscription.
                    </p>
                    }

                    {/*Change cost*/}
                    {initialStartSubscriptionPrice !== startSubscriptionPrice &&
                    <p className="mb-1">Change cost to {' '}<Currency currency={currency} value={startSubscriptionPrice} /></p>
                    }
                  </Col>
                </Row>
                <Row>
                  <Col md={{offset: 2, size: 10}}>
                    <Card>
                      <CardBody>
                        <h5>Current subscription</h5>
                        <ul className="pl-3">
                          <li>Number of units <b>{initialQuantity}</b></li>
                          <li>Cost per {ENTITY_TYPE_OPTIONS[unit]} <b><Currency currency={currency} value={initialStartSubscriptionPrice} /></b></li>
                          {paymentInterval &&
                          <li>Est. {SUBSCRIPTION_PAYMENT_METHOD_INTERVALS[paymentInterval].toLowerCase()} subtotal <b><Currency value={initialQuantity && initialStartSubscriptionPrice ?
                              parseFloat(initialQuantity) * parseFloat(initialStartSubscriptionPrice)
                              : 0} /></b></li>
                          }
                        </ul>

                        <h5 className="d-inline-block mr-2">Updated subscription</h5>
                        {/*(effective March 1, 2020)   */}
                        {quantity > initialQuantity
                            ? <h5 className="d-inline-block">(effective when activated)</h5>
                            : <h5 className="d-inline-block">{this.props.effectiveDate && <>(effective {this.props.effectiveDate})</>}</h5>
                        }
                        <ul className="pl-3">
                          <li>Number of units <b>{quantity}</b></li>
                          <li>Cost per {ENTITY_TYPE_OPTIONS[unit]} <b><Currency currency={currency} value={startSubscriptionPrice} /></b></li>
                          {paymentInterval &&
                          <li>Est. {SUBSCRIPTION_PAYMENT_METHOD_INTERVALS[paymentInterval].toLowerCase()} subtotal <b><Currency currency={currency} value={quantity && startSubscriptionPrice ?
                              parseFloat(quantity) * parseFloat(startSubscriptionPrice)
                              : 0}/></b></li>
                          }
                        </ul>

                        {/*Remove assets*/}
                        {initialQuantity - quantity > 0 &&
                        <p>
                          Changes to cost or a reduction in units will go into effect at the start of the next billing cycle
                          for this subscription.
                        </p>
                        }

                        {/*Add assets*/}
                        {initialQuantity - quantity < 0 &&
                        <p>
                          New units will be ready to activate and begin billing once they are approved by the customer
                          and provisioned.
                        </p>
                        }
                      </CardBody>
                    </Card>
                  </Col>
                </Row>

                <Row className="mb-4">
                  <Col md={{offset: 4, size: 4}}>
                    {subscriptionDiscounts &&
                    <>
                      {/*Recurring subscription*/}
                      <div className="d-flex justify-content-between">
                        <div><p><b>Recurring subscription</b></p></div>
                        <div className="text-right">
                          <p><Currency currency={currency} value={startSubscriptionPrice} /> / {ENTITY_TYPE_OPTIONS[unit]} </p>
                        </div>
                      </div>
                      {subscriptionDiscounts && subscriptionDiscounts.map((subscriptionDiscount, index) => {
                        let amount = 0;
                        if (subscriptionDiscount.amount) {
                          amount = subscriptionDiscount.amount
                        } else {
                          amount = startSubscriptionPrice * subscriptionDiscount.percentage / 100
                        }
                        subscriptionSubtotal -= amount * quantity;
                        return (
                            <div className="d-flex justify-content-between" key={index}>
                              <div>{subscriptionDiscount.name}</div>
                              <div className="text-right">-<Currency currency={currency} value={amount || 0} /></div>
                            </div>
                        )}
                      )}
                      <div className="d-flex justify-content-between">
                        <div>Quantity: {quantity}</div>
                        <div className="text-right" />
                      </div>
                      <div className="d-flex justify-content-between">
                        <div>New units subtotal:</div>
                        <div className="text-right">
                          <Currency
                              currency={currency}
                              value={subscriptionSubtotal || 0}
                          />
                        </div>
                      </div>
                      <hr />
                    </>
                    }
                    {/*One-time charges*/}
                    {(parseFloat(firstOneTimeQuantity) > 0 || parseFloat(secondOneTimeQuantity) > 0) &&
                    <>
                      <p><b>One-time charges</b></p>
                      {parseFloat(firstOneTimeQuantity) > 0 &&
                      <div className="mb-2">
                        <div className="d-flex justify-content-between">
                          <div>{firstOneTimeSubDescription}</div>
                          <div><Currency currency={currency} value={firstOneTimePrice} /></div>
                        </div>
                        {otc1Discounts && otc1Discounts.map((otc1Discount) => {
                          let amount = 0;
                          if (otc1Discount.amount) {
                            amount = otc1Discount.amount
                          } else {
                            amount = firstOneTimePrice * otc1Discount.percentage / 100
                          }
                          oneTimeChargesSubtotal -= amount * firstOneTimeQuantity;
                          return (
                              <div className="d-flex justify-content-between">
                                <div>{otc1Discount.name}</div>
                                <div className="text-right">-<Currency currency={currency} value={amount || 0} /></div>
                              </div>
                          )}
                        )}
                        Quantity: {firstOneTimeQuantity}
                      </div>
                      }

                      {parseFloat(secondOneTimeQuantity) > 0 &&
                      <div className="mb-2">
                        <div className="d-flex justify-content-between">
                          <div>{secondOneTimeSubDescription}</div>
                          <div><Currency currency={currency} value={secondOneTimePrice} /></div>
                        </div>
                        {otc2Discounts && otc2Discounts.map((otc2Discount) => {
                          let amount = 0;
                          if (otc2Discount.amount) {
                            amount = otc2Discount.amount
                          } else {
                            amount = secondOneTimePrice * otc2Discount.percentage / 100
                          }
                          oneTimeChargesSubtotal -= amount * secondOneTimeQuantity;
                          return (
                              <div className="d-flex justify-content-between">
                                <div>{otc2Discount.name}</div>
                                <div className="text-right">-<Currency currency={currency} value={amount || 0} /></div>
                              </div>
                          )}
                        )}
                        Quantity: {secondOneTimeQuantity}
                      </div>
                      }

                      <div className="d-flex justify-content-between">
                        <div><b>One-time charges Subtotal:</b></div>
                        <div><Currency currency={currency} value={oneTimeChargesSubtotal} /></div>
                      </div>

                      <hr />
                    </>
                    }

                    {/*Total*/}
                    {(parseFloat(firstOneTimeQuantity) > 0 || parseFloat(secondOneTimeQuantity) > 0) &&
                    <div className="d-flex justify-content-between">
                      <div>
                        {(parseFloat(firstOneTimeQuantity) > 0 || parseFloat(secondOneTimeQuantity) > 0) && <>Shipping:<br /></>}
                        {!currency || settings.currencies[currency].code === "USD" ? "Estimated Taxes:" : "VAT:"}
                        <br />
                        <b>Estimated Total:</b><br />
                      </div>
                      <div className="text-right">
                        {(parseFloat(firstOneTimeQuantity) > 0 || parseFloat(secondOneTimeQuantity) > 0) &&
                        <><Currency currency={currency} value={shippingTotal} /><br /></>}
                        <Currency currency={currency} value={taxAmountToCollect} /><br />
                        <b>
                          <Currency currency={currency} value={oneTimeChargesSubtotal + shippingTotal + taxAmountToCollect} />
                        </b><br />
                      </div>
                    </div>
                    }

                  </Col>
                </Row>

                <Row>
                  <Col md={{offset: 2, size: 10}}>
                    <p>When you submit these changes, the customer contact will receive an overview of changes to approve.</p>
                  </Col>
                </Row>
              </div>
              }

              {!showReviewSubscriptionChanges &&
              <div className="section">
                <Row>
                  <Col md="2">
                    <h4 className="m-0">
                      {
                        isEditing || customerType === CUSTOMER_TYPE_ECOMMERCE_SUBSCRIBER_LABEL ?
                            "Order Details" : "Set order details"
                      }
                    </h4>
                  </Col>
                  <Col>
                    <FormGroup className="d-inline-block">
                      <Label>
                        <h5 className="font-weight-bold">
                          {
                            customerType === CUSTOMER_TYPE_ECOMMERCE_SUBSCRIBER_LABEL &&
                            "Order Date"
                          }

                          {
                            customerType !== CUSTOMER_TYPE_ECOMMERCE_SUBSCRIBER_LABEL &&
                            <>
                              Contract Date
                              <img alt="" src={HelpIcon} className="help-icon" id="contract-date-tooltip"/>
                              <UncontrolledTooltip placement="bottom" target="contract-date-tooltip">
                                <p style={{textAlign: 'left'}}>
                                  Select the date in which the customer signed the contract.
                                </p>
                              </UncontrolledTooltip>
                            </>
                          }
                        </h5>
                      </Label>
                      <Field
                          type='date'
                          name="orderDate"
                          disabled={isEditing}
                          placeholder="mm/dd/yyyy"
                          validate={!isEditing ? [requiredValidation, isDateValidationMMDDYYYY] : []}
                          component={renderDatetimeField}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={{offset: 2, size: 10}}>
                    <Card>
                      <CardBody>
                        {isEditing ?
                            <>
                              <h5 className="font-weight-bold">Update Subscription</h5>
                              <p>Changes in quantity or cost will go into effect at the start of your next billing cycle.</p>
                            </>
                            :
                            <h5 className="font-weight-bold">Subscription</h5>
                        }
                        <Row>
                          <Col md="3">
                            <FormGroup>
                              <Label><h6>Quantity</h6></Label>
                              {isEditing &&
                              <>
                                <p>Current: {initialQuantity}</p>
                                <Label><h6>New unit total</h6></Label>
                              </>
                              }
                              <Field
                                  type="number"
                                  name="quantity"
                                  disabled={isEditing && paymentMethod === SUBSCRIPTION_PAYMENT_METHOD_HOST_CREDIT_VALUE}
                                  component={renderInputField}
                                  normalize={nonNegativeNumbers}
                                  validate={[requiredValidation, integerValidation, positiveNumberValidation, notZeroValidation, maxNumber1000Validation, this.quantityValidation]}
                                  onChange={
                                    (event) => {
                                      if (event && event.target) {
                                        let newQuantity = event.target.value - initialQuantity;
                                        if (newQuantity <= 0) newQuantity = '';

                                        if (solutionFirstOneTimePrice && !fields.firstOneTimeQuantity) this.props.change("firstOneTimeQuantity", newQuantity);
                                        if (solutionSecondOneTimePrice && !fields.secondOneTimeQuantity) this.props.change("secondOneTimeQuantity", newQuantity);
                                      }
                                    }
                                  }
                              />
                            </FormGroup>
                          </Col>
                          <Col md="3">
                            <FormGroup>
                              <Label><h6>Unit price</h6></Label>
                              {isEditing &&
                              <>
                                <p>Current: <Currency currency={currency} value={initialStartSubscriptionPrice} /></p>
                                <Label><h6>New price</h6></Label>
                              </>
                              }
                              <div className="d-flex align-items-center">
                                <div className="mr-2">{currency && settings.currencies[currency].symbol}</div>
                                <div className="mr-2">
                                  <Field
                                      type='number'
                                      name="startSubscriptionPrice"
                                      component={renderFloatNumberField}
                                      validate={[requiredValidation]}
                                      normalize={nonNegativeNumbers}
                                      disabled={isEditing && paymentMethod === SUBSCRIPTION_PAYMENT_METHOD_HOST_CREDIT_VALUE}
                                  />
                                </div>
                                <div className="text-nowrap"> / {unit ? ENTITY_TYPE_OPTIONS[unit] : 'asset'} </div>
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <Label><h6>{SUBSCRIPTION_PAYMENT_METHOD_INTERVALS[paymentInterval]} subtotal</h6></Label>
                              {isEditing &&
                              <>
                                <p>
                                  Current: <Currency currency={currency} value={initialQuantity && initialStartSubscriptionPrice ?
                                    parseFloat(initialQuantity) * parseFloat(initialStartSubscriptionPrice)
                                    : 0}/>
                                </p>
                                <Label><h6>New</h6></Label>
                              </>
                              }
                              <p className="mt-2">
                                <Currency currency={currency} value={quantity && startSubscriptionPrice ?
                                    parseFloat(quantity) * parseFloat(startSubscriptionPrice)
                                    : 0} />
                              </p>
                            </FormGroup>
                          </Col>
                        </Row>

                        {/*One-time costs*/}
                        <div>
                          {solutionFirstOneTimePrice &&
                          <>
                            <h5 className="font-weight-bold">Specify any one-time costs</h5>
                            {isEditing && <p>Add one-time items to the order for the new units. </p>}
                          </>
                          }

                          <Row className={!solutionFirstOneTimePrice ? "hidden" : null}>
                            <Col md="2"><Label><h6>Quantity</h6></Label></Col>
                            <Col md="3"><Label><h6>Unit price</h6></Label></Col>
                            <Col md="3"><Label><h6>Description</h6></Label></Col>
                            <Col md="2"><Label><h6>Shipping Type</h6></Label></Col>
                            <Col md="2"><Label><h6>Shipping Price</h6></Label></Col>
                          </Row>
                          <Row className={!solutionFirstOneTimePrice ? "hidden" : null}>
                            {/*Quantity*/}
                            <Col md="2">
                              <FormGroup>
                                <Field
                                    type='number'
                                    name="firstOneTimeQuantity"
                                    normalize={nonNegativeNumbers}
                                    component={renderInputField}
                                    className="mb-3"
                                    validate={solutionFirstOneTimePrice && !isEditing ?
                                        [requiredValidation, integerValidation, positiveNumberValidation, maxNumber100000Validation] :
                                        [integerValidation, positiveNumberValidation, maxNumber100000Validation]}
                                    disabled={isEditing && paymentMethod === SUBSCRIPTION_PAYMENT_METHOD_HOST_CREDIT_VALUE}
                                />
                              </FormGroup>
                            </Col>
                            {/*Unit price*/}
                            <Col md="3">
                              <FormGroup>
                                <div className="d-flex align-items-center mb-3">
                                  <div className="mr-2">{currency && settings.currencies[currency].symbol}</div>
                                  <div className="mr-2">
                                    <Field
                                        type='number'
                                        name="firstOneTimePrice"
                                        component={renderFloatNumberField}
                                        normalize={nonNegativeNumbers}
                                        validate={parseFloat(firstOneTimeQuantity) ?
                                            [requiredValidation, positiveNumberValidation, notZeroValidation, minNumber1Validation] :
                                            [positiveNumberValidation, notZeroValidation, minNumber1Validation]}
                                        disabled={isEditing && paymentMethod === SUBSCRIPTION_PAYMENT_METHOD_HOST_CREDIT_VALUE}
                                    />
                                  </div>
                                  <div className="text-nowrap">/ {unit ? ENTITY_TYPE_OPTIONS[unit] : 'asset'} </div>
                                </div>
                              </FormGroup>
                            </Col>
                            {/*Description*/}
                            <Col md="3">
                              <FormGroup>
                                <Field
                                    type='text'
                                    name="firstOneTimeSubDescription"
                                    className="mb-3"
                                    component={renderInputField}
                                    validate={parseFloat(firstOneTimeQuantity) ? [requiredValidation, minLengthValidation5, maxLengthValidation120] : []}
                                    disabled={!parseFloat(firstOneTimePrice) || (isEditing && paymentMethod === SUBSCRIPTION_PAYMENT_METHOD_HOST_CREDIT_VALUE)}
                                />
                              </FormGroup>
                            </Col>
                            {/*Shipping type*/}
                            <Col md="2">
                              <FormGroup inline>
                                <Field
                                    name="firstOneTimeShippingType"
                                    component={renderSelectField}
                                    type="select"
                                    disabled={!parseFloat(firstOneTimePrice) || (isEditing && paymentMethod === SUBSCRIPTION_PAYMENT_METHOD_HOST_CREDIT_VALUE)}
                                    onChange={
                                      (event) => {
                                        if (event && event.target && event.target.value === SUBSCRIPTION_1_OTC_SHIPPING_TYPE_NO_SHIPPING_VALUE) {
                                          this.props.change("firstOneTimeShippingPrice", null);
                                        }
                                      }
                                    }
                                >
                                  <option value="" hidden />
                                  {Object.keys(SUBSCRIPTION_1_OTC_SHIPPING_TYPE_OPTIONS).map((key, index) => (
                                      <option key={index} value={key}>{SUBSCRIPTION_1_OTC_SHIPPING_TYPE_OPTIONS[key]}</option>
                                  ))}
                                </Field>
                              </FormGroup>
                            </Col>
                            {/*Shipping price per unit*/}
                            <Col md="2">
                              <FormGroup>
                                <div className="d-flex align-items-center">
                                  <div className="mr-2">{currency && settings.currencies[currency].symbol}</div>
                                  <div className="mr-2">
                                    <Field
                                        name="firstOneTimeShippingPrice"
                                        type="number"
                                        disabled={!parseFloat(firstOneTimePrice) || firstOneTimeShippingType === SOLUTION_1_OTC_SHIPPING_TYPE_NO_SHIPPING_VALUE || (isEditing && paymentMethod === SUBSCRIPTION_PAYMENT_METHOD_HOST_CREDIT_VALUE)}
                                        validate={firstOneTimeShippingType !== SOLUTION_1_OTC_SHIPPING_TYPE_NO_SHIPPING_VALUE && parseFloat(firstOneTimeQuantity) ?
                                            [requiredValidation, positiveNumberValidation, notZeroValidation] :
                                            [positiveNumberValidation, notZeroValidation]}
                                        component={renderFloatNumberField}
                                        normalize={nonNegativeNumbers}
                                    />
                                  </div>
                                </div>
                              </FormGroup>
                            </Col>
                          </Row>

                          <Row className={!solutionSecondOneTimePrice ? "hidden" : null}>
                            {/*Quantity 2*/}
                            <Col md="2">
                              <FormGroup>
                                <Field
                                    type='number'
                                    name="secondOneTimeQuantity"
                                    normalize={nonNegativeNumbers}
                                    component={renderInputField}
                                    validate={solutionSecondOneTimePrice && !isEditing ?
                                        [requiredValidation, integerValidation, positiveNumberValidation, maxNumber100000Validation] :
                                        [integerValidation, positiveNumberValidation, maxNumber100000Validation]}
                                    disabled={isEditing && paymentMethod === SUBSCRIPTION_PAYMENT_METHOD_HOST_CREDIT_VALUE}
                                />
                              </FormGroup>
                            </Col>
                            {/*Unit price 2*/}
                            <Col md="3">
                              <FormGroup>
                                <div className="d-flex align-items-center">
                                  <div className="mr-2">{currency && settings.currencies[currency].symbol}</div>
                                  <div className="mr-2">
                                    <Field
                                        type='number'
                                        name="secondOneTimePrice"
                                        normalize={nonNegativeNumbers}
                                        component={renderFloatNumberField}
                                        validate={parseFloat(secondOneTimeQuantity) ?
                                            [requiredValidation, positiveNumberValidation, notZeroValidation, minNumber1Validation] :
                                            [positiveNumberValidation, notZeroValidation, minNumber1Validation]}
                                        disabled={!solutionSecondOneTimePrice || (isEditing && paymentMethod === SUBSCRIPTION_PAYMENT_METHOD_HOST_CREDIT_VALUE)}
                                    />
                                  </div>
                                  <div className="text-nowrap"> / {unit ? ENTITY_TYPE_OPTIONS[unit] : 'asset'} </div>
                                </div>
                              </FormGroup>
                            </Col>
                            {/*Description 2*/}
                            <Col md="3">
                              <FormGroup>
                                <Field
                                    type='text'
                                    name="secondOneTimeSubDescription"
                                    className="mb-3"
                                    component={renderInputField}
                                    validate={parseFloat(secondOneTimeQuantity) ? [requiredValidation, minLengthValidation5, maxLengthValidation120] : []}
                                    disabled={!parseFloat(secondOneTimePrice) || (isEditing && paymentMethod === SUBSCRIPTION_PAYMENT_METHOD_HOST_CREDIT_VALUE)}
                                />
                              </FormGroup>
                            </Col>

                            {/*Shipping type 2*/}
                            <Col md="2">
                              <FormGroup inline>
                                <Field
                                    name="secondOneTimeShippingType"
                                    component={renderSelectField}
                                    type="select"
                                    disabled={!parseFloat(secondOneTimePrice) || (isEditing && paymentMethod === SUBSCRIPTION_PAYMENT_METHOD_HOST_CREDIT_VALUE)}
                                    onChange={
                                      (event) => {
                                        if (event && event.target && event.target.value === SUBSCRIPTION_2_OTC_SHIPPING_TYPE_NO_SHIPPING_VALUE) {
                                          this.props.change("secondOneTimeShippingPrice", null);
                                        }
                                      }
                                    }
                                >
                                  <option value="" hidden />
                                  {Object.keys(SUBSCRIPTION_2_OTC_SHIPPING_TYPE_OPTIONS).map((key, index) => (
                                      <option key={index} value={key}>{SUBSCRIPTION_2_OTC_SHIPPING_TYPE_OPTIONS[key]}</option>
                                  ))}
                                </Field>
                              </FormGroup>
                            </Col>
                            {/*Shipping price per unit 2*/}
                            <Col md="2">
                              <FormGroup>
                                <div className="d-flex align-items-center">
                                  <div className="mr-2">{currency && settings.currencies[currency].symbol}</div>
                                  <div className="mr-2">
                                    <Field
                                        name="secondOneTimeShippingPrice"
                                        type="number"
                                        disabled={!parseFloat(secondOneTimePrice) ||secondOneTimeShippingType === SOLUTION_2_OTC_SHIPPING_TYPE_NO_SHIPPING_VALUE || (isEditing && paymentMethod === SUBSCRIPTION_PAYMENT_METHOD_HOST_CREDIT_VALUE)}
                                        validate={secondOneTimeShippingType !== SOLUTION_2_OTC_SHIPPING_TYPE_NO_SHIPPING_VALUE && parseFloat(secondOneTimeQuantity) ?
                                            [requiredValidation, positiveNumberValidation, notZeroValidation] :
                                            [positiveNumberValidation, notZeroValidation]}
                                        component={renderFloatNumberField}
                                        normalize={nonNegativeNumbers}
                                    />
                                  </div>
                                </div>
                              </FormGroup>
                            </Col>
                          </Row>
                        </div>

                        {settings.discount && (subscriptionId || solution) &&
                        <SubscriptionDiscount
                            subscriptionId={subscriptionId}
                            solution={solution}
                            firstOneTimeSubDescription={firstOneTimeSubDescription}
                            secondOneTimeSubDescription={secondOneTimeSubDescription}
                            currency={currency}
                        />
                        }

                        {settings.freeTrialLength &&
                        <>
                          <Row className="mt-4">
                            <Col md="3">
                              <h5 className="font-weight-bold">Trial length</h5>
                            </Col>
                          </Row>
                          <Row className="mb-4">
                            <Col md="3">
                              <div className="d-flex">
                                <div>
                                  <FormGroup>
                                    <Field
                                        name="trialPeriodDays"
                                        type="number"
                                        disabled={isEditing}
                                        component={renderInputField}
                                        normalize={nonNegativeNumbers}
                                        validate={[integerValidation, positiveNumberValidation, freeTrialLengthValidation]}
                                    />
                                  </FormGroup>
                                </div>
                                <div className="mt-2 ml-2">
                                  {Pluralize('day', parseInt(trialPeriodDays))}
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </>
                        }

                        <Row className="mt-4">
                          <Col md="5">
                            <h5 className="font-weight-bold">Payment method</h5>
                          </Col>
                          {paymentMethod && paymentMethod === SUBSCRIPTION_PAYMENT_METHOD_PAY_ON_TERMS_VALUE &&
                          <>
                            <Col md="3">
                              <h5 className="font-weight-bold">Subscription Net Terms</h5>
                            </Col>
                            {(firstOneTimeQuantity || secondOneTimeQuantity) &&
                            <Col md="3">
                              <h5 className="font-weight-bold">One-time charges due date</h5>
                            </Col>
                            }
                          </>
                          }
                        </Row>
                        <Row className="mb-4">
                          <Col md="5">
                            <FormGroup>
                              <Field
                                  type="select"
                                  name="paymentMethod"
                                  component={renderSelectField}
                                  validate={[requiredValidation]}
                                  disabled={isEditing}
                              >
                                <option value="">Select</option>
                                <option value={SUBSCRIPTION_PAYMENT_METHOD_CREDIT_CARD_VALUE}>Automated Charging ({SUBSCRIPTION_PAYMENT_METHOD_CREDIT_CARD_LABEL})</option>
                                {settings.pmHostCredit &&
                                <option value={SUBSCRIPTION_PAYMENT_METHOD_HOST_CREDIT_VALUE}>Manual Charging ({SUBSCRIPTION_PAYMENT_METHOD_HOST_CREDIT_LABEL})</option>
                                }
                                {settings.pmPayOnTerms &&
                                <option value={SUBSCRIPTION_PAYMENT_METHOD_PAY_ON_TERMS_VALUE}>Manual Charging ({SUBSCRIPTION_PAYMENT_METHOD_PAY_ON_TERMS_LABEL})</option>
                                }
                              </Field>
                            </FormGroup>
                          </Col>

                          {paymentMethod && paymentMethod === SUBSCRIPTION_PAYMENT_METHOD_PAY_ON_TERMS_VALUE &&
                          <>
                            <Col md={{size: 2}}>
                              <FormGroup>
                                <Field
                                    type="select"
                                    name="netTerms"
                                    component={renderSelectField}
                                    defaultValue={SUBSCRIPTION_NET_TERMS_30_DAYS_VALUE}
                                    disabled={isEditing}
                                >
                                  {Object.keys(SUBSCRIPTION_NET_TERMS_OPTIONS).map((key, index) => (
                                      <option key={index} value={key}>{SUBSCRIPTION_NET_TERMS_OPTIONS[key]}</option>
                                  ))}
                                </Field>
                              </FormGroup>
                            </Col>

                            {(firstOneTimeQuantity || secondOneTimeQuantity) &&
                            <Col md={{size: 2, offset: 1}}>
                              <FormGroup>
                                <Field
                                    name="otcDueDate"
                                    disabled={isEditing}
                                    placeholder="mm/dd/yyyy"
                                    validate={!isEditing ? [requiredValidation, isDateValidationMMDDYYYY, isNotPastDateValidation] : []}
                                    component={renderDatetimeMinMaxField}
                                />
                              </FormGroup>
                            </Col>
                            }
                          </>
                          }
                        </Row>
                        {(settings.paymentInterval || settings.anchorDay) &&
                        <>
                          <Row className="mt-4">
                            {settings.paymentInterval &&
                            <Col md="3">
                              <h5 className="font-weight-bold">Payment interval</h5>
                            </Col>
                            }
                            {settings.anchorDay &&
                            <Col md="9">
                              <h5 className="font-weight-bold">Billing Cycle Anchor Day</h5>
                            </Col>
                            }
                          </Row>
                          <Row className="mb-4">
                            {settings.paymentInterval &&
                            <Col md="3">
                              <FormGroup>
                                <Field
                                    type="select"
                                    name="paymentInterval"
                                    component={renderSelectField}
                                    validate={[requiredValidation]}
                                    disabled={isEditing}
                                >
                                  {Object.keys(SUBSCRIPTION_PAYMENT_METHOD_INTERVALS).map((key, index) => (
                                      <option key={index}
                                              value={key}>{SUBSCRIPTION_PAYMENT_METHOD_INTERVALS[key]}</option>
                                  ))}
                                </Field>
                              </FormGroup>
                            </Col>
                            }
                            {settings.anchorDay &&
                            <Col md="9">
                              <div className="d-flex">
                                <div className="form-check-radio mr-4 mt-2">
                                  <Label check>
                                    <Field
                                        type="radio"
                                        name="anchorDayRadio"
                                        id="anchorDayRadioSubscribed"
                                        component={renderCheckboxField}
                                        value="0"
                                        disabled={isEditing}
                                        onChange={
                                          () => {
                                            this.props.change("anchorDay", 0);
                                          }
                                        }
                                    />
                                    <span className="custom-radio-label">On subscription activation date</span> <span className="form-check-sign" />
                                  </Label>
                                </div>
                                <div className="form-check-radio mt-2">
                                  <Label check>
                                    <Field
                                        type="radio"
                                        name="anchorDayRadio"
                                        id="anchorDayRadioDayOfMonth"
                                        component={renderCheckboxField}
                                        value="1"
                                        disabled={isEditing}
                                    />
                                    <span className="custom-radio-label">On this day of month: </span>
                                    <span className="form-check-sign" />
                                  </Label>
                                </div>
                                {anchorDayRadio === "1" &&
                                <div className="ml-2 mt-1">
                                  <Field
                                      type="select"
                                      name="anchorDay"
                                      component={renderSelectField}
                                      validate={[]}
                                      disabled={isEditing}
                                      onFocus={
                                        () => {
                                          this.props.change("anchorDayRadio", "1");
                                        }
                                      }
                                  >
                                    {Array.from(Array(32), (e, i) => {
                                      const value = i === 0 ? "-- Select --" : i;
                                      return <option key={i} value={i}>{value}</option>
                                    })}
                                  </Field>
                                </div>
                                }
                              </div>
                            </Col>
                            }
                          </Row>
                        </>
                        }
                        {
                          (customerType !== CUSTOMER_TYPE_ECOMMERCE_SUBSCRIBER_LABEL && !isEditing) &&
                          <div>
                            <Row>
                              <Col>
                                <FormGroup check>
                                  <Label check>
                                    <Field
                                        type='checkbox'
                                        className="mb-3"
                                        name="termCheck"
                                        component={renderCheckboxField}
                                        validate={checkedValidation}
                                    />
                                    The information above accurately reflects a current contract agreement
                                    between {partnerName} and {customerName}, signed
                                    by authorized representatives of both parties.
                                  </Label>
                                </FormGroup>
                              </Col>
                            </Row>
                          </div>
                        }
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
              }
              {/*TODO Just removing the data related UI, it needs to be implemented later */}
              {/* <div className="section">
            <Row>
              <Col md="2">
                <h4 className="m-0">
                  Data access
                  </h4>
              </Col>
              <Col>
                <p>Data assets and permissions are required by this solution</p>
              </Col>
            </Row>
            <Row>
              <Col md={{ offset: 2, size: 5 }}>
                <Card>
                  <CardBody>
                    <Row>
                      <Col>
                        <p>Drivers</p>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        {
                          dataTypeClasses.filter((item) => item.category === "Drivers")
                            .map(dataTypeClass => (
                              <FormGroup check key={dataTypeClass.id}>
                                <Label check>
                                  <Field
                                    name={`dataPermissions.data-${dataTypeClass.id}`}
                                    type="checkbox"
                                    component={renderCheckboxField}
                                    disabled={true}
                                  />
                                  <span className="form-check-sign" />
                                  {dataTypeClass.name}
                                </Label>
                              </FormGroup>
                            ))
                        }
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <p>Devices</p>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        {
                          dataTypeClasses.filter((item) => item.category === "Assets")
                            .map(dataTypeClass => (
                              <FormGroup check key={dataTypeClass.id}>
                                <Label check>
                                  <Field
                                    name={`dataPermissions.data-${dataTypeClass.id}`}
                                    type="checkbox"
                                    component={renderCheckboxField}
                                    disabled={true}
                                  />
                                  <span className="form-check-sign" />
                                  {dataTypeClass.name}
                                </Label>
                              </FormGroup>
                            ))
                        }
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col>
                <Card>
                  <CardBody>
                    <Row>
                      <Col>
                        <p>Vehicles</p>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        {
                          dataTypeClasses.filter((item) => item.category === "Vehicles")
                            .map(dataTypeClass => (
                              <FormGroup check key={dataTypeClass.id}>
                                <Label check>
                                  <Field
                                    name={`dataPermissions.data-${dataTypeClass.id}`}
                                    type="checkbox"
                                    component={renderCheckboxField}
                                    disabled={true}
                                  />
                                  <span className="form-check-sign" />
                                  {dataTypeClass.name}
                                </Label>
                              </FormGroup>
                            ))
                        }
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div> */}
            </fieldset>
          </Card>
          {submitFailed && invalid &&
          <div className="d-flex justify-content-end pr-5 mt-2">
            <p className="text-danger m-0">
              <img alt="" src={WarningIcon} className="mr-1"/>
              Complete all required fields or fix invalid fields before continuing.
            </p>
          </div>
          }
        </form>
    );
  };
}

const selector = formValueSelector('CustomerSubscriptionEditNew');


CustomerSubscriptionEditNewForm = connect(
    state => {
      return {
        customerName: selector(state, 'customerName'),
        marketplace: selector(state, 'marketplace'),
        firstOneTimePrice: selector(state, 'firstOneTimePrice'),
        secondOneTimePrice: selector(state, 'secondOneTimePrice'),
        quantity: selector(state, 'quantity'),
        solution: selector(state, 'solution'),
        paymentMethod: selector(state, 'paymentMethod'),
        paymentInterval: selector(state, 'paymentInterval'),
        startSubscriptionPrice: selector(state, 'startSubscriptionPrice'),
        firstOneTimeShippingType: selector(state, 'firstOneTimeShippingType'),
        secondOneTimeShippingType: selector(state, 'secondOneTimeShippingType'),
        firstOneTimeSubDescription: selector(state, 'firstOneTimeSubDescription'),
        secondOneTimeSubDescription: selector(state, 'secondOneTimeSubDescription'),
        firstOneTimeShippingPrice: selector(state, 'firstOneTimeShippingPrice'),
        secondOneTimeShippingPrice: selector(state, 'secondOneTimeShippingPrice'),
        firstOneTimeQuantity: selector(state, 'firstOneTimeQuantity'),
        secondOneTimeQuantity: selector(state, 'secondOneTimeQuantity'),
        trialPeriodDays: selector(state, 'trialPeriodDays'),
        CustomerSubscriptionEditNew: state.form.CustomerSubscriptionEditNew,
        settings: state.settings,
        account: state.account,
        lead: state.leadDetail.lead,
        fleet: state.fleetDetail.fleet,
        anchorDayRadio: selector(state, 'anchorDayRadio'),
      };
    }
)(CustomerSubscriptionEditNewForm);

export default reduxForm({
  form: 'CustomerSubscriptionEditNew',
})(
    CustomerSubscriptionEditNewForm
);
