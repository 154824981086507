import camelcaseKeys from 'camelcase-keys';
import {
  BILLING_SUBSCRIPTION_FETCH_EFFECTIVE_DATE_REQUEST,
  BILLING_SUBSCRIPTION_FETCH_EFFECTIVE_DATE_SUCCESS,
  BILLING_SUBSCRIPTION_FETCH_EFFECTIVE_DATE_ERROR,
  BILLING_SUBSCRIPTION_FETCH_EFFECTIVE_DATE_CLEAR
} from "../actions/actionTypes";

const initialState = {
  effectiveDate: '',
  isLoading: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case BILLING_SUBSCRIPTION_FETCH_EFFECTIVE_DATE_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: action.error,
      };

    case BILLING_SUBSCRIPTION_FETCH_EFFECTIVE_DATE_SUCCESS:
      const data = camelcaseKeys(action.data, {deep: true});
      return {
        ...state,
        effectiveDate: data.effectiveDate,
        isLoading: false,
        error: action.error
      };

    case BILLING_SUBSCRIPTION_FETCH_EFFECTIVE_DATE_ERROR:
      return {
        ...state,
        effectiveDate: '',
        isLoading: false,
        error: action.error,
      };

    case BILLING_SUBSCRIPTION_FETCH_EFFECTIVE_DATE_CLEAR:
      return {
        effectiveDate: '',
        isLoading: false,
        error: null
      };

    default:
      return state;
  }
};
