/* eslint-disable linebreak-style */
import camelcaseKeys from 'camelcase-keys';
import {
  SUBSCRIPTION_DETAIL_FETCH_REQUEST,
  SUBSCRIPTION_DETAIL_FETCH_SUCCESS,
  SUBSCRIPTION_DETAIL_FETCH_ERROR,
  SUBSCRIPTION_DETAIL_START_REQUEST,
  SUBSCRIPTION_DETAIL_START_SUCCESS,
  SUBSCRIPTION_DETAIL_START_ERROR,
  SUBSCRIPTION_DETAIL_CLEAR,
  SUBSCRIPTION_DETAIL_ADD_ERROR
} from "../actions/actionTypes";

const initialState = {
  subscription: {
    id: null,
    fleet: {},
    solution: {},
    marketplace: {}
  },
  isLoading: false,
  isStartingSubscription: false,
  error: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SUBSCRIPTION_DETAIL_FETCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };

    case SUBSCRIPTION_DETAIL_START_REQUEST:
      return {
        ...state,
        isStartingSubscription: true,
        error: null,
      };

    case SUBSCRIPTION_DETAIL_START_SUCCESS:
      {
        const data = camelcaseKeys(action.data, {deep: true});
        return {
          ...state,
          subscription: data,
          isStartingSubscription: false,
          error: null
        };
      }

    case SUBSCRIPTION_DETAIL_FETCH_SUCCESS:
      {
        const data = camelcaseKeys(action.data, {deep: true});
        return {
          ...state,
          subscription: data,
          isLoading: false,
          error: null
        };
      }

    case SUBSCRIPTION_DETAIL_FETCH_ERROR:
      return {
        ...state,
        subscription: {
          fleet: {},
          solution: {},
          marketplace: {}
        },
        isLoading: false,
        error: action.error,
      };

    case SUBSCRIPTION_DETAIL_START_ERROR:
      return {
        ...state,
        subscription: {
          fleet: {},
          solution: {},
          marketplace: {}
        },
        isStartingSubscription: false,
        error: action.error,
      };

    case SUBSCRIPTION_DETAIL_CLEAR:
      return {
        ...state,
        subscription: {
          fleet: {},
          solution: {},
          marketplace: {}
        },
        isLoading: false,
        isStartingSubscription: false,
        error: null,
      };

    case SUBSCRIPTION_DETAIL_ADD_ERROR:
      return {
        ...state,
        subscription: {
          fleet: {},
          solution: {},
          marketplace: {}
        },
        isLoading: false,
        error: action.error,
      };

    default:
      return state;
  }
};
