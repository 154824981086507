import camelcaseKeys from 'camelcase-keys';
import {
  BILLING_CALCULATE_TAX_ERROR,
  BILLING_CALCULATE_TAX_REQUEST,
  BILLING_CALCULATE_TAX_SUCCESS,
  BILLING_CALCULATE_TAX_CLEAR
} from "../actions/actionTypes";

const initialState = {
  tax: {
    amountToCollect: 0,
    rate: 0
  },
  isLoading: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case BILLING_CALCULATE_TAX_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: action.error,
      };

    case BILLING_CALCULATE_TAX_SUCCESS:
      const data = camelcaseKeys(action.data, {deep: true});
      return {
        ...state,
        tax: data,
        isLoading: false,
        error: action.error
      };

    case BILLING_CALCULATE_TAX_ERROR:
      return {
        ...state,
        tax: {},
        isLoading: false,
        error: action.error,
      };

    case BILLING_CALCULATE_TAX_CLEAR:
      return {
        tax: {
          amountToCollect: 0,
          rate: 0
        },
        isLoading: false,
        error: null
      };

    default:
      return state;
  }
};
