import React from "react";
import { connect } from "react-redux";
import {Card} from "reactstrap";
import classnames from "classnames";

import { fetchLeadList } from "../../store/actions/leadListActions";
import { fetchSubscriptionList } from "../../store/actions/subscriptionListActions";
import {
  LEAD_STATUS_OPEN,
  LEAD_STATUS_OPTIONS,
  LEAD_TYPE_LEAD_LABEL,
  LEAD_TYPE_OPTIONS,
  LEAD_TYPE_TRIAL_LABEL,
  LEAD_TYPE_DIRECT_VALUE
} from "../../config/lead";
import LeadsAndSubscriptionsTable from "./components/LeadsAndSubscriptionsTable";

export const ENTITY_TYPES = {
  Subscription: "S",
  [LEAD_TYPE_LEAD_LABEL]: "L",
  [LEAD_TYPE_TRIAL_LABEL]: "T"
};

class LeadsAndSubscriptions extends React.Component {
  componentDidMount() {
    this.props.fetchLeadList({status: LEAD_STATUS_OPEN});
    this.props.fetchSubscriptionList(
      {requires_activation: true, active_subscriptions_exists: true});
  }

  render() {
    const {
      leadList: {
        leads
      }
    } = this.props;

    const { subscriptions } = this.props.subscriptionList;

    const filteredLeads = leads.filter(lead => lead.type !== LEAD_TYPE_DIRECT_VALUE);
    const reactTableLeads = filteredLeads.map(lead => {
      return {
        id: lead.id,
        entity: LEAD_TYPE_OPTIONS[lead.type],
        status: LEAD_STATUS_OPTIONS[lead.status],
        date: lead.created,
        company: lead.company,
        solution: lead.solution ? lead.solution.name : ""
      };
    });

    const reactTableSubscriptions = subscriptions.map((subscription) => {
      return {
        id: subscription.id,
        entity: 'Subscription',
        status: "Requires Activation",
        date: subscription.created,
        company: subscription.fleet ? subscription.fleet.name : "",
        solution: subscription.solution ? subscription.solution.name : ""
      };
    });

    const data = reactTableLeads.concat(reactTableSubscriptions);

    return (
      <Card className="card-tasks custom-react-table">
        <LeadsAndSubscriptionsTable data={data} history={this.props.history} />

        <div className="d-flex mt-4">
          {Object.keys(ENTITY_TYPES).map((key, index) => {
            return (
              <React.Fragment key={index}>
                <div>
                  <div
                    className={classnames("btn-sm m-0 mr-2", {
                      "div-primary": key === "Subscription",
                      "div-success": key === LEAD_TYPE_LEAD_LABEL,
                      "div-danger": key === LEAD_TYPE_TRIAL_LABEL,
                    })}
                  >
                    {ENTITY_TYPES[key]}
                  </div>
                </div>
                <div className="pr-4 pt-1">{key}</div>
              </React.Fragment>
            );
          })}
        </div>
      </Card>
    );
  }
}

const mapStateToProps = state => ({
  leadList: state.leadList,
  subscriptionList: state.subscriptionList
});

const mapDispatchToProps = {
  fetchLeadList,
  fetchSubscriptionList
};

export default connect(mapStateToProps, mapDispatchToProps)(LeadsAndSubscriptions);
