export const LEAD_TYPE_LEAD_VALUE = 'Lead';
export const LEAD_TYPE_LEAD_LABEL = 'Request Info Lead';

export const LEAD_TYPE_DIRECT_VALUE = 'Direct';
export const LEAD_TYPE_DIRECT_LABEL = 'E-Commerce Subscriber';

export const LEAD_TYPE_TRIAL_VALUE = 'Trial';
export const LEAD_TYPE_TRIAL_LABEL = 'Request for Trial';

export const LEAD_TYPE_OPTIONS = {
    [LEAD_TYPE_DIRECT_VALUE]: LEAD_TYPE_DIRECT_LABEL,
    [LEAD_TYPE_LEAD_VALUE]: LEAD_TYPE_LEAD_LABEL,
    [LEAD_TYPE_TRIAL_VALUE]: LEAD_TYPE_TRIAL_LABEL
};

export const SOURCE_INTERNAL = 'Internal';
export const SOURCE_MARKETPLACE = 'Marketplace';

export const LEAD_STATUS_OPEN = 'O';
export const LEAD_STATUS_CLOSED = 'C';
export const LEAD_STATUS_OPEN_LABEL = 'Open';
export const LEAD_STATUS_CLOSED_LABEL = 'Closed';

export const LEAD_STATUS_OPTIONS = {
    [LEAD_STATUS_OPEN]: LEAD_STATUS_OPEN_LABEL,
    [LEAD_STATUS_CLOSED]: LEAD_STATUS_CLOSED_LABEL,
    'Open': LEAD_STATUS_OPEN_LABEL,
    'Close': LEAD_STATUS_CLOSED_LABEL,
    'Closed': LEAD_STATUS_CLOSED_LABEL
};


export const EQUIPMENT_TYPE_CARS_VALUE = 'CARS';
export const EQUIPMENT_TYPE_LIGHT_TRUCKS_VALUE = 'LIGHT_TRUCKS';
export const EQUIPMENT_TYPE_HEAVY_TRUCKS_VALUE = 'HEAVY_TRUCKS';
export const EQUIPMENT_TYPE_TRAILERS_OR_CONTAINERS_VALUE = 'TRAILERS_OR_CONTAINERS';
export const EQUIPMENT_TYPE_HEAVY_CONSTRUCTION_EQUIPMENT_VALUE = 'HEAVY_CONSTRUCTION_EQUIPMENT';
export const EQUIPMENT_TYPE_STATIONARY_EQUIPMENT_VALUE = 'STATIONARY_EQUIPMENT';

export const EQUIPMENT_TYPE_CARS_LABEL = 'Cars';
export const EQUIPMENT_TYPE_LIGHT_TRUCKS_LABEL = 'Light Trucks';
export const EQUIPMENT_TYPE_HEAVY_TRUCKS_LABEL = 'Heavy Trucks';
export const EQUIPMENT_TYPE_TRAILERS_OR_CONTAINERS_LABEL = 'Trailers / Containers';
export const EQUIPMENT_TYPE_HEAVY_CONSTRUCTION_EQUIPMENT_LABEL = 'Heavy Construction Equipment';
export const EQUIPMENT_TYPE_STATIONARY_EQUIPMENT_LABEL = 'Stationary Equipment';

export const EQUIPMENT_TYPE_OPTIONS = {
    [EQUIPMENT_TYPE_CARS_VALUE]: EQUIPMENT_TYPE_CARS_LABEL,
    [EQUIPMENT_TYPE_LIGHT_TRUCKS_VALUE]: EQUIPMENT_TYPE_LIGHT_TRUCKS_LABEL,
    [EQUIPMENT_TYPE_HEAVY_TRUCKS_VALUE]: EQUIPMENT_TYPE_HEAVY_TRUCKS_LABEL,
    [EQUIPMENT_TYPE_TRAILERS_OR_CONTAINERS_VALUE]: EQUIPMENT_TYPE_TRAILERS_OR_CONTAINERS_LABEL,
    [EQUIPMENT_TYPE_HEAVY_CONSTRUCTION_EQUIPMENT_VALUE]: EQUIPMENT_TYPE_HEAVY_CONSTRUCTION_EQUIPMENT_LABEL,
    [EQUIPMENT_TYPE_STATIONARY_EQUIPMENT_VALUE]: EQUIPMENT_TYPE_STATIONARY_EQUIPMENT_LABEL,
};
