import {
  SUBSCRIPTION_DISCOUNT_LIST_FETCH_REQUEST,
  SUBSCRIPTION_DISCOUNT_LIST_FETCH_SUCCESS,
  SUBSCRIPTION_DISCOUNT_LIST_FETCH_ERROR,
  SUBSCRIPTION_DISCOUNT_LIST_CLEAR,
} from "../actions/actionTypes";

const initialState = {
  subscriptionDiscounts: [],
  isLoading: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SUBSCRIPTION_DISCOUNT_LIST_FETCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: action.error,
      };

    case SUBSCRIPTION_DISCOUNT_LIST_FETCH_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    case SUBSCRIPTION_DISCOUNT_LIST_FETCH_SUCCESS:
      return {
        ...state,
        subscriptionDiscounts: action.data,
        isLoading: false,
        error: action.error
      };

    case SUBSCRIPTION_DISCOUNT_LIST_CLEAR:
      return {
        ...state,
        subscriptionDiscounts: [],
      };

    default:
      return state;
  }
};
