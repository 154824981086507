import React from "react";
import {
  Button,
  Col, Label,
  Row,
} from "reactstrap";
import RemoveIcon from "../../assets/icons/remove.svg";


class SolutionMarketingResourcesUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filesData: [],
      uploadedFilesData: [],
      error: ""
    };
    this.handleFilesChange = this.handleFilesChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    if (this.props.solutionMarketingResources) {
      this.setState({
        uploadedFilesData: this.props.solutionMarketingResources
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.solutionMarketingResources &&
      this.props.solutionMarketingResources
    ) {
      this.setState({
        uploadedFilesData: this.props.solutionMarketingResources
      });
    }
  }

  readFile(files, filesData, idx) {
    let reader = new FileReader();
    let file = files[idx];
    const {
      setAddedMarketingResources
    } = this.props;
    reader.onloadend = () => {
      filesData.push({
        name: files[idx].name,
        data: reader.result,
        size: files[idx].size,
      });
      if (idx + 1 < files.length) {
        this.readFile(files, filesData, idx + 1)
      } else {
        this.setState({
          filesData
        });
        this.refs.fileInput.value = null;
        if (setAddedMarketingResources) {
          setAddedMarketingResources(filesData);
        }
      }
    };
    reader.readAsDataURL(file);
  }

  handleFilesChange(e) {
    e.preventDefault();
    const {setFilesSize} = this.props;
    if (!e.target.files.length) {
      return;
    }
    if (!e.target.files[0].size) {
      this.setState({error: "Empty files cannot be uploaded"});
    } else {
      try {
        setFilesSize(e.target.files[0].size)
        this.setState({error: ""});
        this.readFile(e.target.files, this.state.filesData, 0)
      } catch (e) {
        this.setState({error: e.toString()});
      }
    }
  }

  removeNewFile(idx) {
    const {setFilesSize, removeAddedMarketingResource} = this.props;
    let newFilesData = [
      ...this.state.filesData
    ];
    const deletedFileSize = newFilesData[idx].size;
    newFilesData.splice(idx, 1);
    this.setState({
      filesData: newFilesData
    });
    removeAddedMarketingResource(idx);

    try {
      setFilesSize(deletedFileSize, "minus")
      this.setState({error: ""});
    } catch (e) {
      this.setState({error: e.toString()});
    }

  }

  removeUploadedFile(idx) {
    this.setState({error: ""});
    const {
      addRemovedMarketingResource
    } = this.props;
    let newUploadedFilesData = [
      ...this.state.uploadedFilesData
    ];
    const {
      id
    } = newUploadedFilesData[idx];
    newUploadedFilesData.splice(idx, 1);
    this.setState({
      uploadedFilesData: newUploadedFilesData
    });
    addRemovedMarketingResource(id);
  }

  handleClick() {
    this.refs.fileInput.click();
  }

  render() {
    return (
      <Row>
        <Col md="4">
          <h4 className="m-0">Sales Enablement Materials</h4>
        </Col>
        <Col md="8">
          <Row>
            <Col md="6">
              <label>
                <h5 className="font-weight-bold">
                  Sales Enablement Materials
              </h5>
              </label>
              <p>Add documents to describe your solution(optional).</p>
            </Col>
            <Col md="6">
              <Button
                className="pull-right"
                color="outline-primary"
                onClick={this.handleClick.bind(this)}
              >
                Select File
              </Button>
              <div className="fileinput text-center">
                <input type="file" onChange={this.handleFilesChange} ref="fileInput" multiple />
              </div>
            </Col>
          </Row>
          <Row>
            {
              this.state.uploadedFilesData.map(
                ({resourceFileName}, idx) => (
                  <Col 
                    md="6"
                    className="d-flex justify-content-between align-items-center mb-2 mt-2"
                    key={idx}
                  >
                    <h5 className="m-0">
                      {resourceFileName}
                    </h5>
                    <img alt="" src={RemoveIcon} onClick={() => this.removeUploadedFile(idx)}/>
                  </Col>
                )
              )
            }  
            {
              this.state.filesData.map(
                ({name}, idx) => (
                  <Col 
                    md="6"
                    className="d-flex justify-content-between align-items-center mb-2 mt-2"
                    key={idx}
                  >
                    <h5 className="m-0">
                      {name}
                    </h5>
                    <img alt="" src={RemoveIcon} onClick={() => this.removeNewFile(idx)}/>
                  </Col>
                )
              )
            }              
          </Row>
          {this.state.error &&
          <div>
            <Label className="error mt-1">{this.state.error}</Label>
          </div>
          }
        </Col>
      </Row>
    );
  }
}

export default SolutionMarketingResourcesUpload;
