import camelcaseKeys from "camelcase-keys";
import {
  MARKETPLACE_DETAIL_FETCH_REQUEST,
  MARKETPLACE_DETAIL_FETCH_SUCCESS,
  MARKETPLACE_DETAIL_FETCH_ERROR,
} from "../actions/actionTypes";

const initialState = {
  marketplace: null,
  isLoading: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case MARKETPLACE_DETAIL_FETCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };

    case MARKETPLACE_DETAIL_FETCH_SUCCESS:
      const data = camelcaseKeys(action.data, { deep: true });
      return {
        ...state,
        marketplace: data,
        isLoading: false,
        error: action.error,
      };

    case MARKETPLACE_DETAIL_FETCH_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    default:
      return state;
  }
};
