/* eslint-disable linebreak-style */

/**
 * Marketplace List Action types
 */
export const MARKETPLACE_LIST_FETCH_REQUEST = 'MARKETPLACE/MARKETPLACE_LIST_FETCH_REQUEST';
export const MARKETPLACE_LIST_FETCH_SUCCESS = 'MARKETPLACE/MARKETPLACE_LIST_FETCH_SUCCESS';
export const MARKETPLACE_LIST_FETCH_ERROR = 'MARKETPLACE/MARKETPLACE_LIST_FETCH_ERROR';

export const MARKETPLACE_DETAIL_FETCH_REQUEST = 'MARKETPLACE/MARKETPLACE_DETAIL_FETCH_REQUEST';
export const MARKETPLACE_DETAIL_FETCH_SUCCESS = 'MARKETPLACE/MARKETPLACE_DETAIL_FETCH_SUCCESS';
export const MARKETPLACE_DETAIL_FETCH_ERROR = 'MARKETPLACE/MARKETPLACE_DETAIL_FETCH_ERROR';

/**
 * Metrics Action types
 */
export const METRICS_FETCH_REQUEST = 'DASHBOARD/METRICS_FETCH_REQUEST';
export const METRICS_FETCH_SUCCESS = 'DASHBOARD/METRICS_FETCH_SUCCESS';
export const METRICS_FETCH_ERROR = 'DASHBOARD/METRICS_FETCH_ERROR';

/**
 * Customer Detail Action types
 */
export const CUSTOMER_ADD_UPDATE_REQUEST = 'CUSTOMER/CUSTOMER_ADD_UPDATE_REQUEST';
export const CUSTOMER_ADD_UPDATE_SUCCESS = 'CUSTOMER/CUSTOMER_ADD_UPDATE_SUCCESS';
export const CUSTOMER_ADD_UPDATE_ERROR = 'CUSTOMER/CUSTOMER_ADD_UPDATE_ERROR';

export const FLEET_FETCH_REQUEST = 'FLEET/FLEET_FETCH_REQUEST';
export const FLEET_FETCH_SUCCESS = 'FLEET/FLEET_FETCH_SUCCESS';
export const FLEET_FETCH_ERROR = 'FLEET/FLEET_FETCH_ERROR';

export const FLEET_ADD_REQUEST = 'FLEET/FLEET_ADD_REQUEST';
export const FLEET_ADD_SUCCESS = 'FLEET/FLEET_ADD_SUCCESS';
export const FLEET_ADD_ERROR = 'FLEET/FLEET_ADD_ERROR';

export const FLEET_UPDATE_REQUEST = 'FLEET/FLEET_UPDATE_REQUEST';
export const FLEET_UPDATE_SUCCESS = 'FLEET/FLEET_UPDATE_SUCCESS';
export const FLEET_UPDATE_ERROR = 'FLEET/FLEET_UPDATE_ERROR';

export const FLEET_CLEAR = 'FLEET/FLEET_CLEAR';

/**
 * Lead Detail Action types
 */
export const LEAD_FETCH_REQUEST = 'LEAD/LEAD_FETCH_REQUEST';
export const LEAD_FETCH_SUCCESS = 'LEAD/LEAD_FETCH_SUCCESS';
export const LEAD_FETCH_ERROR = 'LEAD/LEAD_FETCH_ERROR';

export const LEAD_ADD_REQUEST = 'LEAD/LEAD_ADD_REQUEST';
export const LEAD_ADD_SUCCESS = 'LEAD/LEAD_ADD_SUCCESS';
export const LEAD_ADD_ERROR = 'LEAD/LEAD_ADD_ERROR';

export const LEAD_UPDATE_REQUEST = 'LEAD/LEAD_UPDATE_REQUEST';
export const LEAD_UPDATE_SUCCESS = 'LEAD/LEAD_UPDATE_SUCCESS';
export const LEAD_UPDATE_ERROR = 'LEAD/LEAD_UPDATE_ERROR';

export const LEAD_CLEAR = 'LEAD/LEAD_CLEAR';

/**
 * Partner Detail Action types
 */
export const PARTNER_FETCH_REQUEST = 'PARTNER/PARTNER_FETCH_REQUEST';
export const PARTNER_FETCH_SUCCESS = 'PARTNER/PARTNER_FETCH_SUCCESS';
export const PARTNER_FETCH_ERROR = 'PARTNER/PARTNER_FETCH_ERROR';

/**
 * Host List Action types
 */
export const HOST_LIST_FETCH_REQUEST = 'HOST/HOST_LIST_FETCH_REQUEST';
export const HOST_LIST_FETCH_SUCCESS = 'HOST/HOST_LIST_FETCH_SUCCESS';
export const HOST_LIST_FETCH_ERROR = 'HSOT/HOST_LIST_FETCH_ERROR';

/**
 * Report Action types
 */
export const REPORT_FETCH_REQUEST = 'REPORT/REPORT_FETCH_REQUEST';
export const REPORT_FETCH_SUCCESS = 'REPORT/REPORT_FETCH_SUCCESS';
export const REPORT_FETCH_ERROR = 'REPORT/REPORT_FETCH_ERROR';

/**
 * Lead List Action types
 */
export const LEAD_LIST_FETCH_REQUEST = 'LEAD/LEAD_LIST_FETCH_REQUEST';
export const LEAD_LIST_FETCH_SUCCESS = 'LEAD/LEAD_LIST_FETCH_SUCCESS';
export const LEAD_LIST_FETCH_ERROR = 'LEAD/LEAD_LIST_FETCH_ERROR';

/**
 * Solution List Action types
 */
export const SOLUTION_LIST_FETCH_REQUEST = 'SOLUTION/SOLUTION_LIST_FETCH_REQUEST';
export const SOLUTION_LIST_FETCH_SUCCESS = 'SOLUTION/SOLUTION_LIST_FETCH_SUCCESS';
export const SOLUTION_LIST_FETCH_ERROR = 'SOLUTION/SOLUTION_LIST_FETCH_ERROR';

/**
 * Subscription List Action types
 */
export const SUBSCRIPTION_LIST_FETCH_REQUEST = 'SUBSCRIPTION/SUBSCRIPTION_LIST_FETCH_REQUEST';
export const SUBSCRIPTION_LIST_FETCH_SUCCESS = 'SUBSCRIPTION/SUBSCRIPTION_LIST_FETCH_SUCCESS';
export const SUBSCRIPTION_LIST_FETCH_ERROR = 'SUBSCRIPTION/SUBSCRIPTION_LIST_FETCH_ERROR';

/**
 * Subscription Detail Action types
 */
export const SUBSCRIPTION_DETAIL_ADD_REQUEST = 'SUBSCRIPTION/SUBSCRIPTION_DETAIL_ADD_REQUEST';
export const SUBSCRIPTION_DETAIL_ADD_SUCCESS = 'SUBSCRIPTION/SUBSCRIPTION_DETAIL_ADD_SUCCESS';
export const SUBSCRIPTION_DETAIL_ADD_ERROR = 'SUBSCRIPTION/SUBSCRIPTION_DETAIL_ADD_ERROR';

export const SUBSCRIPTION_DETAIL_FETCH_REQUEST = 'SUBSCRIPTION/SUBSCRIPTION_DETAIL_FETCH_REQUEST';
export const SUBSCRIPTION_DETAIL_FETCH_SUCCESS = 'SUBSCRIPTION/SUBSCRIPTION_DETAIL_FETCH_SUCCESS';
export const SUBSCRIPTION_DETAIL_FETCH_ERROR = 'SUBSCRIPTION/SUBSCRIPTION_DETAIL_FETCH_ERROR';

export const SUBSCRIPTION_DETAIL_START_REQUEST = 'SUBSCRIPTION/SUBSCRIPTION_DETAIL_START_REQUEST';
export const SUBSCRIPTION_DETAIL_START_SUCCESS = 'SUBSCRIPTION/SUBSCRIPTION_DETAIL_START_SUCCESS';
export const SUBSCRIPTION_DETAIL_START_ERROR = 'SUBSCRIPTION/SUBSCRIPTION_DETAIL_START_ERROR';

export const SUBSCRIPTION_DETAIL_UPDATE_REQUEST = 'SUBSCRIPTION/SUBSCRIPTION_DETAIL_UPDATE_REQUEST';
export const SUBSCRIPTION_DETAIL_UPDATE_SUCCESS = 'SUBSCRIPTION/SUBSCRIPTION_DETAIL_UPDATE_SUCCESS';
export const SUBSCRIPTION_DETAIL_UPDATE_ERROR = 'SUBSCRIPTION/SUBSCRIPTION_DETAIL_UPDATE_ERROR';

export const SUBSCRIPTION_DETAIL_CLEAR = 'SUBSCRIPTION/SUBSCRIPTION_DETAIL_CLEAR';

/**
 * Solution Detail Action types
 */
export const SOLUTION_FETCH_REQUEST = 'SOLUTION/SOLUTION_FETCH_REQUEST';
export const SOLUTION_FETCH_SUCCESS = 'SOLUTION/SOLUTION_FETCH_SUCCESS';
export const SOLUTION_FETCH_ERROR = 'SOLUTION/SOLUTION_FETCH_ERROR';

export const SOLUTION_ADD_REQUEST = 'SOLUTION/SOLUTION_ADD_REQUEST';
export const SOLUTION_ADD_SUCCESS = 'SOLUTION/SOLUTION_ADD_SUCCESS';
export const SOLUTION_ADD_ERROR = 'SOLUTION/SOLUTION_ADD_ERROR';

export const SOLUTION_DETAIL_CLEAR = 'SOLUTION/SOLUTION_DETAIL_CLEAR';

export const SOLUTION_CLONE_REQUEST = 'SOLUTION/SOLUTION_CLONE_REQUEST';
export const SOLUTION_CLONE_SUCCESS = 'SOLUTION/SOLUTION_CLONE_SUCCESS';
export const SOLUTION_CLONE_ERROR = 'SOLUTION/SOLUTION_CLONE_ERROR';

export const SOLUTION_DELETE_REQUEST = 'SOLUTION/SOLUTION_DELETE_REQUEST';
export const SOLUTION_DELETE_SUCCESS = 'SOLUTION/SOLUTION_DELETE_SUCCESS';
export const SOLUTION_DELETE_ERROR = 'SOLUTION/SOLUTION_DELETE_ERROR';

/**
 * Contact Form Action types
 */
export const CONTACT_FORM_SUBMIT_REQUEST = 'CONTACT_FORM/SUBMIT_REQUEST';
export const CONTACT_FORM_SUBMIT_SUCCESS = 'CONTACT_FORM/SUBMIT_SUCCESS';
export const CONTACT_FORM_SUBMIT_ERROR = 'CONTACT_FORM/SUBMIT_ERROR';

/**
 * Data Action types
 */
export const DATA_TYPE_CLASS_LIST_FETCH_REQUEST = 'DATA_TYPE_CLASS_LIST/FETCH_REQUEST';
export const DATA_TYPE_CLASS_LIST_FETCH_SUCCESS = 'DATA_TYPE_CLASS_LIST/FETCH_SUCCESS';
export const DATA_TYPE_CLASS_LIST_FETCH_ERROR = 'DATA_TYPE_CLASS_LIST/FETCH_ERROR';

/**
 * Spinner Action types
 */
export const UPDATE_SPINNER = 'UPDATE_SPINNER';

export const WFE_BUYFLOW_DETAIL_FETCH_REQUEST = 'WFE/BUYFLOW_DETAIL_FETCH_REQUEST';
export const WFE_BUYFLOW_DETAIL_FETCH_SUCCESS = 'WFE/BUYFLOW_DETAIL_FETCH_SUCCESS';
export const WFE_BUYFLOW_DETAIL_FETCH_ERROR = 'WFE/BUYFLOW_DETAIL_FETCH_ERROR';
export const WFE_BUYFLOW_DETAIL_CLEAR = 'WFE/BUYFLOW_DETAIL_CLEAR';


/**
 * Message Action types
 */
export const ADD_MESSAGE = 'ADD_MESSAGE';
export const REMOVE_MESSAGES = 'REMOVE_MESSAGES';
export const REMOVE_ALL_MESSAGES = 'REMOVE_ALL_MESSAGES';


/**
 * Account Action types
 */
export const TOGGLE_ACCOUNT_MODE = 'TOGGLE_ACCOUNT_MODE';
export const SWITCH_ACCOUNT_MODE = 'SWITCH_ACCOUNT_MODE';
export const ASSIGN_PERMISSIONS = 'ASSIGN_PERMISSIONS';

/**
 * Billing Order List Action types
 */
export const BILLING_ORDER_LIST_FETCH_REQUEST = 'BILLING_ORDER/BILLING_ORDER_LIST_FETCH_REQUEST';
export const BILLING_ORDER_LIST_FETCH_SUCCESS = 'BILLING_ORDER/BILLING_ORDER_LIST_FETCH_SUCCESS';
export const BILLING_ORDER_LIST_FETCH_ERROR = 'BILLING_ORDER/BILLING_ORDER_LIST_FETCH_ERROR';

export const BILLING_CALCULATE_TAX_REQUEST = 'BILLING/BILLING_CALCULATE_TAX_REQUEST';
export const BILLING_CALCULATE_TAX_SUCCESS = 'BILLING/BILLING_CALCULATE_TAX_SUCCESS';
export const BILLING_CALCULATE_TAX_ERROR = 'BILLING/BILLING_CALCULATE_TAX_ERROR';
export const BILLING_CALCULATE_TAX_CLEAR = 'BILLING/BILLING_CALCULATE_TAX_CLEAR';

/**
 * Subscription Update Action types
 */
export const SUBSCRIPTION_UPDATE_ADD_REQUEST = 'SUBSCRIPTION/SUBSCRIPTION_UPDATE_ADD_REQUEST';
export const SUBSCRIPTION_UPDATE_ADD_SUCCESS = 'SUBSCRIPTION/SUBSCRIPTION_UPDATE_ADD_SUCCESS';
export const SUBSCRIPTION_UPDATE_ADD_ERROR = 'SUBSCRIPTION/SUBSCRIPTION_UPDATE_ADD_ERROR';

export const SUBSCRIPTION_UPDATE_LIST_FETCH_REQUEST = 'SUBSCRIPTION_UPDATE/SUBSCRIPTION_UPDATE_LIST_FETCH_REQUEST';
export const SUBSCRIPTION_UPDATE_LIST_FETCH_SUCCESS = 'SUBSCRIPTION_UPDATE/SUBSCRIPTION_UPDATE_LIST_FETCH_SUCCESS';
export const SUBSCRIPTION_UPDATE_LIST_FETCH_ERROR = 'SUBSCRIPTION_UPDATE/SUBSCRIPTION_UPDATE_LIST_FETCH_ERROR';
export const SUBSCRIPTION_UPDATE_LIST_CLEAR = 'SUBSCRIPTION_UPDATE/SUBSCRIPTION_UPDATE_LIST_CLEAR';


/**
 * Billing Subscription Action types
 */
export const BILLING_SUBSCRIPTION_FETCH_EFFECTIVE_DATE_REQUEST = 'BILLING/BILLING_SUBSCRIPTION_FETCH_EFFECTIVE_DATE_REQUEST';
export const BILLING_SUBSCRIPTION_FETCH_EFFECTIVE_DATE_SUCCESS = 'BILLING/BILLING_SUBSCRIPTION_FETCH_EFFECTIVE_DATE_SUCCESS';
export const BILLING_SUBSCRIPTION_FETCH_EFFECTIVE_DATE_ERROR = 'BILLING/BILLING_SUBSCRIPTION_FETCH_EFFECTIVE_DATE_ERROR';
export const BILLING_SUBSCRIPTION_FETCH_EFFECTIVE_DATE_CLEAR = 'BILLING/BILLING_SUBSCRIPTION_FETCH_EFFECTIVE_DATE_CLEAR';

/**
 * Settings Action types
 */

export const SETTINGS_FETCH_REQUEST = 'SETTINGS/SETTINGS_FETCH_REQUEST';
export const SETTINGS_FETCH_SUCCESS = 'SETTINGS/SETTINGS_FETCH_SUCCESS';
export const SETTINGS_FETCH_ERROR = 'SETTINGS/SETTINGS_FETCH_ERROR';

/**
 * Solution Discount Action types
 */

export const SOLUTION_DISCOUNT_LIST_FETCH_REQUEST = 'SOLUTION_DISCOUNT/SOLUTION_DISCOUNT_LIST_FETCH_REQUEST';
export const SOLUTION_DISCOUNT_LIST_FETCH_SUCCESS = 'SOLUTION_DISCOUNT/SOLUTION_DISCOUNT_LIST_FETCH_SUCCESS';
export const SOLUTION_DISCOUNT_LIST_FETCH_ERROR = 'SOLUTION_DISCOUNT/SOLUTION_DISCOUNT_LIST_FETCH_ERROR';
export const SOLUTION_DISCOUNT_LIST_CLEAR = 'SOLUTION_DISCOUNT/SOLUTION_DISCOUNT_LIST_CLEAR';

export const SOLUTION_DISCOUNT_ADD_REQUEST = 'SOLUTION_DISCOUNT/SOLUTION_DISCOUNT_ADD_REQUEST';
export const SOLUTION_DISCOUNT_ADD_SUCCESS = 'SOLUTION_DISCOUNT/SOLUTION_DISCOUNT_ADD_SUCCESS';
export const SOLUTION_DISCOUNT_ADD_ERROR = 'SOLUTION_DISCOUNT/SOLUTION_DISCOUNT_ADD_ERROR';

export const SOLUTION_DISCOUNT_UPDATE_REQUEST = 'SOLUTION_DISCOUNT/SOLUTION_DISCOUNT_UPDATE_REQUEST';
export const SOLUTION_DISCOUNT_UPDATE_SUCCESS = 'SOLUTION_DISCOUNT/SOLUTION_DISCOUNT_UPDATE_SUCCESS';
export const SOLUTION_DISCOUNT_UPDATE_ERROR = 'SOLUTION_DISCOUNT/SOLUTION_DISCOUNT_UPDATE_ERROR';

export const SOLUTION_DISCOUNT_DELETE_REQUEST = 'SOLUTION_DISCOUNT/SOLUTION_DISCOUNT_DELETE_REQUEST';
export const SOLUTION_DISCOUNT_DELETE_SUCCESS = 'SOLUTION_DISCOUNT/SOLUTION_DISCOUNT_DELETE_SUCCESS';
export const SOLUTION_DISCOUNT_DELETE_ERROR = 'SOLUTION_DISCOUNT/SOLUTION_DISCOUNT_DELETE_ERROR';

export const SOLUTION_DISCOUNTS_ADD_REQUEST = 'SOLUTION_DISCOUNT/SOLUTION_DISCOUNTS_ADD_REQUEST';
export const SOLUTION_DISCOUNTS_ADD_ERROR = 'SOLUTION_DISCOUNT/SOLUTION_DISCOUNTS_ADD_ERROR';

/**
 * Subscription Discount Action types
 */

export const SUBSCRIPTION_DISCOUNT_LIST_FETCH_REQUEST = 'SUBSCRIPTION_DISCOUNT/SUBSCRIPTION_DISCOUNT_LIST_FETCH_REQUEST';
export const SUBSCRIPTION_DISCOUNT_LIST_FETCH_SUCCESS = 'SUBSCRIPTION_DISCOUNT/SUBSCRIPTION_DISCOUNT_LIST_FETCH_SUCCESS';
export const SUBSCRIPTION_DISCOUNT_LIST_FETCH_ERROR = 'SUBSCRIPTION_DISCOUNT/SUBSCRIPTION_DISCOUNT_LIST_FETCH_ERROR';
export const SUBSCRIPTION_DISCOUNT_LIST_CLEAR = 'SUBSCRIPTION_DISCOUNT/SUBSCRIPTION_DISCOUNT_LIST_CLEAR';
export const SOLUTION_DISCOUNT_UPDATE_CLEAR_IS_PRIMARY = 'SOLUTION_DISCOUNT_UPDATE_CLEAR_IS_PRIMARY';

/**
 * Solution Promotion Action types
 */

export const SOLUTION_PROMOTION_UPDATE_FETCH_REQUEST = 'SOLUTION_PROMOTION/SOLUTION_PROMOTION_UPDATE_FETCH_REQUEST';
export const SOLUTION_PROMOTION_UPDATE_FETCH_SUCCESS = 'SOLUTION_PROMOTION/SOLUTION_PROMOTION_UPDATE_FETCH_SUCCESS';
export const SOLUTION_PROMOTION_UPDATE_FETCH_ERROR = 'SOLUTION_PROMOTION/SOLUTION_PROMOTION_UPDATE_FETCH_ERROR';

/**
 * Marketplace Resource Action types
 */
export const MARKETPLACE_RESOURCE_LIST_FETCH_REQUEST = 'MARKETPLACE_RESOURCE/MARKETPLACE_RESOURCE_LIST_FETCH_REQUEST';
export const MARKETPLACE_RESOURCE_LIST_FETCH_SUCCESS = 'MARKETPLACE_RESOURCE/MARKETPLACE_RESOURCE_LIST_FETCH_SUCCESS';
export const MARKETPLACE_RESOURCE_LIST_FETCH_ERROR = 'MARKETPLACE_RESOURCE/MARKETPLACE_RESOURCE_LIST_FETCH_ERROR';

export const MARKETPLACE_RESOURCE_CHANGE_ORDER_REQUEST = 'MARKETPLACE_RESOURCE/MARKETPLACE_RESOURCE_CHANGE_ORDER_REQUEST';
export const MARKETPLACE_RESOURCE_CHANGE_ORDER_SUCCESS = 'MARKETPLACE_RESOURCE/MARKETPLACE_RESOURCE_CHANGE_ORDER_SUCCESS';
export const MARKETPLACE_RESOURCE_CHANGE_ORDER_ERROR = 'MARKETPLACE_RESOURCE/MARKETPLACE_RESOURCE_CHANGE_ORDER_ERROR';

export const MARKETPLACE_RESOURCE_ADD_REQUEST = 'MARKETPLACE_RESOURCE/MARKETPLACE_RESOURCE_ADD_REQUEST';
export const MARKETPLACE_RESOURCE_ADD_SUCCESS = 'MARKETPLACE_RESOURCE/MARKETPLACE_RESOURCE_ADD_SUCCESS';
export const MARKETPLACE_RESOURCE_ADD_ERROR = 'MARKETPLACE_RESOURCE/MARKETPLACE_RESOURCE_ADD_ERROR';

export const MARKETPLACE_RESOURCE_EDIT_REQUEST = 'MARKETPLACE_RESOURCE/MARKETPLACE_RESOURCE_EDIT_REQUEST';
export const MARKETPLACE_RESOURCE_EDIT_SUCCESS = 'MARKETPLACE_RESOURCE/MARKETPLACE_RESOURCE_EDIT_SUCCESS';
export const MARKETPLACE_RESOURCE_EDIT_ERROR = 'MARKETPLACE_RESOURCE/MARKETPLACE_RESOURCE_EDIT_ERROR';

export const MARKETPLACE_RESOURCE_DELETE_REQUEST = 'MARKETPLACE_RESOURCE/MARKETPLACE_RESOURCE_DELETE_REQUEST';
export const MARKETPLACE_RESOURCE_DELETE_SUCCESS = 'MARKETPLACE_RESOURCE/MARKETPLACE_RESOURCE_DELETE_SUCCESS';
export const MARKETPLACE_RESOURCE_DELETE_ERROR = 'MARKETPLACE_RESOURCE/MARKETPLACE_RESOURCE_DELETE_ERROR';

export const MARKETPLACE_RESOURCE_PUBLISH_REQUEST = 'MARKETPLACE_RESOURCE/MARKETPLACE_RESOURCE_PUBLISH_REQUEST';
export const MARKETPLACE_RESOURCE_PUBLISH_SUCCESS = 'MARKETPLACE_RESOURCE/MARKETPLACE_RESOURCE_PUBLISH_SUCCESS';
export const MARKETPLACE_RESOURCE_PUBLISH_ERROR = 'MARKETPLACE_RESOURCE/MARKETPLACE_RESOURCE_PUBLISH_ERROR';

export const MARKETPLACE_RESOURCE_FETCH_REQUEST = 'MARKETPLACE_RESOURCE/MARKETPLACE_RESOURCE_FETCH_REQUEST';
export const MARKETPLACE_RESOURCE_FETCH_SUCCESS = 'MARKETPLACE_RESOURCE/MARKETPLACE_RESOURCE_FETCH_SUCCESS';
export const MARKETPLACE_RESOURCE_FETCH_ERROR = 'MARKETPLACE_RESOURCE/MARKETPLACE_RESOURCE_FETCH_ERROR';

export const MARKETPLACE_RESOURCE_CLEAR_DETAIL = 'MARKETPLACE_RESOURCE/MARKETPLACE_RESOURCE_CLEAR_DETAIL';

export const MARKETPLACE_RESOURCE_STATIC_FILES_FETCH_REQUEST = 'MARKETPLACE_RESOURCE/MARKETPLACE_RESOURCE_STATIC_FILES_FETCH_REQUEST';
export const MARKETPLACE_RESOURCE_STATIC_FILES_LIST_FETCH_SUCCESS = 'MARKETPLACE_RESOURCE/MARKETPLACE_RESOURCE_STATIC_FILES_LIST_FETCH_SUCCESS';
export const MARKETPLACE_RESOURCE_STATIC_FILES_LIST_FETCH_ERROR = 'MARKETPLACE_RESOURCE/MARKETPLACE_RESOURCE_STATIC_FILES_LIST_FETCH_ERROR';

/**
 * Solution Resource Action types
 */
export const SOLUTION_RESOURCE_DETAIL_FETCH_REQUEST = 'SOLUTION_RESOURCE/SOLUTION_RESOURCE_DETAIL_FETCH_REQUEST';
export const SOLUTION_RESOURCE_DETAIL_FETCH_SUCCESS = 'SOLUTION_RESOURCE/SOLUTION_RESOURCE_DETAIL_FETCH_SUCCESS';
export const SOLUTION_RESOURCE_DETAIL_FETCH_ERROR = 'SOLUTION_RESOURCE/SOLUTION_RESOURCE_DETAIL_FETCH_ERROR';

export const SOLUTION_RESOURCE_LIST_FETCH_REQUEST = 'SOLUTION_RESOURCE/SOLUTION_RESOURCE_LIST_FETCH_REQUEST';
export const SOLUTION_RESOURCE_LIST_FETCH_SUCCESS = 'SOLUTION_RESOURCE/SOLUTION_RESOURCE_LIST_FETCH_SUCCESS';
export const SOLUTION_RESOURCE_LIST_FETCH_ERROR = 'SOLUTION_RESOURCE/SOLUTION_RESOURCE_LIST_FETCH_ERROR';

/**
 * Solution Marketplace Action types
 */
export const SOLUTION_MARKETPLACE_LIST_FETCH_REQUEST = 'SOLUTION_MARKETPLACE/SOLUTION_MARKETPLACE_LIST_FETCH_REQUEST';
export const SOLUTION_MARKETPLACE_LIST_FETCH_SUCCESS = 'SOLUTION_MARKETPLACE/SOLUTION_MARKETPLACE_LIST_FETCH_SUCCESS';
export const SOLUTION_MARKETPLACE_LIST_FETCH_ERROR = 'SOLUTION_MARKETPLACE/SOLUTION_MARKETPLACE_LIST_FETCH_ERROR';

/**
 * Solution Version Action types
 */
export const SOLUTION_VERSION_LIST_FETCH_REQUEST = 'SOLUTION_VERSION/SOLUTION_VERSION_LIST_FETCH_REQUEST';
export const SOLUTION_VERSION_LIST_FETCH_SUCCESS = 'SOLUTION_VERSION/SOLUTION_VERSION_LIST_FETCH_SUCCESS';
export const SOLUTION_VERSION_LIST_FETCH_ERROR = 'SOLUTION_VERSION/SOLUTION_VERSION_LIST_FETCH_ERROR';

export const SOLUTION_VERSION_DETAIL_FETCH_REQUEST = 'SOLUTION_VERSION/SOLUTION_VERSION_DETAIL_FETCH_REQUEST';
export const SOLUTION_VERSION_DETAIL_FETCH_SUCCESS = 'SOLUTION_VERSION/SOLUTION_VERSION_DETAIL_FETCH_SUCCESS';
export const SOLUTION_VERSION_DETAIL_FETCH_ERROR = 'SOLUTION_VERSION/SOLUTION_VERSION_DETAIL_FETCH_ERROR';

export const SOLUTION_VERSION_DETAIL_CLEAR = 'SOLUTION_VERSION/SOLUTION_VERSION_DETAIL_CLEAR';
