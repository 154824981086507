import React from "react";
import {Button, Col, Row, FormGroup, Label} from "reactstrap";

class SolutionPromotionResourceUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filesData: [],
      uploadedFilesData: [],
      error: "",
    };
    this.handleFilesChange = this.handleFilesChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    if (this.props.resources) {
      this.setState({
        uploadedFilesData: this.props.resources,
      });
    }
  }

  readFile(files, filesData, idx) {
    let reader = new FileReader();
    let file = files[idx];
    const {setAddedResources} = this.props;
    reader.onloadend = () => {
      filesData.push({
        name: files[idx].name,
        data: reader.result,
        size: files[idx].size,
      });

      if (idx + 1 < files.length) {
        this.readFile(files, filesData, idx + 1);
      } else {
        this.setState({filesData});
        this.refs.fileInput.value = null;
        if (setAddedResources) {
          setAddedResources(filesData);
          if (this.state.uploadedFilesData && this.state.uploadedFilesData.length > 0) {
            this.removeUploadedFile(0)
          }
        }
      }
    };
    reader.readAsDataURL(file);
  }

  handleFilesChange(e) {
    e.preventDefault();
    const {setFilesSize} = this.props;
    const availableFileTypes = [
      "image/gif",
      "image/jpeg",
      "image/png",
      "image/tiff",
    ];
    if (!e.target.files.length) {
      return;
    }
    if (!e.target.files[0].size) {
      this.setState({error: "Empty files cannot be uploaded"});
    } else if (!availableFileTypes.includes(e.target.files[0].type)) {
      this.setState({
        error: `The file type (${e.target.files[0].type}) is not supported`,
      });
    } else {
      try {
        setFilesSize(e.target.files[0].size);
        this.setState({error: ""});
        this.readFile(e.target.files, this.state.filesData, 0);
      } catch (e) {
        this.setState({error: e.toString()});
      }
    }
  }

  removeNewFile(idx) {
    const {setFilesSize, removeAddedResource} = this.props;
    let newFilesData = [...this.state.filesData];
    const deletedFileSize = newFilesData[idx].size;
    newFilesData.splice(idx, 1);
    this.setState({
      filesData: newFilesData,
    });
    if (removeAddedResource) {
      removeAddedResource(idx);
    }

    try {
      setFilesSize(deletedFileSize, "minus");
      this.setState({error: ""});
    } catch (e) {
      this.setState({error: e.toString()});
    }
  }

  removeUploadedFile(idx) {
    const {addRemovedResource} = this.props;
    let newUploadedFilesData = [...this.state.uploadedFilesData];
    newUploadedFilesData.splice(idx, 1);
    this.setState({
      uploadedFilesData: newUploadedFilesData,
    });
    addRemovedResource(idx);
  }

  handleClick() {
    this.refs.fileInput.click();
  }

  render() {

    const {filesData, uploadedFilesData} = this.state;
    const totalFilesLen = uploadedFilesData.length + filesData.length;
    const {submitFailed, imageRequired} = this.props;

    return (
      <div>
        <Row
          className={
            !totalFilesLen && submitFailed && imageRequired ? "media-upload-error" : null
          }
        >
          <Col>
            {((!this.state.filesData || this.state.filesData.length === 0) &&
                (!this.state.uploadedFilesData || this.state.uploadedFilesData.length === 0)) &&
              <FormGroup>
                <Button
                  id="addImages"
                  className="has-danger"
                  color="outline-primary"
                  onClick={() => this.handleClick()}
                >
                  Add Image
                </Button>
              </FormGroup>
            }
            <div className="fileinput text-center">
              <input
                type="file"
                onChange={this.handleFilesChange}
                ref="fileInput"
                accept="image/gif, image/jpeg, image/png, image/tiff"
              />
            </div>

          </Col>
        </Row>
        {!totalFilesLen && submitFailed && imageRequired && (
          <label className="error">Please add one or more files</label>
        )}

        <div className="d-flex media-carousel">
          {uploadedFilesData.map((resourceLink, idx) => {
            return (
              <div key={idx} className="pr-3 pt-3">
                <div className="position-relative media-item-wrapper">
                  <div className="media-item-front-cover"></div>
                  <div className="position-absolute media-item-remove-icon">
                    <i
                      className="fa fa-times fa-lg"
                      onClick={() => this.removeUploadedFile(idx)}
                    />
                  </div>

                  <img
                    className="media-img"
                    alt=""
                    src={resourceLink || data}
                  />
                </div>
              </div>
            );
          })}
          {filesData.map(({resourceLink, data}, idx) => {
            return (
              <div key={idx} className="pr-3 pt-3">
                <div className="position-relative media-item-wrapper">
                  <div className="media-item-front-cover"></div>
                  <div className="position-absolute media-item-remove-icon">
                    <i
                      className="fa fa-times fa-lg"
                      onClick={() => this.removeNewFile(idx)}
                    />
                  </div>

                  <img className="media-img" alt="" src={data}/>
                </div>
              </div>
            );
          })}
        </div>

        {this.state.error && (
          <div>
            <Label className="error mt-1">{this.state.error}</Label>
          </div>
        )}
      </div>
    );
  }
}

export default SolutionPromotionResourceUpload;
