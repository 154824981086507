/* eslint-disable linebreak-style */
import camelcaseKeys from 'camelcase-keys';
import {
  SOLUTION_FETCH_REQUEST,
  SOLUTION_FETCH_SUCCESS,
  SOLUTION_FETCH_ERROR,
  SOLUTION_ADD_REQUEST,
  SOLUTION_ADD_SUCCESS,
  SOLUTION_ADD_ERROR,
  SOLUTION_DETAIL_CLEAR,
  SOLUTION_PROMOTION_UPDATE_FETCH_REQUEST,
  SOLUTION_PROMOTION_UPDATE_FETCH_ERROR,
  SOLUTION_PROMOTION_UPDATE_FETCH_SUCCESS, SOLUTION_CLONE_REQUEST, SOLUTION_CLONE_ERROR,
} from "../actions/actionTypes";

const initialState = {
  solution: {
    name: "",
    description: "",
    fleetSegments: "",
    vehicleClasses: "",
    companyName: "",
    summary: "",
    internalName: ""
  },
  isLoading: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SOLUTION_ADD_REQUEST:
    case SOLUTION_FETCH_REQUEST:
    case SOLUTION_CLONE_REQUEST:
    case SOLUTION_PROMOTION_UPDATE_FETCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: '',
      };

    case SOLUTION_ADD_SUCCESS:
    case SOLUTION_FETCH_SUCCESS:
      const data = camelcaseKeys(action.data, {deep: true});
      return {
        ...state,
        solution: data,
        isLoading: false,
        error: action.error
      };

    case SOLUTION_CLONE_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    case SOLUTION_ADD_ERROR:
    case SOLUTION_FETCH_ERROR:
      return {
        ...state,
        solution: {
          name: "",
          description: "",
          fleetSegments: "",
          vehicleClasses: "",
          companyName: "",
          summary: "",
          internalName: ""
        },
        isLoading: false,
        error: action.error,
      };
    case SOLUTION_DETAIL_CLEAR:
      return {
        solution: {
          name: "",
          description: "",
          fleetSegments: "",
          vehicleClasses: "",
          companyName: "",
          summary: "",
          internalName: ""
        },
        isLoading: false,
        error: null
      };

    case SOLUTION_PROMOTION_UPDATE_FETCH_ERROR:
      return {
        ...state,
        solution: {
          ...state.solution,
          promotion: "",
        },
        isLoading: false,
        error: action.error,
      };

    case SOLUTION_PROMOTION_UPDATE_FETCH_SUCCESS:
      const payload = camelcaseKeys(action.data, {deep: true});
      return {
        ...state,
        solution: {
          ...state.solution,
          promotion: payload,
        },
        isLoading: false,
        error: action.error
      };

    default:
      return state;
  }
};
