/* eslint-disable linebreak-style */
import camelcaseKeys from 'camelcase-keys';
import {
  PARTNER_FETCH_REQUEST,
  PARTNER_FETCH_SUCCESS,
  PARTNER_FETCH_ERROR
} from "../actions/actionTypes";

const initialState = {
  partner: {}, // partner detail returned from the api
  isLoading: false, // boolean variable to indicate fetching api is in progress
  error: null, // error details
};

export default (state = initialState, action) => {
  switch (action.type) {

    case PARTNER_FETCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };


    case PARTNER_FETCH_SUCCESS:
      {
        const data = camelcaseKeys(action.data, {deep: true});
        return {
          ...state,
          partner: data,
          isLoading: false,
          error: null
        };
      }

    case PARTNER_FETCH_ERROR:
      return {
        ...state,
        partner: {},
        isLoading: false,
        error: action.error,
      };

    default:
      return state;
  }
};
