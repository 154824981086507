// VARIANT_0
export const ONE_TO_FIVE = '1 - 5';
export const SIX_TO_TWENTY = '6 - 20';
export const TWENTY_ONE_TO_FIFTY = '21 - 50';
export const FIFTY_ONE_TO_HUNDRED = '51 - 100';
export const ONE_HUNDRED_ONE_TO_TWO_HUNDRED_FIFTY = '101 - 250';
export const TWO_HUNDRED_FIFTY_ONE_TO_FIVE_HUNDRED = '251 - 500';
export const FIVE_HUNDRED_TO_ONE_THOUSAND = '501 - 1000';
export const ONE_THOUSAND_ONE_TO_TWO_THOUSAND_FIVE_HUNDRED = '1001 - 2500';
export const TWO_THOUSAND_FIVE_HUNDRED_ONE_TO_FIVE_THOUSAND = '2501 - 5000';
export const FIVE_THOUSAND_ONE_TO_TEN_HUNDRED_THOUSAND = '5001 - 10000';
export const TEN_THOUSAND_ONE_OR_MORE = '10001 +';

// VARIANT_1
export const OWNER_OPERATOR = 'Owner-operator'
export const TWO_TO_FIVE = '2 - 5'
// export const SIX_TO_TWENTY = '6 - 20'
export const TWENTY_ONE_TO_ONE_HUNDRED = '21 - 100'
export const ONE_HUNDRED_ONE_TO_FIVE_HUNDRED = '101 - 500'
export const FIVE_HUNDRED_ONE_OR_MORE = '501 +'


export const FLEET_SIZE_OPTIONS_VARIANT_0 = {
    ONE_TO_FIVE : ONE_TO_FIVE,
    SIX_TO_TWENTY : SIX_TO_TWENTY,
    TWENTY_ONE_TO_FIFTY : TWENTY_ONE_TO_FIFTY,
    FIFTY_ONE_TO_HUNDRED : FIFTY_ONE_TO_HUNDRED,
    ONE_HUNDRED_ONE_TO_TWO_HUNDRED_FIFTY : ONE_HUNDRED_ONE_TO_TWO_HUNDRED_FIFTY,
    TWO_HUNDRED_FIFTY_ONE_TO_FIVE_HUNDRED : TWO_HUNDRED_FIFTY_ONE_TO_FIVE_HUNDRED,
    FIVE_HUNDRED_TO_ONE_THOUSAND : FIVE_HUNDRED_TO_ONE_THOUSAND,
    ONE_THOUSAND_ONE_TO_TWO_THOUSAND_FIVE_HUNDRED : ONE_THOUSAND_ONE_TO_TWO_THOUSAND_FIVE_HUNDRED,
    TWO_THOUSAND_FIVE_HUNDRED_ONE_TO_FIVE_THOUSAND : TWO_THOUSAND_FIVE_HUNDRED_ONE_TO_FIVE_THOUSAND,
    FIVE_THOUSAND_ONE_TO_TEN_HUNDRED_THOUSAND : FIVE_THOUSAND_ONE_TO_TEN_HUNDRED_THOUSAND,
    TEN_THOUSAND_ONE_OR_MORE : TEN_THOUSAND_ONE_OR_MORE,
};

export const FLEET_SIZE_OPTIONS_VARIANT_1 = {
    OWNER_OPERATOR : OWNER_OPERATOR,
    TWO_TO_FIVE : TWO_TO_FIVE,
    SIX_TO_TWENTY : SIX_TO_TWENTY,
    TWENTY_ONE_TO_ONE_HUNDRED : TWENTY_ONE_TO_ONE_HUNDRED,
    ONE_HUNDRED_ONE_TO_FIVE_HUNDRED : ONE_HUNDRED_ONE_TO_FIVE_HUNDRED,
    FIVE_HUNDRED_ONE_OR_MORE : FIVE_HUNDRED_ONE_OR_MORE,
};

export const FLEET_SIZE_OPTIONS_VARIANT_3 = {};
Object.assign(FLEET_SIZE_OPTIONS_VARIANT_3, FLEET_SIZE_OPTIONS_VARIANT_1);


export const FLEET_SIZE_OPTIONS = {};
Object.assign(FLEET_SIZE_OPTIONS, FLEET_SIZE_OPTIONS_VARIANT_0);
Object.assign(FLEET_SIZE_OPTIONS, FLEET_SIZE_OPTIONS_VARIANT_1);


// export const VARIANT_0 = 'VARIANT_0'
export const VARIANT_1 = 'VARIANT_1'
// export const VARIANT_2 = 'VARIANT_2'
export const VARIANT_3 = 'VARIANT_3'
export const VARIANT_4 = 'VARIANT_4'
