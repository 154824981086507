/* eslint-disable linebreak-style */
import axios from 'axios';
import {
  LEAD_FETCH_REQUEST,
  LEAD_FETCH_SUCCESS,
  LEAD_FETCH_ERROR,
  LEAD_ADD_REQUEST,
  LEAD_ADD_SUCCESS,
  LEAD_ADD_ERROR,
  LEAD_CLEAR,
  LEAD_UPDATE_REQUEST,
  LEAD_UPDATE_SUCCESS,
  LEAD_UPDATE_ERROR,
} from './actionTypes';
import { updateSpinner } from "./spinnerActions";
import {history} from "../index";


export const fetchLeadDetail = (leadId) => (dispatch, state) => {
  dispatch(updateSpinner(true));
  dispatch({ type: LEAD_FETCH_REQUEST , request: true});

  let url = `/api/lead/leads/${leadId}/`;

  const { mode } = state().account;
  url += `?mode=${mode}`;

  axios.get(url)
  .then(({ data }) => {
    if (data) {
      dispatch({ type: LEAD_FETCH_SUCCESS, data });
    } else {
      dispatch({ type: LEAD_FETCH_ERROR, error: { mes: "" }});
    }
    dispatch(updateSpinner(false));
  })
  .catch((error) => {
    dispatch(updateSpinner(false));
    history.push(`/admin/leads/`);
    dispatch({ type: LEAD_FETCH_ERROR, error });
  });
};

export const addLeadDetail = (data) => async (dispatch) => {
  dispatch(updateSpinner(true));
  dispatch({ type: LEAD_ADD_REQUEST , request: true});
  data.submit = true;
  let url = '/api/lead/leads/';
  try {
    await axios.post(url, data);
    dispatch({ type: LEAD_ADD_SUCCESS, data });
    dispatch(updateSpinner(false));
  } catch(error) {
    dispatch({ type: LEAD_ADD_ERROR, error });
    dispatch(updateSpinner(false));
    return error;
  }
};

export const updateLeadDetail = (leadId, data) => async (dispatch) => {
  dispatch(updateSpinner(true));
  dispatch({ type: LEAD_UPDATE_REQUEST , request: true});
  let url = `/api/lead/leads/${leadId}/`;
  try {
    await axios.patch(url, data);
    dispatch({type: LEAD_UPDATE_SUCCESS, data});
    dispatch(updateSpinner(false));
  } catch (error) {
    dispatch({ type: LEAD_UPDATE_ERROR, error });
    dispatch(updateSpinner(false));
    return error;
  }
};

export const clearLeadDetail = () => (dispatch) => {
  dispatch({ type: LEAD_CLEAR });
};
