import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Card, CardHeader, CardBody, Row, Col} from "reactstrap";
import { formatDatetimeAsLocalDate } from "../../utils/datetime";
import classnames from "classnames";

import {
  fetchSubscriptionDetail,
  startSubscriptionDetail,
  clearSubscriptionDetail,
} from "../../store/actions/subscriptionDetailActions";

import {
  clearWfeBuyFlowDetail,
  fetchWfeBuyFlowDetail
} from "../../store/actions/workflowDetailActions";

import { ActiveSubscriptionsByFleet } from "../../store/actions/subscriptionListActions";
import { addMessage } from "../../store/actions/messageActions";

import { FLEET_SIZE_OPTIONS } from "../../config";

import {
  SUBSCRIPTION_STATUS_LIVE_VALUE,
  WFE_NOTIFY_HOST_OF_LEAD,
  SUBSCRIPTION_PAYMENT_METHOD_OPTIONS,
  SUBSCRIPTION_NET_TERMS_OPTIONS,
  SUBSCRIPTION_PAYMENT_METHOD_PAY_ON_TERMS_VALUE,
  WFE_OTC_INVOICE_WAS_CREATED,
  WFE_NOTIFY_CUSTOMER_OF_OTC_INVOICE,
  SUBSCRIPTION_STATUS_CANCELED_LABEL,
  SUBSCRIPTION_STATUS_CANCELED_VALUE,
  WFE_NOTIFY_CUSTOMER_TO_APPROVE_ORDER_AND_PAY,
  SUBSCRIPTION_PAYMENT_METHOD_INTERVALS, SUBSCRIPTION_TYPE_FREE_VALUE
} from "../../config/subscription";

import EmbeddedAlert from "../../components/EmbeddedAlert";
import FleetSubscriptionDetail from "./fleets/FleetSubscriptionDetail";
import {
  clearSubscriptionUpdateList,
  fetchSubscriptionUpdateBySubscription
} from "../../store/actions/subscriptionUpdateActions";
import {
  clearSubscriptionEffectiveDate,
  fetchSubscriptionEffectiveDate
} from "../../store/actions/billingSubscription";
import { ENTITY_TYPE_OPTIONS } from "../../config/solution";
import Currency from "../../components/Currency";
import Pluralize from "pluralize";

class CustomerDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeSubscriptionExists: null
    };
  }

  componentDidMount() {
    const { subscriptionId } = this.props.match.params;
    if (subscriptionId) {
      this.props.fetchSubscriptionDetail(subscriptionId).then((err) => {
        if (!err) {
          const {subscription} = this.props.subscriptionDetail;
          this.props.fetchSubscriptionEffectiveDate(subscription.uid);
          this.props.fetchSubscriptionUpdateBySubscription(subscriptionId);
        }
      });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.subscriptionDetail.isLoading && !this.props.subscriptionDetail.isLoading) {
      const { subscription } = this.props.subscriptionDetail;
      if (subscription && subscription.id) {
        this.props.ActiveSubscriptionsByFleet(subscription.fleet.id, subscription.solution.id).then(response => {
          if (response.data) {
            if (response.data.find(item => {
              return item.id !== subscription.id && item.status === SUBSCRIPTION_STATUS_LIVE_VALUE
            })) {
              this.setState({activeSubscriptionExists: true});
            } else {
              this.setState({activeSubscriptionExists: false});
            }
          }
        });
        this.props.fetchWfeBuyFlowDetail(subscription.uid);
      }
    }
  }

  componentWillUnmount() {
    this.props.clearWfeBuyFlowDetail();
    this.props.clearSubscriptionDetail();
    this.props.clearSubscriptionUpdateList();
    this.props.clearSubscriptionEffectiveDate();
  }

  render() {
    const { account, subscriptionUpdateList, settings } = this.props;
    const { effectiveDate } = this.props.billingSubscription;

    const {
      subscriptionDetail: {
        subscription: {
          status,
          fleet: {
            name: fleetName,
            size
          },
          solution: {
            name: solutionName,
            unit: solutionUnit,
            currency,
          },
          marketplace: {
            name: marketplaceName
          },
          quantity,
          paymentMethod,
          netTerms,
          subscriptionEntities,
          subscriptionStart,
          startSubscriptionPrice,
          paymentInterval,
          trialPeriodDays,
          subscriptionDiscountAmount,
          subscriptionType
        },
        isStartingSubscription
      },
      workflowDetail: {
        buyFlow: {
          workflowState
        }
      },
      match: {
        params: {
          subscriptionId
        }
      },
    } = this.props;

    const { activeSubscriptionExists } = this.state;

    const verboseSolutionUnits = {
      D: "drivers",
      V: "vehicles",
      A: "assets",
    };

    const isReadyToBeActivated = (
      (workflowState === WFE_NOTIFY_HOST_OF_LEAD ||
        // Pay on Terms payment method
        workflowState === WFE_OTC_INVOICE_WAS_CREATED ||
        workflowState === WFE_NOTIFY_CUSTOMER_OF_OTC_INVOICE
      )
      && !activeSubscriptionExists
    );

    let costPerEntity = startSubscriptionPrice;
    if (subscriptionDiscountAmount) {
      costPerEntity -= subscriptionDiscountAmount
    }

    let costPerEntitySubscriptionUpdate;
    if (subscriptionUpdateList && subscriptionUpdateList.length > 0) {
      costPerEntitySubscriptionUpdate = subscriptionUpdateList[0].subscriptionPrice;
      if (subscriptionUpdateList[0].subscriptionUpdateDiscountAmount) {
        costPerEntitySubscriptionUpdate -= subscriptionUpdateList[0].subscriptionUpdateDiscountAmount
      }
    }

    return (
      <>
        <div className="breadcrumb-nav">
          <div>
            <ol className="breadcrumb-container">
              <li className="breadcrumb-container__item">
                <h5>
                  <Link to="/admin/customers">Subscriptions</Link>
                </h5>
              </li>
              <li className="breadcrumb-container__item">
                <h5>/</h5>
              </li>
              <li className="breadcrumb-container__item">
              <h5>{fleetName} {subscriptionStart == null ? "(Pending)" : null}</h5>
              </li>
            </ol>
          </div>
        </div>
        <div className="content customer-detail-page">
          <div className="pt-3 c-subscription-alert">
            <EmbeddedAlert />
          </div>
          {subscriptionStart == null && !isStartingSubscription && activeSubscriptionExists !== null
          && status !== SUBSCRIPTION_STATUS_CANCELED_VALUE &&
            <Card className="mb-3">
              <CardBody>
                {activeSubscriptionExists === true ? (
                  <div className="mb-3">
                    This customer already has an active subscription for the solution.
                    It is not possible to have multiple subscriptions for the same solution and customer combination.
                  </div>
                ) : workflowState === WFE_NOTIFY_CUSTOMER_TO_APPROVE_ORDER_AND_PAY ? (
                  <p className="font-weight-bold">
                    Your new subscription has been sent to the customer for review and approval.
                    When the customer approves the order, and you have completed any required setup,
                    you will be able to activate the subscription.
                  </p>
                ) : (
                  <>
                    <p className="font-weight-bold">
                      Your new subscription is pending. When you have completed any required setup,
                      and you’re ready to start this subscription, click the Activate button below.
                      You'll be able to activate some or all subscription units.
                    </p>
                    <div>
                      <div>
                        Before you activate and start subscription charges:<br/>
                      </div>
                      <div className="ml-3">
                        <ul>
                          <li>Complete any required installation or provisioning</li>
                          <li>If data is required for this solution, send asset data to Roadz for data activation</li>
                          <li>Send to Roadz the email addresses of users who need access to your solution</li>
                        </ul>
                      </div>
                    </div>
                  </>
                )}
                <div className="text-center">
                  {account.permissions.includes("write:customers") &&
                  <Button
                    id="activateButton"
                    color={isReadyToBeActivated ? "primary" : "secondary"}
                    onClick={() => this.props.history.push(`/admin/customers/${subscriptionId}/entities`)}
                    disabled={!isReadyToBeActivated}
                  >
                    Activate
                  </Button>
                  }
                </div>
              </CardBody>
            </Card>
          }
          <Card>
            <CardHeader>
              <Row>
                <Col>
                  <p>
                    <b>Solution:</b> {solutionName}
                  </p>
                  <p>
                    <b>Marketplace:</b> {marketplaceName}
                  </p>
                </Col>
                <Col>
                  <div className="d-flex justify-content-between align-items-center">
                    <p>
                      <b>Start date: </b>
                      {status === SUBSCRIPTION_STATUS_CANCELED_VALUE ? SUBSCRIPTION_STATUS_CANCELED_LABEL
                        :  subscriptionStart == null ? " Pending"
                          : " " + formatDatetimeAsLocalDate(subscriptionStart)}
                    </p>
                    {
                      isStartingSubscription && <p/>
                    }
                  </div>
                  <p>
                    <b>Customer fleet size:</b> {FLEET_SIZE_OPTIONS[size]}
                  </p>
                </Col>
              </Row>
            </CardHeader>
            <CardBody className="p-3">
              <Row>
                <Col>
                  <div className="d-flex align-items-center">
                    <div className="flex-grow-1"><h6>Subscription Details</h6></div>
                    <div>
                      <Button
                        className={classnames("float-right mb-3 ", {
                          "c-btn-outline-secondary": subscriptionStart === null,
                          "btn-outline-primary": subscriptionStart !== null,
                        })}
                        type="button"
                        onClick={() => this.props.history.push(`/admin/customers/${subscriptionId}/edit`)}
                        disabled={subscriptionStart === null ||
                        status === SUBSCRIPTION_STATUS_CANCELED_VALUE ||
                        subscriptionType === SUBSCRIPTION_TYPE_FREE_VALUE}
                      >
                        Change quantity or cost
                      </Button>
                    </div>
                  </div>


                  <Card>
                    <CardBody>
                      {subscriptionType !== SUBSCRIPTION_TYPE_FREE_VALUE &&
                        <>
                          <div className="d-flex justify-content-between">
                            <p className="font-weight-bold mb-1">
                              Number of {verboseSolutionUnits[solutionUnit]}
                            </p>
                            <p className="mb-1">
                              {quantity} ({subscriptionEntities ? countActiveEntities(subscriptionEntities) : 0} active)
                            </p>
                          </div>
                          <div className="d-flex justify-content-end">
                            {
                              subscriptionStart === null || status === SUBSCRIPTION_STATUS_CANCELED_VALUE
                                ?
                                <p style={{"color": "#9A9A9A"}}>
                                  Manage Entities
                                </p>
                                :
                                <p>
                                  <Link to={`/admin/customers/${this.props.match.params.subscriptionId}/entities`}>
                                    Manage Entities
                                  </Link>
                                </p>
                            }
                          </div>
                        </>
                      }
                      <div className="d-flex justify-content-between">
                        <p className="font-weight-bold">Cost per {ENTITY_TYPE_OPTIONS[solutionUnit]}</p>
                        <p><Currency currency={currency} value={costPerEntity} /></p>
                      </div>
                      <div className="d-flex justify-content-between">
                        {paymentInterval &&
                          <p className="font-weight-bold">Est. {SUBSCRIPTION_PAYMENT_METHOD_INTERVALS[paymentInterval].toLowerCase()} subtotal</p>
                        }
                        {startSubscriptionPrice &&
                          <p><Currency currency={currency} value={costPerEntity * parseInt(quantity)} /></p>
                        }
                      </div>
                    </CardBody>
                  </Card>
                  {subscriptionUpdateList && subscriptionUpdateList.length > 0 &&
                  <>
                    <div className="flex-grow-1 mb-3"><h6>Pending Updates {" "}
                      {effectiveDate && <>(Effective {effectiveDate})</>}
                    </h6></div>
                    <Card>
                      <CardBody>
                        <div className="d-flex justify-content-between">
                          <p className="font-weight-bold">Number of {verboseSolutionUnits[solutionUnit]}</p>
                          <p>{subscriptionUpdateList[0].subscriptionQuantity}</p>
                        </div>
                        <div className="d-flex justify-content-between">
                          <p className="font-weight-bold">Cost per {ENTITY_TYPE_OPTIONS[solutionUnit]}</p>
                          <p><Currency currency={currency} value={costPerEntitySubscriptionUpdate} /></p>
                        </div>
                        <div className="d-flex justify-content-between">
                          {paymentInterval &&
                            <p className="font-weight-bold">Est. {SUBSCRIPTION_PAYMENT_METHOD_INTERVALS[paymentInterval].toLowerCase()} subtotal</p>
                          }
                          {subscriptionUpdateList[0] && <p>
                            <Currency currency={currency} value={costPerEntitySubscriptionUpdate *
                            subscriptionUpdateList[0].subscriptionQuantity} />
                          </p>
                          }
                        </div>
                      </CardBody>
                    </Card>
                  </>
                  }
                  {paymentMethod &&
                    <Card>
                      <CardBody>
                        <div className="d-flex justify-content-between">
                          <p className="font-weight-bold">Payment method</p>
                          <p>{SUBSCRIPTION_PAYMENT_METHOD_OPTIONS[paymentMethod]}</p>
                        </div>
                        {paymentMethod === SUBSCRIPTION_PAYMENT_METHOD_PAY_ON_TERMS_VALUE &&
                          <div className="d-flex justify-content-between">
                            <p className="font-weight-bold">Payment Terms</p>
                            <p>{SUBSCRIPTION_NET_TERMS_OPTIONS[netTerms]}</p>
                          </div>
                        }
                        {settings.paymentInterval &&
                          <div className="d-flex justify-content-between">
                            <p className="font-weight-bold">Payment interval</p>
                            <p>{SUBSCRIPTION_PAYMENT_METHOD_INTERVALS[paymentInterval]}</p>
                          </div>
                        }
                        {settings.freeTrialLength &&
                        <div className="d-flex justify-content-between">
                          <p className="font-weight-bold">Trial</p>
                          <p>{Pluralize('day', parseInt(trialPeriodDays), true)}</p>
                        </div>
                        }
                      </CardBody>
                    </Card>
                  }
                </Col>
                <Col><FleetSubscriptionDetail /></Col>
                {/*<Col>*/}
                  {/*TODO Just removing the data related UI, it needs to be implemented later */}
                  {/*
                  <h6>Assets and Data</h6>
                  <Card>
                    <CardBody>
                      <p className="font-weight-bold">
                        Vehicles
                      </p>
                      <p>
                        <Link
                          to={{
                            pathname: `/admin/customers/${subscriptionId}/vehicles`,
                            state: {
                              customerName: fleetName
                            }
                          }}
                        >
                          View vehicle info (66)
                        </Link>
                      </p>
                      <p className="font-weight-bold">
                        Drivers
                      </p>
                        <p>
                  */}
                  {/*
                        <a href="#">View driver info (13)</a>
                      </p>
                      <p className="font-weight-bold">
                        Assets
                      </p>
                      <p>
                      */}
                  {/*
                        <a href="#">View asset info (0)</a>
                      </p>
                    </CardBody>
                  </Card>
                  <Card>
                    <CardBody>
                      <div className="d-flex justify-content-between">
                        <p className="font-weight-bold">
                          Data requirements
                        </p>
                        <Link
                          to={`/admin/customers/${subscriptionId}/edit`}
                        >
                          View
                        </Link>
                      </div>
                    </CardBody>
                  </Card>
                  */}
                {/*</Col>*/}
              </Row>
            </CardBody>
          </Card>
        </div>
      </>
    );
  }
}

function countActiveEntities(entities) {
  return entities.reduce((acc, entity) => {
    return acc + (entity.isActive ? 1 : 0);
  }, 0);
}

const mapStateToProps = state => ({
  subscriptionDetail: state.subscriptionDetail,
  subscriptionUpdateList: state.subscriptionUpdate.subscriptionUpdateList,
  workflowDetail: state.workflowDetail,
  account: state.account,
  settings: state.settings,
  billingSubscription: state.billingSubscription,
});

const mapDispatchToProps = {
  clearSubscriptionDetail,
  fetchSubscriptionDetail,
  fetchWfeBuyFlowDetail,
  clearWfeBuyFlowDetail,
  startSubscriptionDetail,
  ActiveSubscriptionsByFleet,
  addMessage,
  fetchSubscriptionUpdateBySubscription,
  clearSubscriptionUpdateList,
  fetchSubscriptionEffectiveDate,
  clearSubscriptionEffectiveDate
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerDetail);

// Exports for easy testing
export {CustomerDetail};
