import camelcaseKeys from 'camelcase-keys';
import {
  SUBSCRIPTION_UPDATE_ADD_ERROR,
  SUBSCRIPTION_UPDATE_ADD_REQUEST,
  SUBSCRIPTION_UPDATE_ADD_SUCCESS,
  SUBSCRIPTION_UPDATE_LIST_FETCH_ERROR,
  SUBSCRIPTION_UPDATE_LIST_FETCH_REQUEST,
  SUBSCRIPTION_UPDATE_LIST_FETCH_SUCCESS,
  SUBSCRIPTION_UPDATE_LIST_CLEAR
} from "../actions/actionTypes";

const initialState = {
  subscriptionUpdateList: [],
  subscriptionUpdate: {},
  isLoading: false,
  isStartingSubscription: false,
  error: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SUBSCRIPTION_UPDATE_ADD_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };

    case SUBSCRIPTION_UPDATE_ADD_SUCCESS:
    {
      const data = camelcaseKeys(action.data, {deep: true});
      return {
        ...state,
        subscriptionUpdate: data,
        isLoading: false,
        error: null
      };
    }

    case SUBSCRIPTION_UPDATE_ADD_ERROR:
      return {
        ...state,
        subscriptionUpdate: {},
        isLoading: false,
        error: action.error,
      };

    case SUBSCRIPTION_UPDATE_LIST_FETCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };

    case SUBSCRIPTION_UPDATE_LIST_FETCH_SUCCESS:
      const data = camelcaseKeys(action.data, {deep: true});
      return {
        ...state,
        subscriptionUpdateList: data.results,
        isLoading: false,
        error: null
      };

    case SUBSCRIPTION_UPDATE_LIST_FETCH_ERROR:
      return {
        ...state,
        subscriptionUpdateList: [],
        isLoading: false,
        error: action.error,
      };

    case SUBSCRIPTION_UPDATE_LIST_CLEAR:
      return {
        ...state,
        subscriptionUpdateList: [],
        isLoading: false,
        isStartingSubscription: false,
        error: null,
      };

    default:
      return state;
  }
};
