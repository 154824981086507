import React, { useEffect, useState } from "react";
import { useTable, useSortBy } from "react-table";
import { formatDisplayDatetimeShort } from "../../../utils/datetime";

const Table = ({ columns, data, history }) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
        initialState: {
          sortBy: [
            {
              id: "date",
              desc: true,
            },
          ],
        },
      },
      useSortBy
    );

  return (
    <div className="ReactTable7">
      <table {...getTableProps()} className="rt-table">
        <thead className="rt-thead">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()} className="rt-rh rt-tr">
              {headerGroup.headers.map((column) => {
                const sortClass =
                  column.isSorted && !column.isSortedDesc
                    ? "-sort-asc"
                    : column.isSorted && column.isSortedDesc
                    ? "-sort-desc"
                    : "no-sort";

                return (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    <div className={"rt-header rt-td " + sortClass}>
                      {column.render("Header")}
                    </div>
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows && rows.length > 0
            ? rows.map((row, i) => {
                prepareRow(row);
                return (
                  <tr
                    {...row.getRowProps()}
                    className="rt-tr"
                    onClick={() => {
                      history.push(`/admin/leads/${row.original.id}/`);
                    }}
                  >
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()} className="rt-td">
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })
            : [...Array(10).keys()].map((val) => (
                <tr className="rt-tr" key={val}>
                  <td colSpan={10} className="rt-td empty-rows">
                    {val === 4 ? "No rows found" : ""}
                  </td>
                </tr>
              ))}
        </tbody>
      </table>
    </div>
  );
};

const LeadListTable = ({ data, typeFilter, companyFilter, history }) => {
  const [filteredData, setFilteredData] = useState(data);

  useEffect(() => {
    let newFilteredData = data;
    if (data && typeFilter) {
      newFilteredData = newFilteredData.filter(
        (subscription) => subscription.type === typeFilter
      );
    }
    if (data && companyFilter) {
      newFilteredData = newFilteredData.filter(
        (subscription) => subscription.company === companyFilter
      );
    }
    setFilteredData(newFilteredData);
  }, [data, typeFilter, companyFilter]);

  const columns = React.useMemo(
    () => [
      {
        id: "date",
        Header: "Date",
        accessor: "date",
        Cell: ({ cell: { value } }) => <div>{formatDisplayDatetimeShort(value)}</div>,
      },
      {
        Header: "Type",
        accessor: "type",
      },
      {
        Header: "Company",
        accessor: "company",
      },
      {
        Header: "Solution",
        accessor: "solution",
      },
      {
        Header: "Marketplace",
        accessor: "marketplace",
      },
    ],
    []
  );

  return <Table columns={columns} data={filteredData} history={history} />;
};

export default LeadListTable;
