import React from "react";
import { Link } from "react-router-dom";
import { Button, Card, CardBody, Row, Col } from "reactstrap";

import EmbeddedAlert from "../../components/EmbeddedAlert";
import { connect } from "react-redux";
import { fetchSolutionResourceList } from "../../store/actions/solutionResource";
import MarketplaceResourceForm from "./MarketplaceResourceForm";
import snakeCaseKeys from "snakecase-keys";
import {
  addMarketplaceResourceDetail,
  clearMarketplaceResource,
  fetchMarketplaceResourceDetail,
  updateMarketplaceResourceDetail,
} from "../../store/actions/marketplaceResourceDetailActions";
import { fetchSolutionMarketplaceList } from "../../store/actions/solutionMarketplaceActions";
import { addMessage } from "../../store/actions/messageActions";
import moment from "moment";
import { fetchMarketplaceDetail } from "../../store/actions/marketplaceDetailActions";

class MarketplaceResourceDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      image: null,
      imageError: null,
      payload: {
        speakers: [],
      },
      selectedFile: null,
    };
    this.form = React.createRef();
  }

  componentDidMount() {
    const {
      match: {
        params: { resourceId, marketplaceId },
      },
    } = this.props;
    const {
      fetchSolutionMarketplaceList,
      fetchMarketplaceResourceDetail,
      fetchMarketplaceDetail,
    } = this.props;
    fetchSolutionMarketplaceList(marketplaceId);
    fetchMarketplaceDetail(marketplaceId);
    if (resourceId) {
      fetchMarketplaceResourceDetail(resourceId);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { marketplaceResource } = this.props;
    if (prevProps.marketplaceResource !== marketplaceResource) {
      if (marketplaceResource) {
        this.setState({ image: marketplaceResource.picture });
        this.setState({ payload: marketplaceResource.payload });
      }
    }
  }

  componentWillUnmount() {
    this.props.clearMarketplaceResource();
  }

  submitForm(event) {
    this.form.current.submit();
    event.preventDefault();
  }

  onSelectFile(selectedFile) {
    this.setState({ selectedFile });
  }

  onSubmit(values) {
    const {
      addMarketplaceResourceDetail,
      updateMarketplaceResourceDetail,
      marketplaceResource,
    } = this.props;

    const {
      match: {
        params: { marketplaceId },
      },
    } = this.props;

    if (!this.state.image) {
      this.setState({ imageError: "Image required" });
    } else {
      values.marketplace = marketplaceId;
      values.changed = !!values.changed;
      values.payload = this.state.payload;
      values.staticFile = this.state.selectedFile;
      if (values.date) {
        values.date = moment(values.date)
          .startOf("day")
          .hour(12)
          .minute(0)
          .toISOString();
      }

      if (
        !marketplaceResource ||
        this.state.image !== marketplaceResource.picture
      ) {
        values.picture = this.state.image;
      }

      const data = snakeCaseKeys(values, { deep: true });
      if (values.id) {
        updateMarketplaceResourceDetail(values.id, data).then(() => {
          this.props.history.push("/admin/resources", {
            selectedMarketplace: values.marketplace,
          });
          this.props.addMessage("The marketplace resource has been saved");
        });
      } else {
        addMarketplaceResourceDetail(data).then(() => {
          this.props.history.push("/admin/resources", {
            selectedMarketplace: values.marketplace,
          });
          this.props.addMessage("The marketplace resource has been created");
        });
      }
    }
  }

  setImage(image) {
    this.setState({ image });
  }

  setImageError(imageError) {
    this.setState({ imageError });
  }

  render() {
    const {
      match: {
        params: { resourceId, marketplaceId },
      },
    } = this.props;
    const { account, marketplaceResource, marketplace } = this.props;

    const { state } = this.props.location;

    return (
      <>
        <div className="breadcrumb-nav">
          <Row>
            <Col>
              <ol className="breadcrumb-container">
                <li className="breadcrumb-container__item">
                  <h5>
                    <Link to="/admin/resources">Marketplace Resources</Link>
                  </h5>
                </li>
                <li className="breadcrumb-container__item">
                  <h5>/</h5>
                </li>
                {marketplace && (
                  <li className="breadcrumb-container__item">
                    <h5>
                      <Link to="/admin/resources">{marketplace.name}</Link>
                    </h5>
                  </li>
                )}
                <li className="breadcrumb-container__item">
                  <h5>/</h5>
                </li>
                <li className="breadcrumb-container__item">
                  <h5>
                    {marketplaceResource
                      ? `Edit ${marketplaceResource.label}`
                      : !resourceId
                      ? "Create Marketplace Resource"
                      : ""}
                  </h5>
                </li>
              </ol>
            </Col>
          </Row>
        </div>
        <div className="content solution-new-info-page">
          <div className="page-content-header">
            <div className="pt-2">
              <EmbeddedAlert />
            </div>
            <Row>
              <Col md={5}>
                <h3>
                  {marketplaceResource
                    ? `Edit ${marketplaceResource.label}`
                    : !resourceId
                    ? "Create Marketplace Resource"
                    : ""}
                </h3>
              </Col>
              {account.permissions.includes("write:resources") && (
                <Col md={7}>
                  <div className="d-flex align-items-center justify-content-end">
                    <Link to={`/admin/resources`}>
                      <Button className="mr-3" color="outline-primary">
                        Cancel
                      </Button>
                    </Link>

                    <Button
                      color="primary"
                      onClick={(event) => this.submitForm(event)}
                    >
                      {resourceId ? "Save" : "Create"}
                    </Button>
                  </div>
                </Col>
              )}
            </Row>
          </div>
          <Card>
            <CardBody>
              <MarketplaceResourceForm
                ref={this.form}
                selectedMarketplaceResource={marketplaceResource}
                isEditing={resourceId}
                onSubmit={this.onSubmit.bind(this)}
                fetchSolutionResourceList={this.props.fetchSolutionResourceList}
                image={this.state.image}
                setImage={this.setImage.bind(this)}
                imageError={this.state.imageError}
                setImageError={this.setImageError.bind(this)}
                payload={this.state.payload}
                marketplaceId={marketplaceId}
                setPayload={(payload) => {
                  this.setState({ payload });
                }}
                onSelectFile={this.onSelectFile.bind(this)}
                selectedCategory={state ? state.selectedCategory : null}
              />
            </CardBody>
          </Card>
          {account.permissions.includes("write:resources") && (
            <div className="page-content-footer d-flex justify-content-end mr-3">
              <Link to={`/admin/resources`}>
                <Button className="mr-3" color="outline-primary">
                  Cancel
                </Button>
              </Link>
              <Button
                color="primary"
                onClick={(event) => this.submitForm(event)}
              >
                {resourceId ? "Save" : "Create"}
              </Button>
            </div>
          )}
        </div>
      </>
    );
  }
}

const mapDispatchToProps = {
  addMarketplaceResourceDetail,
  updateMarketplaceResourceDetail,
  fetchSolutionMarketplaceList,
  fetchSolutionResourceList,
  fetchMarketplaceResourceDetail,
  addMessage,
  clearMarketplaceResource,
  fetchMarketplaceDetail,
};

const mapStateToProps = (state) => ({
  account: state.account,
  marketplaceResource: state.marketplaceResourceList.marketplaceResource,
  marketplace: state.marketplaceDetail.marketplace,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MarketplaceResourceDetail);
