import React from 'react';
import { connect } from "react-redux";
import { Switch, Route } from 'react-router-dom';
import SolutionList from './SolutionList';
import SolutionInfoEditNew from './SolutionInfoEditNew';
import SolutionListingEditNew from './SolutionListingEditNew';
import SolutionDetail from './SolutionDetail';
import {
  fetchDataTypeClassList
} from '../../store/actions/dataActions';
import VersionList from "./version/VersionList";
import {clearSolutionDetail} from "../../store/actions/solutionDetailActions";

class Solution extends React.Component {
  componentDidMount() {
    // It is not used right now
    // this.props.fetchDataTypeClassList();
  }

  componentWillUnmount() {
    const {clearSolutionDetail} = this.props;
    clearSolutionDetail()
  }

  render() {
    return (
      <Switch>
        <Route key="1" path="/admin/solutions" exact component={SolutionList} />
        <Route key="2" path="/admin/solutions/listing-new" exact component={SolutionListingEditNew} />
        <Route key="3" path="/admin/solutions/info-new" exact component={SolutionInfoEditNew} />
        <Route key="4" path="/admin/solutions/:solutionId/versions/" exact component={VersionList} />
        <Route key="5" path="/admin/solutions/:solutionId/" exact component={SolutionDetail} />
        <Route key="6" path="/admin/solutions/:solutionId/version/:versionId/" exact component={SolutionDetail} />
        <Route key="7" path="/admin/solutions/:solutionId/info-edit" exact component={SolutionInfoEditNew} />
        <Route key="8" path="/admin/solutions/:solutionId/listing-edit" exact component={SolutionListingEditNew} />
      </Switch>
    );
  }
}

// const mapStateToProps = state => ({
//   solutionDetail: state.solutionDetail
// });

const mapDispatchToProps = {
  fetchDataTypeClassList,
  clearSolutionDetail
};

export default connect(
  null,
  mapDispatchToProps
)(
  Solution
);
