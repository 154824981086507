import React from "react";
import { useTable, useSortBy } from "react-table";
import { formatDisplayDatetimeShort } from "../../../utils/datetime";
import classnames from "classnames";
import {
  LEAD_TYPE_LEAD_LABEL,
  LEAD_TYPE_TRIAL_LABEL,
} from "../../../config/lead";
import { Button, UncontrolledTooltip } from "reactstrap";
import { ENTITY_TYPES } from "../LeadsAndSubscriptions";

const Table = ({ columns, data, history }) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
        initialState: {
          sortBy: [
            {
              id: "date",
              desc: true,
            },
          ],
        },
      },
      useSortBy
    );

  return (
    <div className="ReactTable7">
      <table {...getTableProps()} className="rt-table">
        <thead className="rt-thead">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()} className="rt-rh rt-tr">
              {headerGroup.headers.map((column) => {
                const sortClass =
                  column.isSorted && !column.isSortedDesc
                    ? "-sort-asc"
                    : column.isSorted && column.isSortedDesc
                    ? "-sort-desc"
                    : "no-sort";

                return (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    <div className={"rt-header rt-td " + sortClass}>
                      {column.render("Header")}
                    </div>
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows && rows.length > 0
            ? rows.map((row, i) => {
                prepareRow(row);
                return (
                  <tr
                    {...row.getRowProps()}
                    className="rt-tr"
                    onClick={() => {
                      if (row.original.entity === "Subscription")
                        history.push(`/admin/customers/${row.original.id}`);
                      else if (
                        row.original.entity === LEAD_TYPE_LEAD_LABEL ||
                        row.original.entity === LEAD_TYPE_TRIAL_LABEL
                      )
                        history.push(`/admin/leads/${row.original.id}`);
                    }}
                  >
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()} className="rt-td">
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })
            : [...Array(10).keys()].map((val) => (
                <tr className="rt-tr" key={val}>
                  <td colSpan={10} className="rt-td empty-rows">
                    {val === 4 ? "No rows found" : ""}
                  </td>
                </tr>
              ))}
        </tbody>
      </table>
    </div>
  );
};

const LeadsAndSubscriptionsTable = ({ data, history }) => {
  const columns = React.useMemo(
    () => [
      {
        Header: "Type",
        accessor: (row) => {
          return (
            <>
              <Button
                id={`entity-tooltip${row.index}`}
                className={classnames("btn btn-sm m-0", {
                  "btn-primary": row.entity === "Subscription",
                  "btn-success": row.entity === LEAD_TYPE_LEAD_LABEL,
                  "btn-danger": row.entity === LEAD_TYPE_TRIAL_LABEL,
                })}
              >
                {ENTITY_TYPES[row.entity]}
              </Button>
              <UncontrolledTooltip
                placement="bottom"
                target={`entity-tooltip${row.index}`}
              >
                {row.value}
              </UncontrolledTooltip>
            </>
          );
        },
      },
      {
        id: "date",
        Header: "Date",
        accessor: "date",
        Cell: ({ cell: { value } }) => <div>{formatDisplayDatetimeShort(value)}</div>,
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Company",
        accessor: "company",
      },
      {
        Header: "Solution",
        accessor: "solution",
      },
    ],
    []
  );

  return <Table columns={columns} data={data} history={history} />;
};

export default LeadsAndSubscriptionsTable;
