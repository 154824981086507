import PartnerDashboard from "./views/dashboard/PartnerDashboard";
import Solution from "./views/solutions/index";
import Lead from "./views/leads/index";
import Customer from "./views/customers/index";
import Billing from "./views/billing/index";
import HostDashboard from "./views/dashboard/HostDashboard";
import HostReport from "./views/reports/HostReport";
import Resources from "./views/resources";

const routes = [
  {
    path: "/home",
    name: "Home",
    icon: "ti ti-home",
    component: PartnerDashboard,
    layout: "/admin",
    modes: ["Partner"]
  },
  {
    path: "/host/dashboard",
    name: "Home",
    icon: "ti ti-home",
    component: HostDashboard,
    layout: "/admin",
    modes: ["Host"]
  },
  {
    path: "/solutions",
    name: "Solutions",
    icon: "ti ti-package",
    component: Solution,
    layout: "/admin",
    permission: "read:solutions",
    modes: ["Partner"]
  },
  {
    path: "/leads",
    name: "Leads",
    icon: "ti ti-flag-alt",
    component: Lead,
    layout: "/admin",
    permission: "read:leads",
    modes: ["Partner", "Host"]
  },
  {
    path: "/customers",
    name: "Subscriptions",
    icon: "ti ti-user",
    component: Customer,
    layout: "/admin",
    permission: "read:customers",
    modes: ["Partner", "Host"]
  },
  {
    path: "/billing",
    name: "Billing",
    icon: "ti ti-receipt",
    component: Billing,
    layout: "/admin",
    permission: "read:billing",
    modes: ["Partner"]
  },
  {
    path: "/host/reports",
    name: "Reports",
    icon: "ti ti-home",
    component: HostReport,
    layout: "/admin",
    modes: ["Host"]
  },
  {
    path: "/resources",
    name: "Resources",
    icon: "ti-view-list",
    component: Resources,
    layout: "/admin",
    permission: "read:resources",
    modes: ["Host"]
  },
];

export default routes;
