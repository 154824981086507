import Login from "./views/pages/Login.jsx";
import UserProfile from "./views/pages/UserProfile.jsx";


const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
    layout: "/auth"
  },
  {
    path: "/user-profile",
    name: "UserProfile",
    component: UserProfile,
    layout: "/admin"
  }
];

export default routes;
