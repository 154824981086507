import React from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Row, Col } from "reactstrap";
import BillingListTable from "./components/BillingListTable";

const dataTable = [
  [
    {
      order: "Order #524739",
      invoice: "Monthly subscription",
    },
    "$338.76",
    "ACME Trucking",
    "SmartCharge Manager",
    "JJ Keller - Freight & Assets",
  ],
  [
    {
      order: "Order #430543",
      invoice: "Monthly subscription",
    },

    "$3,626.91",
    "Plumbers South",
    "SmartCharge Manager",
    "NextTraq Marketplace",
  ],
  [
    {
      order: "Order #556411",
      invoice: "One-time charge",
    },

    "$121.46",
    "WeDriveU",
    "Route Track",
    "JJ Keller - Freight & Assets",
  ],
];

const dataTable2 = [
  [
    {
      order: "Order #524739",
      invoice: "Monthly subscription",
    },
    "$338.76",
    "ACME Trucking",
    "SmartCharge Manager",
    "JJ Keller - Freight & Assets",
  ],
  [
    {
      order: "Order #430543",
      invoice: "Monthly subscription",
    },

    "$3,360.78",
    "WeDriveU",
    "Route Track",
    "JJ Keller - Freight & Assets",
  ],
  [
    {
      order: "Order #556411",
      invoice: "One-time charge",
    },

    "$121.46",
    "WeDriveU",
    "Route Track",
    "NextTraq Marketplace",
  ],
];

class BillingList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: dataTable.map((prop, key) => {
        return {
          id: key,
          item: prop[0],
          amount: prop[1],
          customer: prop[2],
          solution: prop[3],
          marketplace: prop[4],
        };
      }),

      data2: dataTable2.map((prop, key) => {
        return {
          id: key,
          item: prop[0],
          amount: prop[1],
          customer: prop[2],
          solution: prop[3],
          marketplace: prop[4],
        };
      }),
    };
  }

  render() {
    return (
      <>
        <div className="breadcrumb-nav">
          <Row>
            <Col>
              <ol className="breadcrumb-container">
                <li className="breadcrumb-container__item">
                  <h5>
                    <Link to="/admin/home">Home</Link>
                  </h5>
                </li>
                <li className="breadcrumb-container__item">
                  <h5>/</h5>
                </li>
                <li className="breadcrumb-container__item">
                  <h5>Billing</h5>
                </li>
              </ol>
            </Col>
          </Row>
        </div>
        <div className="content customer-list-page">
          <div className="page-content-header">
            <Row>
              <Col>
                <h3>Past Orders</h3>
              </Col>
            </Row>
          </div>
          <Card className="custom-react-table">
            <CardBody>
              <p className="h5">Billing cycle: Mar 1-31, 2020</p>
              <BillingListTable
                data={this.state.data}
                history={this.props.history}
              />

              <p className="h5 pt-5">Billing cycle: Feb 1-28, 2020</p>

              <BillingListTable
                data={this.state.data2}
                history={this.props.history}
              />
            </CardBody>
          </Card>
        </div>
      </>
    );
  }
}

export default BillingList;
