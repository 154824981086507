import camelcaseKeys from 'camelcase-keys';
import {
  SOLUTION_RESOURCE_LIST_FETCH_ERROR,
  SOLUTION_RESOURCE_LIST_FETCH_SUCCESS,
  SOLUTION_RESOURCE_LIST_FETCH_REQUEST,
} from "../actions/actionTypes";

const initialState = {
  solutionResources: [],
  isLoading: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SOLUTION_RESOURCE_LIST_FETCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: action.error,
      };

    case SOLUTION_RESOURCE_LIST_FETCH_SUCCESS:
      const data = camelcaseKeys(action.data, {deep: true});
      return {
        ...state,
        solutionResources: data,
        isLoading: false,
        error: action.error
      };

    case SOLUTION_RESOURCE_LIST_FETCH_ERROR:
      return {
        ...state,
        solutionResources: [],
        isLoading: false,
        error: action.error,
      };

    default:
      return state;
  }
};
