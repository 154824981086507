import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm, formValueSelector } from "redux-form";
import moment from "moment";
import camelcaseKeys from "camelcase-keys";

// reactstrap components
import { Card, CardBody, Col, FormGroup, Label, Row } from "reactstrap";

import {
  emailValidation,
  phoneNumberValidation,
  requiredValidation,
} from "./validation";

import {
  renderCheckboxField,
  renderInputField,
  renderSelectCountriesField,
  renderSelectField,
  renderSelectUSStatesField,
} from "./utils";
import WarningIcon from "../../assets/icons/warning-icon.svg";
import { formatDatetimeAsLocalDate } from "../../utils/datetime";
import {
  SOURCE_INTERNAL,
  LEAD_TYPE_LEAD_LABEL,
  LEAD_TYPE_OPTIONS,
  LEAD_TYPE_DIRECT_VALUE,
  EQUIPMENT_TYPE_OPTIONS,
} from "../../config/lead";
import {
  FLEET_SIZE_OPTIONS_VARIANT_0,
  FLEET_SIZE_OPTIONS_VARIANT_1,
  FLEET_SIZE_OPTIONS_VARIANT_3,
  VARIANT_1,
  VARIANT_3,
  VARIANT_4,
} from "../../config";
import { validateAddress } from "../../store/actions/addressValidationActions";
import CheckboxGroup from "./fields/CheckboxGroup";

class LeadNewDetailForm extends React.Component {
  componentDidMount() {
    const { isEditing, isLoading, lead } = this.props;
    if (isEditing && !isLoading && lead && lead.id) {
      const newLead = { ...lead, solution: lead.solution.id };
      if (newLead.marketplace) {
        const marketplace = newLead.marketplace.id;
        newLead.marketplace = marketplace;
      }
      this.props.initialize(newLead);
    } else {
      this.props.initialize({
        contactPreference: "",
        country: "US",
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isLoading === true && this.props.isLoading === false) {
      if (this.props.isEditing) {
        const newLead = {
          ...this.props.lead,
          solution: this.props.lead.solution.id,
        };
        if (newLead.marketplace) {
          const marketplace = newLead.marketplace.id;
          newLead.marketplace = marketplace;
        }
        this.props.initialize(newLead);
      }
    }
  }

  render() {
    const { account } = this.props;
    const {
      handleSubmit,
      hasSubscription,
      marketplaces,
      marketplace,
      solution,
      invalid,
      country,
      submitFailed,
      isEditing,
      lead: {
        created: leadCreated,
        type: leadType,
        source: leadSource,
        formVariant,
      },
    } = this.props;

    let solutions = [];
    let source = leadSource;
    const marketplaceObj = marketplaces.find(
      (marketplaceObj) => parseInt(marketplaceObj.id) === parseInt(marketplace)
    );
    if (marketplaceObj) {
      solutions = marketplaceObj.solutions;
      if (leadSource === "Marketplace") {
        source = `${marketplaceObj.name} ${source}`;
      }
    }

    const solutionObj =
      (solutions &&
        solutions.find(
          (solutionObj) => parseInt(solutionObj.id) === parseInt(solution)
        )) ||
      {};
    const { leadsContactFirstName, leadsContactSecondName, leadsContactEmail } =
      solutionObj;

    return (
      <form onSubmit={handleSubmit}>
        {submitFailed && invalid && (
          <div className="d-flex justify-content-end pr-5 pt-3">
            <p className="text-danger">
              <img alt="" src={WarningIcon} className="mr-1" />
              Complete all required fields or fix invalid fields before
              continuing.
            </p>
          </div>
        )}

        <fieldset disabled={account.mode === "Host"}>
          <div className="lead-general-info page-content-header">
            <Row>
              <Col md="6">
                {/* TODO should populate actual fields according to the lead onboard process */}
                <Card className="pl-4 pt-5">
                  <CardBody>
                    <div className="d-flex lead-info-box">
                      <div className="lead-info">
                        <div className="mr-4 lead-info-header">
                          <p className="font-weight-bold">Date Created</p>
                        </div>
                        <div>
                          <p>
                            {isEditing
                              ? formatDatetimeAsLocalDate(leadCreated)
                              : formatDatetimeAsLocalDate(moment())}
                          </p>
                        </div>
                      </div>
                      <div className="lead-info">
                        <div className="mr-4 lead-info-header">
                          <p className="font-weight-bold">Customer type</p>
                        </div>
                        <div>
                          <p>
                            {isEditing
                              ? LEAD_TYPE_OPTIONS[leadType]
                              : LEAD_TYPE_LEAD_LABEL}
                          </p>
                        </div>
                      </div>
                      <div className="lead-info">
                        <div className="mr-4 lead-info-header">
                          <p className="font-weight-bold">Source</p>
                        </div>
                        <div>
                          <p>{isEditing ? source : SOURCE_INTERNAL}</p>
                        </div>
                      </div>
                      <div className="lead-info">
                        <div className="mr-4 lead-info-header">
                          <p className="font-weight-bold">Solution contact</p>
                        </div>
                        <div>
                          <p>
                            {leadsContactFirstName} {leadsContactSecondName}{" "}
                            <br />
                            {leadsContactEmail}
                          </p>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col md="6">
                <FormGroup className="lead-status">
                  <Label>
                    <h5 className="font-weight-bold">Status *</h5>
                  </Label>
                  <Field
                    type="select"
                    name="status"
                    component={renderSelectField}
                    validate={[requiredValidation]}
                    defaultValue={""}
                  >
                    <option value="" disabled hidden>
                      Select status
                    </option>
                    <option value="O">Open</option>
                    <option value="C">Closed</option>
                  </Field>
                </FormGroup>
                <FormGroup>
                  <Label>
                    <h5 className="font-weight-bold">Notes</h5>
                  </Label>
                  <Field
                    type="textarea"
                    name="notes"
                    component={renderInputField}
                  />
                </FormGroup>
              </Col>
            </Row>
          </div>
          <Card>
            <CardBody>
              <div className="section">
                <Row className="m-10">
                  <Col md="4">
                    <h4 className="m-0">Lead</h4>
                  </Col>
                  <Col md="8">
                    <Row>
                      <Col md="6">
                        <FormGroup>
                          <label>
                            <h5 className="font-weight-bold">Marketplace *</h5>
                          </label>
                          <Field
                            type="select"
                            name="marketplace"
                            validate={[requiredValidation]}
                            component={renderSelectField}
                            disabled={
                              (isEditing && leadSource !== SOURCE_INTERNAL) ||
                              hasSubscription
                            }
                            onChange={() => {
                              this.props.change("solution", "");
                            }}
                            defaultValue={""}
                          >
                            <option value="" disabled hidden>
                              Select marketplace
                            </option>
                            {marketplaces.map(({ id, host, name }) => (
                              <option key={id} value={id}>
                                {host.name} - {name}
                              </option>
                            ))}
                          </Field>
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <label>
                            <h5 className="font-weight-bold">Solution *</h5>
                          </label>
                          <Field
                            type="select"
                            name="solution"
                            component={renderSelectField}
                            validate={[requiredValidation]}
                            disabled={
                              leadType === LEAD_TYPE_DIRECT_VALUE ||
                              hasSubscription
                            }
                          >
                            <option value="">Select solution</option>
                            {solutions &&
                              solutions.map(({ id, name }) => (
                                <option key={id} value={id}>
                                  {name}
                                </option>
                              ))}
                          </Field>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
              <div className="section">
                <Row className="m-10">
                  <Col md="4">
                    <h4 className="m-0">Customer Info</h4>
                  </Col>
                  <Col md="8">
                    <Row>
                      <Col md="6">
                        <FormGroup>
                          <label>
                            <h5 className="font-weight-bold">First name *</h5>
                          </label>
                          <Field
                            type="text"
                            name="firstName"
                            component={renderInputField}
                            validate={[requiredValidation]}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <label>
                            <h5 className="font-weight-bold">Last name *</h5>
                          </label>
                          <Field
                            type="text"
                            name="lastName"
                            component={renderInputField}
                            validate={[requiredValidation]}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <FormGroup>
                          <label>
                            <h5 className="font-weight-bold">Email *</h5>
                          </label>
                          <Field
                            type="text"
                            name="email"
                            component={renderInputField}
                            validate={[requiredValidation, emailValidation]}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <label>
                            <h5 className="font-weight-bold">
                              Phone number (International format) *
                            </h5>
                          </label>
                          <Field
                            type="text"
                            name="phoneNumber"
                            component={renderInputField}
                            validate={[
                              requiredValidation,
                              phoneNumberValidation,
                            ]}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="d-flex">
                          <Label className="mr-3">
                            <h5 className="font-weight-bold">
                              Contact preference
                            </h5>
                          </Label>
                          <div className="form-check-radio mr-3">
                            <Label check>
                              <Field
                                type="radio"
                                name="contactPreference"
                                id="contactPreferenceEmail"
                                component={renderCheckboxField}
                                value="E"
                              />
                              Email <span className="form-check-sign" />
                            </Label>
                          </div>
                          <div className="form-check-radio">
                            <Label check>
                              <Field
                                type="radio"
                                name="contactPreference"
                                id="contactPreferencePhone"
                                component={renderCheckboxField}
                                value="P"
                              />
                              Phone number <span className="form-check-sign" />
                            </Label>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
              <div className="section">
                <Row className="m-10">
                  <Col md="4">
                    <h4 className="m-0">Company</h4>
                  </Col>
                  <Col md="8">
                    <Row>
                      <Col md="6">
                        <FormGroup>
                          <label>
                            <h5 className="font-weight-bold">Company *</h5>
                          </label>
                          <Field
                            type="text"
                            name="company"
                            component={renderInputField}
                            validate={[requiredValidation]}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <label>
                            <h5 className="font-weight-bold">Fleet size *</h5>
                          </label>
                          <Field
                            type="select"
                            name="fleetSize"
                            component={renderSelectField}
                            validate={[requiredValidation]}
                            defaultValue={""}
                          >
                            <option value="">Select fleet size</option>

                            {formVariant === VARIANT_1
                              ? Object.keys(FLEET_SIZE_OPTIONS_VARIANT_1).map(
                                  (key, index) => (
                                    <option key={index} value={key}>
                                      {FLEET_SIZE_OPTIONS_VARIANT_1[key]}
                                    </option>
                                  )
                                )
                              : formVariant === VARIANT_3
                              ? Object.keys(FLEET_SIZE_OPTIONS_VARIANT_3).map(
                                  (key, index) => (
                                    <option key={index} value={key}>
                                      {FLEET_SIZE_OPTIONS_VARIANT_3[key]}
                                    </option>
                                  )
                                )
                              : Object.keys(FLEET_SIZE_OPTIONS_VARIANT_0).map(
                                  (key, index) => (
                                    <option key={index} value={key}>
                                      {FLEET_SIZE_OPTIONS_VARIANT_0[key]}
                                    </option>
                                  )
                                )}
                          </Field>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col md="6">
                        <h5 className="font-weight-bold pb-2">
                          Equipment Type
                        </h5>
                        <Field
                          name="equipmentType"
                          component={CheckboxGroup}
                          options={Object.keys(EQUIPMENT_TYPE_OPTIONS).map(
                            (key) => {
                              return {
                                name: key,
                                value: EQUIPMENT_TYPE_OPTIONS[key],
                              };
                            }
                          )}
                        />
                      </Col>
                      <Col md="6"></Col>
                    </Row>

                    <Row>
                      <Col md="6">
                        <FormGroup>
                          <label>
                            <h5 className="font-weight-bold">
                              {country === "US" ? "Address" : "Address Line 1"}{" "}
                              {formVariant !== VARIANT_4 && "*"}
                            </h5>
                          </label>
                          <Field
                            type="input"
                            name="address"
                            component={renderInputField}
                            validate={
                              formVariant !== VARIANT_4
                                ? [requiredValidation]
                                : []
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <label>
                            <h5 className="font-weight-bold">
                              {country === "US"
                                ? "Apt, suite, etc."
                                : "Address Line 2"}
                            </h5>
                          </label>
                          <Field
                            type="input"
                            name="address2"
                            component={renderInputField}
                            validate={[]}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <FormGroup>
                          <label>
                            <h5 className="font-weight-bold">
                              City {formVariant !== VARIANT_4 && "*"}
                            </h5>
                          </label>
                          <Field
                            type="input"
                            name="city"
                            component={renderInputField}
                            validate={
                              formVariant !== VARIANT_4
                                ? [requiredValidation]
                                : []
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        {country === "US" ? (
                          <FormGroup>
                            <label>
                              <h5 className="font-weight-bold">
                                State {formVariant !== VARIANT_4 && "*"}
                              </h5>
                            </label>
                            <Field
                              type="select"
                              name="state"
                              component={renderSelectUSStatesField}
                              validate={
                                formVariant !== VARIANT_4
                                  ? [requiredValidation]
                                  : []
                              }
                              defaultValue={""}
                            />
                          </FormGroup>
                        ) : (
                          <FormGroup>
                            <label>
                              <h5 className="font-weight-bold">County</h5>
                            </label>
                            <Field
                              type="input"
                              name="state"
                              component={renderInputField}
                              validate={[]}
                            />
                          </FormGroup>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6" className="min-h-130">
                        <FormGroup>
                          <label>
                            <h5 className="font-weight-bold">
                              {country === "US" ? "Zip code" : "Postcode"}{" "}
                              {formVariant !== VARIANT_4 && "*"}
                            </h5>
                          </label>
                          <Field
                            type="input"
                            name="zipCode"
                            component={renderInputField}
                            validate={
                              formVariant !== VARIANT_4
                                ? [requiredValidation]
                                : []
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <label>
                            <h5 className="font-weight-bold">Country</h5>
                          </label>
                          <Field
                            type="select"
                            name="country"
                            component={renderSelectCountriesField}
                            validate={
                              formVariant !== VARIANT_4
                                ? [requiredValidation]
                                : []
                            }
                            defaultValue={""}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="mt-5">
                      <Col>
                        <FormGroup>
                          <label>
                            <h5 className="font-weight-bold">Customer note</h5>
                          </label>
                          <Field
                            type="textarea"
                            name="comment"
                            component={renderInputField}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </CardBody>
          </Card>
          {submitFailed && invalid && (
            <div className="d-flex justify-content-end pr-5 mt-2">
              <p className="text-danger m-0">
                <img alt="" src={WarningIcon} className="mr-1" />
                Complete all required fields or fix invalid fields before
                continuing.
              </p>
            </div>
          )}
        </fieldset>
      </form>
    );
  }
}

const asyncValidate = (values, dispatch, props) => {
  // Validate Address
  return validateAddress(
    values.zipCode,
    values.state,
    values.city,
    values.country
  ).then((res) => {
    if (res.data && res.data.errors) {
      res.data.errors.map((errors) => {
        throw camelcaseKeys(errors, { deep: true });
      });
    }
  });
};

const selector = formValueSelector("LeadNewDetail");

LeadNewDetailForm = connect((state) => {
  return {
    marketplace: selector(state, "marketplace"),
    solution: selector(state, "solution"),
    country: selector(state, "country"),
    account: state.account,
  };
})(LeadNewDetailForm);

export default reduxForm({
  form: "LeadNewDetail",
  asyncValidate,
})(LeadNewDetailForm);
