/* eslint-disable linebreak-style */
import axios from 'axios';
import {
  SUBSCRIPTION_LIST_FETCH_REQUEST,
  SUBSCRIPTION_LIST_FETCH_SUCCESS,
  SUBSCRIPTION_LIST_FETCH_ERROR,
  SOLUTION_ADD_ERROR,
} from './actionTypes';
import { updateSpinner } from "./spinnerActions";

import {
  SUBSCRIPTION_STATUS_LIVE_VALUE
} from "../../config/subscription";


export const fetchSubscriptionList = ({limit, requires_activation, active_subscriptions_exists} = {}) => (dispatch, state) => {
  dispatch(updateSpinner(true));
  dispatch({ type: SUBSCRIPTION_LIST_FETCH_REQUEST , request: true});
  let url = `/api/core/subscriptions/`;

  const { mode } = state().account;
  url += `?mode=${mode}`;

  if (limit) url += `&limit=${limit}`;
  if (requires_activation) url += `&requires_activation=${requires_activation}`;
  if (active_subscriptions_exists) url += `&active_subscriptions_exists=${active_subscriptions_exists}`;

  axios.get(url)
  .then(({ data }) => {
    if (data) {
      dispatch({ type: SUBSCRIPTION_LIST_FETCH_SUCCESS, data });
    } else {
      dispatch({ type: SUBSCRIPTION_LIST_FETCH_ERROR, error: { mes: "" }});
    }
    dispatch(updateSpinner(false));
  })
  .catch((error) => {
    dispatch({ type: SUBSCRIPTION_LIST_FETCH_ERROR, error });
    dispatch(updateSpinner(false));
  });
};


export const ActiveSubscriptionsByFleet = (fleetId, solutionId) => async (dispatch) => {
  dispatch(updateSpinner(true));
  let url = `/api/core/subscriptions/?fleet__id=${fleetId}&solution__id=${solutionId}&status=${SUBSCRIPTION_STATUS_LIVE_VALUE}`;
  try {
    const res = await axios.get(url);
    dispatch(updateSpinner(false));
    return res;
  } catch (error) {
    dispatch({ type: SUBSCRIPTION_LIST_FETCH_ERROR, error });
    dispatch(updateSpinner(false));
    return error;
  }
};

export const activeSubscriptionsByFleetEmail = async (fleetEmail, solutionId) => {
  let url = `/api/core/subscriptions/?fleet_email=${fleetEmail}&solution__id=${solutionId}&status=${SUBSCRIPTION_STATUS_LIVE_VALUE}`;
  try {
    return await axios.get(url);
  } catch (error) {
    return error;
  }
};

export const ActiveSubscriptionsByHostFleetIdAndHost = (hostFleet_id, solutionId, hostId) => async (dispatch) => {
  dispatch(updateSpinner(true));
  let url = `/api/core/subscriptions/?host_fleet_id=${hostFleet_id}&solution__id=${solutionId}&host_id=${hostId}&status=${SUBSCRIPTION_STATUS_LIVE_VALUE}`;
  try {
    const res = await axios.get(url);
    dispatch(updateSpinner(false));
    return res;
  } catch (error) {
    dispatch({ type: SUBSCRIPTION_LIST_FETCH_ERROR, error });
    dispatch(updateSpinner(false));
    throw error;
  }
};