import React from "react";
import {
  Col,
  Label,
  Row,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalBody,
  ModalFooter,
  Button
} from "reactstrap";
import RemoveIcon from "../../../assets/icons/remove.svg";
import SolutionAdditionalResourcesUploadForm from "./SolutionAdditionalResourcesUploadForm";


class SolutionAdditionalResourcesUpload extends React.Component {
  constructor(props) {
    super(props);
    this.form = React.createRef();
    this.state = {
      filesData: [],
      uploadedFilesData: [],
      error: "",
      modal: false,
    };
    this.handleFilesChange = this.handleFilesChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.submitForm.bind(this);
  }

  componentDidMount() {
    if (this.props.solutionAdditionalResources) {
      this.setState({
        uploadedFilesData: this.props.solutionAdditionalResources
      });
    }    
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.solutionAdditionalResources && 
        this.props.solutionAdditionalResources
      ) {
        this.setState({
          uploadedFilesData: this.props.solutionAdditionalResources
        });
      }
  }

  readFile(files, filesData, idx) {
    let reader = new FileReader();
    let file = files[idx];
    const {
      setAddedAdditionalResources
    } = this.props;
    reader.onloadend = () => {
      filesData.push({
        name: files[idx].name,
        data: reader.result,
        size: files[idx].size,
      });
      if (idx + 1 < files.length) {
        this.readFile(files, filesData, idx + 1)
      } else {
        this.setState({
          filesData
        });
        this.refs.fileInput.value = null;
        if (setAddedAdditionalResources) {
          setAddedAdditionalResources(filesData);
        }
      }
    };
    reader.readAsDataURL(file);
  }

  handleFilesChange(e) {
    e.preventDefault();
    const {setFilesSize} = this.props;
    if (!e.target.files.length) {
      return;
    }
    if (!e.target.files[0].size) {
      this.setState({error: "Empty files cannot be uploaded"});
    } else {
      try {
        setFilesSize(e.target.files[0].size)
        this.setState({error: ""});
        this.readFile(e.target.files, this.state.filesData, 0)
      } catch (e) {
        this.setState({error: e.toString()});
      }
    }
  }

  removeNewFile(idx) {
    const {setFilesSize, removeAddedAdditionalResource} = this.props;
    let newFilesData = [
      ...this.state.filesData
    ];
    const deletedFileSize = newFilesData[idx].size;
    newFilesData.splice(idx, 1);
    this.setState({
      filesData: newFilesData
    });
    removeAddedAdditionalResource(idx);

    try {
      setFilesSize(deletedFileSize, "minus")
      this.setState({error: ""});
    } catch (e) {
      this.setState({error: e.toString()});
    }

  }

  removeUploadedFile(idx) {
    const {
      addRemovedAdditionalResource
    } = this.props;
    let newUploadedFilesData = [
      ...this.state.uploadedFilesData
    ];
    const {
      id
    } = newUploadedFilesData[idx];
    newUploadedFilesData.splice(idx, 1);
    this.setState({
      uploadedFilesData: newUploadedFilesData
    });
    addRemovedAdditionalResource(id);
  }

  handleClick() {
    this.refs.fileInput.click();
  }

  toggleModal(){
    this.setState({
      modal: !this.state.modal
    });
  }

  submitForm(event) {
    this.form.current.submit();
    event.preventDefault();
  }

  onSubmit(values) {
    const {setAddedAdditionalResources} = this.props;
    const {filesData} = this.state;
    filesData.push(values);
    this.setState({filesData});
    setAddedAdditionalResources(filesData);
    this.toggleModal();
  }

  render() {
    return (
      <div>
        <h4>Additional Resources</h4>
        <div className="additional-resources-panel">
          <div className="d-flex">
            <div className="flex-grow-1 pt-3">
              Upload documents or materials about your solution
            </div>
            <div>
              <UncontrolledDropdown group>
                <DropdownToggle color="outline-primary" >
                  Add
                </DropdownToggle>
                <DropdownMenu >
                  <DropdownItem
                    style={{cursor: "pointer"}}
                    className="-cursor-pointer"
                    onClick={() => this.handleClick()}>
                    Upload
                  </DropdownItem>
                  <DropdownItem
                    onClick={this.toggleModal}
                    style={{cursor: "pointer"}}>
                    Link/URL
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <div className="fileinput text-center">
                <input type="file" onChange={this.handleFilesChange} ref="fileInput" multiple />
              </div>

              <Modal isOpen={this.state.modal} toggle={this.toggleModal}>
                <div className="modal-header justify-content-center">
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.toggleModal}>
                    <span aria-hidden="true">×</span>
                  </button>
                  <h5 className="modal-title">Add Resource Link</h5>
                </div>
                <ModalBody>
                  <SolutionAdditionalResourcesUploadForm
                    ref={this.form}
                    onSubmit={this.onSubmit.bind(this)}
                  />
                </ModalBody>
                <ModalFooter>
                  <Button color="secondary" onClick={this.toggleModal}>
                    Cancel
                  </Button>
                  <Button color="primary" className="mr-2" onClick={(event) => this.submitForm(event)}>
                    Save
                  </Button>
                </ModalFooter>
              </Modal>

            </div>
          </div>
          {
            <Row>
              <Col>
                {
                  this.state.uploadedFilesData.map(
                    (fileData, idx) => (
                      <div key={idx} className="additional-resources-panel__resource-item">
                        <div className="text-truncate">
                          {fileData.resourceFileName || fileData.name}
                        </div>
                        <img src={RemoveIcon} alt="" onClick={
                          () => {
                            this.removeUploadedFile(idx)
                          }
                        } />
                      </div>
                    )
                  )
                }
                {
                  this.state.filesData.map(
                    (fileData, idx) => (
                      <div key={idx} className="additional-resources-panel__resource-item">
                        <div className="text-truncate">
                          {fileData.name}
                        </div>
                        <img alt="" src={RemoveIcon} onClick={
                          () => {
                            this.removeNewFile(idx)
                          }
                        } />
                      </div>
                    )
                  )
                }
              </Col>
            </Row>
          }
          {this.state.error &&
            <div>
              <Label className="error mt-1">{this.state.error}</Label>
            </div>
          }
        </div>
      </div>
    );
  }
}

export default SolutionAdditionalResourcesUpload;
