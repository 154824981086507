import {
  ChonkyActions,
  FileBrowser,
  FileList,
  FileNavbar,
  FileToolbar,
  setChonkyDefaults,
} from "chonky";
import { ChonkyIconFA } from "chonky-icon-fontawesome";
import React, { useCallback, useEffect, useState, useMemo } from "react";

setChonkyDefaults({ iconComponent: ChonkyIconFA });

function basename(path) {
  return path.replace(/\/$/, "").split("/").reverse()[0];
}

function pathJoin(parts, sep){
  const separator = sep || '/';
  const replace   = new RegExp(separator+'{1,}', 'g');
  return parts.join(separator).replace(replace, separator);
}


const fetchS3BucketContents = (
  prefix,
  fetchMarketplaceResourceStaticFiles,
  marketplaceId
) => {
  return fetchMarketplaceResourceStaticFiles(
    marketplaceId,
    prefix !== "/" ? prefix : ""
  ).then((response) => {
    const chonkyFiles = [];
    const s3Objects = response.Contents;
    const s3Prefixes = response.CommonPrefixes;
    if (s3Objects) {
      chonkyFiles.push(
        ...s3Objects.map((object) => {
          return {
            id: object.Key,
            name: basename(object.Key),
            modDate: object.LastModified,
            size: object.Size,
          };
        })
      );
    }

    if (s3Prefixes) {
      chonkyFiles.push(
        ...s3Prefixes.map((prefix) => {
          return {
            id: prefix.Prefix,
            name: basename(prefix.Prefix),
            isDir: true,
          };
        })
      );
    }

    return chonkyFiles;
  });
};

const S3Browser = ({
  fetchMarketplaceResourceStaticFiles,
  marketplaceId,
  onSelectFile,
  staticFile,
}) => {
  const [folderPrefix, setKeyPrefix] = useState(null);
  const [files, setFiles] = useState([]);
  const fileBrowserRef = React.useRef(null);

  useEffect(() => {
    if (staticFile) {
      if (staticFile.endsWith("/")) {
        setKeyPrefix(staticFile.slice(0, -1));
      } else {
        setKeyPrefix(staticFile);
      }
    } else {
      setKeyPrefix("/");
    }
  }, [staticFile]);

  useEffect(() => {
    if (fileBrowserRef && fileBrowserRef.current) {
      const selectedFiles = new Set();
      selectedFiles.add(staticFile);
      fileBrowserRef.current.setFileSelection(selectedFiles);
    }
  }, [staticFile, files]);

  useEffect(() => {
    if (folderPrefix) {
      fetchS3BucketContents(
        folderPrefix,
        fetchMarketplaceResourceStaticFiles,
        marketplaceId
      ).then(setFiles);
    }
  }, [folderPrefix, setFiles]);

  const folderChain = React.useMemo(() => {
    if (folderPrefix) {
      let folderChain;
      if (folderPrefix === "/") {
        folderChain = [];
      } else {
        let currentPrefix = "";
        folderChain = folderPrefix
          .replace(/\/*$/, "")
          .split("/")
          .map((prefixPart) => {
            currentPrefix = currentPrefix
              ? pathJoin([currentPrefix, prefixPart])
              : prefixPart;
            return {
              id: currentPrefix,
              name: prefixPart,
              isDir: true,
            };
          });
      }
      folderChain.unshift({
        id: "/",
        name: "static content",
        isDir: true,
      });
      return folderChain;
    }
  }, [folderPrefix]);

  const handleFileAction = useCallback(
    (data) => {
      if (data.id === ChonkyActions.OpenFiles.id) {
        if (data.payload.files && data.payload.files.length !== 1) return;
        if (!data.payload.targetFile || !data.payload.targetFile.isDir) return;

        const newPrefix = `${data.payload.targetFile.id.replace(/\/*$/, "")}/`;
        setKeyPrefix(newPrefix);
      } else if (
        data.id === ChonkyActions.ChangeSelection.id ||
        data.id === ChonkyActions.MouseClickFile.id
      ) {
        if (data.payload.selection && data.payload.selection.size > 0) {
          const files = [...data.payload.selection];
          onSelectFile(files[0]);
        }
      } else if (data.id === ChonkyActions.CreateFolder.id) {
        window.open(process.env.REACT_APP_STATIC_CONTENT_SITE_URL, "_blank");
      }
    },
    [setKeyPrefix]
  );

  const fileActions = useMemo(() => [ChonkyActions.CreateFolder], []);

  const i18n = {
    messages: {
      [`chonky.actions.${ChonkyActions.CreateFolder.id}.button.name`]:
        "Create Static Content",
    },
  };

  return (
    <div style={{ height: 400 }}>
      <FileBrowser
        ref={fileBrowserRef}
        instanceId="Static Content"
        files={files}
        folderChain={folderChain}
        onFileAction={handleFileAction}
        disableDefaultFileActions={[
          ChonkyActions.OpenSelection.id,
          ChonkyActions.SelectAllFiles.id,
          ChonkyActions.ClearSelection.id,
          ChonkyActions.SortFilesByName.id,
          ChonkyActions.SortFilesBySize.id,
          ChonkyActions.SortFilesByDate.id,
          ChonkyActions.ToggleHiddenFiles.id,
          ChonkyActions.ToggleShowFoldersFirst.id,
          ChonkyActions.StartDragNDrop.id,
          ChonkyActions.EndDragNDrop.id,
          ChonkyActions.MoveFiles.id,
        ]}
        clearSelectionOnOutsideClick={false}
        disableDragAndDrop={true}
        fileActions={fileActions}
        i18n={i18n}
      >
        <FileNavbar />
        <FileToolbar />
        <FileList />
      </FileBrowser>
    </div>
  );
};

export default S3Browser;
