import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { Button, Card, CardBody, FormGroup, Input, Row, Col } from "reactstrap";
import { fetchMarketplaceList } from "../../store/actions/marketplaceListActions";
import { fetchSubscriptionList } from "../../store/actions/subscriptionListActions";
import {
  SUBSCRIPTION_STATUS_CANCELED_LABEL,
  SUBSCRIPTION_STATUS_CANCELED_VALUE,
  SUBSCRIPTION_STATUS_LIVE_VALUE,
} from "../../config/subscription";
import { clearFleetDetail } from "../../store/actions/customerDetailActions";
import { clearLeadDetail } from "../../store/actions/leadDetailActions";
import CustomerListTable from "./components/CustomerListTable";

class CustomerList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      marketplaceFilter: "",
      statusFilter: "",
      fleetNameFilter: "",
    };
  }

  componentDidMount() {
    const { fetchMarketplaceList, fetchSubscriptionList } = this.props;
    fetchMarketplaceList();
    fetchSubscriptionList();
  }

  onCreateNewSubscription() {
    this.props.clearFleetDetail();
    this.props.clearLeadDetail();
    this.props.history.push("/admin/customers/new");
  }

  render() {
    const { account } = this.props;
    const { subscriptions, marketplaces } = this.props;
    const { marketplaceFilter, statusFilter, fleetNameFilter } = this.state;

    const data = subscriptions.map((subscription) => {
      if (subscription.status === SUBSCRIPTION_STATUS_LIVE_VALUE)
        subscription.ui_status = "Active";
      else if (subscription.status === SUBSCRIPTION_STATUS_CANCELED_VALUE)
        subscription.ui_status = SUBSCRIPTION_STATUS_CANCELED_LABEL;
      else subscription.ui_status = "Pending";
      return subscription;
    });

    return (
      <>
        <div className="breadcrumb-nav">
          <Row>
            <Col>
              <ol className="breadcrumb-container">
                <li className="breadcrumb-container__item">
                  <h5>
                    <Link to="/admin/home">Home</Link>
                  </h5>
                </li>
                <li className="breadcrumb-container__item">
                  <h5>/</h5>
                </li>
                <li className="breadcrumb-container__item">
                  <h5>Subscriptions</h5>
                </li>
              </ol>
            </Col>
          </Row>
        </div>
        <div className="content customer-list-page">
          <div className="page-content-header">
            <Row>
              <Col>
                <h3>Subscriptions</h3>
              </Col>
              <Col>
                {account.permissions.includes("write:customers") && (
                  <Button
                    className="pull-right"
                    color="primary"
                    onClick={() => this.onCreateNewSubscription()}
                  >
                    Create New Subscription
                  </Button>
                )}
              </Col>
            </Row>
          </div>
          <Card className="custom-react-table">
            <CardBody>
              <div className="d-flex align-items-center mb-3">
                <div className="mr-3">Filter by</div>
                <FormGroup className="m-0 mr-3">
                  <Input
                    type="select"
                    className="custom-select custom-select-xl pr-4"
                    onChange={(event) => {
                      this.setState({
                        marketplaceFilter: event.target.value,
                      });
                    }}
                  >
                    <option value="">All marketplaces</option>
                    {marketplaces.map((marketplace) => (
                      <option key={marketplace.id}>{marketplace.name}</option>
                    ))}
                  </Input>
                </FormGroup>

                <FormGroup className="m-0 mr-3">
                  <Input
                    type="select"
                    className="custom-select custom-select-xl pr-4"
                    onChange={(event) => {
                      this.setState({
                        statusFilter: event.target.value,
                      });
                    }}
                  >
                    <option value="">All statuses</option>
                    <option value="Active">Active</option>
                    <option value="Pending">Pending</option>
                  </Input>
                </FormGroup>

                <div className="mr-3 ml-4">Customer</div>
                <FormGroup className="m-0 mr-3">
                  <Input
                    type="text"
                    onChange={(event) => {
                      this.setState({
                        fleetNameFilter: event.target.value,
                      });
                    }}
                  ></Input>
                </FormGroup>
              </div>
              <CustomerListTable
                data={data}
                marketplaceFilter={marketplaceFilter}
                statusFilter={statusFilter}
                fleetNameFilter={fleetNameFilter}
                history={this.props.history}
              />
            </CardBody>
          </Card>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  subscriptions: state.subscriptionList.subscriptions,
  marketplaces: state.marketplaceList.marketplaces,
  account: state.account,
});

const mapDispatchToProps = {
  fetchMarketplaceList,
  fetchSubscriptionList,
  clearFleetDetail,
  clearLeadDetail,
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerList);
