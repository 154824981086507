export const SOLUTION_DISCOUNT_ENTITY_TYPE_SUBSCRIPTION_VALUE = 'SUBSCRIPTION';
export const SOLUTION_DISCOUNT_ENTITY_TYPE_OTC1_VALUE = 'OTC1';
export const SOLUTION_DISCOUNT_ENTITY_TYPE_OTC2_VALUE = 'OTC2';
export const SOLUTION_DISCOUNT_ENTITY_TYPE_NON_MONETARY_VALUE = 'NON_MONETARY';

export const SOLUTION_DISCOUNT_ENTITY_TYPE_SUBSCRIPTION_LABEL = 'Subscription';
export const SOLUTION_DISCOUNT_ENTITY_TYPE_OTC1_LABEL = 'First one-time charge';
export const SOLUTION_DISCOUNT_ENTITY_TYPE_OTC2_LABEL = 'Second one-time charge';
export const SOLUTION_DISCOUNT_ENTITY_TYPE_NON_MONETARY_LABEL = 'Non-Monetary';

export const SOLUTION_DISCOUNT_PRICE_TYPE_PERCENTAGE_VALUE = 'PERCENTAGE';
export const SOLUTION_DISCOUNT_PRICE_TYPE_FIXED_AMOUNT_VALUE = 'AMOUNT';

// export const SOLUTION_DISCOUNT_PRICE_TYPE_PERCENTAGE_LABEL = 'Percentage';
// export const SOLUTION_DISCOUNT_PRICE_TYPE_FIXED_AMOUNT_LABEL = 'Fixed amount';
