import React, { useEffect } from "react";
import { useTable, useColumnOrder, useResizeColumns } from "react-table";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import update from "immutability-helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faEllipsisV,
  faTrashAlt,
  faSync,
  faArrowAltCircleRight,
} from "@fortawesome/free-solid-svg-icons";
import { withRouter } from "react-router-dom";

import {
  changeMarketplaceResourceOrder,
  fetchMarketplaceResourceList,
} from "../../store/actions/marketplaceResourceListActions";
import { connect } from "react-redux";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  UncontrolledTooltip,
} from "reactstrap";
import {
  deleteMarketplaceResource,
  publishMarketplaceResource,
} from "../../store/actions/marketplaceResourceDetailActions";
import { addMessage } from "../../store/actions/messageActions";
import { M_RESOURCE_CATEGORY_OPTIONS } from "../../config/marketplaceResource";
import { useAuth0 } from "../../services/auth/react-auth0-wrapper";

let reportVisibleColumns = "?";
let reportFlatHeaders = "?"; // Table.getHeaderGroupProps.flatHeaders

const Table = ({
  columns,
  data,
  changeMarketplaceResourceOrder,
  deleteHandler,
  history,
  selectedMarketplace,
  publishMarketplaceResource,
  selectedCategory,
}) => {
  const [records, setRecords] = React.useState(data);

  useEffect(() => {
    setRecords(data);
  }, [data]);

  const getRowId = React.useCallback((row) => {
    return row.id;
  }, []);

  const { getTableProps, getTableBodyProps, rows, headerGroups, prepareRow } =
    useTable(
      {
        data: records,
        columns,
        getRowId,
      },
      useResizeColumns,
      useColumnOrder
    );

  const myTableRef = React.useRef({});

  const moveRow = (dragIndex, hoverIndex) => {
    const dragRecord = records[dragIndex];
    const hoverRecord = records[hoverIndex];

    changeMarketplaceResourceOrder({
      source: dragRecord.order,
      destination: hoverRecord.order,
      marketplace: selectedMarketplace,
      category: selectedCategory,
    });
    setRecords(
      update(records, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragRecord],
        ],
      })
    );
  };

  return (
    <table
      {...getTableProps()}
      ref={myTableRef}
      className="table"
      style={{ backgroundColor: "#FFFFFF" }}
    >
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            <th />
            {headerGroup.headers.map((column, index) => (
              <th {...column.getHeaderProps()} className="pt-4">
                {column.render("Header")}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map(
          (row, index) =>
            prepareRow(row) || (
              <Row
                index={index}
                row={row}
                moveRow={moveRow}
                deleteHandler={deleteHandler}
                history={history}
                selectedMarketplace={selectedMarketplace}
                publishMarketplaceResource={publishMarketplaceResource}
                marketplaceResource={row.original}
                {...row.getRowProps()}
              />
            )
        )}
      </tbody>
    </table>
  );
};

const DND_ROW_TYPE = "row";

const Row = ({
  row,
  index,
  moveRow,
  deleteHandler,
  selectedMarketplace,
  history,
  publishMarketplaceResource,
  marketplaceResource,
}) => {
  const { user } = useAuth0();
  const dropRefRow = React.useRef(null);
  const dragRefRow = React.useRef(null);

  const [, drop] = useDrop({
    accept: DND_ROW_TYPE,
    hover(item, monitor) {
      if (!dropRefRow.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = dropRefRow.current.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action

      moveRow(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDraggingRow }, drag, preview] = useDrag({
    type: DND_ROW_TYPE,
    item: { index },
    collect: (monitor) => ({
      isDraggingRow:
        monitor.isDragging() && monitor.getItemType() === DND_ROW_TYPE,
      isOver: monitor.isOver,
      canDrop: monitor.canDrop,
    }),
  });

  const opacity = isDraggingRow ? 0 : 1;

  preview(drop(dropRefRow));
  drag(dragRefRow);

  return (
    <tr ref={dropRefRow} style={{ opacity }}>
      <td ref={dragRefRow}>
        <div
          className="pl-3"
          style={{
            cursor: "grab",
          }}
        >
          <FontAwesomeIcon icon={faEllipsisV} />
        </div>
      </td>
      {row.cells.map((cell) => {
        const { column } = cell;
        if (column.id && column.id === "actions") {
          return (
            <td {...cell.getCellProps()}>
              <Button
                className="btn-icon mr-2 my-1"
                color="success"
                size="sm"
                onClick={() => {
                  history.push(
                    `/admin/resources/${selectedMarketplace}/${row.original.id}`
                  );
                }}
              >
                <i className="fa fa-edit" />
              </Button>
              {!marketplaceResource.deleteRequested && (
                <Button
                  className="btn-icon mr-2 my-1"
                  color="danger"
                  size="sm"
                  onClick={() => {
                    deleteHandler(row.id);
                  }}
                >
                  <i className="fa fa-times" />
                </Button>
              )}
              {user.scope.includes("SuperAdmin") &&
                marketplaceResource.changedRequest && (
                  <div className='d-inline'>
                    <UncontrolledTooltip
                      placement="bottom"
                      target={`publish_id-${row.id}`}
                    >
                      Sync with marketplace
                    </UncontrolledTooltip>
                    <Button
                      className="btn-icon my-1"
                      size="sm"
                      color="default"
                      id={`publish_id-${row.id}`}
                      onClick={() => {
                        publishMarketplaceResource(row.id);
                      }}
                    >
                      <FontAwesomeIcon icon={faArrowAltCircleRight} />
                    </Button>
                  </div>
                )}
            </td>
          );
        } else {
          return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
        }
      })}
    </tr>
  );
};

class MarketplaceResourceList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      modalDeleteConfirmation: false,
      checkedForDelete: null,
      selectedMarketplaceResource: null,
    };
    this.deleteHandler = this.deleteHandler.bind(this);
    this.deleteConfirmed = this.deleteConfirmed.bind(this);
    this.toggleModalDeleteConfirmation =
      this.toggleModalDeleteConfirmation.bind(this);
  }

  componentDidMount() {
    const { state } = this.props.location;
    const { fetchMarketplaceResourceList, selectedMarketplace } = this.props;
    if (state && state.selectedMarketplace) {
      this.props.setSelectedMarketplace(state.selectedMarketplace);
      fetchMarketplaceResourceList(state.selectedMarketplace);
    } else {
      fetchMarketplaceResourceList(selectedMarketplace);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { marketplaceResources, selectedCategory, selectedMarketplace } =
      this.props;
    if (
      prevProps.marketplaceResources !== marketplaceResources ||
      prevProps.selectedCategory !== selectedCategory ||
      prevProps.selectedMarketplace !== selectedMarketplace
    ) {
      let filteredMarketplaceResources = selectedCategory
        ? marketplaceResources.filter(
            (marketplaceResource) =>
              marketplaceResource.category === selectedCategory
          )
        : marketplaceResources;

      filteredMarketplaceResources = selectedMarketplace
        ? filteredMarketplaceResources.filter(
            (marketplaceResource) =>
              marketplaceResource.marketplace === parseInt(selectedMarketplace)
          )
        : filteredMarketplaceResources;

      const data = filteredMarketplaceResources.map((marketplaceResource) => {
        return {
          id: marketplaceResource.id,
          order: marketplaceResource.order,
          label: marketplaceResource.label,
          category: marketplaceResource.category,
          resourceLink: marketplaceResource.resourceLink,
          changedRequest: marketplaceResource.changed,
          deleteRequested: marketplaceResource.deleteRequested,
          changed: marketplaceResource.changed ? (
            <>
              {marketplaceResource.deleteRequested ? (
                <>
                  <UncontrolledTooltip
                    placement="bottom"
                    target={`delete_id-${marketplaceResource.id}`}
                  >
                    Marked for deletion
                  </UncontrolledTooltip>
                  <FontAwesomeIcon
                    icon={faTrashAlt}
                    id={`delete_id-${marketplaceResource.id}`}
                  />
                </>
              ) : (
                <>
                  <UncontrolledTooltip
                    placement="bottom"
                    target={`sync_id-${marketplaceResource.id}`}
                  >
                    Waiting for synchronization
                  </UncontrolledTooltip>
                  <FontAwesomeIcon
                    icon={faSync}
                    id={`sync_id-${marketplaceResource.id}`}
                  />
                </>
              )}
            </>
          ) : (
            <>
              <UncontrolledTooltip
                placement="bottom"
                target={`updated_id-${marketplaceResource.id}`}
              >
                Up to Date
              </UncontrolledTooltip>
              <FontAwesomeIcon
                icon={faCheck}
                id={`updated_id-${marketplaceResource.id}`}
              />
            </>
          ),
          solution: marketplaceResource.solutionName,
        };
      });
      this.setState({ data: data });
    }

    if (prevProps.selectedMarketplace !== this.props.selectedMarketplace) {
      const { fetchMarketplaceResourceList, selectedMarketplace } = this.props;
      fetchMarketplaceResourceList(selectedMarketplace);
    }
  }

  toggleModalDeleteConfirmation() {
    this.setState({
      modalDeleteConfirmation: !this.state.modalDeleteConfirmation,
    });
  }

  deleteHandler(solutionResourceId) {
    this.setState({
      modalDeleteConfirmation: true,
      checkedForDelete: solutionResourceId,
    });
  }

  deleteConfirmed() {
    const { deleteMarketplaceResource } = this.props;
    const { checkedForDelete } = this.state;
    deleteMarketplaceResource(checkedForDelete).then(() => {
      this.props.addMessage("Marketplace resource marked for deletion");
    });
    this.setState({
      modalDeleteConfirmation: false,
      checkedForDelete: null,
    });
  }

  render() {
    const {
      selectedMarketplace,
      history,
      publishMarketplaceResource,
      selectedCategory,
    } = this.props;
    const { data } = this.state;

    return (
      <DndProvider backend={HTML5Backend}>
        {!data || data.length === 0 ? (
          <h5>No Resources found</h5>
        ) : (
          <Table
            columns={[
              {
                Header: "Title",
                accessor: "label",
                filterable: false,
                sortable: true,
                Cell: (cellInfo) => {
                  return (
                    <>
                      <div
                        role="button"
                        onClick={() => {
                          this.props.history.push(
                            `/admin/resources/${selectedMarketplace}/${cellInfo.row.id}`
                          );
                        }}
                      >
                        {cellInfo.value}
                      </div>
                    </>
                  );
                },
              },
              {
                Header: "Category",
                accessor: "category",
                filterable: false,
                sortable: true,
                Cell: (cellInfo) => {
                  return (
                    <>
                      <div
                        role="button"
                        onClick={() => {
                          this.props.history.push(
                            `/admin/resources/${selectedMarketplace}/${cellInfo.row.id}`
                          );
                        }}
                      >
                        {cellInfo.value
                          ? M_RESOURCE_CATEGORY_OPTIONS[cellInfo.value]
                          : ""}
                      </div>
                    </>
                  );
                },
              },
              {
                Header: "Source",
                accessor: "resourceLink",
                filterable: false,
                sortable: true,
                Cell: (cellInfo) => {
                  return (
                    <>
                      <div
                        role="button"
                        onClick={() => {
                          this.props.history.push(
                            `/admin/resources/${selectedMarketplace}/${cellInfo.row.id}`
                          );
                        }}
                      >
                        {cellInfo.value}
                      </div>
                    </>
                  );
                },
              },
              {
                Header: "Up to Date",
                accessor: "changed",
                filterable: false,
                sortable: true,
              },
              {
                id: "actions",
                Header: "Actions",
                accessor: "actions",
                filterable: false,
                sortable: false,
              },
              // {
              //     Header: "Solution",
              //     accessor: "solution",
              //     filterable: false,
              //     sortable: true
              // },
            ]}
            data={data}
            reportVisibleColumns={reportVisibleColumns}
            FlatHeaders={reportFlatHeaders}
            changeMarketplaceResourceOrder={
              this.props.changeMarketplaceResourceOrder
            }
            deleteHandler={this.deleteHandler}
            history={history}
            selectedMarketplace={selectedMarketplace}
            publishMarketplaceResource={publishMarketplaceResource}
            selectedCategory={selectedCategory}
          />
        )}

        <Modal
          isOpen={this.state.modalDeleteConfirmation}
          fade={false}
          toggle={this.toggleModalDeleteConfirmation}
          className="modal-dialog-centered"
        >
          <div className="modal-header justify-content-center">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={this.toggleModalDeleteConfirmation}
            >
              <span aria-hidden="true">×</span>
            </button>
            <h5 className="modal-title">Delete Marketplace Resource</h5>
          </div>
          <ModalBody>
            Are you sure, you want to delete the selected marketplace resource?
          </ModalBody>
          <ModalFooter className="mr-3">
            <Button
              color="outline-primary"
              onClick={this.toggleModalDeleteConfirmation}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              onClick={(event) => this.deleteConfirmed(event)}
            >
              Delete
            </Button>
          </ModalFooter>
        </Modal>
      </DndProvider>
    );
  }
}

const mapStateToProps = (state) => ({
  marketplaceResources: state.marketplaceResourceList.marketplaceResources,
  account: state.account,
});

const mapDispatchToProps = {
  fetchMarketplaceResourceList,
  changeMarketplaceResourceOrder,
  deleteMarketplaceResource,
  addMessage,
  publishMarketplaceResource,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MarketplaceResourceList)
);
