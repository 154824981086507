import React from 'react';
import { Switch, Route } from 'react-router-dom';
import LeadList from './LeadList';
import LeadNewDetail from './LeadNewDetail';

const Lead = () => {
    return (
        <Switch>
            <Route key="1" path="/admin/leads/" exact component={LeadList} />
            <Route key="2" path="/admin/leads/new/" exact component={LeadNewDetail} />
            <Route key="3" path="/admin/leads/:leadId/" exact component={LeadNewDetail} />
        </Switch>
    )
}

export default Lead;
