/* eslint-disable linebreak-style */
import camelcaseKeys from 'camelcase-keys';
import {
  WFE_BUYFLOW_DETAIL_FETCH_REQUEST,
  WFE_BUYFLOW_DETAIL_FETCH_SUCCESS,
  WFE_BUYFLOW_DETAIL_FETCH_ERROR,
  WFE_BUYFLOW_DETAIL_CLEAR,
} from "../actions/actionTypes";

const initialState = {
  buyFlow: {},
  isLoading: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case WFE_BUYFLOW_DETAIL_FETCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: action.error,
      };

    case WFE_BUYFLOW_DETAIL_FETCH_SUCCESS:
      const data = camelcaseKeys(action.data, {deep: true});
      return {
        ...state,
        buyFlow: data,
        isLoading: false,
        error: action.error
      };

    case WFE_BUYFLOW_DETAIL_FETCH_ERROR:
      return {
        ...state,
        buyFlow: {},
        isLoading: false,
        error: action.error,
      };
    case WFE_BUYFLOW_DETAIL_CLEAR:
      return {
        ...state,
        buyFlow: {},
        isLoading: false,
        error: null
      };
    default:
      return state;
  }
};
