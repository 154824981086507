import axios from 'axios';
import { updateSpinner } from "./spinnerActions";
import {
  BILLING_SUBSCRIPTION_FETCH_EFFECTIVE_DATE_REQUEST,
  BILLING_SUBSCRIPTION_FETCH_EFFECTIVE_DATE_SUCCESS,
  BILLING_SUBSCRIPTION_FETCH_EFFECTIVE_DATE_ERROR,
  BILLING_SUBSCRIPTION_FETCH_EFFECTIVE_DATE_CLEAR
} from "./actionTypes";


export const fetchSubscriptionEffectiveDate = (subscriptionUid) => async (dispatch) => {
  dispatch(updateSpinner(true));
  dispatch({ type: BILLING_SUBSCRIPTION_FETCH_EFFECTIVE_DATE_REQUEST , request: true});
  const url = `/api/billing/subscription/effective_date/?subscription_uid=${subscriptionUid}`;
  try {
    const res = await axios.get(url);
    dispatch({ type: BILLING_SUBSCRIPTION_FETCH_EFFECTIVE_DATE_SUCCESS, data: res.data });
    dispatch(updateSpinner(false));
  } catch (error) {
    dispatch({ type: BILLING_SUBSCRIPTION_FETCH_EFFECTIVE_DATE_ERROR, error });
    dispatch(updateSpinner(false));
    return error;
  }
};


export const clearSubscriptionEffectiveDate = () => (dispatch) => {
  dispatch({type: BILLING_SUBSCRIPTION_FETCH_EFFECTIVE_DATE_CLEAR});
};
