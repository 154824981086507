import axios from 'axios';
import {
  SOLUTION_DISCOUNT_LIST_FETCH_REQUEST,
  SOLUTION_DISCOUNT_LIST_FETCH_SUCCESS,
  SOLUTION_DISCOUNT_LIST_FETCH_ERROR,
  SOLUTION_DISCOUNT_LIST_CLEAR,
  SOLUTION_DISCOUNT_ADD_REQUEST,
  SOLUTION_DISCOUNT_ADD_SUCCESS,
  SOLUTION_DISCOUNT_ADD_ERROR,
  SOLUTION_DISCOUNT_UPDATE_REQUEST,
  SOLUTION_DISCOUNT_UPDATE_SUCCESS,
  SOLUTION_DISCOUNT_UPDATE_ERROR,
  SOLUTION_DISCOUNT_DELETE_REQUEST,
  SOLUTION_DISCOUNT_DELETE_SUCCESS,
  SOLUTION_DISCOUNT_DELETE_ERROR,
  SOLUTION_DISCOUNTS_ADD_REQUEST,
  SOLUTION_DISCOUNTS_ADD_ERROR, SOLUTION_DISCOUNT_UPDATE_CLEAR_IS_PRIMARY
} from './actionTypes';
import { updateSpinner } from "./spinnerActions";
import camelcaseKeys from "camelcase-keys";
import snakeCaseKeys from "snakecase-keys";

export const fetchSolutionDiscountList = (solutionId) => (dispatch, state) => {
  dispatch(updateSpinner(true));
  dispatch({ type: SOLUTION_DISCOUNT_LIST_FETCH_REQUEST , request: true});
  let url = `/api/solution/solution_discounts/`;

  const { mode } = state().account;
  url += `?mode=${mode}`;
  url += `&solution=${solutionId}`;

  axios.get(url)
    .then(({ data }) => {
      if (data) {
        const payload = camelcaseKeys(data, {deep: true});
        dispatch({ type: SOLUTION_DISCOUNT_LIST_FETCH_SUCCESS, data: payload });
      } else {
        dispatch({ type: SOLUTION_DISCOUNT_LIST_FETCH_ERROR, error: { mes: "" }});
      }
      dispatch(updateSpinner(false));
    })
    .catch((error) => {
      dispatch({ type: SOLUTION_DISCOUNT_LIST_FETCH_ERROR, error });
      dispatch(updateSpinner(false));
    });
};

export const clearSolutionDiscountList = () => (dispatch) => {
  dispatch({ type: SOLUTION_DISCOUNT_LIST_CLEAR , request: false});
};

export const addSolutionDiscountLocal = (data) => async (dispatch) => {
  data.id = Math.floor(Math.random() * 10000).toString();
  if (data.isPrimary) {
    dispatch({ type: SOLUTION_DISCOUNT_UPDATE_CLEAR_IS_PRIMARY });
  }
  dispatch({ type: SOLUTION_DISCOUNT_ADD_SUCCESS, data });
};


export const addSolutionDiscount = (data) => async (dispatch, state) => {
  dispatch(updateSpinner(true));
  dispatch({ type: SOLUTION_DISCOUNT_ADD_REQUEST , request: true});
  let url = '/api/solution/solution_discounts/';
  const { mode } = state().account;
  url += `?mode=${mode}`;
  try {
    const res = await axios.post(url, data);
    const payload = camelcaseKeys(res.data, {deep: true});
    if (payload.isPrimary) {
      dispatch({ type: SOLUTION_DISCOUNT_UPDATE_CLEAR_IS_PRIMARY });
    }
    dispatch({ type: SOLUTION_DISCOUNT_ADD_SUCCESS, data: payload });
    dispatch(updateSpinner(false));
  } catch (error) {
    dispatch({ type: SOLUTION_DISCOUNT_ADD_ERROR, error });
    dispatch(updateSpinner(false));
    return error;
  }
};

export const addSolutionDiscounts = (data, solutionId) => async (dispatch, state) => {
  dispatch(updateSpinner(true));
  dispatch({ type: SOLUTION_DISCOUNTS_ADD_REQUEST , request: true});
  let url = '/api/solution/solution_discounts/add_multiple/';
  const { mode } = state().account;
  url += `?mode=${mode}`;
  try {
    const payload = snakeCaseKeys({discounts: data, solutionId}, {deep: true});
    await axios.post(url, payload);
    dispatch(updateSpinner(false));
  } catch (error) {
    dispatch({ type: SOLUTION_DISCOUNTS_ADD_ERROR, error });
    dispatch(updateSpinner(false));
    return error;
  }
};


export const updateSolutionDiscount = (discountId, data) => async (dispatch, state) => {
  dispatch(updateSpinner(true));
  dispatch({ type: SOLUTION_DISCOUNT_UPDATE_REQUEST , request: true});
  let url = `/api/solution/solution_discounts/${discountId}/`;
  const { mode } = state().account;
  url += `?mode=${mode}`;
  try {
    const res = await axios.put(url, data);
    const payload = camelcaseKeys(res.data, {deep: true});
    if (payload.isPrimary) {
      dispatch({ type: SOLUTION_DISCOUNT_UPDATE_CLEAR_IS_PRIMARY });
    }
    dispatch({ type: SOLUTION_DISCOUNT_UPDATE_SUCCESS, data: payload });
    dispatch(updateSpinner(false));
  } catch (error) {
    dispatch(updateSpinner(false));
    dispatch({ type: SOLUTION_DISCOUNT_UPDATE_ERROR, error });
    return error;
  }
};

export const updateSolutionDiscountLocal = (discountId, data) => async (dispatch) => {
  data.id = discountId;
  if (data.isPrimary) {
    dispatch({ type: SOLUTION_DISCOUNT_UPDATE_CLEAR_IS_PRIMARY });
  }
  dispatch({ type: SOLUTION_DISCOUNT_UPDATE_SUCCESS, data: data });
};

export const deleteSolutionDiscount = (discountId) => async (dispatch, state) => {
  dispatch(updateSpinner(true));
  dispatch({ type: SOLUTION_DISCOUNT_DELETE_REQUEST , request: true});
  let url = `/api/solution/solution_discounts/${discountId}/`;
  const { mode } = state().account;
  url += `?mode=${mode}`;
  try {
    const res = await axios.delete(url);
    dispatch({ type: SOLUTION_DISCOUNT_DELETE_SUCCESS, data: {id: discountId} });
    dispatch(updateSpinner(false));
    return res;
  } catch (error) {
    dispatch(updateSpinner(false));
    dispatch({ type: SOLUTION_DISCOUNT_DELETE_ERROR, error });
    return error;
  }
};

export const deleteSolutionDiscountLocal = (discountId) => async (dispatch) => {
  dispatch({ type: SOLUTION_DISCOUNT_DELETE_SUCCESS, data: {id: discountId} });
};
