import React from "react";
import FadeLoader from 'react-spinners/FadeLoader'

const Loading = () => (
  <div style={{position: "absolute", top: "50%", left: "50%"}}>
    <FadeLoader />
  </div>
);

export default Loading;
