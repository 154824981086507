import React from "react";

const CircleBorder = ({ size, borderWidth, backgroundColor, children, ...props }) => (
  <div
    style={{
      width: size,
      height: size,
      borderRadius: 0.5 * size,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor,
      borderWidth,
    }}
    {...props}
  >
    {children}
  </div>
);


export default CircleBorder;
