import "core-js/stable";
import "regenerator-runtime/runtime";
import React from "react";
import { createRoot } from "react-dom/client";
import * as Sentry from "@sentry/browser";
import { Auth0Provider } from "./services/auth/react-auth0-wrapper";
import { auth0Config } from "./config/authConfig.js";
import App from "./App";
import "bootstrap/dist/css/bootstrap.css";
import "./assets/scss/paper-dashboard.scss";
import "./assets/scss/custom.scss";
import "./assets/scss/utils.scss";
import "../node_modules/slick-carousel/slick/slick.css";
import "../node_modules/slick-carousel/slick/slick-theme.css";
import 'react-notifications-component/dist/theme.css'
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import 'react-18-image-lightbox/style.css'
import { history } from "./store";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { setChonkyDefaults } from "chonky";
import { ChonkyIconFA } from "chonky-icon-fontawesome";

setChonkyDefaults({ iconComponent: ChonkyIconFA });

// Auth0
// A function that routes the user to the right place
// after login
const onRedirectCallback = (appState) => {
  window.history.replaceState(
    {},
    document.title,
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  );
};

if (process.env.REACT_APP_SENTRY)
  Sentry.init({ dsn: process.env.REACT_APP_SENTRY });

createRoot(document.getElementById("root")).render(
  <Auth0Provider
    domain={auth0Config.domain}
    client_id={auth0Config.clientId}
    redirect_uri={window.location.origin}
    audience={auth0Config.audience}
    onRedirectCallback={onRedirectCallback}
  >
    <App />
  </Auth0Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();

serviceWorkerRegistration.register({
  onUpdate: (registration) => {
    if (registration && registration.waiting) {
      registration.waiting.postMessage({ type: "SKIP_WAITING" });
    }
    window.location.reload();
  },
});

history.listen((location, action) => {
  // check for sw updates on page change
  if (navigator.serviceWorker) {
    navigator.serviceWorker
      .getRegistrations()
      .then((regs) => regs.forEach((reg) => reg.update()));
  }
});
