import React from "react";
import {Field, formValueSelector, reduxForm} from "redux-form";
import { Card, Col, FormGroup, Label, Row } from "reactstrap";

import {
  requiredValidation,
  emailValidation,
  phoneNumberValidation
} from "../../forms/validation";
import {
  renderInputField,
  renderSelectCountriesField,
  renderSelectField,
  renderSelectUSStatesField
} from "../../forms/utils";
import WarningIcon from "../../../assets/icons/warning-icon.svg";
import {
  FLEET_SIZE_OPTIONS_VARIANT_0,
  FLEET_SIZE_OPTIONS_VARIANT_1,
  FLEET_SIZE_OPTIONS_VARIANT_3,
  VARIANT_1,
  VARIANT_3
} from "../../../config";
import { validateAddress } from "../../../store/actions/addressValidationActions";
import {connect} from "react-redux";


class FleetEditNewForm extends React.Component {
  render() {
    const { submitFailed, invalid, account, initialValues: {formVariant}, country } = this.props;
    return (
      <form>
        <Card>
          <fieldset disabled={account.mode === 'Host'}>
            <div className="section">
              <Row>
                <Col md={2}>
                  <h4 className="m-0">
                    Customer Info
                  </h4>
                </Col>
                <Col md="5">
                  <FormGroup>
                    <Label>
                      <h5 className="font-weight-bold">Company name</h5>
                    </Label>
                    <Field
                        name={"name"}
                        type="text"
                        component={renderInputField}
                        validate={[requiredValidation]}
                    />
                  </FormGroup>
                </Col>
                <Col md="5">
                  <FormGroup>
                    <Label>
                      <h5 className="font-weight-bold">
                        Customer fleet size
                      </h5>
                    </Label>
                    <Field
                        type="select"
                        name="size"
                        component={renderSelectField}
                        validate={[requiredValidation]}
                        defaultValue={""}
                    >
                      <option value="" disabled hidden>Select customer fleet size</option>
                      {formVariant === VARIANT_1 ?
                          Object.keys(FLEET_SIZE_OPTIONS_VARIANT_1).map((key, index) => (
                              <option key={index} value={key}>{FLEET_SIZE_OPTIONS_VARIANT_1[key]}</option>
                          ))
                          : formVariant === VARIANT_3 ?
                              Object.keys(FLEET_SIZE_OPTIONS_VARIANT_3).map((key, index) => (
                                  <option key={index} value={key}>{FLEET_SIZE_OPTIONS_VARIANT_3[key]}</option>
                              )) :
                              Object.keys(FLEET_SIZE_OPTIONS_VARIANT_0).map((key, index) => (
                                  <option key={index} value={key}>{FLEET_SIZE_OPTIONS_VARIANT_0[key]}</option>
                              ))
                      }
                    </Field>
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col md={{ size: '5', offset: 2 }}>
                  <FormGroup>
                    <Label>
                      <h5 className="font-weight-bold">First name</h5>
                    </Label>
                    <Field
                        name={"customerFirstName"}
                        type="text"
                        component={renderInputField}
                        validate={[requiredValidation]}
                    />
                  </FormGroup>
                </Col>
                <Col md="5">
                  <FormGroup>
                    <Label>
                      <h5 className="font-weight-bold">Last name</h5>
                    </Label>
                    <Field
                        name={"customerLastName"}
                        type="text"
                        component={renderInputField}
                        validate={[requiredValidation]}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col md={{ size: '5', offset: 2 }}>
                  <FormGroup>
                    <Label>
                      <h5 className="font-weight-bold">Email address</h5>
                    </Label>
                    <Field
                        name={'customerEmail'}
                        type="text"
                        component={renderInputField}
                        validate={[emailValidation, requiredValidation]}
                    />
                  </FormGroup>
                </Col>
                <Col md="5">
                  <FormGroup>
                    <Label>
                      <h5 className="font-weight-bold">Phone (International format)</h5>
                    </Label>
                    <Field
                        name={'customerPhoneNumber'}
                        type="text"
                        component={renderInputField}
                        validate={[phoneNumberValidation, requiredValidation]}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col md={{ size: '5', offset: 2 }}>
                  <FormGroup>
                    <Label>
                      <h5 className="font-weight-bold">
                        {country === "US" ? "Address" : "Address Line 1"}
                      </h5>
                    </Label>
                    <Field
                        name={'companyAddress'}
                        type="text"
                        component={renderInputField}
                        validate={[requiredValidation]}
                    />
                  </FormGroup>
                </Col>
                <Col md="5">
                  <FormGroup>
                    <Label>
                      <h5 className="font-weight-bold">
                        {country === "US" ? "Apt, suite, etc." : "Address Line 2"}
                      </h5>
                    </Label>
                    <Field
                        name={"companyAddress2"}
                        type="text"
                        component={renderInputField}
                        validate={[]}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col md={{ size: '5', offset: 2 }}>
                  <FormGroup>
                    <Label>
                      <h5 className="font-weight-bold">City</h5>
                    </Label>
                    <Field
                        name={"companyCity"}
                        type="text"
                        component={renderInputField}
                        validate={[requiredValidation]}
                    />
                  </FormGroup>
                </Col>
                <Col md="5">
                  <FormGroup>
                    <Label>
                      <h5 className="font-weight-bold">
                        {country === "US" ? "Zip code" : "Postcode"}
                      </h5>
                    </Label>
                    <Field
                        name={"companyZipCode"}
                        type="text"
                        component={renderInputField}
                        validate={[requiredValidation]}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col md={{ size: '5', offset: 2 }}>
                  {country === "US" ?
                      <FormGroup>
                        <Label>
                          <h5 className="font-weight-bold">State</h5>
                        </Label>
                        <Field
                            name={'companyState'}
                            type="select"
                            component={renderSelectUSStatesField}
                            validate={[requiredValidation]}
                            defaultValue={""}
                        >
                        </Field>
                      </FormGroup>
                      :
                      <FormGroup>
                        <label>
                          <h5 className="font-weight-bold">County</h5>
                        </label>
                        <Field
                            type="input"
                            name="companyState"
                            component={renderInputField}
                            validate={[]}
                        />
                      </FormGroup>
                  }

                </Col>
                <Col md="5">
                  <FormGroup>
                    <label>
                      <h5 className="font-weight-bold">Country</h5>
                    </label>
                    <Field
                        type="select"
                        name="country"
                        component={renderSelectCountriesField}
                        validate={[requiredValidation]}
                        defaultValue={""}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </div>
          </fieldset>
        </Card>

        {submitFailed && invalid &&
          <div className="d-flex justify-content-end pr-5 mt-2">
            <p className="text-danger m-0">
              <img alt="" src={WarningIcon} className="mr-1" />
              Complete all required fields or fix invalid fields before continuing.
            </p>
          </div>
        }
      </form>
    );
  };
}

const asyncValidate = (values, dispatch, props) => {
  // Validate Address
  return validateAddress(values.companyZipCode, values.companyState, values.companyCity, values.country).then(res => {
    if (res.data && res.data.errors) {
      res.data.errors.map(errors => {
        return Object.entries(errors).forEach(([key, value]) => {
          // Eslint is disabled because throwing not an error object is a part of the docs
          // https://redux-form.com/6.7.0/examples/asyncvalidation/
          // eslint-disable-next-line no-throw-literal
          if (key === 'zip_code') throw({companyZipCode: value});
          // eslint-disable-next-line no-throw-literal
          else if (key === 'state') throw({companyZipCode: value});
          // eslint-disable-next-line no-throw-literal
          else if (key === 'city') throw({companyCity: value});
        });
      })
    }
  })
};

const selector = formValueSelector('FleetEditNewForm');

FleetEditNewForm = connect(
  state => {
    return {
      account: state.account,
      country: selector(state, 'country'),
    };
  }
)(FleetEditNewForm);

export default reduxForm({ form: 'FleetEditNewForm', asyncValidate })(FleetEditNewForm);
