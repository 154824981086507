/* eslint-disable linebreak-style */
import camelcaseKeys from 'camelcase-keys';
import {
  METRICS_FETCH_REQUEST,
  METRICS_FETCH_SUCCESS,
  METRICS_FETCH_ERROR,
} from "../actions/actionTypes";

const initialState = {
  marketplaceCount: undefined,
  viewCount: undefined,
  leadCount: undefined,
  isLoading: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case METRICS_FETCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: action.error,
      };

    case METRICS_FETCH_SUCCESS:
      const data = camelcaseKeys(action.data, {deep: true});
      return {
        ...state,
        ...data,
        isLoading: false,
        error: action.error
      };

    case METRICS_FETCH_ERROR:
      return {
        ...state,
        user: null,
        isLoading: false,
        error: action.error,
      };

    default:
      return state;
  }
};
