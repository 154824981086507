import { createElement } from "react";

/**
 * Given a string, split it and return an array
 *
 * @param {string} text
 * @private
 * @returns {Array<string>}
 */
const _breakText = function(text) {
  return text.replace(/\r\n/gi, "\n").split(/\n/);
};

/**
 * Given a text with linebreaks, render it as a multiline text.
 *
 * If you pass a HTML string, it will by default use the `dangerouslySetInnerHTML` property,
 *
 * @param {string} text
 * @param {string} as - Render tag for the root tag
 * @param {string} childrenAs - Render tag for the children
 * @param {Object} childrenProps - Props to be passed directly to the generated children tags
 * @param {boolean} useBr - Renders each line followed by a `<br>` tag
 * @param {boolean} disableUnsafeHTML - Disables the HTML rendering
 * @param {Object} props - Props to be passed directly to the root tag
 * @returns {null|*}
 * @constructor
 */
const MultilineText = ({
  text,
  as = "p",
  childrenAs = "span",
  childrenProps = {},
  useBr = true,
  disableUnsafeHTML = false,
  ...props
} = {}) => {
  if (!text) {
    return null;
  }

  // account for html string
  if (/<\/?[a-z][\s\S]*>/i.test(text) && !disableUnsafeHTML) {
    return createElement(as === "p" ? "div" : as, {
      ...props,
      dangerouslySetInnerHTML: {
        __html: text
      }
    });
  }

  const children = _breakText(text).map((line, index) =>
    createElement(
      childrenAs,
      {
        key: index,
        style: { display: "block" },
        ...childrenProps
      },
      useBr ? [line, createElement("br", { key: index })] : line
    )
  );
  return createElement(as, props, children);
};
export default MultilineText;
