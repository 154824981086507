/* eslint-disable linebreak-style */
import camelcaseKeys from 'camelcase-keys';
import {
  REPORT_FETCH_REQUEST,
  REPORT_FETCH_SUCCESS,
  REPORT_FETCH_ERROR,
} from "../actions/actionTypes";

const initialState = {
  reportLink: null,
  isLoading: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case REPORT_FETCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };

    case REPORT_FETCH_SUCCESS:
      const data = camelcaseKeys(action.data, {deep: true});
      return {
        ...state,
        reportLink: data.reportLink,
        isLoading: false,
        error: null
      };

    case REPORT_FETCH_ERROR:
      return {
        ...state,
        reportLink: null,
        isLoading: false,
        error: action.error,
      };

    default:
      return state;
  }
};
