import React from "react";
import { connect } from "react-redux";
import { Row, Col } from "reactstrap";

import { Auth0Context } from '../../services/auth/react-auth0-wrapper';
import { Link } from "react-router-dom";
import {fetchReportLink} from "../../store/actions/reportActions";


class ReportIFrame extends React.Component {
  render() {
    return (
      <iframe
        src={this.props.reportLink}
        frameBorder="0"
        allowFullScreen
      />
    );
  }
}

class HostReport extends React.Component {
  static contextType = Auth0Context;

  componentDidMount() {
    this.props.fetchReportLink();
  }

  render() {
    return (
      <>
        <div className="breadcrumb-nav">
          <Row>
            <Col>
              <ol className="breadcrumb-container">
                <li className="breadcrumb-container__item">
                  <h5>
                    <Link to="/admin/home">Home</Link>
                  </h5>
                </li>
                <li className="breadcrumb-container__item">
                  <h5>/</h5>
                </li>
                <li className="breadcrumb-container__item">
                  <h5>Report</h5>
                </li>
              </ol>
            </Col>
          </Row>
        </div>
        <div className="content report-page">
          <ReportIFrame reportLink={this.props.reportLink} />
        </div>
      </>
    );

  }
}

const mapStateToProps = state => ({
  reportLink: state.report.reportLink
});

const mapDispatchToProps = {
  fetchReportLink
};

export default connect(mapStateToProps, mapDispatchToProps)(HostReport);
