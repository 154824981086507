/* eslint-disable linebreak-style */
import { combineReducers } from "redux";
import { reducer as reduxFormReducer } from "redux-form";
import { createRouterReducer } from '@lagunovsky/redux-react-router'
import contactFormReducers from "./contactForm";
import hostListReducers from "./hostList";
import reportReducers from "./report";
import marketplaceListReducers from "./marketplaceList";
import marketplaceDetailReducers from "./marketplaceDetail";
import metricsReducers from "./metrics";
import leadDetailReducers from "./leadDetail";
import leadListReducers from "./leadList";
import partnerDetailReducers from "./partnerDetail";
import solutionDetailReducers from "./solutionDetail";
import solutionListReducers from "./solutionList";
import subscriptionDetailReducers from "./subscriptionDetail";
import subscriptionListReducers from "./subscriptionList";
import workflowDetailReducers from "./workflowDetail";
import dataListReducers from "./dataList";
import spinnerReducers from "./spinner";
import messageReducers from "./message";
import accountReducers from "./account";
import billingOrderListReducers from "./billingOrderList";
import billingTaxReducers from "./billingTax";
import fleetDetailReducers from "./fleetDetail";
import subscriptionUpdateReducers from "./subscriptionUpdate";
import billingSubscriptionReducers from "./billingSubscription";
import settingsReducers from "./settings";
import solutionDiscountReducers from "./solutionDiscount";
import subscriptionDiscountReducers from "./subscriptionDiscount";
import marketplaceResourceListReducers from "./marketplaceResourceList";
import solutionMarketplaceReducers from "./solutionMarketplace";
import solutionResourceDetailReducers from "./solutionResourceDetail";
import solutionResourceListReducers from "./solutionResourceList";
import solutionVersionListReducers from "./solutionVersionList";
import solutionVersionDetailReducers from "./solutionVersionDetail";

export default (history) =>
  combineReducers({
    contactForm: contactFormReducers,
    form: reduxFormReducer,
    hostList: hostListReducers,
    report: reportReducers,
    leadDetail: leadDetailReducers,
    leadList: leadListReducers,
    marketplaceList: marketplaceListReducers,
    marketplaceDetail: marketplaceDetailReducers,
    metrics: metricsReducers,
    partnerDetail: partnerDetailReducers,
    solutionDetail: solutionDetailReducers,
    solutionList: solutionListReducers,
    subscriptionDetail: subscriptionDetailReducers,
    subscriptionList: subscriptionListReducers,
    dataList: dataListReducers,
    workflowDetail: workflowDetailReducers,
    mainSpinner: spinnerReducers,
    notifications: messageReducers,
    account: accountReducers,
    billingOrderList: billingOrderListReducers,
    billingTax: billingTaxReducers,
    fleetDetail: fleetDetailReducers,
    subscriptionUpdate: subscriptionUpdateReducers,
    billingSubscription: billingSubscriptionReducers,
    settings: settingsReducers,
    solutionDiscount: solutionDiscountReducers,
    subscriptionDiscount: subscriptionDiscountReducers,
    marketplaceResourceList: marketplaceResourceListReducers,
    solutionMarketplace: solutionMarketplaceReducers,
    solutionResourceDetail: solutionResourceDetailReducers,
    solutionResourceList: solutionResourceListReducers,
    solutionVersionList: solutionVersionListReducers,
    solutionVersionDetail: solutionVersionDetailReducers,
    router: createRouterReducer(history),
  });
