import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import snakeCaseKeys from "snakecase-keys";
import { Button, Col, Row } from "reactstrap";
import { SubmissionError } from 'redux-form'

import { addSolutionDetail, fetchSolutionDetail, updateSolutionDetail } from "../../store/actions/solutionDetailActions";
import SolutionListingEditNewForm from "../forms/SolutionListingEditNewForm";
import { formatDisplayDatetime } from "../../utils/datetime";
import { addMessage } from "../../store/actions/messageActions";
import {history} from "../../store";
import {addSolutionDiscounts} from "../../store/actions/solutionDiscountActions";
import {updateSolutionPromotion} from "../../store/actions/solutionPromotionActions";
import {SOLUTION_TYPE_FREE_VALUE, SOLUTION_TYPE_PAID_VALUE} from "../../config/solution";
import {isEmpty} from "lodash";
import VersionState from "./version/VersionState";
import {clearSolutionVersionDetail, fetchSolutionVersionDetail} from "../../store/actions/solutionVersionActions";

class SolutionListingEditNew extends React.Component {
  constructor(props) {
    super(props);
    this.form = React.createRef();
    this.state = {
      draft: true,
      solutionLogo: null,
      addedAdditionalResources: [],
      removedAdditionalResources: [],
      addedMediaResources: [],
      removedMediaResources: [],
      addedPromotionResources: [],
      removedPromotionResources: [],
      filesSize: 0,
    };
    this.submitListingForm.bind(this);
  }

  setLogo(solutionLogo) {
    this.setState({solutionLogo});
  }

  setAddedPromotionResources(data) {
    this.setState({addedPromotionResources: [...data]});
  }

  removeAddedPromotionResource(idx) {
    const addedPromotionResources = [...this.state.addedPromotionResources];
    addedPromotionResources.splice(idx, 1);
    this.setState({ addedPromotionResources });
  }

  addRemovedPromotionResource(resourceId) {
    const removedPromotionResources = [...this.state.removedPromotionResources];
    removedPromotionResources.push(resourceId);
    this.setState({ removedPromotionResources });
  }

  setAddedMediaResources(data) {
    this.setState({addedMediaResources: [...data]});
  }

  removeAddedMediaResource(idx) {
    const addedMediaResources = [...this.state.addedMediaResources];
    addedMediaResources.splice(idx, 1);
    this.setState({ addedMediaResources });
  }

  addRemovedMediaResource(resourceId) {
    const removedMediaResources = [...this.state.removedMediaResources];
    removedMediaResources.push(resourceId);
    this.setState({ removedMediaResources });
  }

  setAddedAdditionalResources(data) {
    this.setState({ addedAdditionalResources: [...data] });
  }

  removeAddedAdditionalResource(idx) {
    const addedAdditionalResources = [...this.state.addedAdditionalResources];
    addedAdditionalResources.splice(idx, 1);
    this.setState({ addedAdditionalResources });
  }

  addRemovedAdditionalResource(resourceId) {
    const removedAdditionalResources = [...this.state.removedAdditionalResources];
    removedAdditionalResources.push(resourceId);
    this.setState({ removedAdditionalResources });
  }

  componentDidMount() {
    const {
      match: {
        params: {
          versionId
        }
      }
    } = this.props;
    const solutionId = this.getSolutionId();
    if (solutionId) {
      this.props.fetchSolutionDetail(solutionId);
    }
    if (versionId) {
      this.props.fetchSolutionVersionDetail(versionId);
    }
  }

  componentWillUnmount() {
    this.props.clearSolutionVersionDetail();
  }

  onSubmit(values) {
    const {
      addSolutionDetail,
      updateSolutionDetail,
      addSolutionDiscounts,
      solutionDiscounts,
      updateSolutionPromotion
    } = this.props;
    const { removedMediaResources, addedMediaResources, draft } = this.state;
    const { solutionMediaResources, promotion } = this.props.solutionDetail.solution;
    const { state } = this.props.location;
    const solutionId = this.getSolutionId();

    const mediaResourcesCount = (solutionMediaResources ? solutionMediaResources.length : 0)
      - removedMediaResources.length + addedMediaResources.length;
    if (!mediaResourcesCount) {
      throw new SubmissionError({
        _error: 'There is no media resource'
      });
    }

    const data = snakeCaseKeys(values, { deep: true });

    const startSubscriptionPrice = parseFloat(values.startSubscriptionPrice);
    if (startSubscriptionPrice === 0 || startSubscriptionPrice === 0.00) {
      data["solution_type"] = SOLUTION_TYPE_FREE_VALUE;
    } else {
      data["solution_type"] = SOLUTION_TYPE_PAID_VALUE;
    }

    if (this.state.solutionLogo) {
      data.logo = this.state.solutionLogo;
    }
    if (this.state.addedAdditionalResources.length > 0) {
      data["added_additional_resources"] = this.state.addedAdditionalResources;
    }
    if (this.state.removedAdditionalResources.length > 0) {
      data["removed_additional_resources"] = this.state.removedAdditionalResources;
    }
    if (this.state.addedMediaResources.length > 0) {
      data["added_media_resources"] = snakeCaseKeys(this.state.addedMediaResources, { deep: true });
    }
    if (this.state.removedMediaResources.length > 0) {
      data["removed_media_resources"] = this.state.removedMediaResources;
    }

    data["draft"] = draft

    const promotionData = {
      name: values.promotionName,
      description: values.promotionDescription,
      badge_main: values.promotionBadgeMain,
      badge_small: values.promotionBadgeSmall,
    };

    if (this.state.addedPromotionResources.length > 0) {
      promotionData["image"] = this.state.addedPromotionResources[0]['data'];
    }

    if (this.state.removedPromotionResources && this.state.removedPromotionResources.length > 0) {
      promotionData["removed_promotion_resource"] = this.state.removedPromotionResources[0];
    }

    if (!solutionId) {
      addSolutionDetail(data).then((res) => {
        addSolutionDiscounts(solutionDiscounts, res.data['id']).then((err) => {
          if (!err) {
            promotionData.solution = res.data['id'];
            updateSolutionPromotion(promotion, promotionData).then((err) => {
              if (!err) {
                if (draft) {
                  this.props.history.push(`/admin/solutions/${res.data['id']}/`);
                } else {
                  this.props.history.push("/admin/solutions/info-new", {solutionId: res.data['id']});
                }
              }
            });
          }
        });
      });
    } else {
      updateSolutionDetail(solutionId, data).then((err) => {
        if (!err) {
          promotionData.solution = solutionId;
          addSolutionDiscounts(solutionDiscounts, solutionId).then(() => {
            updateSolutionPromotion(promotion, promotionData).then((err) => {
              if (!err) {
                if (state && state.solutionId && !draft) {
                  this.props.history.push("/admin/solutions/info-new", {solutionId: state.solutionId});
                } else if (solutionId) {
                  this.props.addMessage("Solution saved");
                  this.props.history.push(`/admin/solutions/${solutionId}/`);
                } else {
                  this.props.history.push(`/admin/solutions/${solutionId}/info-edit`);
                }
              }
            });
          });

        }
      });
    }
  }

  submitListingForm(event, isDraft) {
    this.setState({draft: isDraft}, () => {this.form.current.submit();});
    event.preventDefault();
  }

  getSolutionId() {
    const { state } = this.props.location;
    let { solutionId } = this.props.match.params;
    if (!solutionId && state) {
      solutionId = state.solutionId
    }
    return solutionId
  }

  setFilesSize(filesSize, action="plus") {
    let newFilesSize;
    if (action === "minus") {
      newFilesSize = this.state.filesSize - filesSize
      this.setState({filesSize: newFilesSize})
    } else { // plus
      newFilesSize = this.state.filesSize + filesSize
    }

    if (newFilesSize >= (50 * 1024 * 1024)) {
      throw new Error("The size of all uploaded files cannot exceed 50 MB.")
    }

    if (action === "plus") {
      this.setState({filesSize: newFilesSize})
    }
  }

  render() {
    const { solution } = this.props.solutionDetail;
    const { addedAdditionalResources } = this.state;
    const { solutionId } = this.props.match.params;
    const { solutionDiscounts, settings } = this.props;
    const { solutionVersion } = this.props.solutionVersionDetail;

    if (!settings || isEmpty(settings) || !settings.defaultCurrency) {
      return ''
    }

    const {
      name,
      published,
      updated,
      featureAndBenefits,
      solutionLogo,
      solutionAdditionalResources,
      solutionMediaResources
    } = solution;

    const solutionListing = {
      name: solution.name,
      description: solution.description,
      companyDescription: solution.companyDescription,
      companyName: solution.companyName,
      fleetSegments: solution.fleetSegments,
      vehicleClasses: solution.vehicleClasses,
      summary: solution.summary,
      customerSizeFrom: solution.customerSizeFrom,
      customerSizeTo: solution.customerSizeTo,
      startSubscriptionPrice: solution.startSubscriptionPrice,
      firstOneTimePrice: solution.firstOneTimePrice,
      firstOneTimeSubDescription: solution.firstOneTimeSubDescription,
      secondOneTimePrice: solution.secondOneTimePrice,
      secondOneTimeSubDescription: solution.secondOneTimeSubDescription,
      firstOneTimeTaxCode: solution.firstOneTimeTaxCode,
      secondOneTimeTaxCode: solution.secondOneTimeTaxCode,
      unit: solution.unit,
      firstOneTimeShippingType: solution.firstOneTimeShippingType,
      secondOneTimeShippingType: solution.secondOneTimeShippingType,
      firstOneTimeShippingPrice: solution.firstOneTimeShippingPrice,
      secondOneTimeShippingPrice: solution.secondOneTimeShippingPrice,
      paymentInterval: solution.paymentInterval,
      anchorDay: solution.anchorDay,
      promotionName: solution.promotion ? solution.promotion.name : '',
      promotionDescription: solution.promotion ? solution.promotion.description : '',
      promotionBadgeMain: solution.promotion ? solution.promotion.badgeMain : '',
      promotionBadgeSmall: solution.promotion ? solution.promotion.badgeSmall : '',
      currency: solution.currency
    };

    return (
      <>
        <div className="breadcrumb-nav">
          <Row>
            <Col>
              <ol className="breadcrumb-container">
                <li className="breadcrumb-container__item">
                  <h5>
                    <Link to="/admin/solutions">Solutions</Link>
                  </h5>
                </li>
                <li className="breadcrumb-container__item">
                  <h5>/</h5>
                </li>
                <li className="breadcrumb-container__item">
                  <h5>
                    {
                      solutionId ? `Edit ${name}` : "Add New"
                    }
                  </h5>
                </li>
              </ol>
            </Col>
          </Row>
          <VersionState
              solutionVersion={solutionVersion}
              solutionId={solutionId}
              latestSolutionVersion={solution.latestSolutionVersion}
          />
        </div>
        <div className="content solution-new-listing-page">
          <div className="page-content-header">
            <Row>
              <Col md={5}>
                <h3>
                  {"Customize Your Solution Listing"}
                </h3>
              </Col>
              <Col md={7}>
                <div className="d-flex align-items-center justify-content-end">
                  {
                    solutionId &&
                    <Link to={`/admin/solutions/${solutionId}`}>
                      <Button
                        className="mr-3"
                        color="outline-primary"
                      >
                        Cancel
                      </Button>
                    </Link>
                  }
                  <Button
                    className="mr-3"
                    color="outline-primary"
                    onClick={(event) => this.submitListingForm(event, true)}
                  >
                    Save Draft
                  </Button>
                  {
                    solutionId &&
                    (<Button
                      className="pull-right mr-3"
                      color="primary"
                      onClick={(event) => {
                        this.submitListingForm(event, false);
                      }}
                    >
                      Submit Listing
                    </Button>)
                  }
                  {
                    !solutionId &&
                    (
                      <Button
                        className="mr-3"
                        color="primary"
                        onClick={(event) => {
                          this.submitListingForm(event, false);
                        }}
                      >
                        SAVE & CONTINUE
                      </Button>
                    )
                  }
                </div>

                {
                  solutionId &&
                  (<div className='float-right mr-2'>
                    <div className="saved-date-text mr-2">
                      Last Saved: {updated ? formatDisplayDatetime(updated) : null}
                    </div>
                    {
                      published &&
                      <div className="saved-date-text">
                        Last Published: {formatDisplayDatetime(published)}
                      </div>
                    }
                  </div>)
                }

              </Col>
            </Row>
          </div>
            <SolutionListingEditNewForm
              ref={this.form}
              onSubmit={this.onSubmit.bind(this)}
              solutionListing={solutionListing}
              isEditing={!!solutionId}
              solutionId={this.getSolutionId()}
              setLogo={this.setLogo.bind(this)}
              solutionDiscounts={solutionDiscounts}
              solutionLogo={solutionLogo}
              solutionAdditionalResources={solutionAdditionalResources}
              addedAdditionalResources={addedAdditionalResources}
              setAddedAdditionalResources={this.setAddedAdditionalResources.bind(this)}
              removeAddedAdditionalResource={this.removeAddedAdditionalResource.bind(this)}
              addRemovedAdditionalResource={this.addRemovedAdditionalResource.bind(this)}
              setFilesSize={this.setFilesSize.bind(this)}

              solutionMediaResources={solutionMediaResources}
              setAddedMediaResources={this.setAddedMediaResources.bind(this)}
              removeAddedMediaResource={this.removeAddedMediaResource.bind(this)}
              addRemovedMediaResource={this.addRemovedMediaResource.bind(this)}

              promotionResources={solution.promotion && solution.promotion.image ? [solution.promotion.image] : solution.promotion ? [] : null}
              setAddedPromotionResources={this.setAddedPromotionResources.bind(this)}
              removeAddedPromotionResource={this.removeAddedPromotionResource.bind(this)}
              addRemovedPromotionResource={this.addRemovedPromotionResource.bind(this)}
              addedPromotionResources={this.state.addedPromotionResources}
              removedPromotionResources={this.state.removedPromotionResources}

              featureAndBenefits={typeof(featureAndBenefits) == 'string'
                ? featureAndBenefits.split('\n')
                : featureAndBenefits}
            />
          <div className="page-content-footer d-flex justify-content-end">
            <div>
              <div className="d-flex align-items-center mb-3">
                {
                  solutionId &&
                  <Link to={`/admin/solutions/${solutionId}`}>
                    <Button
                      className="mr-3"
                      color="outline-primary"
                    >
                      Cancel
                    </Button>
                  </Link>
                }
                <Button
                  className="mt-0 mb-0 mr-3"
                  color="outline-primary"
                  onClick={(event) => this.submitListingForm(event, true)}
                >
                  SAVE DRAFT
                </Button>
                {
                  solutionId &&
                  <Button
                    className="mt-0 mb-0 mr-3"
                    color="primary"
                    onClick={(event) => {
                      this.submitListingForm(event, false);
                    }}
                  >
                    SUBMIT LISTING
                  </Button>
                }
                {
                  !solutionId &&
                  <Button
                    className="mt-0 mb-0 mr-3"
                    color="primary"
                    onClick={(event) => this.submitListingForm(event, false)}
                  >
                    SAVE & CONTINUE
                  </Button>
                }
                {
                  solutionId &&
                  (<div className="mr-4">
                    <div className="saved-date-text mb-2">
                      Last Saved: {updated ? formatDisplayDatetime(updated) : null}
                    </div>
                    {
                      published &&
                      <div className="saved-date-text">
                        Last Published: {formatDisplayDatetime(published)}
                      </div>
                    }
                  </div>)
                }
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  solutionDetail: state.solutionDetail,
  solutionVersionDetail: state.solutionVersionDetail,
  solutionDiscounts: state.solutionDiscount.solutionDiscounts,
  settings: state.settings
});

const mapDispatchToProps = {
  addSolutionDetail,
  fetchSolutionDetail,
  updateSolutionDetail,
  addMessage,
  addSolutionDiscounts,
  updateSolutionPromotion,
  fetchSolutionVersionDetail,
  clearSolutionVersionDetail
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  SolutionListingEditNew
);  
