export const ENTITY_TYPE_DRIVER_VALUE = 'D';
export const ENTITY_TYPE_VEHICLE_VALUE = 'V';
export const ENTITY_TYPE_ASSET_VALUE = 'A';
export const ENTITY_TYPE_DRIVER_LABEL = 'driver';
export const ENTITY_TYPE_VEHICLE_LABEL = 'vehicle';
export const ENTITY_TYPE_ASSET_LABEL = 'asset';
export const ENTITY_TYPE_OPTIONS = {
    [ENTITY_TYPE_ASSET_VALUE]: ENTITY_TYPE_ASSET_LABEL,
    [ENTITY_TYPE_VEHICLE_VALUE]: ENTITY_TYPE_VEHICLE_LABEL,
    [ENTITY_TYPE_DRIVER_VALUE]: ENTITY_TYPE_DRIVER_LABEL,
};

export const SOLUTION_1_OTC_SHIPPING_TYPE_FLAT_RATE_VALUE = 'FLAT_RATE';
export const SOLUTION_1_OTC_SHIPPING_TYPE_PER_UNIT_VALUE = 'PER_UNIT';
export const SOLUTION_1_OTC_SHIPPING_TYPE_NO_SHIPPING_VALUE = 'NO_SHIPPING';
export const SOLUTION_1_OTC_SHIPPING_TYPE_FLAT_RATE_LABEL = 'Flat rate';
export const SOLUTION_1_OTC_SHIPPING_TYPE_PER_UNIT_LABEL = 'Price per unit';
export const SOLUTION_1_OTC_SHIPPING_TYPE_NO_SHIPPING_LABEL = 'No shipping';

export const SOLUTION_1_OTC_SHIPPING_TYPE_OPTIONS = {
    [SOLUTION_1_OTC_SHIPPING_TYPE_FLAT_RATE_VALUE]: SOLUTION_1_OTC_SHIPPING_TYPE_FLAT_RATE_LABEL,
    [SOLUTION_1_OTC_SHIPPING_TYPE_PER_UNIT_VALUE]: SOLUTION_1_OTC_SHIPPING_TYPE_PER_UNIT_LABEL,
    [SOLUTION_1_OTC_SHIPPING_TYPE_NO_SHIPPING_VALUE]: SOLUTION_1_OTC_SHIPPING_TYPE_NO_SHIPPING_LABEL
};

export const SOLUTION_2_OTC_SHIPPING_TYPE_FLAT_RATE_VALUE = 'FLAT_RATE';
export const SOLUTION_2_OTC_SHIPPING_TYPE_PER_UNIT_VALUE = 'PER_UNIT';
export const SOLUTION_2_OTC_SHIPPING_TYPE_NO_SHIPPING_VALUE = 'NO_SHIPPING';
export const SOLUTION_2_OTC_SHIPPING_TYPE_FLAT_RATE_LABEL = 'Flat rate';
export const SOLUTION_2_OTC_SHIPPING_TYPE_PER_UNIT_LABEL = 'Price per unit';
export const SOLUTION_2_OTC_SHIPPING_TYPE_NO_SHIPPING_LABEL = 'No shipping';

export const SOLUTION_2_OTC_SHIPPING_TYPE_OPTIONS = {
    [SOLUTION_2_OTC_SHIPPING_TYPE_FLAT_RATE_VALUE]: SOLUTION_2_OTC_SHIPPING_TYPE_FLAT_RATE_LABEL,
    [SOLUTION_2_OTC_SHIPPING_TYPE_PER_UNIT_VALUE]: SOLUTION_2_OTC_SHIPPING_TYPE_PER_UNIT_LABEL,
    [SOLUTION_2_OTC_SHIPPING_TYPE_NO_SHIPPING_VALUE]: SOLUTION_2_OTC_SHIPPING_TYPE_NO_SHIPPING_LABEL
};

export const SOLUTION_STATUS_READY = 'R';
export const SOLUTION_STATUS_OPEN = 'O';
export const SOLUTION_STATUS_PUBLISHED = 'P';

export const SOLUTION_STATUS_READY_LABEL = 'Ready';
export const SOLUTION_STATUS_OPEN_LABEL = 'Open';
export const SOLUTION_STATUS_PUBLISHED_LABEL = 'Published';

export const SOLUTION_STATUS_OPTIONS = {
    [SOLUTION_STATUS_READY]: SOLUTION_STATUS_READY_LABEL,
    [SOLUTION_STATUS_OPEN]: SOLUTION_STATUS_OPEN_LABEL,
    [SOLUTION_STATUS_PUBLISHED]: SOLUTION_STATUS_PUBLISHED_LABEL
};

export const SOLUTION_TYPE_PAID_VALUE = 'PAID';
export const SOLUTION_TYPE_FREE_VALUE = 'FREE';
// export const SOLUTION_TYPE_PAID_LABEL = 'Paid';
// export const SOLUTION_TYPE_FREE_LABEL = 'Free';
