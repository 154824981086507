import axios from 'axios';
import { updateSpinner } from "./spinnerActions";
import {
  BILLING_CALCULATE_TAX_ERROR,
  BILLING_CALCULATE_TAX_REQUEST,
  BILLING_CALCULATE_TAX_SUCCESS,
  BILLING_CALCULATE_TAX_CLEAR
} from "./actionTypes";


export const calculateBillingTax = (data) => async (dispatch) => {
  dispatch(updateSpinner(true));
  dispatch({ type: BILLING_CALCULATE_TAX_REQUEST , request: true});
  let url = '/api/billing/tax/';
  try {
    const res = await axios.post(url, data);
    dispatch({ type: BILLING_CALCULATE_TAX_SUCCESS, data: res.data });
    dispatch(updateSpinner(false));
  } catch (error) {
    dispatch({ type: BILLING_CALCULATE_TAX_ERROR, error });
    dispatch(updateSpinner(false));
    return error;
  }
};

export const clearBillingTax = () => (dispatch) => {
  dispatch({type: BILLING_CALCULATE_TAX_CLEAR});
};
