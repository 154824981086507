import React, { useEffect, useState } from "react";
import { useTable, useSortBy } from "react-table";
import { caseInsensitiveFilter } from "../../../services/reactTableService";

const Table = ({ columns, data, history }) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
      },
      useSortBy
    );

  return (
    <div className="ReactTable7">
      <table {...getTableProps()} className="rt-table">
        <thead className="rt-thead">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()} className="rt-rh rt-tr">
              {headerGroup.headers.map((column) => {
                const sortClass =
                  column.isSorted && !column.isSortedDesc
                    ? "-sort-asc"
                    : column.isSorted && column.isSortedDesc
                    ? "-sort-desc"
                    : "no-sort";

                return (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    <div className={"rt-header rt-td " + sortClass}>
                      {column.render("Header")}
                    </div>
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows && rows.length > 0
            ? rows.map((row, i) => {
                prepareRow(row);
                return (
                  <tr
                    {...row.getRowProps()}
                    className="rt-tr"
                    onClick={() => {
                      history.push(`/admin/customers/${row.original.id}/`);
                    }}
                  >
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()} className="rt-td">
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })
            : [...Array(10).keys()].map((val) => (
                <tr className="rt-tr" key={val}>
                  <td colSpan={10} className="rt-td empty-rows">
                    {val === 4 ? "No rows found" : ""}
                  </td>
                </tr>
              ))}
        </tbody>
      </table>
    </div>
  );
};

const CustomerListTable = ({
  data,
  marketplaceFilter,
  statusFilter,
  fleetNameFilter,
  history,
}) => {
  const [filteredData, setFilteredData] = useState(data);

  useEffect(() => {
    let newFilteredData = data;
    if (data && marketplaceFilter) {
      newFilteredData = newFilteredData.filter(
        (subscription) => subscription.marketplace.name === marketplaceFilter
      );
    }
    if (data && statusFilter) {
      newFilteredData = newFilteredData.filter(
        (subscription) => subscription.ui_status === statusFilter
      );
    }
    if (data && fleetNameFilter) {
      newFilteredData = newFilteredData.filter((subscription) =>
        caseInsensitiveFilter(subscription.fleet.name, fleetNameFilter)
      );
    }
    setFilteredData(newFilteredData);
  }, [data, marketplaceFilter, statusFilter, fleetNameFilter]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Customer",
        accessor: "fleet.name",
      },
      {
        Header: "Solution",
        accessor: "solution.name",
      },
      {
        Header: "Marketplace",
        accessor: "marketplace.name",
      },
      {
        Header: "Units",
        accessor: "quantity",
      },
      {
        Header: "Status",
        accessor: "ui_status",
      },
    ],
    []
  );

  return <Table columns={columns} data={filteredData} history={history} />;
};

export default CustomerListTable;
