import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import snakeCaseKeys from "snakecase-keys";
import { Button } from "reactstrap";
import moment from "moment";
import CustomerSubscriptionEditNewForm from "../forms/CustomerSubscriptionEditNewForm";
import { clearLeadDetail } from "../../store/actions/leadDetailActions";
import { fetchMarketplaceList } from "../../store/actions/marketplaceListActions";
import { clearSolutionDetail, fetchSolutionDetail } from "../../store/actions/solutionDetailActions";
import { fetchPartnerDetail } from "../../store/actions/partnerDetailActions";
import {
  addSubscriptionDetail,
  clearSubscriptionDetail,
  fetchSubscriptionDetail,
  updateSubscriptionDetail
} from "../../store/actions/subscriptionDetailActions";

import { fetchDataTypeClassList } from '../../store/actions/dataActions';
import { addFleetDetail, clearFleetDetail } from "../../store/actions/customerDetailActions";
import { addSubscriptionUpdate } from "../../store/actions/subscriptionUpdateActions";
import { calculateBillingTax, clearBillingTax } from "../../store/actions/billingTax";
import { ONBOARD_TYPE } from "../../config/customer";
import {
  CUSTOMER_TYPE_ECOMMERCE_SUBSCRIBER_LABEL,
  SUBSCRIPTION_PAYMENT_METHOD_HOST_CREDIT_VALUE,
  CUSTOMER_TYPE_REQUEST_INFO_LEAD_VALUE,
  CUSTOMER_TYPE_OPTIONS,
  CUSTOMER_TYPE_ECOMMERCE_SUBSCRIBER_VALUE,
  CUSTOMER_TYPE_TRIAL_LEAD_VALUE,
  SUBSCRIPTION_PAYMENT_INTERVAL_MONTH_VALUE,
  SUBSCRIPTION_1_OTC_SHIPPING_TYPE_FLAT_RATE_VALUE,
  SUBSCRIPTION_1_OTC_SHIPPING_TYPE_PER_UNIT_VALUE,
  SUBSCRIPTION_2_OTC_SHIPPING_TYPE_FLAT_RATE_VALUE, SUBSCRIPTION_2_OTC_SHIPPING_TYPE_PER_UNIT_VALUE
} from "../../config/subscription";
import { LEAD_TYPE_DIRECT_VALUE, LEAD_TYPE_LEAD_VALUE, LEAD_TYPE_TRIAL_VALUE } from "../../config/lead";
import { history } from "../../store";
import { clearSubscriptionEffectiveDate, fetchSubscriptionEffectiveDate} from "../../store/actions/billingSubscription";
import { addMessage } from "../../store/actions/messageActions";

class CustomerEditNew extends React.Component {
  constructor(props) {
    super(props);
    this.form = React.createRef();
    this.state = {
      showReviewSubscriptionChanges: false,
      doubleSubmit: false
    }
  }

  componentDidMount() {
    const { subscriptionId } = this.props.match.params;
    const { account } = this.props;
    if (subscriptionId) this.props.fetchSubscriptionDetail(subscriptionId);
    // this.props.fetchDataTypeClassList();  # Is not used yet
    this.props.fetchMarketplaceList();
    if (account.mode === "Partner") this.props.fetchPartnerDetail();
    const { lead } = this.props.leadDetail;
    if (lead.solution) {
      const solutionId = typeof lead.solution === 'object' && lead.solution.id ? lead.solution.id : lead.solution
      this.props.fetchSolutionDetail(solutionId);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.subscriptionDetail.isLoading === true && this.props.subscriptionDetail.isLoading === false &&
      !this.props.solutionDetail.solution.name
    ) {
      const { solution } = this.props.subscriptionDetail.subscription;
      this.props.fetchSolutionDetail(solution.id);
    }
  }

  componentWillUnmount() {
    this.props.clearSolutionDetail();
    this.props.clearSubscriptionDetail();
    this.props.clearBillingTax();
    this.props.clearSubscriptionEffectiveDate()
  }

  submitCustomerSubscriptionForm(event) {
    this.form.current.submit();
    event.preventDefault();
  }

  onSubmit(values) {
    this.setState({ doubleSubmit: true });

    const { subscriptionId } = this.props.match.params;
    const { lead } = this.props.leadDetail;
    const { fleet } = this.props.fleetDetail;
    const { subscription } = this.props.subscriptionDetail;
    const { solution } = this.props.solutionDetail;

    if (subscriptionId) {
      const subscriptionData = snakeCaseKeys({
        subscriptionPrice: values.startSubscriptionPrice,
        subscriptionQuantity: values.quantity,
        firstOneTimePrice: values.firstOneTimePrice,
        firstOneTimeQuantity: values.firstOneTimeQuantity || undefined,
        firstOneTimeShippingType: values.firstOneTimeShippingType,
        firstOneTimeShippingPrice: values.firstOneTimeShippingPrice,
        firstOneTimeSubDescription: values.firstOneTimeSubDescription,
        secondOneTimePrice: values.secondOneTimePrice,
        secondOneTimeQuantity: values.secondOneTimeQuantity || undefined,
        secondOneTimeShippingType: values.secondOneTimeShippingType,
        secondOneTimeShippingPrice: values.secondOneTimeShippingPrice,
        secondOneTimeSubDescription: values.secondOneTimeSubDescription,
        netTerms: values.netTerms,
        otcDueDate: values.otcDueDate ? moment(values.otcDueDate).startOf("day").hour(12).minute(0).toISOString() : null,
        paymentInterval: values.paymentInterval || SUBSCRIPTION_PAYMENT_INTERVAL_MONTH_VALUE,
        anchorDay: values.anchorDay
      }, {deep: true});
      this.props.addSubscriptionUpdate(subscription.uid, subscriptionData).then((err) => {
        if (!err) {
          this.props.clearFleetDetail();
          this.props.clearLeadDetail();
          this.props.addMessage("Your subscription has been updated.");
          this.props.history.push(`/admin/customers/${subscriptionId}/`);
        }
        else {
          this.setState({ doubleSubmit: false });
        }
      });
    } else {
      let customerType = CUSTOMER_TYPE_REQUEST_INFO_LEAD_VALUE;
      if (lead && lead.type) {
        if (lead.type === LEAD_TYPE_LEAD_VALUE) customerType = CUSTOMER_TYPE_REQUEST_INFO_LEAD_VALUE;
        else if (lead.type === LEAD_TYPE_DIRECT_VALUE) customerType = CUSTOMER_TYPE_ECOMMERCE_SUBSCRIBER_VALUE;
        else if (lead.type === LEAD_TYPE_TRIAL_VALUE) customerType = CUSTOMER_TYPE_TRIAL_LEAD_VALUE;
      }

      let subscriptionData = snakeCaseKeys({
        marketplace: values.marketplace,
        solution: solution.id ? solution.id : values.solution,
        status: 'R',
        paymentMethod: values.paymentMethod,
        lead: lead.id,
        fleet: fleet.id,
        orderDate: values.orderDate ? moment(values.orderDate).startOf("day").hour(12).minute(0).toISOString() : null,
        quantity: values.quantity,
        startSubscriptionPrice: values.startSubscriptionPrice,
        firstOneTimePrice: values.firstOneTimePrice,
        firstOneTimeQuantity: values.firstOneTimeQuantity || undefined,
        firstOneTimeSubDescription: values.firstOneTimeSubDescription,
        secondOneTimePrice: values.secondOneTimePrice,
        secondOneTimeQuantity: values.secondOneTimeQuantity || undefined,
        secondOneTimeSubDescription: values.secondOneTimeSubDescription,
        firstOneTimeShippingType: values.firstOneTimeShippingType,
        secondOneTimeShippingType: values.secondOneTimeShippingType,
        firstOneTimeShippingPrice: values.firstOneTimeShippingPrice,
        secondOneTimeShippingPrice: values.secondOneTimeShippingPrice,
        netTerms: values.netTerms,
        otcDueDate: values.otcDueDate ? moment(values.otcDueDate).startOf("day").hour(12).minute(0).toISOString() : null,
        paymentInterval: values.paymentInterval || SUBSCRIPTION_PAYMENT_INTERVAL_MONTH_VALUE,
        anchorDay: values.anchorDay,
        trialPeriodDays: values.trialPeriodDays || 0,
        customerType
      }, {deep: true});

      if (fleet.id) {
        this.props.addSubscriptionDetail(subscriptionData).then(err => {
          if (!err) {
            this.props.clearFleetDetail();
            this.props.clearLeadDetail();
            this.props.addMessage("The order has been sent to the customer");
          } else {
            this.setState({ doubleSubmit: false });
          }
        });
      }
      else {
        const data = {
          customerFirstName: lead.firstName,
          customerEmail: lead.email,
          companyAddress: lead.address,
          companyState: lead.state,
          customerLastName: lead.lastName,
          customerPhoneNumber: lead.phoneNumber,
          companyCity: lead.city,
          companyZipCode: lead.zipCode,
          country: lead.country,
          name: lead.company,
          size: lead.fleetSize,
          hostFleetId: lead.hostFleetId,
          marketplace_pk: values.marketplace
          // companyAddress2: lead.companyAddress2
        };

        this.props.addFleetDetail(snakeCaseKeys(data)).then((res) => {
          if (!res.err) {
            subscriptionData.fleet = res.data.id;
            this.props.addSubscriptionDetail(subscriptionData).then(err => {
              if (!err) {
                this.props.clearFleetDetail();
                this.props.clearLeadDetail();
                this.props.addMessage("The order has been sent to the customer");
              } else {
                this.setState({ doubleSubmit: false });
              }
            });
          } else {
            this.setState({ doubleSubmit: false });
          }
        });
      }
    }

  }

  onChangeSolution(solutionId) {
    this.props.fetchSolutionDetail(solutionId);
  }

  onChangeFleetName(fleetName) {
    // Searching by fleet name is not implemented yet
  }

  onReviewUpdates() {
    const { fleet } = this.props.subscriptionDetail.subscription;
    const { values } = this.props.CustomerSubscriptionEditNew;
    const { subscription } = this.props.subscriptionDetail;
    const { solution } = this.props.solutionDetail;
    const { partner } = this.props.partnerDetail;
    const { taxCodes } = this.props.settings;


    this.props.fetchSubscriptionEffectiveDate(subscription.uid);

    this.setState({ showReviewSubscriptionChanges: true });

    if (values.firstOneTimePrice && values.firstOneTimeQuantity) {
      let shippingTotal = 0;
      if (parseFloat(values.firstOneTimeShippingPrice) && parseFloat(values.firstOneTimeQuantity)) {
        if (values.firstOneTimeShippingType === SUBSCRIPTION_1_OTC_SHIPPING_TYPE_FLAT_RATE_VALUE) {
          shippingTotal += parseFloat(values.firstOneTimeShippingPrice)
        } else if (values.firstOneTimeShippingType === SUBSCRIPTION_1_OTC_SHIPPING_TYPE_PER_UNIT_VALUE) {
          shippingTotal += parseFloat(values.firstOneTimeShippingPrice) * parseFloat(values.firstOneTimeQuantity)
        }
      }
      if (parseFloat(values.secondOneTimeShippingPrice) && parseFloat(values.secondOneTimeQuantity)) {
        if (values.secondOneTimeShippingType === SUBSCRIPTION_2_OTC_SHIPPING_TYPE_FLAT_RATE_VALUE) {
          shippingTotal += parseFloat(values.secondOneTimeShippingPrice)
        } else if (values.secondOneTimeShippingType === SUBSCRIPTION_2_OTC_SHIPPING_TYPE_PER_UNIT_VALUE) {
          shippingTotal += parseFloat(values.secondOneTimeShippingPrice) * parseFloat(values.secondOneTimeQuantity)
        }
      }

      let item1Discount = 0;
      subscription.otc1Discounts.forEach((otc1Discount) => {
        if (otc1Discount.amount) {
          item1Discount += parseFloat(otc1Discount.amount)
        } else {
          item1Discount += values.firstOneTimePrice * parseInt(otc1Discount.percentage) / 100
        }
      });

      let item2Discount = 0;
      subscription.otc2Discounts.forEach((otc2Discount) => {
        if (otc2Discount.amount) {
          item2Discount += parseFloat(otc2Discount.amount)
        } else {
          item2Discount += values.secondOneTimePrice * parseInt(otc2Discount.percentage) / 100
        }
      });

      const data = snakeCaseKeys({
        toCountry: fleet.country,
        toZip: fleet.companyZipCode,
        toCity: fleet.companyCity,
        toState: fleet.companyState,
        shipping: shippingTotal,
        item1Quantity: values.firstOneTimeQuantity,
        item1UnitPrice: values.firstOneTimePrice,
        item1TaxCode: solution.firstOneTimeTaxCode && taxCodes && taxCodes.length > 0 ? taxCodes.find(code => code.id === solution.firstOneTimeTaxCode).code : null,
        item1Discount: item1Discount,
        item2Quantity: values.secondOneTimeQuantity,
        item2UnitPrice: values.secondOneTimePrice,
        item2TaxCode: solution.secondOneTimeTaxCode && taxCodes && taxCodes.length > 0 ? taxCodes.find(code => code.id === solution.secondOneTimeTaxCode).code : null,
        item2Discount: item2Discount,
        partnerId: partner.id
      }, {deep: true});
      this.props.calculateBillingTax(data);
    }
  }

  render() {
    const { subscriptionId } = this.props.match.params;
    const { account, settings } = this.props;
    const { marketplaces } = this.props;
    const { partner } = this.props.partnerDetail;
    const { lead } = this.props.leadDetail;
    const { fleet } = this.props.fleetDetail;
    const { billingTax } = this.props;
    const form = this.props.CustomerSubscriptionEditNew;
    const solutionObj = this.props.solutionDetail.solution;
    const subscriptionObj = this.props.subscriptionDetail.subscription;

    if (!settings.defaultCurrency) {
      return <></>;
    }

    let customerType = null;
    let quantity = null;
    let customerName = null;
    let marketplace = "";
    let solutionId = "";
    let isEditing = false;
    let startSubscriptionPrice = null;
    let firstOneTimeShippingPrice = null;
    let secondOneTimeShippingPrice = null;
    let firstOneTimeSubDescription = null;
    let secondOneTimeSubDescription = null;
    let dataPermissions = {};
    let unit = null;
    let firstOneTimePrice = null;
    let secondOneTimePrice = null;
    let firstOneTimeShippingType = null;
    let secondOneTimeShippingType = null;
    let solutionFirstOneTimePrice = null;
    let solutionSecondOneTimePrice = null;
    let trialPeriodDays = null;
    let paymentInterval = SUBSCRIPTION_PAYMENT_INTERVAL_MONTH_VALUE; // By default
    // The first day of month by default
    let anchorDay = 1;
    let currency = settings.defaultCurrency.id;


    if (this.props.solutionDetail.isLoading === false && solutionObj.id) {
      solutionId = solutionObj.id;
      startSubscriptionPrice = solutionObj.startSubscriptionPrice;
      firstOneTimeShippingPrice = solutionObj.firstOneTimeShippingPrice;
      secondOneTimeShippingPrice = solutionObj.secondOneTimeShippingPrice;
      firstOneTimeSubDescription = solutionObj.firstOneTimeSubDescription;
      secondOneTimeSubDescription = solutionObj.secondOneTimeSubDescription;
      firstOneTimeShippingType = solutionObj.firstOneTimeShippingType;
      secondOneTimeShippingType = solutionObj.secondOneTimeShippingType;
      unit = solutionObj.unit;
      solutionFirstOneTimePrice = firstOneTimePrice = solutionObj.firstOneTimePrice;
      solutionSecondOneTimePrice = secondOneTimePrice = solutionObj.secondOneTimePrice;
      const solutionDataPermissions = solutionObj.solutionDataPermissions;
      if (solutionDataPermissions) {
        solutionDataPermissions.map(
          ({ id }) => dataPermissions[`data-${id}`] = true
        );
      }
      paymentInterval = solutionObj.paymentInterval;
      anchorDay = solutionObj.anchorDay;
      trialPeriodDays = solutionObj.trialPeriodDays;
      currency = solutionObj.currency;
    }

    // If we came from the lead view page some values are already defined
    if (lead && lead.company) {
      customerName = lead.company;
      marketplace = lead.marketplace;
      if (!solutionObj) solutionId = lead.solution;
      if (lead.type === LEAD_TYPE_LEAD_VALUE) customerType = CUSTOMER_TYPE_REQUEST_INFO_LEAD_VALUE;
      else if (lead.type === LEAD_TYPE_DIRECT_VALUE) customerType = CUSTOMER_TYPE_ECOMMERCE_SUBSCRIBER_VALUE;
      else if (lead.type === LEAD_TYPE_TRIAL_VALUE) customerType = CUSTOMER_TYPE_TRIAL_LEAD_VALUE;
    }

    if (fleet && fleet.name) {
      customerName = fleet.name;
    }

    const onboardType = subscriptionId || (lead && lead.id) ? ONBOARD_TYPE.LEAD : ONBOARD_TYPE.NEW;

    if (subscriptionId && this.props.subscriptionDetail.isLoading === false) {
      marketplace = subscriptionObj.marketplace.id;
      solutionId = subscriptionObj.solution.id;
      const solutionDataPermissions = subscriptionObj.solution.solutionDataPermissions;
      if (solutionDataPermissions) {
        solutionDataPermissions.map(
          ({ id }) => dataPermissions[`data-${id}`] = true
        );
      }
      startSubscriptionPrice = subscriptionObj.startSubscriptionPrice;
      firstOneTimePrice = subscriptionObj.firstOneTimePrice;
      secondOneTimePrice = subscriptionObj.secondOneTimePrice;
      firstOneTimeShippingPrice = subscriptionObj.firstOneTimeShippingPrice;
      secondOneTimeShippingPrice = subscriptionObj.secondOneTimeShippingPrice;
      firstOneTimeSubDescription = subscriptionObj.firstOneTimeSubDescription;
      secondOneTimeSubDescription = subscriptionObj.secondOneTimeSubDescription;
      firstOneTimeShippingType = subscriptionObj.firstOneTimeShippingType;
      secondOneTimeShippingType = subscriptionObj.secondOneTimeShippingType;
      quantity = subscriptionObj.quantity;
      customerType = subscriptionObj.customerType;
      customerName = subscriptionObj.fleet.name;

      unit = subscriptionObj.solution.unit;
      const subscriptionStatus = subscriptionObj.status;
      if (subscriptionStatus === 'R' || subscriptionStatus === "L") isEditing = true;
      paymentInterval = subscriptionObj.paymentInterval;
      anchorDay = subscriptionObj.anchorDay;
      trialPeriodDays = subscriptionObj.trialPeriodDays;
    }

    // For create subscription form
    if (!customerType) customerType = CUSTOMER_TYPE_REQUEST_INFO_LEAD_VALUE;
    // Change customerType from value to label
    customerType = CUSTOMER_TYPE_OPTIONS[customerType];

    // Form values
    let values;
    const { CustomerSubscriptionEditNew } = this.props;
    if (CustomerSubscriptionEditNew && "values" in CustomerSubscriptionEditNew) {
      values = CustomerSubscriptionEditNew.values;
    }

    return (
      <>
        <div className="breadcrumb-nav">
          <div>
            <ol className="breadcrumb-container">
              <li className="breadcrumb-container__item">
                <h5>
                  <Link to="/admin/customers">Subscriptions</Link>
                </h5>
              </li>
              <li className="breadcrumb-container__item"><h5>/</h5></li>
              {subscriptionId ?
                <>
                  <li className="breadcrumb-container__item">
                    <h5><Link to={`/admin/customers/${subscriptionId}/`}>{customerName}</Link></h5>
                  </li>
                  <li className="breadcrumb-container__item"><h5>/</h5></li>
                  <li className="breadcrumb-container__item">
                    {this.state.showReviewSubscriptionChanges
                      ? <h5>Review Subscription</h5>
                      : <h5>Edit Subscription</h5>
                    }
                  </li>
                </>
                : <li className="breadcrumb-container__item"><h5>Create Subscription</h5></li>}
            </ol>
            {!subscriptionId &&
            <div>*If the customer has multiple shipping locations, you will need to contact{" "}
              <a href="mailto:operations@roadz.com">operations@roadz.com</a> to create the subscription.</div>
            }
          </div>
        </div>
        <div className="content customer-new-page">
          <CustomerSubscriptionEditNewForm
            marketplaces={marketplaces}
            dataTypeClasses={this.props.dataTypeClasses}
            partnerName={partner && partner.name ? partner.name : ''}
            customerType={customerType}
            onboardType={onboardType}
            isEditing={isEditing}
            initialCustomerName={customerName}
            initialQuantity={quantity}
            initialStartSubscriptionPrice={startSubscriptionPrice}
            solutionFirstOneTimePrice={solutionFirstOneTimePrice}
            solutionSecondOneTimePrice={solutionSecondOneTimePrice}
            effectiveDate={this.props.billingSubscription.effectiveDate}
            showReviewSubscriptionChanges={this.state.showReviewSubscriptionChanges}
            subscriptionDiscounts={subscriptionObj.subscriptionDiscounts}
            otc1Discounts={subscriptionObj.otc1Discounts}
            subscriptionEntities={subscriptionObj.subscriptionEntities}
            otc2Discounts={subscriptionObj.otc2Discounts}
            unit={unit}
            subscriptionId={subscriptionId}
            fleetId={fleet.id || subscriptionObj.fleet.id}
            billingTax={billingTax}
            lead={lead}
            currency={currency}
            initialValues={{
              customerName,
              marketplace: marketplace,
              solution: solutionId,
              startSubscriptionPrice,
              firstOneTimePrice,
              secondOneTimePrice,
              firstOneTimeSubDescription,
              secondOneTimeSubDescription,
              firstOneTimeShippingType,
              secondOneTimeShippingType,
              firstOneTimeShippingPrice,
              secondOneTimeShippingPrice,
              dataPermissions,
              netTerms: subscriptionObj.netTerms,
              otcDueDate: subscriptionObj.otcDueDate ? moment(subscriptionObj.otcDueDate).format('MM/DD/YYYY') : null,
              quantity,
              orderDate: subscriptionObj.orderDate ? moment(subscriptionObj.orderDate).format('MM/DD/YYYY') : null,
              firstOneTimeQuantity: null,
              secondOneTimeQuantity: null,
              paymentMethod: subscriptionObj.paymentMethod,
              paymentInterval,
              anchorDay: anchorDay,
              anchorDayRadio: anchorDay ? "1" : "0",
              trialPeriodDays
            }}
            ref={this.form}
            enableReinitialize={true}
            keepDirtyOnReinitialize={true}
            onChangeSolution={this.onChangeSolution.bind(this)}
            onChangeFleetName={this.onChangeFleetName.bind(this)}
            onSubmit={this.onSubmit.bind(this)}
          />

          <div className="page-content-footer">
            {
              customerType !== CUSTOMER_TYPE_ECOMMERCE_SUBSCRIBER_LABEL && !isEditing
              && account.permissions.includes("write:customers") &&
              <div className="d-flex pull-right align-items-center flex-column flex-sm-row">
                <p className="mr-2">
                  When you click the Create & Send Order button, the order above will be sent to the customer for confirmation and payment.
                </p>
                <Button
                  disabled={this.state.doubleSubmit}
                  color="primary"
                  onClick={this.submitCustomerSubscriptionForm.bind(this)}
                >
                  CREATE & SEND ORDER
                </Button>
              </div>
            }
            {
              customerType === CUSTOMER_TYPE_ECOMMERCE_SUBSCRIBER_LABEL && !isEditing &&
              <Button
                className="pull-right"
                color="primary"
                onClick={this.submitCustomerSubscriptionForm.bind(this)}
              >
                CONTINUE TO ACTIVATION
              </Button>
            }
            {
              isEditing &&
              <div className="d-flex justify-content-end">
                {account.permissions.includes("write:customers") && !this.state.showReviewSubscriptionChanges &&
                  <>
                    <Link to={subscriptionId ? `/admin/customers/${subscriptionId}`: '/admin/customers'}>
                      <Button color="outline-primary" className="mr-3">Cancel</Button>
                    </Link>
                    <Button
                      color="primary"
                      onClick={this.onReviewUpdates.bind(this)}
                      disabled={isEditing &&
                      (
                        subscriptionObj.paymentMethod === SUBSCRIPTION_PAYMENT_METHOD_HOST_CREDIT_VALUE ||
                        (
                          values &&
                          values.startSubscriptionPrice && parseFloat(subscriptionObj.startSubscriptionPrice) === parseFloat(values.startSubscriptionPrice) &&
                          values.quantity && parseFloat(subscriptionObj.quantity) === parseFloat(values.quantity) &&
                          (!values.firstOneTimeQuantity || parseFloat(values.firstOneTimeQuantity) === 0) &&
                          (!values.secondOneTimeQuantity || parseFloat(values.secondOneTimeQuantity) === 0)
                        ) ||
                        subscriptionObj.subscriptionStart === null ||
                          !!(form && form.syncErrors)
                      )
                      }
                    >
                      Review Updates
                    </Button>
                  </>
                }
                {account.permissions.includes("write:customers") && this.state.showReviewSubscriptionChanges &&
                  <>
                    <Button
                      color="outline-primary"
                      onClick={() => this.setState({ showReviewSubscriptionChanges: false })}>
                      Back
                    </Button>
                    <Button color="primary" onClick={this.submitCustomerSubscriptionForm.bind(this)}>
                      {form && form.values && form.values.firstOneTimeQuantity && parseFloat(form.values.firstOneTimeQuantity) !== 0 ? <>Send Order</> : <>Update Subscription</>}
                    </Button>
                  </>
                }
              </div>
            }
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  dataTypeClasses: state.dataList.dataTypeClasses,
  leadDetail: state.leadDetail,
  marketplaces: state.marketplaceList.marketplaces,
  partnerDetail: state.partnerDetail,
  solutionDetail: state.solutionDetail,
  subscriptionDetail: state.subscriptionDetail,
  fleetDetail: state.fleetDetail,
  account: state.account,
  settings: state.settings,
  billingTax: state.billingTax,
  billingSubscription: state.billingSubscription,
  CustomerSubscriptionEditNew: state.form.CustomerSubscriptionEditNew
});

const mapDispatchToProps = {
  clearSolutionDetail,
  clearLeadDetail,
  clearSubscriptionDetail,
  clearFleetDetail,
  fetchDataTypeClassList,
  fetchMarketplaceList,
  fetchPartnerDetail,
  fetchSolutionDetail,
  fetchSubscriptionDetail,
  updateSubscriptionDetail,
  addSubscriptionDetail,
  addFleetDetail,
  addSubscriptionUpdate,
  calculateBillingTax,
  clearBillingTax,
  fetchSubscriptionEffectiveDate,
  clearSubscriptionEffectiveDate,
  addMessage
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerEditNew);
