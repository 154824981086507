import axios from 'axios';
import {
  SOLUTION_MARKETPLACE_LIST_FETCH_REQUEST,
  SOLUTION_MARKETPLACE_LIST_FETCH_SUCCESS,
  SOLUTION_MARKETPLACE_LIST_FETCH_ERROR,
} from './actionTypes';
import { updateSpinner } from "./spinnerActions";

export const fetchSolutionMarketplaceList = ({marketplace=null} = {}) => (dispatch, state) => {
  dispatch(updateSpinner(true));
  dispatch({ type: SOLUTION_MARKETPLACE_LIST_FETCH_REQUEST , request: true});
  let url = `/api/solution/solution_marketplaces/`;

  const { mode } = state().account;
  url += `?mode=${mode}`;

  if (marketplace) url += `&marketplace=${marketplace}`;

  axios.get(url)
    .then(({ data }) => {
      if (data) {
        dispatch({ type: SOLUTION_MARKETPLACE_LIST_FETCH_SUCCESS, data });
      } else {
        dispatch({ type: SOLUTION_MARKETPLACE_LIST_FETCH_ERROR, error: { mes: "" }});
      }
      dispatch(updateSpinner(false));
    })
    .catch((error) => {
      dispatch({ type: SOLUTION_MARKETPLACE_LIST_FETCH_ERROR, error });
      dispatch(updateSpinner(false));
    });
};

