import camelcaseKeys from 'camelcase-keys';
import {
  SOLUTION_RESOURCE_DETAIL_FETCH_REQUEST,
  SOLUTION_RESOURCE_DETAIL_FETCH_SUCCESS,
  SOLUTION_RESOURCE_DETAIL_FETCH_ERROR,
} from "../actions/actionTypes";

const initialState = {
  solutionResource: {},
  isLoading: false, // boolean variable to indicate fetching api is in progress
  error: null, // error details
};

export default (state = initialState, action) => {
  switch (action.type) {

    case SOLUTION_RESOURCE_DETAIL_FETCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };


    case SOLUTION_RESOURCE_DETAIL_FETCH_SUCCESS:
    {
      const data = camelcaseKeys(action.data, {deep: true});
      return {
        ...state,
        solutionResource: data,
        isLoading: false,
        error: null
      };
    }

    case SOLUTION_RESOURCE_DETAIL_FETCH_ERROR:
      return {
        ...state,
        solutionResource: {},
        isLoading: false,
        error: action.error,
      };

    default:
      return state;
  }
};
