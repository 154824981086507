import React from 'react';
import { connect } from "react-redux";
import { Nav, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";

import { Auth0Context } from '../../services/auth/react-auth0-wrapper';
import ProfileIcon from "../../assets/icons/profile.svg";
import LogoutIcon from "../../assets/icons/logout.svg";
import { toggleMode } from "../../store/actions/accountActions";
import { fetchSettings } from "../../store/actions/settingsActions";

class AuthDropdownNavBar extends React.Component {
  static contextType = Auth0Context;

  toggleMode() {
    this.props.toggleMode();
    this.props.fetchSettings();
  }

  render() {
    const { logout, user } = this.context;
    const { account } = this.props;

    return (
      <Nav navbar>
        <UncontrolledDropdown className="btn-rotate" nav>
          <DropdownToggle
            aria-haspopup={true}
            caret
            color="primary"
            data-toggle="dropdown"
            id="navbarDropdownMenuLink"
            nav
          >
            <p id="logged-user-email">{user.email}</p>
          </DropdownToggle>
          <DropdownMenu aria-labelledby="navbarDropdownMenuLink" right>
            {/*    // TODO Implement profile page*/}
            {/*<DropdownItem*/}
            {/*  href="#pablo"*/}
            {/*  onClick={e => {*/}
            {/*    e.preventDefault();*/}
            {/*  }}*/}
            {/*>*/}
            {/*  <img alt="" className="mr-3" src={ProfileIcon} />*/}
            {/*  Account Profile*/}
            {/*</DropdownItem>*/}

            {account && user.scope && (
              (user.scope.includes('Host') && user.scope.includes('Partner'))
            ) &&
              <>
                {account.mode === 'Partner' &&
                  <DropdownItem href="#" onClick={this.toggleMode.bind(this)}>
                    <img alt="" className="mr-3" src={ProfileIcon} />
                    Switch to Host Admin
                  </DropdownItem>
                }

                {account.mode === 'Host' &&
                  <DropdownItem href="#" onClick={this.toggleMode.bind(this)}>
                    <img alt="" className="mr-3" src={ProfileIcon} />
                    Switch to Partner Admin
                  </DropdownItem>
                }
              </>
            }

            <DropdownItem
              href="#pablo"
              onClick={() => logout({
                returnTo: process.env.REACT_APP_HOST,
              })}
            >
              <img alt="" className="mr-3" src={LogoutIcon} />
              Logout
            </DropdownItem>

          </DropdownMenu>
        </UncontrolledDropdown>
      </Nav>
    );
  }

}


const mapStateToProps = state => ({
  account: state.account
});

const mapDispatchToProps = {
  toggleMode,
  fetchSettings
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthDropdownNavBar);
