import axios from "axios";
import {
  CONTACT_FORM_SUBMIT_REQUEST,
  CONTACT_FORM_SUBMIT_SUCCESS,
  CONTACT_FORM_SUBMIT_ERROR,
} from "./actionTypes";
import { updateSpinner } from "./spinnerActions";

export const sendContactForm = (data) => async (dispatch) => {
  dispatch(updateSpinner(true));
  dispatch({ type: CONTACT_FORM_SUBMIT_REQUEST, request: true });
  let url = `/api/core/send-contact-email/`;
  try {
    const res = axios.post(url, data);
    dispatch({ type: CONTACT_FORM_SUBMIT_SUCCESS });
    dispatch(updateSpinner(false));
    return res;
  } catch (error) {
    dispatch({ type: CONTACT_FORM_SUBMIT_ERROR, error });
    dispatch(updateSpinner(false));
    return error;
  }
};
