import React, { useEffect, createRef } from "react";
import { useSelector } from "react-redux";
import { ReactNotifications } from "react-notifications-component";
import { Store } from "react-notifications-component";

const Notify = () => {
  const seenMessagesRef = createRef();

  if (!seenMessagesRef.current) {
    seenMessagesRef.current = [];
  }
  const errors = [];
  errors.push({
    error: useSelector(({ leadDetail }) => leadDetail.error),
    type: "leadDetail",
  });
  errors.push({
    error: useSelector(({ leadList }) => leadList.error),
    type: "leadList",
  });
  errors.push({
    error: useSelector(({ marketplaceList }) => marketplaceList.error),
    type: "marketplaceList",
  });
  errors.push({
    error: useSelector(({ contactForm }) => contactForm.error),
    type: "contactForm",
  });
  errors.push({
    error: useSelector(({ metrics }) => metrics.error),
    type: "metrics",
  });
  errors.push({
    error: useSelector(({ solutionDetail }) => solutionDetail.error),
    type: "solutionDetail",
  });
  errors.push({
    error: useSelector(({ solutionList }) => solutionList.error),
    type: "solutionList",
  });
  errors.push({
    error: useSelector(({ dataList }) => dataList.error),
    type: "dataList",
  });
  errors.push({
    error: useSelector(({ hostList }) => hostList.error),
    type: "hostList",
  });
  errors.push({
    error: useSelector(({ subscriptionList }) => subscriptionList.error),
    type: "subscriptionList",
  });
  errors.push({
    error: useSelector(({ subscriptionDetail }) => subscriptionDetail.error),
    type: "subscriptionDetail",
  });
  errors.push({
    error: useSelector(({ partnerDetail }) => partnerDetail.error),
    type: "partnerDetail",
  });
  errors.push({
    error: useSelector(({ subscriptionUpdate }) => subscriptionUpdate.error),
    type: "subscriptionUpdate",
  });
  errors.push({
    error: useSelector(({ billingTax }) => billingTax.error),
    type: "billingTax",
  });
  errors.push({
    error: useSelector(({ fleetDetail }) => fleetDetail.error),
    type: "fleetDetail",
  });
  errors.push({
    error: useSelector(({ solutionDiscount }) => solutionDiscount.error),
    type: "solutionDiscount",
  });
  errors.push({
    error: useSelector(
      ({ marketplaceResourceList }) => marketplaceResourceList.error
    ),
    type: "marketplaceResourceList",
  });
  errors.push({
    error: useSelector(({ marketplaceDetail }) => marketplaceDetail.error),
    type: "marketplaceDetail",
  });

  useEffect(() => {
    errors.forEach((error) => {
      if (error && error.error) {
        if (error.error.response && error.error.response.status) {
          // We have axios hook, and we handle 401 error code there. In that case we redirect to the login page
          if (error.error.response.status === 401) {
            return;
          }
        }

        if (error.error.message === "Network Error") {
          addMessage(
            "Network Error: Please check network connection and try again."
          );
        }
        // React custom errors
        else if (error.error.mes && error.error.mes !== "") {
          addMessage(error.error.mes);
        }
        // Django custom validation errors
        else if (
          ((error.error.response || {}).data || {}).message &&
          error.error.response.data.message !== ""
        ) {
          addMessage(error.error.response.data.message);
        }
        // Django validation errors
        else if ((error.error.response || {}).data || {}) {
          // fleetDetail errors are handled in the FleetEditNew and leadDetail components.
          if (error.type !== "fleetDetail" && error.type !== "leadDetail") {
            addMessage(
              "Server is temporarily unavailable, please try again later."
            );
          }
        }
        // Empty message
        else
          addMessage(
            "Server is temporarily unavailable, please try again later."
          );
      }
    });
  }, [errors]);

  const addMessage = (message) => {
    if (!seenMessagesRef.current.includes(message)) {
      seenMessagesRef.current.push(message);
      Store.addNotification({
        id: message,
        title: "Error!",
        message,
        type: "danger",
        insert: "top",
        container: "top-left",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
    }
  };

  return <ReactNotifications />;
};

export default Notify;
