import camelcaseKeys from 'camelcase-keys';
import {
  BILLING_ORDER_LIST_FETCH_REQUEST,
  BILLING_ORDER_LIST_FETCH_SUCCESS,
  BILLING_ORDER_LIST_FETCH_ERROR
} from "../actions/actionTypes";

const initialState = {
  billingOrders: [],
  isLoading: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case BILLING_ORDER_LIST_FETCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: action.error,
      };

    case BILLING_ORDER_LIST_FETCH_SUCCESS:
      const data = camelcaseKeys(action.data, {deep: true});
      return {
        ...state,
        billingOrders: data,
        isLoading: false,
        error: action.error
      };

    case BILLING_ORDER_LIST_FETCH_ERROR:
      return {
        ...state,
        billingOrders: [],
        isLoading: false,
        error: action.error,
      };

    default:
      return state;
  }
};
