import React from "react";
import { connect } from "react-redux";
import { Card, CardBody, Row, Col } from "reactstrap";
import ClipLoader from "react-spinners/ClipLoader";

import { fetchMetrics } from "../../store/actions/metricsActions";
import { Auth0Context } from '../../services/auth/react-auth0-wrapper';
import LeadsAndSubscriptions from "./LeadsAndSubscriptions";
import { Link } from "react-router-dom";

class HostDashboard extends React.Component {
  static contextType = Auth0Context;

  componentDidMount() {
    this.props.fetchMetrics();
  }

  render() {
    const { user } = this.context;

    const {
      metrics: {
        marketplaceCount,
        viewCount,
        leadCount
      }
    } = this.props;

    return (
      <>
        <div className="breadcrumb-nav">
          <Row>
            <Col>
              <ol className="breadcrumb-container">
                <li className="breadcrumb-container__item">
                  <h5>
                    Welcome, {user.name ? user.name : user.email}
                  </h5>
                </li>
              </ol>
            </Col>
          </Row>
        </div>
        <div className="content home-page">
          <Card className="card-tasks d-inline-block">
            <CardBody className="d-flex metrics flex-column flex-sm-row">
              <div className="pl-5 pr-5 d-flex pb-3 pt-3">
                <div className="mr-2 pt-2">
                  <i className="ti-icon ti-shopping-cart-full"/>
                </div>
                <div>
                  <h3 className="font-weight-bold m-0">
                    <ClipLoader
                      size={20}
                      color={"#000000"}
                      loading={marketplaceCount === undefined}
                    />
                    {marketplaceCount && marketplaceCount}
                  </h3>
                  <h5>
                    Marketplaces
                  </h5>
                </div>
              </div>
              <div className="pl-5 pr-5 d-flex pb-3 pt-3">
                <div className="mr-2">
                  <i className="ti-icon ti-eye"/>
                </div>
                <div>
                  <h3 className="font-weight-bold m-0">
                    <ClipLoader
                      size={20}
                      color={"#000000"}
                      loading={viewCount === undefined}
                    />
                    {viewCount && viewCount}
                  </h3>
                  <h5>
                    Views
                  </h5>
                </div>
              </div>
              <div className="pl-5 pr-5 d-flex pb-3 pt-3">
                <div className="mr-2 pt-2">
                  <i className="ti-icon ti-flag"/>
                </div>
                <div>
                  <h3 className="font-weight-bold m-0">
                    <ClipLoader
                      size={20}
                      color={"#000000"}
                      loading={leadCount === undefined}
                    />
                    {leadCount && leadCount}
                  </h3>
                  <h5>
                    Leads
                  </h5>
                </div>
              </div>
            </CardBody>
          </Card>
          <Row>
            <Col>
              <h4>Open Leads and Subscriptions</h4>
            </Col>
          </Row>
          <Row>
            <Col>
              <LeadsAndSubscriptions {...this.props} />
            </Col>
          </Row>
          <Row>
            <Col md="8">
              <Link to={"/admin/leads/"}>View All leads ></Link>
              <Link className={"ml-4"} to={"/admin/customers/"}>View All subscriptions ></Link>
            </Col>
          </Row>
        </div>
      </>
    );

  }
}

const mapStateToProps = state => ({
  metrics: state.metrics
});

const mapDispatchToProps = {
  fetchMetrics
};

export default connect(mapStateToProps, mapDispatchToProps)(HostDashboard);
