import {
  ADD_MESSAGE,
  REMOVE_ALL_MESSAGES,
  REMOVE_MESSAGES,
} from "./actionTypes";

export const addMessage = (mes, color='primary') => (dispatch) => {
  dispatch({
    type: ADD_MESSAGE,
    data: { id: (Math.random() + 1).toString(36).substring(2), mes, color },
  });
};

export const removeMessages = (message) => (dispatch) => {
  dispatch({ type: REMOVE_MESSAGES, payload: message });
};

export const removeAllMessages = () => (dispatch) => {
  dispatch({ type: REMOVE_ALL_MESSAGES });
};
