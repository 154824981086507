import React from "react";
import {Field, formValueSelector, reduxForm} from "redux-form";
import {
  Card,
  Col,
  FormGroup,
  Label,
  Row,
  UncontrolledTooltip
} from "reactstrap";

import SolutionMarketingResourcesUpload from "../../components/CustomUpload/SolutionMarketingResourcesUpload.jsx";

import HelpIcon from "../../assets/icons/help-icon.svg";
import WarningIcon from "../../assets/icons/warning-icon.svg";

import {
  emailValidation,
  maxLengthValidation256,
  minLengthValidation2,
  minLengthValidation5,
  phoneNumberValidation,
  requiredValidation,
  urlValidation,
  maxLengthValidation120,
  integerValidation,
  positiveNumberValidation,
  freeTrialLengthValidation
} from "./validation";

import {
  nonNegativeNumbers,
  renderCheckboxField,
  renderInputField
} from "./utils";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import camelcaseKeys from "camelcase-keys";
import {validateSolutionFields} from "../../store/actions/solutionListActions";
import snakeCaseKeys from "snakecase-keys";
import Pluralize from "pluralize";
import TagsInput from 'react-tagsinput'


class SolutionInfoEditNewForm extends React.Component {
  componentDidMount() {
    const {
      solutionInfo,
      solutionDataPermissions
    } = this.props;
    if (solutionInfo.internalName) {
      let dataPermissions = {};
      if (solutionDataPermissions) {
        this.props.solutionDataPermissions.map(
          ({id}) => dataPermissions[`data-${id}`] = true
        );
      }
      this.props.initialize({dataPermissions, ...this.props.solutionInfo});
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.solutionInfo.leadsContactFirstName !== this.props.solutionInfo.leadsContactFirstName) {
      let dataPermissions = {};
      if (this.props.solutionDataPermissions) {
        this.props.solutionDataPermissions.map(
          ({id}) => dataPermissions[`data-${id}`] = true
        );
      }
      this.props.initialize({dataPermissions, ...this.props.solutionInfo});
    }
  }

  render() {
    const {
      handleSubmit,
      solutionMarketingResources,
      setAddedMarketingResources,
      addRemovedMarketingResource,
      removeAddedMarketingResource,
      valid,
      submitFailed,
      trialPeriodDays,
      settings,
      setFilesSize,
      handleTagsChange,
      tags,
    } = this.props;

    return (
      <>
        <form onSubmit={handleSubmit}>
          {!valid && submitFailed &&
            <div className="d-flex justify-content-end pr-5">
              <p className="text-danger">
                <img alt="" src={WarningIcon} className="mr-1"/>
                Complete all required fields or fix invalid fields before continuing.
              </p>
            </div>
          }
          <Card>
            <div className="section">
              <Row className="m-10">
                <Col md="4">
                  <h4 className="m-0">Solution Version Name</h4>
                </Col>
                <Col md="8">
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <label>
                          <h5 className="font-weight-bold">Solution Internal Name</h5>
                        </label>
                        <Field
                          type='text'
                          name="internalName"
                          component={renderInputField}
                          validate={[requiredValidation, maxLengthValidation120, minLengthValidation5]}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <label>
                          <h5 className="font-weight-bold">Access URL</h5>
                        </label>
                        <Field
                          type='url'
                          name="accessUrl"
                          component={renderInputField}
                          validate={[requiredValidation, urlValidation, maxLengthValidation256]}
                        />
                        <label>Specify the URL including http:// or https:// for solution access</label>
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
            <div className="section">
              <Row>
                <Col md="4">
                  <h4 className="m-0">Leads Contact</h4>
                </Col>
                <Col md="8">
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <label>
                          <h5 className="font-weight-bold">
                            First Name
                          </h5>
                        </label>
                        <Field
                          type='text'
                          name="leadsContactFirstName"
                          component={renderInputField}
                          validate={[requiredValidation, minLengthValidation2]}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <label>
                          <h5 className="font-weight-bold">
                            Last Name
                          </h5>
                        </label>
                        <Field
                          type='text'
                          name="leadsContactSecondName"
                          component={renderInputField}
                          validate={[requiredValidation, minLengthValidation2]}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <label>
                          <h5 className="font-weight-bold">
                            Email
                          </h5>
                        </label>
                        <Field
                          type='text'
                          name="leadsContactEmail"
                          component={renderInputField}
                          validate={[requiredValidation, emailValidation]}
                        />
                        <label>Marketplace leads will be directed to this email</label>
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
            <div className="section">
              <Row>
                <Col md="4">
                  <h4 className="m-0">
                    Support Access
                    <img alt="" src={HelpIcon} className="help-icon" id="solution-support-access-tooltip"/>
                    <UncontrolledTooltip placement="bottom" target="solution-support-access-tooltip">
                      <p style={{textAlign: 'left'}}>
                        Set up where customer inquiries will be directed for your Marketplace solution users.
                      </p>
                    </UncontrolledTooltip>
                  </h4>
                </Col>
                <Col md="8">
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <label>
                          <h5 className="font-weight-bold">
                            Support Email
                          </h5>
                        </label>
                        <Field
                          type='text'
                          name="supportEmail"
                          component={renderInputField}
                          validate={[requiredValidation, emailValidation]}
                        />
                        <label>Provide an email for customer support</label>
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <label>
                          <div className='d-flex'>
                            <h5 className="font-weight-bold">
                              Support Phone
                            </h5>
                            <div>
                              <img alt="" src={HelpIcon} className="help-icon" id="solution-support-phone-tooltip"/>
                              <UncontrolledTooltip placement="bottom" target="solution-support-phone-tooltip">
                                <p style={{textAlign: 'left'}}>
                                  International phone number format required
                                </p>
                              </UncontrolledTooltip>
                            </div>
                          </div>

                        </label>
                        <Field
                          type='text'
                          name="supportPhoneNumber"
                          component={renderInputField}
                          validate={[requiredValidation, phoneNumberValidation]}
                        />
                        <label>Provide a phone for customer support</label>
                      </FormGroup>
                      <FormGroup check>
                        <Label check>
                          <Field
                            type="checkbox"
                            name="showSupportPhoneNumber"
                            component={renderCheckboxField}
                          />
                          <p style={{color: "#252422", fontSize: "14px"}}>
                            Show in the solution access page of the marketplace
                          </p>
                        </Label>
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
            <div className="section">
              <SolutionMarketingResourcesUpload
                solutionMarketingResources={solutionMarketingResources}
                setAddedMarketingResources={setAddedMarketingResources}
                addRemovedMarketingResource={addRemovedMarketingResource}
                removeAddedMarketingResource={removeAddedMarketingResource}
                setFilesSize={setFilesSize}
              />
            </div>
            {/*TODO Just removing the data related UI, it needs to be implemented later */}
            {/* <div className="section">
              <Row>
                <Col md="4">
                  <h4 className="m-0">Data Access</h4>
                </Col>
                <Col md="8">
                  <label>
                    <h5 className="font-weight-bold">
                      Data assets and permissions needed for this solution
                    </h5>
                  </label>
                  <p>
                    Define assets and review data acess permissions
                  </p>
                  <div>
                    <Row>
                      <Col md="6">
                        <div className="mb-5">
                          <h6>Drivers</h6>
                          {
                            dataTypeClasses.filter((item) => item.category === "Drivers")
                              .map((dataTypeClass, i) => (
                                <FormGroup key={i} check>
                                  <Label check>
                                    <Field
                                      name={`dataPermissions.data-${dataTypeClass.id}`}
                                      type="checkbox"
                                      component={renderCheckboxField}
                                    />
                                    <span className="form-check-sign" />
                                    {dataTypeClass.name}
                                  </Label>
                                </FormGroup>
                              ))
                          }
                        </div>
                        <div>
                          <h6>Assets</h6>
                          {
                            dataTypeClasses.filter((item) => item.category === "Assets")
                              .map((dataTypeClass, i) => (
                                <FormGroup key={i} check>
                                  <Label check>
                                    <Field
                                      name={`dataPermissions.data-${dataTypeClass.id}`}
                                      type="checkbox"
                                      component={renderCheckboxField}
                                    />
                                    <span className="form-check-sign" />
                                    {dataTypeClass.name}
                                  </Label>
                                </FormGroup>
                              ))
                          }
                        </div>
                      </Col>
                      <Col md="6">
                        <h6>Vehicles</h6>
                        {
                          dataTypeClasses.filter((item) => item.category === "Vehicles")
                            .map((dataTypeClass, i) => (
                              <FormGroup key={i} check>
                                <Label check>
                                  <Field
                                    name={`dataPermissions.data-${dataTypeClass.id}`}
                                    type="checkbox"
                                    component={renderCheckboxField}
                                  />
                                  <span className="form-check-sign" />
                                  {dataTypeClass.name}
                                </Label>
                              </FormGroup>
                            ))
                        }
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </div> */}
            <div className="section">
              <Row>
                <Col md="4">
                  <h4 className="m-0">
                    Marketplace
                  </h4>
                </Col>
                <Col md="8">
                  <FreeTrialCheckbox/>
                  {settings.freeTrialLength &&
                    <div className="d-flex">
                      <div style={{color: "#252422", fontSize: "14px"}} className="mt-2 mr-3">
                        Trial length
                      </div>
                      <div className="form-row">
                        <FormGroup inline className="col-6">
                          <Field
                            name="trialPeriodDays"
                            type="number"
                            component={renderInputField}
                            normalize={nonNegativeNumbers}
                            validate={[integerValidation, positiveNumberValidation, freeTrialLengthValidation]}
                          />
                        </FormGroup>
                        <div style={{color: "#252422", fontSize: "14px"}} className="mt-2 ml-2">
                          {Pluralize('day', parseInt(trialPeriodDays))}
                        </div>
                      </div>
                    </div>
                  }
                  <DirectCheckoutCheckbox/>
                  <TermsAndConditionsField isDirectCheckoutEnabled={!!this.props.isDirectCheckoutEnabled}/>

                  <div>
                    <label>
                      <h5 className="font-weight-bold m-0 pr-5">
                        Search Tags
                      </h5>
                    </label>
                  </div>

                  <TagsInput value={tags} onChange={handleTagsChange}/>

                  <div className='gray-small-text'>Hit {'<enter>'} to save a tag</div>

                </Col>
              </Row>
            </div>
          </Card>
          {!valid && submitFailed &&
            <div className="d-flex justify-content-end pr-5 mt-2">
              <p className="text-danger">
                <img alt="" src={WarningIcon} className="mr-1"/>
                Complete all required fields or fix invalid fields before continuing.
              </p>
            </div>
          }
        </form>
      </>
    );
  }
}

function FreeTrialCheckbox() {
  return (
    <>
      <label>
        <h5 className="font-weight-bold">
          Free Trial
        </h5>
      </label>
      <FormGroup check>
        <Label check>
          <Field
            type="checkbox"
            name="isTrialEnabled"
            component={renderCheckboxField}
          />
          <p style={{color: "#252422", fontSize: "14px"}}>
            Show Free Trial and enable Request for Free Trial
          </p>
        </Label>
      </FormGroup>
    </>
  )
}

function DirectCheckoutCheckbox() {
  return (
    <>
      <label>
        <h5 className="font-weight-bold">
          Direct Checkout
        </h5>
      </label>
      <FormGroup check>
        <Label check>
          <Field
            type="checkbox"
            name="isDirectCheckoutEnabled"
            component={renderCheckboxField}
          />
          <p style={{color: "#252422", fontSize: "14px"}}>
            Show Buy option and enable direct checkout
          </p>
        </Label>
      </FormGroup>
    </>
  )
}

const termsAndConditionsFieldPropTypes = {
  isDirectCheckoutEnabled: PropTypes.bool.isRequired,
};

function TermsAndConditionsField(props) {
  const validators = props.isDirectCheckoutEnabled ? [requiredValidation, minLengthValidation5] : [];

  return (
    <>
      <label>
        <h5 className="font-weight-bold">
          Direct Checkout Terms & Conditions
        </h5>
      </label>
      <p>
        Add legal agreement copy to be used for online subscription
      </p>
      <FormGroup>
        <Field
          type='textarea'
          name="legalTerms"
          component={renderInputField}
          validate={validators}
        />
      </FormGroup>
    </>
  )
}

TermsAndConditionsField.propTypes = termsAndConditionsFieldPropTypes;

const asyncValidate = (values, dispatch, props) => {
  const {solutionId} = props;
  const fields = {
    id: solutionId,
    internalName: values.internalName,
    supportPhoneNumber: values.supportPhoneNumber
  };
  return validateSolutionFields(snakeCaseKeys(fields)).then(res => {
    const errors = {};
    if (res.data) {
      for (let [key, value] of Object.entries(res.data)) {
        errors[key] = value;
      }
      if (Object.entries(errors).length !== 0) {
        // Eslint is disabled because throwing not an error object is a part of the docs
        // https://redux-form.com/6.7.0/examples/asyncvalidation/
        // eslint-disable-next-line no-throw-literal
        throw(camelcaseKeys(errors, {deep: true}))
      }
    }
  })
};

const selector = formValueSelector('SolutionInfo');

SolutionInfoEditNewForm = connect(
  state => ({
    isDirectCheckoutEnabled: selector(state, 'isDirectCheckoutEnabled'),
    trialPeriodDays: selector(state, 'trialPeriodDays'),
    settings: state.settings,
  })
)(SolutionInfoEditNewForm);

export default reduxForm({
  form: 'SolutionInfo',
  asyncValidate,
  asyncChangeFields: ['internalName', 'supportPhoneNumber'],
  asyncBlurFields: ['internalName', 'supportPhoneNumber']
})(
  SolutionInfoEditNewForm
);

// Exports for easy testing
export {DirectCheckoutCheckbox, FreeTrialCheckbox, TermsAndConditionsField};
