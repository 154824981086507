import React from "react";
import { connect } from "react-redux";

import {Button, Modal, ModalBody, ModalFooter} from "reactstrap";
import {restoreSolutionVersion} from "../../../store/actions/solutionVersionActions";

class RestoreButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalConfirmation: false
        };
        this.toggleModalConfirmation = this.toggleModalConfirmation.bind(this);
    }

    toggleModalConfirmation(){
        this.setState({
            modalConfirmation: !this.state.modalConfirmation
        });
    }

    onConfirmRestore(solutionId, versionId) {
        this.toggleModalConfirmation();
        this.props.restoreSolutionVersion(solutionId, versionId);
    }

    render() {
        const {versionId, solutionId} = this.props;
        if (!versionId) {
            return <></>
        }

        return (
            <>
                <Button
                    className="pull-right"
                    color="primary"
                    onClick={this.toggleModalConfirmation}
                >
                    Restore
                </Button>
                <Modal
                    isOpen={this.state.modalConfirmation}
                    fade={false}
                    toggle={this.toggleModalConfirmation}
                    className="modal-dialog-centered">
                    <div className="modal-header justify-content-center">
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.toggleModalDeleteConfirmation}>
                            <span aria-hidden="true">×</span>
                        </button>
                        <h5 className="modal-title">Restore Solution Version</h5>
                    </div>
                    <ModalBody>
                        Are you sure, you want to restore the solution version?
                    </ModalBody>
                    <ModalFooter className="mr-3">
                        <Button color="outline-primary" onClick={this.toggleModalConfirmation}>
                            Cancel
                        </Button>
                        <Button
                            color="primary"
                            onClick={() => {this.onConfirmRestore(solutionId, versionId)}}
                        >
                            Restore
                        </Button>
                    </ModalFooter>
                </Modal>
            </>
        )
    }
}

const mapStateToProps = state => ({

});

const mapDispatchToProps = {
    restoreSolutionVersion
};

export default connect(mapStateToProps, mapDispatchToProps)(RestoreButton);
