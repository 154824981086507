import React from "react";
import { Link } from "react-router-dom";
import { Button, Card, CardBody, Row, Col, FormGroup, Input } from "reactstrap";

import EmbeddedAlert from "../../components/EmbeddedAlert";
import { connect } from "react-redux";
import MarketplaceResourceList from "./MarketplaceResourceList";
import { fetchMarketplaceList } from "../../store/actions/marketplaceListActions";
import { M_RESOURCE_CATEGORY_OPTIONS } from "../../config/marketplaceResource";

class MarketplaceResourceListLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedMarketplace: null,
      selectedCategory: null,
    };
  }

  componentDidMount() {
    const { state } = this.props.location;
    const { fetchMarketplaceList } = this.props;

    fetchMarketplaceList().then((res) => {
      if (!res.err && res.data && res.data.length > 0) {
        if (state && state.selectedMarketplace) {
          this.setState({
            selectedMarketplace: state.selectedMarketplace,
          });
        } else if (!this.state.selectedMarketplace) {
          this.setState({
            selectedMarketplace: res.data[0].id,
          });
        }
      }
    });
  }

  setSelectedMarketplace(selectedMarketplace) {
    this.setState({ selectedMarketplace });
  }

  render() {
    const { account, marketplaces } = this.props;
    const { selectedMarketplace, selectedCategory } = this.state;

    return (
      <>
        <div className="breadcrumb-nav">
          <Row>
            <Col>
              <ol className="breadcrumb-container">
                <li className="breadcrumb-container__item">
                  <h5>
                    <Link to="/admin/home">Home</Link>
                  </h5>
                </li>
                <li className="breadcrumb-container__item">
                  <h5>/</h5>
                </li>
                <li className="breadcrumb-container__item">
                  <h5>Marketplace Resources</h5>
                </li>
              </ol>
            </Col>
          </Row>
        </div>
        <div className="content lead-list-page">
          <div className="page-content-header">
            <div className="pt-3">
              <EmbeddedAlert />
            </div>
            <Row>
              <Col>
                <h3>Marketplace Resources</h3>
              </Col>
            </Row>
          </div>
          <Card className="custom-react-table pt-2">
            <CardBody>
              {selectedMarketplace ? (
                <>
                  <div className="d-flex align-items-center mb-3 ">
                    <div className="d-flex align-items-center flex-grow-1 bg">
                      <div className="mr-3">Filter by marketplace</div>
                      <FormGroup className="m-0">
                        <Input
                          className="custom-select custom-select-xl pr-4"
                          type="select"
                          onChange={(event) => {
                            this.setState({
                              selectedMarketplace: event.target.value,
                            });
                          }}
                        >
                          {marketplaces.map((marketplace, index) => {
                            const selected =
                              this.state.selectedMarketplace &&
                              marketplace.id ===
                                parseInt(this.state.selectedMarketplace);

                            return (
                              <option
                                key={index}
                                value={marketplace.id}
                                selected={selected}
                              >
                                {marketplace.name}
                              </option>
                            );
                          })}
                        </Input>
                      </FormGroup>

                      <div className="mr-3 ml-4">Filter by category</div>

                      <FormGroup className="m-0">
                        <Input
                          className="custom-select custom-select-xl pr-4"
                          type="select"
                          onChange={(event) => {
                            this.setState({
                              selectedCategory: event.target.value,
                            });
                          }}
                        >
                          <option value="">--- Select Category ---</option>
                          {Object.keys(M_RESOURCE_CATEGORY_OPTIONS).map(
                            (key) => {
                              return (
                                <option key={key} value={key}>
                                  {M_RESOURCE_CATEGORY_OPTIONS[key]}
                                </option>
                              );
                            }
                          )}
                        </Input>
                      </FormGroup>
                    </div>

                    <div className="mx-auto">
                      {account.permissions.includes("write:resources") && (
                        <Button
                          color="primary"
                          className="pull-right ml-2"
                          onClick={() => {
                            this.props.history.push(
                              `/admin/resources/${selectedMarketplace}/new`,
                              { selectedCategory: this.state.selectedCategory }
                            );
                          }}
                        >
                          Create New Resource
                        </Button>
                      )}
                    </div>
                  </div>
                  <MarketplaceResourceList
                    selectedMarketplace={selectedMarketplace}
                    selectedCategory={selectedCategory}
                    setSelectedMarketplace={this.setSelectedMarketplace.bind(
                      this
                    )}
                  />
                </>
              ) : (
                <h5>Marketplace not found</h5>
              )}
            </CardBody>
          </Card>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = {
  fetchMarketplaceList,
};

const mapStateToProps = (state) => ({
  account: state.account,
  marketplaces: state.marketplaceList.marketplaces,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MarketplaceResourceListLayout);
