import axios from 'axios';

export const setupAxios = (token) => {
  axios.defaults.baseURL = process.env.REACT_APP_API_URL;
  axios.interceptors.request.use(
    (config) => {
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    }
  );
};

export const handleAxiosErrors = (logout, loginWithRedirect) => {
  // Add a response interceptor
  axios.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    return response;
  }, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    if (error.response && error.response.status === 401) {
      logout({
        returnTo: process.env.REACT_APP_HOST,
      });
      loginWithRedirect({})
    }
    return Promise.reject(error);
  });
};
