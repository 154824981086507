import React from "react";
import { Field, reduxForm } from "redux-form";
import {
	renderField
} from "./utils";
import {
	requiredValidation,
	emailValidation,
} from "./validation";


class ContactForm extends React.Component {
	render() {
		return (
			<form>
				<Field
					name="name"
					type="text"
					component={renderField}
					label="Name *"
					validate={[requiredValidation]}
				/>
				<Field
					name="email"
					type="text"
					component={renderField}
					label="Email *"
					validate={[requiredValidation, emailValidation]}
				/>
				<Field
					name="companyName"
					type="text"
					component={renderField}
					label="Company Name"
				/>
				<Field
					name="phone"
					type="text"
					component={renderField}
					label="Phone"
				/>
				<Field
					name="note"
					type="textarea"
					component={renderField}
					validate={requiredValidation}
					label="Note *"
				/>
			</form>
		);
	}
}

export default reduxForm({
  form: 'ContactForm'
})(
  ContactForm
);
