import camelcaseKeys from 'camelcase-keys';
import {
  FLEET_FETCH_REQUEST,
  FLEET_FETCH_SUCCESS,
  FLEET_FETCH_ERROR,
  FLEET_ADD_REQUEST,
  FLEET_ADD_SUCCESS,
  FLEET_ADD_ERROR,
  FLEET_UPDATE_REQUEST,
  FLEET_UPDATE_SUCCESS,
  FLEET_UPDATE_ERROR,
  FLEET_CLEAR,
} from "../actions/actionTypes";

const initialState = {
  fleet: {
    id: null
  }, // fleet detail returned from the api
  isLoading: false, // boolean variable to indicate fetching api is in progress
  isAdding: false, // boolean variable to indicate creating api is in progress
  isUpdating: false, // boolean variable to indicate updating api is in progress
  error: null, // error
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FLEET_ADD_REQUEST:
      return {
        ...state,
        isAdding: true,
        error: null,
      };

    case FLEET_FETCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };

    case FLEET_UPDATE_REQUEST:
      return {
        ...state,
        isUpdating: true,
        error: null,
      };

    case FLEET_ADD_SUCCESS:
    {
      const data = camelcaseKeys(action.data, {deep: true});
      return {
        ...state,
        fleet: data,
        isAdding: false,
        error: null
      };
    }

    case FLEET_FETCH_SUCCESS:
    {
      const data = camelcaseKeys(action.data, {deep: true});
      return {
        ...state,
        fleet: data,
        isLoading: false,
        error: null
      };
    }

    case FLEET_UPDATE_SUCCESS:
    {
      const data = camelcaseKeys(action.data, {deep: true});
      return {
        ...state,
        fleet: data,
        isUpdating: false,
        error: null
      };
    }

    case FLEET_ADD_ERROR:
      return {
        ...state,
        fleet: {},
        isAdding: false,
        error: action.error,
      };

    case FLEET_FETCH_ERROR:
      return {
        ...state,
        fleet: {},
        isLoading: false,
        error: action.error,
      };

    case FLEET_UPDATE_ERROR:
      return {
        ...state,
        fleet: {},
        isUpdating: false,
        error: action.error,
      };

    case FLEET_CLEAR:
      return  {
        fleet: {},
        isLoading: false,
        isAdding: false,
        isUpdating: false,
        error: null,
      };

    default:
      return state;
  }
};
