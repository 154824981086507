import React from "react";
import { Switch, Route } from "react-router-dom";
import MarketplaceResourceListLayout from "./MarketplaceResourceListLayout";
import MarketplaceResourceDetail from "./MarketplaceResourceDetail";

const Resources = () => {
  return (
    <Switch>
      <Route
        key="1"
        path="/admin/resources"
        exact
        component={MarketplaceResourceListLayout}
      />
      <Route
        key="2"
        path="/admin/resources/:marketplaceId/new/"
        exact
        component={MarketplaceResourceDetail}
      />
      <Route
        key="3"
        path="/admin/resources/:marketplaceId/:resourceId/"
        exact
        component={MarketplaceResourceDetail}
      />
    </Switch>
  );
};

export default Resources;
