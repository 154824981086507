import React from "react";
import { connect }  from "react-redux";
import { Route, Switch } from "react-router-dom";
import LoadingOverlay from 'react-loading-overlay-ts'

import AdminNavbar from "../../components/Navbars/AdminNavbar.jsx";
import Footer from "../../components/Footer/Footer.jsx";
import Sidebar from "../../components/Sidebar/Sidebar.jsx";
import routes from "../../routes.js";
import { Auth0Context } from "../../services/auth/react-auth0-wrapper";
import { switchMode, assignPermissions } from "../../store/actions/accountActions";
import { fetchSettings } from "../../store/actions/settingsActions";

class Admin extends React.Component {
  static contextType = Auth0Context;

  constructor(props) {
    super(props);
    this.sidebarMiniBreakpoint = 1350;
    this.state = {
      backgroundColor: "black",
      activeColor: "info",
      sidebarMini: window.innerWidth < this.sidebarMiniBreakpoint,
      accountMode: null
    };
  }

  componentDidMount() {
    const {user} = this.context;
    let accountMode = localStorage.getItem('accountMode');
    const userScope = user.scope[0];  // Default user scope
    if (!accountMode || accountMode === 'null' || (accountMode && !user.scope.includes(accountMode))) {
      accountMode = userScope
    }
    this.setState({accountMode});
    this.props.switchMode(accountMode);
    localStorage.setItem('accountMode', accountMode);
    this.props.assignPermissions(user);
    this.props.fetchSettings();

    this.toggleSidebarMini();
    window.addEventListener('resize', this.toggleSidebarMini.bind(this), true);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.account.mode !== prevProps.account.mode && prevProps.account.mode) {
      const { account } = this.props;
      const { user } = this.context;
      if (account.mode === 'Host') this.props.history.push("/admin/host/dashboard");
      else if (account.mode === 'Partner') this.props.history.push("/admin/home");
      this.props.assignPermissions(user);
      this.setState({ accountMode: account.mode });
      localStorage.setItem('accountMode', account.mode);
    }
  }

  toggleSidebarMini() {
    if (window.innerWidth < this.sidebarMiniBreakpoint) {
      if (!document.body.classList.contains("sidebar-mini")) {
        document.body.classList.add("sidebar-mini");
      }
    } else {
      if (document.body.classList.contains("sidebar-mini")) {
        document.body.classList.remove("sidebar-mini");
      }
    }
  }

  getRoutes = routes => {
    const { account } = this.props;

    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
        if (prop.permission && !account.permissions.includes(prop.permission)) {
          return null;
        }

        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  handleActiveClick = color => {
    this.setState({ activeColor: color });
  };

  handleBgClick = color => {
    this.setState({ backgroundColor: color });
  };

  handleMiniClick = () => {
    if (document.body.classList.contains("sidebar-mini")) {
      this.setState({ sidebarMini: false });
    } else {
      this.setState({ sidebarMini: true });
    }
    document.body.classList.toggle("sidebar-mini");
  };

  render() {
    if (!this.state.accountMode) return(<></>);

    return (
      <LoadingOverlay
        active={this.props.mainSpinner.backgroundSpinner}
        text='Loading...'
        spinner={true}
        styles={{
          spinner: (base) => ({
            ...base,
            width: '100px',
          }),
          content: () => ({
            top: '50%',
            left: '50%',
            position: 'fixed'
          }),
          overlay: (base) => ({
            ...base,
            zIndex: "10000000 !important"
          })
        }}
      >
        <div className="wrapper">
          <Sidebar
            {...this.props}
            routes={routes}
          />
          <div className="main-panel" ref="mainPanel">
            <AdminNavbar {...this.props} handleMiniClick={this.handleMiniClick} />
            <div className="content-wrapper">
              <Switch>{this.getRoutes(routes)}</Switch>
            </div>
          </div>
        </div>
        <Footer fluid />
      </LoadingOverlay>
    );
  }
}

const mapStateToProps = state => ({
  mainSpinner: state.mainSpinner,
  account: state.account
});

const mapDispatchToProps = {
  switchMode,
  assignPermissions,
  fetchSettings
};

export default connect(mapStateToProps, mapDispatchToProps)(Admin);
