/* eslint-disable linebreak-style */
import {
  CONTACT_FORM_SUBMIT_REQUEST,
  CONTACT_FORM_SUBMIT_SUCCESS,
  CONTACT_FORM_SUBMIT_ERROR
} from "../actions/actionTypes";

const initialState = {
  isLoading: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CONTACT_FORM_SUBMIT_REQUEST:
      return {
        isLoading: true,
        error: action.error,
      };

    case CONTACT_FORM_SUBMIT_SUCCESS:
      return {
        isLoading: false,
        error: action.error
      };

    case CONTACT_FORM_SUBMIT_ERROR:
      return {
        isLoading: false,
        error: action.error,
      };

    default:
      return state;
  }
};
