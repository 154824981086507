import { UPDATE_SPINNER } from './actionTypes';

export const updateSpinner = enableSpinner => (dispatch, state) => {
  if (enableSpinner) {
    // show the spinner but wait 500 ms
    // displaySpinner counter is used in case we have more then 1 request
    dispatch({type: UPDATE_SPINNER, data: {backgroundSpinner: false, displaySpinner: state().mainSpinner.displaySpinner + 1}});

    setTimeout(() => {
      // If the request is taking more then 500 ms we show the spinner
      if (state().mainSpinner.displaySpinner > 0) {
        dispatch({ type: UPDATE_SPINNER , data: { backgroundSpinner: true, displaySpinner: state().mainSpinner.displaySpinner }});
      }
    }, 500);
  }

  else {
    dispatch({type: UPDATE_SPINNER, data: {backgroundSpinner: false, displaySpinner: state().mainSpinner.displaySpinner - 1}});
  }
};
