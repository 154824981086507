export const CUSTOMER_TYPE_REQUEST_INFO_LEAD_LABEL = 'Request Info Lead';
export const CUSTOMER_TYPE_ECOMMERCE_SUBSCRIBER_LABEL = 'E-Commerce Subscriber';
export const CUSTOMER_TYPE_TRIAL_LEAD_LABEL = 'Free Trial Lead';

export const CUSTOMER_TYPE_REQUEST_INFO_LEAD_VALUE = 'L';
export const CUSTOMER_TYPE_ECOMMERCE_SUBSCRIBER_VALUE = 'D';
export const CUSTOMER_TYPE_TRIAL_LEAD_VALUE = 'Trial';

export const CUSTOMER_TYPE_OPTIONS = {
  [CUSTOMER_TYPE_REQUEST_INFO_LEAD_VALUE]: CUSTOMER_TYPE_REQUEST_INFO_LEAD_LABEL,
  [CUSTOMER_TYPE_ECOMMERCE_SUBSCRIBER_VALUE]: CUSTOMER_TYPE_ECOMMERCE_SUBSCRIBER_LABEL,
  [CUSTOMER_TYPE_TRIAL_LEAD_VALUE]: CUSTOMER_TYPE_TRIAL_LEAD_LABEL
};

export const SUBSCRIPTION_STATUS_LIVE_VALUE = 'L';
// export const SUBSCRIPTION_STATUS_OPEN_VALUE = 'O';
export const SUBSCRIPTION_STATUS_CANCELED_VALUE = 'CANCELED';

export const SUBSCRIPTION_STATUS_CANCELED_LABEL = 'Canceled';

export const WFE_NOTIFY_HOST_OF_LEAD = 'NOTIFY HOST OF LEAD';
export const WFE_OTC_INVOICE_WAS_CREATED = 'OTC INVOICE WAS CREATED';
export const WFE_NOTIFY_CUSTOMER_OF_OTC_INVOICE = 'NOTIFY CUSTOMER OF OTC INVOICE';

// export const WFE_NOTIFY_HOST_OF_ACTIVATION = 'NOTIFY HOST OF ACTIVATION';
// export const WFE_NOTIFY_CUSTOMER_MONTHLY_CHARGE = 'NOTIFY CUSTOMER MONTHLY CHARGE';
export const WFE_NOTIFY_CUSTOMER_TO_APPROVE_ORDER_AND_PAY = 'NOTIFY CUSTOMER TO APPROVE ORDER AND PAY';
// export const WFE_ORDER_CONFIRMATION = 'ORDER CONFIRMATION';


export const SUBSCRIPTION_PAYMENT_METHOD_CREDIT_CARD_VALUE = 'C';
export const SUBSCRIPTION_PAYMENT_METHOD_HOST_CREDIT_VALUE = 'H';
export const SUBSCRIPTION_PAYMENT_METHOD_PAY_ON_TERMS_VALUE = 'PAY_ON_TERMS';
export const SUBSCRIPTION_PAYMENT_METHOD_CREDIT_CARD_LABEL = 'Credit Card';
export const SUBSCRIPTION_PAYMENT_METHOD_HOST_CREDIT_LABEL = 'Host Invoice';
export const SUBSCRIPTION_PAYMENT_METHOD_PAY_ON_TERMS_LABEL = 'Pay on Terms';
export const SUBSCRIPTION_PAYMENT_METHOD_OPTIONS = {
  [SUBSCRIPTION_PAYMENT_METHOD_CREDIT_CARD_VALUE]: SUBSCRIPTION_PAYMENT_METHOD_CREDIT_CARD_LABEL,
  [SUBSCRIPTION_PAYMENT_METHOD_HOST_CREDIT_VALUE]: SUBSCRIPTION_PAYMENT_METHOD_HOST_CREDIT_LABEL,
  [SUBSCRIPTION_PAYMENT_METHOD_PAY_ON_TERMS_VALUE]: SUBSCRIPTION_PAYMENT_METHOD_PAY_ON_TERMS_LABEL
};


export const SUBSCRIPTION_1_OTC_SHIPPING_TYPE_FLAT_RATE_VALUE = 'FLAT_RATE';
export const SUBSCRIPTION_1_OTC_SHIPPING_TYPE_PER_UNIT_VALUE = 'PER_UNIT';
export const SUBSCRIPTION_1_OTC_SHIPPING_TYPE_NO_SHIPPING_VALUE = 'NO_SHIPPING';
export const SUBSCRIPTION_1_OTC_SHIPPING_TYPE_FLAT_RATE_LABEL = 'Flat rate';
export const SUBSCRIPTION_1_OTC_SHIPPING_TYPE_PER_UNIT_LABEL = 'Price per unit';
export const SUBSCRIPTION_1_OTC_SHIPPING_TYPE_NO_SHIPPING_LABEL = 'No shipping';

export const SUBSCRIPTION_1_OTC_SHIPPING_TYPE_OPTIONS = {
  [SUBSCRIPTION_1_OTC_SHIPPING_TYPE_FLAT_RATE_VALUE]: SUBSCRIPTION_1_OTC_SHIPPING_TYPE_FLAT_RATE_LABEL,
  [SUBSCRIPTION_1_OTC_SHIPPING_TYPE_PER_UNIT_VALUE]: SUBSCRIPTION_1_OTC_SHIPPING_TYPE_PER_UNIT_LABEL,
  [SUBSCRIPTION_1_OTC_SHIPPING_TYPE_NO_SHIPPING_VALUE]: SUBSCRIPTION_1_OTC_SHIPPING_TYPE_NO_SHIPPING_LABEL
};

export const SUBSCRIPTION_2_OTC_SHIPPING_TYPE_FLAT_RATE_VALUE = 'FLAT_RATE';
export const SUBSCRIPTION_2_OTC_SHIPPING_TYPE_PER_UNIT_VALUE = 'PER_UNIT';
export const SUBSCRIPTION_2_OTC_SHIPPING_TYPE_NO_SHIPPING_VALUE = 'NO_SHIPPING';
export const SUBSCRIPTION_2_OTC_SHIPPING_TYPE_FLAT_RATE_LABEL = 'Flat rate';
export const SUBSCRIPTION_2_OTC_SHIPPING_TYPE_PER_UNIT_LABEL = 'Price per unit';
export const SUBSCRIPTION_2_OTC_SHIPPING_TYPE_NO_SHIPPING_LABEL = 'No shipping';

export const SUBSCRIPTION_2_OTC_SHIPPING_TYPE_OPTIONS = {
  [SUBSCRIPTION_2_OTC_SHIPPING_TYPE_FLAT_RATE_VALUE]: SUBSCRIPTION_2_OTC_SHIPPING_TYPE_FLAT_RATE_LABEL,
  [SUBSCRIPTION_2_OTC_SHIPPING_TYPE_PER_UNIT_VALUE]: SUBSCRIPTION_2_OTC_SHIPPING_TYPE_PER_UNIT_LABEL,
  [SUBSCRIPTION_2_OTC_SHIPPING_TYPE_NO_SHIPPING_VALUE]: SUBSCRIPTION_2_OTC_SHIPPING_TYPE_NO_SHIPPING_LABEL
};


export const SUBSCRIPTION_NET_TERMS_30_DAYS_VALUE = 30;
export const SUBSCRIPTION_NET_TERMS_60_DAYS_VALUE = 60;
export const SUBSCRIPTION_NET_TERMS_90_DAYS_VALUE = 90;
export const SUBSCRIPTION_NET_TERMS_30_DAYS_LABEL = '30 days';
export const SUBSCRIPTION_NET_TERMS_60_DAYS_LABEL = '60 days';
export const SUBSCRIPTION_NET_TERMS_90_DAYS_LABEL = '90 days';
export const SUBSCRIPTION_NET_TERMS_OPTIONS = {
  [SUBSCRIPTION_NET_TERMS_30_DAYS_VALUE]: SUBSCRIPTION_NET_TERMS_30_DAYS_LABEL,
  [SUBSCRIPTION_NET_TERMS_60_DAYS_VALUE]: SUBSCRIPTION_NET_TERMS_60_DAYS_LABEL,
  [SUBSCRIPTION_NET_TERMS_90_DAYS_VALUE]: SUBSCRIPTION_NET_TERMS_90_DAYS_LABEL
};

export const SUBSCRIPTION_PAYMENT_INTERVAL_MONTH_VALUE = 'MONTHLY';
export const SUBSCRIPTION_PAYMENT_INTERVAL_YEAR_VALUE = 'YEARLY';
export const SUBSCRIPTION_PAYMENT_INTERVAL_QUARTER_VALUE = 'QUARTERLY';
export const SUBSCRIPTION_PAYMENT_INTERVAL_MONTH_LABEL = 'Monthly';
export const SUBSCRIPTION_PAYMENT_INTERVAL_YEAR_LABEL = 'Yearly';
export const SUBSCRIPTION_PAYMENT_INTERVAL_QUARTER_LABEL = 'Quarterly';
export const SUBSCRIPTION_PAYMENT_METHOD_INTERVALS = {
  [SUBSCRIPTION_PAYMENT_INTERVAL_MONTH_VALUE]: SUBSCRIPTION_PAYMENT_INTERVAL_MONTH_LABEL,
  [SUBSCRIPTION_PAYMENT_INTERVAL_QUARTER_VALUE]: SUBSCRIPTION_PAYMENT_INTERVAL_QUARTER_LABEL,
  [SUBSCRIPTION_PAYMENT_INTERVAL_YEAR_VALUE]: SUBSCRIPTION_PAYMENT_INTERVAL_YEAR_LABEL
};

export const SUBSCRIPTION_TYPE_PAID_VALUE = 'PAID';
export const SUBSCRIPTION_TYPE_FREE_VALUE = 'FREE';
// export const SUBSCRIPTION_TYPE_PAID_LABEL = 'Paid';
// export const SUBSCRIPTION_TYPE_FREE_LABEL = 'Free';
