// TODO Consider using external validation library For example: https://www.npmjs.com/package/validator
import validator from 'validator';
import moment from "moment";

import { parsePhoneNumberFromString } from 'libphonenumber-js';

export const notZeroValidation = value => (parseFloat(value) !== 0 ? undefined : 'This value cannot be zero');
export const checkedValidation = value => (value  === true ? undefined : 'Required');
export const minLengthValidation5 = value => (!value || value.length >= 5 ? undefined : 'Should be at least 5 characters');
export const minLengthValidation2 = value => (value.length >= 2 ? undefined : 'Should be at least 2 characters');

export const maxLengthValidation2048 = value => (
    !value || value.length <= 2048 ? undefined : 'Should not be more than 2048 characters.'
);
export const maxLengthValidation500 = value => (
  !value || value.length <= 500 ? undefined : 'Should not be more than 500 characters.'
);
export const maxLengthValidation256 = value => (
  value && value.length <= 256 ? undefined : 'Should not be more than 256 characters.'
);
export const maxLengthValidation200 = value => (
  value && value.length <= 200 ? undefined : 'Should not be more than 200 characters.'
);

export const maxLengthValidation120 = value => (
  !value || value.length <= 120 ? undefined : 'Should not be more than 120 characters.'
);

export const maxLengthValidation20 = value => (
    !value || value.length <= 20 ? undefined : 'Should not be more than 20 characters.'
);

export const minNumber1Validation = value => (!value || value >= 1 ? undefined : 'Please enter a number greater than or equal to 1');
export const maxNumber100000Validation = value => (!value || value < 100000 ? undefined : 'Please enter a number less than 100,000');
export const maxNumber1000Validation = value => (!value || value <= 1000 ? undefined : 'Please enter a number less than or equal to 1000');
export const maxNumber500000000Validation = value => (!value || value < 500000000  ? undefined : 'Please enter a number less than 500,000,000');
export const maxNumber100Validation = value => (!value || value <= 100  ? undefined : 'Please enter a number less than or equal to 100');

export const emailValidation = value =>
  value &&  !validator.isEmail(value)
    ? 'Invalid email address'
    : undefined;

export const requiredValidation = value => {
  if (value !== 0 && !value) return "Required";
  else if (value && !value.toString().trim().length) return "Required";
  else return undefined
};

export const freeTrialLengthValidation = value => {
  if (value && value > 730) return "Trial period must be less than two years (730 days)";
  else return undefined
};

export const solutionSearchFieldValidation = value => {
  if (!value) return "No matching solutions";
  else return undefined
};

//function that validates phone number
export const phoneNumberValidation = value => {
  // var phoneNumRex = /^(1?(-?\d{3})-?)?(\d{3})(-?\d{4})$/;
  if (!value) {
    return undefined;
  }
  const phoneNumber = parsePhoneNumberFromString(value);
  if (phoneNumber && phoneNumber.isValid()) {
    return undefined;
  }
  return "Invalid Phone Number";
};

//function that validates company name
export const companyNameValidation = value => {
  var companyRex = /^(?!\s)(?!.*\s$)(?=.*[a-zA-Z0-9])[a-zA-Z0-9 '~?!]{2,}$/;
  if (value && value.length > 0 && (!companyRex.test(value))) {
    return "Invalid Company Name";
  }
  return undefined;
};

export const integerValidation = value => {
  if (!value) {
    return undefined;
  }
  if (parseInt(value) + "" === value || parseInt(value) === value) {
    return undefined;
  } else {
    return "Invalid Integer";
  }
};

export const positiveNumberValidation = value => {
  if (!value) {
    return undefined;
  }
  if (parseFloat(value) >= 0) {
    return undefined;
  } else {
    return "Please input positive number";
  }
};

export const urlValidation = value =>
  value &&  !validator.isURL(value, {require_protocol: true})
    ? 'Please input valid URL'
    : undefined;

export const zipCodeValidation = zipCode => {
  if (!zipCode) {
    return undefined;
  }
  var zipCodePattern = /^\d{5}$|^\d{5}-\d{4}$/;
  if(zipCodePattern.test(zipCode)) {
    return undefined;
  } else {
    return "Please enter a valid zip code";
  }
};

export const customerValidation = (value, allValues, props) => {
  const { lead, fleetId, initialCustomerName } = props;
  // We came from the lead view page and we don't have a lead.address
  // in this case we need to show a validation error
  if (initialCustomerName && !fleetId && lead) {
    if (!lead.address) {
      return "Customer address is missing";
    }
  }
  return undefined
};

export const isDateValidation = value =>
  value && !validator.isDate(value)
    ? 'Invalid date'
    : undefined;

export const isDateValidationMMDDYYYY = value =>
  value && !moment(value, 'MM/DD/YYYY',true).isValid()
    ? 'Invalid date'
    : undefined;

export const isNotPastDateValidation = value =>
  value && !validator.isAfter(value.toString())
    ? 'The date must be in the future'
    : undefined;


export const freeSolutionValidation = (value, allValues, props) => {
  const {firstOneTimePrice, secondOneTimePrice} = allValues;
  const { solutionDiscounts } = props;
  if (value === 0 || value ==='0' || value === '0.00') {
    if (firstOneTimePrice || secondOneTimePrice || (solutionDiscounts && solutionDiscounts.length > 0)) {
      return "For a free solution, one-time charges or discounts must be removed";
    }
  }
  return undefined
};
