import { SWITCH_ACCOUNT_MODE, TOGGLE_ACCOUNT_MODE, ASSIGN_PERMISSIONS } from './actionTypes';

export const toggleMode = () => (dispatch) => {
  dispatch({ type: TOGGLE_ACCOUNT_MODE });
};

export const switchMode = (mode) => (dispatch) => {
  dispatch({ type: SWITCH_ACCOUNT_MODE, data: mode });
};

export const assignPermissions = (user) => (dispatch, state) => {
  // TODO We have to know which permissions belong to the host role and which to the partner role.
  //  Instead of hard code these permissions here we can create Auth0 hook and assign host_permissions and
  //  partner_permissions to the user object.
  //  Right now we duplicate auth0 permissions. If we change permissions for a role here we have to do the same in Auth0

  const partner_permissions = [
    "read:dashboard",
    "read:solutions",
    "read:customers",
    "read:leads",
    "read:billing",
    "write:dashboard",
    "write:solutions",
    "write:customers",
    "write:leads",
    "write:billing"
  ];

  const host_permissions = [
    "read:dashboard",
    "read:customers",
    "read:leads",
    "full:resources",
    "read:resources",
    "write:resources"
  ];

  let mode_permissions = [];

  if (state().account.mode === 'Partner') {
    partner_permissions.forEach(permission => {
      const full_permission = `full:${permission.split(":")[1]}`;
      if (user.permissions.includes(permission) || user.permissions.includes(full_permission)) {
        mode_permissions.push(permission);
      }
    });
  } else { // Host
    host_permissions.forEach(permission => {
      const full_permission = `full:${permission.split(":")[1]}`;
      if (user.permissions.includes(permission) || user.permissions.includes(full_permission)) {
        mode_permissions.push(permission);
      }
    });
  }
  dispatch({ type: ASSIGN_PERMISSIONS, data: mode_permissions });
};
