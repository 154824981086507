/* eslint-disable linebreak-style */
import axios from 'axios';
import {
  LEAD_LIST_FETCH_REQUEST,
  LEAD_LIST_FETCH_SUCCESS,
  LEAD_LIST_FETCH_ERROR
} from './actionTypes';
import { updateSpinner } from "./spinnerActions";


export const fetchLeadList = ({limit, status} = {}) => (dispatch, state) => {
  dispatch(updateSpinner(true));
  dispatch({ type: LEAD_LIST_FETCH_REQUEST , request: true});
  let url = `/api/lead/leads/`;

  const { mode } = state().account;
  url += `?mode=${mode}`;

  if (limit) url += `&limit=${limit}`;
  if (status) url += `&status=${status}`;

  axios.get(url)
  .then(({ data }) => {
    if (data) {
      dispatch({ type: LEAD_LIST_FETCH_SUCCESS, data });
    } else {
      dispatch({ type: LEAD_LIST_FETCH_ERROR, error: { mes: "" }});
    }
    dispatch(updateSpinner(false));
  })
  .catch((error) => {
    dispatch({ type: LEAD_LIST_FETCH_ERROR, error });
    dispatch(updateSpinner(false));
  });
};
