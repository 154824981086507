import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Button, Card, CardBody, FormGroup, Input, Row, Col } from "reactstrap";

import { fetchLeadList } from "../../store/actions/leadListActions";
import {
  LEAD_TYPE_LEAD_LABEL,
  LEAD_TYPE_OPTIONS,
  LEAD_TYPE_TRIAL_LABEL,
} from "../../config/lead";
import EmbeddedAlert from "../../components/EmbeddedAlert";
import LeadListTable from "./components/LeadListTable";

class LeadList extends React.Component {

  componentDidMount() {
    this.props.fetchLeadList();
  }
  
  constructor(props) {
    super(props);
    this.state = {
      type: "",
      company: ""
    }
  }

  render() {
    const { leads } = this.props.leadList;
    const { account } = this.props;

    const data = leads.map((lead) => {
      return {
        id: lead.id,
        type: LEAD_TYPE_OPTIONS[lead.type],
        date: lead.created,
        company: lead.company,
        solution: lead.solution ? lead.solution.name: "",
        status: lead.status,
        marketplace: lead.marketplace ? lead.marketplace.name: ""
      };
    });

    const filtered = [
      {
        id: "type",
        value: [this.state.type]
      },
      {
        id: 'company',
        value: [this.state.company]
      }
    ];

    const companyOptions = new Set(leads.map(lead => lead.company));

    return (
      <>
        <div className="breadcrumb-nav">
          <Row>
            <Col>
              <ol className="breadcrumb-container">
                <li className="breadcrumb-container__item">
                  <h5>
                    <Link to="/admin/home">Home</Link>
                  </h5>
                </li>
                <li className="breadcrumb-container__item">
                  <h5>/</h5>
                </li>
                <li className="breadcrumb-container__item">
                  <h5>Leads</h5>
                </li>
              </ol>
            </Col>
          </Row>
        </div>
        <div className="content lead-list-page">
          <div className="page-content-header">
            <div className="pt-3">
              <EmbeddedAlert />
            </div>
            <Row>
              <Col>
                <h3>Leads</h3>
              </Col>
              <Col>
                {account.permissions.includes("write:leads")  &&
                  <Button
                    color="primary"
                    className="pull-right"
                    onClick={
                      () => {
                        this.props.history.push("/admin/leads/new");
                      }
                    }
                  >
                    Create New Lead
                  </Button>
                }
                {/* <Button
                  color="outline-primary"
                  className="pull-right mr-3"
                >
                  Export as CSV
                </Button> */}
              </Col>
            </Row>
          </div>
          <Card className="custom-react-table">
            <CardBody>
              <div className="d-flex align-items-center mb-3">

                <div className="mr-3">
                  Filter by type
                </div>
                <FormGroup className="m-0">
                  <Input
                    className="custom-select custom-select-xl pr-4"
                    type="select"
                    onChange={
                      (event) => {
                        this.setState({
                          type: event.target.value
                        });
                      }
                    }
                  >
                    <option value="">
                      All
                    </option>
                    <option value={LEAD_TYPE_LEAD_LABEL}>
                      {LEAD_TYPE_LEAD_LABEL}
                    </option>
                    <option value={LEAD_TYPE_TRIAL_LABEL}>
                      {LEAD_TYPE_TRIAL_LABEL}
                    </option>
                  </Input>
                </FormGroup>

                <div className="mr-3 ml-3">
                  Filter by company
                </div>
                <FormGroup className="m-0">
                  <Input
                    className="custom-select custom-select-xl pr-4"
                    type="select"
                    onChange={
                      (event) => {
                        this.setState({
                          company: event.target.value
                        });
                      }
                    }
                  >
                    <option key="a" value="">
                      All
                    </option>
                    {
                      Array.from(companyOptions).map((company, i) => {
                        return <option key={i} value={company}>{company}</option>
                      })
                    }
                  </Input>
                </FormGroup>
              </div>

              <LeadListTable
                data={data}
                typeFilter={this.state.type}
                companyFilter={this.state.company}
                history={this.props.history}
              />
            </CardBody>
          </Card>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  leadList: state.leadList,
  account: state.account
});

const mapDispatchToProps = {
  fetchLeadList
};

export default connect(mapStateToProps, mapDispatchToProps)(LeadList);
