import React from "react";
import { connect } from "react-redux";
import {Col, Row, Table} from "reactstrap";
import{ Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import SolutionDiscountForm from "./SolutionDiscountForm";
import {
  addSolutionDiscountLocal,
  clearSolutionDiscountList, deleteSolutionDiscountLocal,
  fetchSolutionDiscountList, updateSolutionDiscountLocal
} from "../../../store/actions/solutionDiscountActions";
import {
  SOLUTION_DISCOUNT_ENTITY_TYPE_NON_MONETARY_LABEL,
  SOLUTION_DISCOUNT_ENTITY_TYPE_NON_MONETARY_VALUE,
  SOLUTION_DISCOUNT_ENTITY_TYPE_OTC1_LABEL,
  SOLUTION_DISCOUNT_ENTITY_TYPE_OTC1_VALUE,
  SOLUTION_DISCOUNT_ENTITY_TYPE_OTC2_LABEL,
  SOLUTION_DISCOUNT_ENTITY_TYPE_OTC2_VALUE,
  SOLUTION_DISCOUNT_ENTITY_TYPE_SUBSCRIPTION_LABEL,
  SOLUTION_DISCOUNT_ENTITY_TYPE_SUBSCRIPTION_VALUE
} from "../../../config/solutionDiscount";

import {addMessage} from "../../../store/actions/messageActions";
import Currency from "../../../components/Currency";

class SolutionDiscount extends React.Component {
  constructor(props) {
    super(props);
    this.form = React.createRef();
    this.state = {
      modalSubscriptionDiscount: false,
      modalDeleteConfirmation: false,
      checkedForDelete: null,
      solutionDiscount: {}
    };
    this.toggleModalSubscriptionDiscount = this.toggleModalSubscriptionDiscount.bind(this);
    this.toggleModalDeleteConfirmation = this.toggleModalDeleteConfirmation.bind(this);
    this.submitDiscountForm.bind(this);
  }

  componentDidMount() {
    const {solutionId, fetchSolutionDiscountList} = this.props;
    if (solutionId) {
      fetchSolutionDiscountList(solutionId)
    }
  }

  componentWillUnmount() {
    this.props.clearSolutionDiscountList();
  }


  toggleModalSubscriptionDiscount(){
    this.setState({
      modalSubscriptionDiscount: !this.state.modalSubscriptionDiscount
    });
  }

  toggleModalDeleteConfirmation(){
    this.setState({
      modalDeleteConfirmation: !this.state.modalDeleteConfirmation
    });
  }

  submitDiscountForm(event) {
    this.form.current.submit();
    event.preventDefault();
  }

  deleteSolutionConfirmed() {
    const {deleteSolutionDiscountLocal, solutionId, validateStartSolutionPrice} = this.props;
    const {checkedForDelete} = this.state;
    if (solutionId) {
      deleteSolutionDiscountLocal(checkedForDelete).then(() => {
        // We need to validate the startSubscriptionPrice field in case the value is 0 and there is still a message
        // "For a free solution, one-time charges or discounts must be removed"
        validateStartSolutionPrice()
      });
    } else {
      deleteSolutionDiscountLocal(checkedForDelete).then(() => {
        validateStartSolutionPrice()
      });
    }
    this.setState({
      modalDeleteConfirmation: false,
      checkedForDelete: null
    });
  }

  deleteSolutionDiscountHandler(solutionDiscountId) {
    this.setState({
      modalDeleteConfirmation: true,
      checkedForDelete: solutionDiscountId
    });
  }

  onSubmit(values) {
    const {
      addSolutionDiscountLocal,
      updateSolutionDiscountLocal,
      solutionId
    } = this.props;
    const {solutionDiscount} = this.state;

    if (values.entityType !== SOLUTION_DISCOUNT_ENTITY_TYPE_SUBSCRIPTION_VALUE) {
      values.isPrimary = false;
    }

    if (!solutionDiscount.id) {
      if (solutionId) {
        values.solution = solutionId;
      }
      addSolutionDiscountLocal(values)
    } else {
      updateSolutionDiscountLocal(solutionDiscount.id, values)
    }

    this.toggleModalSubscriptionDiscount()
  }

  render() {
    const {
      solutionDiscounts,
      solutionListing,
      firstOneTimeSubDescription,
      secondOneTimeSubDescription,
      disableFieldOnEditing,
      startSubscriptionPrice
    } = this.props;

    const {solutionDiscount} = this.state;
    let currency = null;
    if (this.props.SolutionListing && this.props.SolutionListing.values) {
      currency = this.props.SolutionListing.values.currency;
    }

    return (
      <>
        <Row className="mt-2">
          <Col>
            <h5 className="font-weight-bold mb-2">
              Discounts
            </h5>
          </Col>
        </Row>
        {solutionDiscounts && solutionDiscounts.length > 0 &&
        <Row className="mt-2">
          <Col>
            <Table responsive className="table-nonfluid mb-0" style={{minWidth: 500}}>
              <thead>
              <tr>
                <th className="text-center">#</th>
                <th>Name</th>
                <th>
                  <div className="mx-5">Product</div>
                </th>
                <th>Amount</th>
                <th>
                  <div className="mx-5">Is primary</div>
                </th>
                <th className="text-right">Actions</th>
              </tr>
              </thead>
              <tbody>
              {solutionDiscounts.map((solutionDiscount, index) => {
                return (
                  <tr key={index}>
                    <td className="text-center">{index + 1}</td>
                    <td>{solutionDiscount.name}</td>
                    <td>
                      <div className="mx-5">
                        {solutionDiscount.entityType === SOLUTION_DISCOUNT_ENTITY_TYPE_SUBSCRIPTION_VALUE
                        && SOLUTION_DISCOUNT_ENTITY_TYPE_SUBSCRIPTION_LABEL}
                        {solutionDiscount.entityType === SOLUTION_DISCOUNT_ENTITY_TYPE_NON_MONETARY_VALUE
                        && SOLUTION_DISCOUNT_ENTITY_TYPE_NON_MONETARY_LABEL}
                        {solutionDiscount.entityType === SOLUTION_DISCOUNT_ENTITY_TYPE_OTC1_VALUE
                        && (solutionListing.firstOneTimeSubDescription
                          ? solutionListing.firstOneTimeSubDescription
                          : firstOneTimeSubDescription
                            ? firstOneTimeSubDescription
                            : SOLUTION_DISCOUNT_ENTITY_TYPE_OTC1_LABEL)}
                        {solutionDiscount.entityType === SOLUTION_DISCOUNT_ENTITY_TYPE_OTC2_VALUE
                        && (solutionListing.secondOneTimeSubDescription
                          ? solutionListing.secondOneTimeSubDescription
                          : secondOneTimeSubDescription
                            ? secondOneTimeSubDescription
                            : SOLUTION_DISCOUNT_ENTITY_TYPE_OTC2_LABEL)}
                      </div>
                    </td>
                    {solutionDiscount.percentage && <td>{solutionDiscount.percentage}%</td>}
                    {solutionDiscount.amount && <td><Currency currency={currency} value={solutionDiscount.amount} /></td>}
                    {solutionDiscount.entityType === SOLUTION_DISCOUNT_ENTITY_TYPE_NON_MONETARY_VALUE && <td />}
                    <td>
                      <div className="mx-5">{solutionDiscount.isPrimary && <i className="fa fa-check" />}</div>
                    </td>
                    <td className="text-right text-nowrap">
                      <Button
                        className="btn-icon mr-2"
                        color="success"
                        size="sm"
                        disabled={disableFieldOnEditing}
                        onClick={() => {
                          this.setState({solutionDiscount: solutionDiscount});
                          this.toggleModalSubscriptionDiscount()
                        }}>
                        <i className="fa fa-edit" />
                      </Button>
                      <Button
                        className="btn-icon"
                        color="danger"
                        size="sm"
                        disabled={disableFieldOnEditing}
                        onClick={() => {
                          this.deleteSolutionDiscountHandler(solutionDiscount.id)
                        }}>
                        <i className="fa fa-times" />
                      </Button>
                    </td>
                  </tr>
                )
              })}
              </tbody>
            </Table>
          </Col>
        </Row>
        }
        <Row>
          <Col>
            <Button className="btn btn-outline-primary"
                    disabled={disableFieldOnEditing || !startSubscriptionPrice || parseInt(startSubscriptionPrice) === 0}
                    onClick={() => {
                      this.setState({solutionDiscount: {}});
                      this.toggleModalSubscriptionDiscount()
                    }}
            >
              Add discount
            </Button>

            <Modal
              isOpen={this.state.modalSubscriptionDiscount}
              fade={false}
              toggle={this.toggleModalSubscriptionDiscount}
              className="modal-dialog-centered">
              <div className="modal-header justify-content-center">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.toggleModalSubscriptionDiscount}>
                  <span aria-hidden="true">×</span>
                </button>
                <h5 className="modal-title">Add Discount</h5>
              </div>
              <ModalBody>
                <SolutionDiscountForm
                  ref={this.form}
                  discount={solutionDiscount}
                  solutionListing={solutionListing}
                  onSubmit={this.onSubmit.bind(this)}
                />
              </ModalBody>
              <ModalFooter className="mr-3">
                <Button color="outline-primary" onClick={this.toggleModalSubscriptionDiscount}>
                  Cancel
                </Button>
                <Button
                  color="primary"
                  onClick={(event) => this.submitDiscountForm(event)}>
                  Save
                </Button>
              </ModalFooter>
            </Modal>

            <Modal
              isOpen={this.state.modalDeleteConfirmation}
              fade={false}
              toggle={this.toggleModalDeleteConfirmation}
              className="modal-dialog-centered">
              <div className="modal-header justify-content-center">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.toggleModalDeleteConfirmation}>
                  <span aria-hidden="true">×</span>
                </button>
                <h5 className="modal-title">Delete discount</h5>
              </div>
              <ModalBody>
                Are you sure, you want to delete the selected discount?
              </ModalBody>
              <ModalFooter className="mr-3">
                <Button color="outline-primary" onClick={this.toggleModalDeleteConfirmation}>
                  Cancel
                </Button>
                <Button
                  color="primary"
                  onClick={(event) => this.deleteSolutionConfirmed(event)}>
                  Delete
                </Button>
              </ModalFooter>
            </Modal>
          </Col>
        </Row>
      </>
    );
  }
}

const mapStateToProps = state => ({
  solutionDiscounts: state.solutionDiscount.solutionDiscounts,
  SolutionListing: state.form.SolutionListing,
});

const mapDispatchToProps = {
  fetchSolutionDiscountList,
  clearSolutionDiscountList,
  addSolutionDiscountLocal,
  addMessage,
  deleteSolutionDiscountLocal,
  updateSolutionDiscountLocal
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  SolutionDiscount
);
