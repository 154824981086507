import axios from 'axios';
import {
    SOLUTION_VERSION_LIST_FETCH_REQUEST,
    SOLUTION_VERSION_LIST_FETCH_SUCCESS,
    SOLUTION_VERSION_LIST_FETCH_ERROR,
    SOLUTION_VERSION_DETAIL_FETCH_REQUEST,
    SOLUTION_VERSION_DETAIL_FETCH_SUCCESS,
    SOLUTION_VERSION_DETAIL_FETCH_ERROR,
    SOLUTION_VERSION_DETAIL_CLEAR, SOLUTION_FETCH_SUCCESS, SOLUTION_FETCH_ERROR, SOLUTION_FETCH_REQUEST
} from './actionTypes';
import { updateSpinner } from "./spinnerActions";
import {history} from "../index";


export const fetchSolutionVersionList = (solutionId) => async (dispatch) => {
    dispatch(updateSpinner(true));
    dispatch({ type: SOLUTION_VERSION_LIST_FETCH_REQUEST , request: true});
    let url = `/api/solution/solution_versions/?solution=${solutionId}`;
    try {
        const res = await axios.get(url);
        dispatch({ type: SOLUTION_VERSION_LIST_FETCH_SUCCESS, data: res.data });
        dispatch(updateSpinner(false));
    } catch (error) {
        dispatch({ type: SOLUTION_VERSION_LIST_FETCH_ERROR, error });
        dispatch(updateSpinner(false));
        return error;
    }
};

export const fetchSolutionVersionDetail = (solutionVersion) => async (dispatch) => {
    dispatch(updateSpinner(true));
    dispatch({ type: SOLUTION_VERSION_DETAIL_FETCH_REQUEST , request: true});
    let url = `/api/solution/solution_versions/${solutionVersion}/`;
    try {
        const res = await axios.get(url);
        dispatch({ type: SOLUTION_VERSION_DETAIL_FETCH_SUCCESS, data: res.data });
        dispatch(updateSpinner(false));
    } catch (error) {
        dispatch({ type: SOLUTION_VERSION_DETAIL_FETCH_ERROR, error });
        dispatch(updateSpinner(false));
        return error;
    }
};

export const clearSolutionVersionDetail = () => (dispatch) => {
    dispatch({ type: SOLUTION_VERSION_DETAIL_CLEAR , request: false});
};

export const restoreSolutionVersion = (solutionId, versionId) => async (dispatch, state) => {
    dispatch(updateSpinner(true));
    dispatch({ type: SOLUTION_FETCH_REQUEST , request: true});
    let url = `/api/solution/solutions/${solutionId}/restore_version/`;

    const { mode } = state().account;
    url += `?mode=${mode}`;

    if (versionId) {
        url += `&version_id=${versionId}`
    }

    try {
        const res = await axios.get(url);
        dispatch({ type: SOLUTION_FETCH_SUCCESS, data: res.data });
        dispatch(updateSpinner(false));
        history.push(`/admin/solutions/${solutionId}/?`);
    } catch (error) {
        dispatch({ type: SOLUTION_FETCH_ERROR, error });
        dispatch(updateSpinner(false));
        return error;
    }
};
