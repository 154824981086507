/*eslint-disable*/
import React from "react";
import { Container, Row, Col } from "reactstrap";
// used for making the prop types of this component
import PropTypes from "prop-types";
import LocationIcon from "../../assets/icons/location.svg"

class AuthFooter extends React.Component {
  render() {
    return (
      <footer
        className={"footer footer-auth"}
      >
        <Container fluid={this.props.fluid ? true : false}>
          <Row className="location-section">
          <div className="ml-auto mr-auto">
            <Row className="location-panel">
              <Col md="4">
                <div className="d-flex align-items-start">
                  <img src={LocationIcon} className="m-1" />
                  <div>
                    <h5 className="text-white mb-1">
                      Palo Alto Office
                    </h5>
                    <p className="text-nowrap">
                      2625 Middlefield Road, #597<br/>
                      Palo Alto, CA 94306
                    </p>
                  </div>
                </div>
              </Col>
              
              <Col md="1">
                <div className="d-flex align-items-start">
                </div>
              </Col>
              
              <Col md="4">
                <div className="location-question-section">
                  <h5 className="text-white mb-1">
                    Questions?
                  </h5>
                  <p className="text-nowrap">
                    Reach out to us at <a href="mailto:info@roadz.com">info@roadz.com</a>
                  </p>
                </div>
              </Col>
            </Row>
          </div>
          </Row>
          <Row>
            <div className="ml-auto mr-auto">
              <p className="copyright">
                &copy; {1900 + new Date().getYear()} Roadz, Inc.
                All Rights Reserved
              </p>
            </div>
          </Row>
        </Container>
      </footer>
    );
  }
}

AuthFooter.propTypes = {
  default: PropTypes.bool,
  fluid: PropTypes.bool
};

export default AuthFooter;
