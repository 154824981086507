import React from "react";
import { useTable, useSortBy } from "react-table";
import {
  formatDisplayDatetime,
  formatDisplayDatetimeShort,
} from "../../../utils/datetime";
import { FormGroup, Input, Label } from "reactstrap";

const Table = ({ columns, data }) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
      },
      useSortBy
    );

  return (
    <div className="ReactTable7">
      <table {...getTableProps()} className="rt-table">
        <thead className="rt-thead">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()} className="rt-rh rt-tr">
              {headerGroup.headers.map((column) => {
                const sortClass =
                  column.isSorted && !column.isSortedDesc
                    ? "-sort-asc"
                    : column.isSorted && column.isSortedDesc
                    ? "-sort-desc"
                    : "no-sort";

                return (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    <div className={"rt-header rt-td " + sortClass}>
                      {column.render("Header")}
                    </div>
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows && rows.length > 0
            ? rows.map((row, i) => {
                prepareRow(row);
                const style = row.original.isActive
                  ? { cursor: "initial" }
                  : {
                      background: "#f2f2f2",
                      cursor: "initial",
                    };
                return (
                  <tr {...row.getRowProps()} style={style} className="rt-tr">
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()} className="rt-td">
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })
            : [...Array(10).keys()].map((val) => (
                <tr className="rt-tr" key={val}>
                  <td colSpan={10} className="rt-td empty-rows">
                    {val === 4 ? "No rows found" : ""}
                  </td>
                </tr>
              ))}
        </tbody>
      </table>
    </div>
  );
};

const CustomerEntitiesTable = ({
  data,
  history,
  handleLabelChange,
  handleIsActiveChange,
}) => {
  const columns = React.useMemo(
    () => [
      {
        Header: "#",
        accessor: (row, i) => {
          return <p style={{ width: 100 }}>{i + 1}</p>;
        },
      },
      {
        Header: "Label",
        accessor: (row, i) => {
          return (
            <Input
              style={{ maxWidth: 300 }}
              type="text"
              value={row.label}
              disabled={row.isDisabled}
              onChange={(event) => handleLabelChange(i, event.target.value)}
            />
          );
        },
      },
      {
        Header: "Status",
        accessor: (row, i) => {
          return (
            <FormGroup check style={{ margin: 0 }}>
              <Label check>
                <Input
                  type="checkbox"
                  checked={row.isActive}
                  disabled={row.isDisabled}
                  onChange={(event) =>
                    handleIsActiveChange(i, event.target.checked)
                  }
                  style={{ visibility: "initial", width: 250 }} // Necessary to make checkboxes accessible by "tab".
                />
                <span className="form-check-sign" />
                <p style={{ color: "#252422", fontSize: "14px", margin: 0 }}>
                  Active
                </p>
              </Label>
            </FormGroup>
          );
        },
      },
      {
        Header: "Activation date",
        accessor: (row) =>
          row.activatedAt ? formatDisplayDatetimeShort(row.activatedAt) : "",
      },
    ],
    []
  );

  return <Table columns={columns} data={data} history={history} />;
};

export default CustomerEntitiesTable;
