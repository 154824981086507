import axios from "axios";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Card, CardBody, Col, Row } from "reactstrap";

import { SUBSCRIPTION_STATUS_LIVE_VALUE } from "../../config/subscription";
import { addMessage } from "../../store/actions/messageActions";
import {
  clearSubscriptionDetail,
  fetchSubscriptionDetail,
  startSubscriptionDetail,
} from "../../store/actions/subscriptionDetailActions";
import { updateSpinner } from "../../store/actions/spinnerActions";
import CustomerEntitiesTable from "./components/CustomerEntitiesTable";

///////////
// REDUX //
///////////

const mapStateToProps = (state) => ({
  subscriptionDetail: state.subscriptionDetail,
  account: state.account,
});

const mapDispatchToProps = {
  addMessage,
  clearSubscriptionDetail,
  fetchSubscriptionDetail,
  startSubscriptionDetail,
  updateSpinner,
};

////////////////////
// MAIN COMPONENT //
////////////////////

class CustomerEntities extends React.Component {
  state = {
    originalEntities: [],
    tableEntities: [],
    allEntitiesActive: false,
  };

  ///////////////////////
  // LIFECYCLE METHODS //
  ///////////////////////

  componentDidMount() {
    const {
      fetchSubscriptionDetail,
      match: {
        params: { subscriptionId },
      },
    } = this.props;

    fetchSubscriptionDetail(subscriptionId);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const subscriptionWasLoaded =
      prevProps.subscriptionDetail.isLoading &&
      !this.props.subscriptionDetail.isLoading;

    if (subscriptionWasLoaded) {
      const {
        subscriptionDetail: { subscription },
      } = this.props;

      let entities;
      try {
        entities = deepCopy(subscription.subscriptionEntities);
      } catch (error) {
        entities = [];
      }

      const actualEntitiesNum = entities.length;
      const maxEntitiesNum = subscription.quantity;

      if (maxEntitiesNum > actualEntitiesNum) {
        const unallocatedEntitiesNum = maxEntitiesNum - actualEntitiesNum;
        entities = this._getEntitiesHandledForUnallocatedSlots(
          entities,
          unallocatedEntitiesNum
        );
      } else if (actualEntitiesNum > maxEntitiesNum) {
        const entitiesToDisableNum = actualEntitiesNum - maxEntitiesNum;
        entities = this._getEntitiesHandledForDisabledItems(
          entities,
          entitiesToDisableNum
        );
      }

      entities.sort(defaultSort);

      this.setState({
        originalEntities: deepCopy(entities),
        tableEntities: deepCopy(entities),
      });
    }
  }

  _getEntitiesHandledForUnallocatedSlots(entities, unallocatedEntitiesNum) {
    const entitiesCopy = deepCopy(entities);
    for (let i = 0; i < unallocatedEntitiesNum; i++) {
      entitiesCopy.push({
        id: null,
        label: "",
        isActive: false,
        activatedAt: null,
      });
    }
    return entitiesCopy;
  }

  _getEntitiesHandledForDisabledItems = (entities, entitiesToDisableNum) => {
    const entitiesCopy = deepCopy(entities);
    const entitiesSortedByPriorityToDisable = entitiesCopy.sort(
      sortByPriorityToDisable
    );

    const disabledEntitiesIDs = new Set();
    for (let i = 0; i < entitiesToDisableNum; i++) {
      disabledEntitiesIDs.add(entitiesSortedByPriorityToDisable[i].id);
    }

    // entitiesHandledForDisabledItems
    return entities.map((entity) => {
      if (disabledEntitiesIDs.has(entity.id)) {
        entity.isDisabled = true;
        entity.isActive = false;
        entity.activatedAt = null;
      } else {
        entity.isDisabled = false;
      }
      return entity;
    });
  };

  componentWillUnmount() {
    this.props.clearSubscriptionDetail();
  }

  //////////////
  // HANDLERS //
  //////////////

  handleLabelChange = (changedEntityIndex, newLabel) => {
    const changedEntity = {
      ...this.state.tableEntities[changedEntityIndex],
      label: newLabel,
    };
    this._updateStateWithEntity(changedEntity, changedEntityIndex);
  };

  handleIsActiveChange = (changedEntityIndex, newIsActiveValue) => {
    const changedEntity = {
      ...this.state.tableEntities[changedEntityIndex],
      isActive: newIsActiveValue,
    };
    this._updateStateWithEntity(changedEntity, changedEntityIndex);
  };

  _updateStateWithEntity = (updatedEntity, updatedEntityIndex) => {
    this.setState({
      tableEntities: this.state.tableEntities.map((currentEntity, index) => {
        return index === updatedEntityIndex ? updatedEntity : currentEntity;
      }),
    });
  };

  activateAllEntities = (action = true) => {
    let numAvailableEntities = 0;
    let numActiveEntities = 0;

    const updatedTableEntities = this.state.tableEntities.map((entity) => {
      const entityCopy = deepCopy(entity);
      if (!entityCopy.isDisabled && entityCopy.isActive === !action) {
        entityCopy.isActive = action;
      }
      if (!entityCopy.isDisabled) {
        numAvailableEntities++;
      }
      if (!entityCopy.isDisabled && entityCopy.isActive) {
        numActiveEntities++;
      }
      return entityCopy;
    });

    this.setState({
      allEntitiesActive: numActiveEntities === numAvailableEntities,
    });
    this.setState({ tableEntities: updatedTableEntities });
  };

  saveEntities = () => {
    const {
      addMessage,
      history,
      match: {
        params: { subscriptionId },
      },
      startSubscriptionDetail,
      subscriptionDetail: { subscription },
    } = this.props;
    const entitiesToSave = this.state.tableEntities.map(this._getEntityToSave);
    const url = `/api/core/subscriptions/${subscriptionId}/update_or_save_subscription_entities/`;
    this.props.updateSpinner(true);

    axios
      .post(url, entitiesToSave)
      .then(() => {
        this._addSavedEntitiesMsg();

        if (entitiesToSave.find((item) => item.is_active === true)) {
          const data = {};
          if (subscription.status !== SUBSCRIPTION_STATUS_LIVE_VALUE) {
            data["status"] = SUBSCRIPTION_STATUS_LIVE_VALUE;
            data["subscription_start"] = getTodayDate();
          }
          startSubscriptionDetail(subscriptionId, data).then((err) => {
            if (err) {
              throw err;
            }
          });
        }
      })
      .catch(() => {
        addMessage(
          "Server is temporarily unavailable, please try again later."
        );
        this.props.updateSpinner(false);
      })
      .finally(() => {
        this.props.updateSpinner(false);
        history.push(`/admin/customers/${subscriptionId}`);
      });
  };

  _getEntityToSave = (entity, index) => {
    const originalEntity = this.state.originalEntities[index];

    let activated_at;
    if (originalEntity.isActive === entity.isActive) {
      activated_at = originalEntity.activatedAt;
    } else {
      activated_at = entity.isActive === true ? getTodayDate() : null;
    }

    const entityToSave = {
      label: entity.label,
      is_active: entity.isActive,
      activated_at: activated_at,
    };
    if (entity.id > 0) {
      entityToSave.id = entity.id;
    }
    return entityToSave;
  };

  _addSavedEntitiesMsg = () => {
    const { addMessage } = this.props;

    let activatedEntities = 0;
    let deactivatedEntities = 0;

    this.state.originalEntities.forEach((entity, idx) => {
      if (!entity.isActive && this.state.tableEntities[idx].isActive)
        activatedEntities += 1;
      else if (entity.isActive && !this.state.tableEntities[idx].isActive)
        deactivatedEntities += 1;
    });

    let message = "Your subscription has been updated. ";
    if (activatedEntities === 1)
      message += `${activatedEntities} entity was added. `;
    else if (activatedEntities > 1)
      message += `${activatedEntities} entities were added. `;
    if (deactivatedEntities === 1)
      message += `${deactivatedEntities} entity was removed. `;
    else if (deactivatedEntities > 1)
      message += `${deactivatedEntities} entities were removed. `;

    addMessage(message);
  };

  ////////////
  // RENDER //
  ////////////

  render() {
    const {
      match: {
        params: { subscriptionId },
      },
      subscriptionDetail: { subscription },
    } = this.props;

    let subscriptionLabel;
    if (subscription.fleet.name) {
      const subscriptionStatus = !subscription.subscriptionStart
        ? "(Pending)"
        : "";
      subscriptionLabel = `${subscription.fleet.name} ${subscriptionStatus}`;
    } else {
      subscriptionLabel = "...";
    }

    const allEntitiesNum =
      this.props.subscriptionDetail.subscription.quantity || 0;
    const disabledEntitiesNum =
      this.state.tableEntities.length - allEntitiesNum || 0;

    const originalActiveEntitiesNum = countActiveEntities(
      this.state.originalEntities
    );
    return (
      <>
        <MemoizedBreadcrumbs
          subscriptionId={subscriptionId}
          subscriptionLabel={subscriptionLabel}
        />
        <div className="content customer-list-page">
          <MemoizedPageHeader
            solutionUnit={subscription.solution.unit || "default"}
            activeEntitiesNum={originalActiveEntitiesNum}
            allEntitiesNum={allEntitiesNum}
            activateAllEntities={this.activateAllEntities}
            account={this.props.account}
            allEntitiesActive={this.state.allEntitiesActive}
          />
          <Card className="custom-react-table" style={{ padding: 0 }}>
            <CardBody
              style={{
                paddingLeft: "40px",
                paddingRight: "40px",
                paddingTop: "40px",
              }}
            >
              <Table
                entities={this.state.tableEntities}
                handleLabelChange={this.handleLabelChange}
                handleIsActiveChange={this.handleIsActiveChange}
                account={this.props.account}
                history={this.props.history}
              />
              <WarningMessages disabledEntitiesNum={disabledEntitiesNum} />
              <MemoizedSaveAndCancelButtons
                history={this.props.history}
                subscriptionId={subscriptionId}
                saveEntities={this.saveEntities}
                account={this.props.account}
              />
            </CardBody>
          </Card>
        </div>
      </>
    );
  }
}

/////////////////
// BREADCRUMBS //
/////////////////

const breadcrumbsPropTypes = {
  subscriptionId: PropTypes.string.isRequired,
  subscriptionLabel: PropTypes.string.isRequired,
};

function Breadcrumbs(props) {
  return (
    <div className="breadcrumb-nav">
      <Row>
        <Col>
          <ol className="breadcrumb-container">
            <li className="breadcrumb-container__item">
              <h5>
                <Link to="/admin/customers">Subscriptions</Link>
              </h5>
            </li>
            <li className="breadcrumb-container__item">
              <h5>/</h5>
            </li>
            <li className="breadcrumb-container__item">
              <h5>
                <Link to={`/admin/customers/${props.subscriptionId}`}>
                  {props.subscriptionLabel}
                </Link>
              </h5>
            </li>
          </ol>
        </Col>
      </Row>
    </div>
  );
}

Breadcrumbs.propTypes = breadcrumbsPropTypes;
const MemoizedBreadcrumbs = React.memo(Breadcrumbs);

/////////////////
// PAGE HEADER //
/////////////////

const pageHeaderPropTypes = {
  solutionUnit: PropTypes.string.isRequired,
  activeEntitiesNum: PropTypes.number.isRequired,
  allEntitiesNum: PropTypes.number.isRequired,
  activateAllEntities: PropTypes.func.isRequired,
  account: PropTypes.object.isRequired,
};

const verboseSolutionUnits = {
  default: "Entities",
  D: "Drivers",
  V: "Vehicles",
  A: "Assets",
};

function PageHeader(props) {
  return (
    <div className="page-content-header">
      <Row className="mb-2">
        <Col>
          <h3>Manage Entities</h3>
          <p>
            You can manage labels and activation status below. Units that are
            de-activated will remain active until the next billing period.
          </p>
        </Col>
      </Row>
      <Row>
        <Col className="align-self-center">
          <p className="font-weight-bold my-0">
            {verboseSolutionUnits[props.solutionUnit]} (
            {props.activeEntitiesNum} of {props.allEntitiesNum} active)
          </p>
        </Col>
        <Col style={{ display: "flex" }}>
          {props.account.permissions.includes("write:customers") && (
            <Button
              style={{
                marginTop: "auto",
                marginBottom: "auto",
                marginLeft: "auto",
                marginRight: 0,
              }}
              color="outline-primary"
              onClick={() =>
                props.activateAllEntities(!props.allEntitiesActive)
              }
            >
              {props.allEntitiesActive ? "Deactivate all" : "Activate all"}
            </Button>
          )}
        </Col>
      </Row>
    </div>
  );
}

PageHeader.propTypes = pageHeaderPropTypes;
const MemoizedPageHeader = React.memo(PageHeader);

///////////
// TABLE //
///////////

const tablePropTypes = {
  entities: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleLabelChange: PropTypes.func.isRequired,
  handleIsActiveChange: PropTypes.func.isRequired,
};

class Table extends React.Component {
  render() {
    return (
      <fieldset disabled={this.props.account.mode === "Host"}>
        <CustomerEntitiesTable
          data={this.props.entities}
          handleLabelChange={this.props.handleLabelChange}
          handleIsActiveChange={this.props.handleIsActiveChange}
          history={this.props.history}
        />
      </fieldset>
    );
  }
}

Table.propTypes = tablePropTypes;

/////////////////
// WARNING MSG //
/////////////////

const warningMessagesPropTypes = {
  disabledEntitiesNum: PropTypes.number.isRequired,
};

function WarningMessages(props) {
  let disabledEntitiesWarningMsg = null;

  if (props.disabledEntitiesNum === 1) {
    disabledEntitiesWarningMsg = (
      <p className={"mb-0"}>There is 1 disabled entity.</p>
    );
  } else if (props.disabledEntitiesNum >= 2) {
    disabledEntitiesWarningMsg = (
      <p className={"mb-0"}>
        There are {props.disabledEntitiesNum} disabled entities.
      </p>
    );
  }

  if (!disabledEntitiesWarningMsg) {
    return null;
  }

  return (
    <Row style={{ color: "#EF8157" }}>
      <Col>
        <div className={"mt-2 text-right"}>{disabledEntitiesWarningMsg}</div>
      </Col>
    </Row>
  );
}

WarningMessages.propTypes = warningMessagesPropTypes;

////////////////////////////
// SAVE-AND-CANCEL BUTTON //
////////////////////////////

const saveAndCancelButtonsPropTypes = {
  history: PropTypes.object.isRequired,
  subscriptionId: PropTypes.string.isRequired,
  saveEntities: PropTypes.func.isRequired,
  account: PropTypes.object.isRequired,
};

function SaveAndCancelButtons(props) {
  const [double, setDouble] = useState(false);
  return (
    <Row>
      <Col>
        <div className="d-flex justify-content-end">
          <Button
            color="outline-primary"
            onClick={() => {
              props.history.push(`/admin/customers/${props.subscriptionId}`);
            }}
          >
            Cancel
          </Button>
          {props.account.permissions.includes("write:customers") && (
            <Button
              disabled={double}
              color="primary"
              onClick={() => {
                setDouble(true);
                props.saveEntities();
              }}
            >
              Save
            </Button>
          )}
        </div>
      </Col>
    </Row>
  );
}

SaveAndCancelButtons.propTypes = saveAndCancelButtonsPropTypes;
const MemoizedSaveAndCancelButtons = React.memo(SaveAndCancelButtons);

/////////////
// HELPERS //
/////////////

function countActiveEntities(entities) {
  return entities.reduce((acc, entity) => {
    return acc + (entity.isActive ? 1 : 0);
  }, 0);
}

function getTodayDate() {
  return new Date();
}

function deepCopy(object) {
  return JSON.parse(JSON.stringify(object));
}

/////////////
// SORTERS //
/////////////

function defaultSort(a, b) {
  if (a.isActive !== b.isActive) {
    return _sortByActiveFirst(a, b);
  } else if (a.isDisabled !== b.isDisabled) {
    return _sortByDisabledLast(a, b);
  } else if (a.label === "" || b.label === "") {
    return _sortByEmptyLabelLast(a, b);
  } else {
    return _sortByLabel(a, b);
  }
}

function sortByPriorityToDisable(a, b) {
  if (a.isActive !== b.isActive) {
    return _sortByActiveLast(a, b);
  } else if (a.label === "" || b.label === "") {
    return _sortByEmptyLabelFirst(a, b);
  } else if (a.activatedAt !== b.activatedAt) {
    return _sortByActivationDate(a, b);
  } else {
    return _sortByLabelDesc(a, b);
  }
}

function _sortByActivationDate(a, b) {
  if (a.activatedAt > b.activatedAt) {
    return 1;
  } else if (a.activatedAt < b.activatedAt) {
    return -1;
  } else {
    return 0;
  }
}

function _sortByActiveFirst(a, b) {
  if (!a.isActive && b.isActive) {
    return 1;
  } else if (a.isActive && !b.isActive) {
    return -1;
  } else {
    return 0;
  }
}

function _sortByActiveLast(a, b) {
  if (a.isActive && !b.isActive) {
    return 1;
  } else if (!a.isActive && b.isActive) {
    return -1;
  } else {
    return 0;
  }
}

function _sortByEmptyLabelFirst(a, b) {
  if (a.label !== "" && b.label === "") {
    return 1;
  } else if (a.label === "" && b.label !== "") {
    return -1;
  } else {
    return 0;
  }
}

function _sortByEmptyLabelLast(a, b) {
  if (a.label === "" && b.label !== "") {
    return 1;
  } else if (a.label !== "" && b.label === "") {
    return -1;
  } else {
    return 0;
  }
}

function _sortByLabel(a, b) {
  if (a.label > b.label) {
    return 1;
  } else if (a.label < b.label) {
    return -1;
  } else {
    return 0;
  }
}

function _sortByLabelDesc(a, b) {
  if (a.label < b.label) {
    return 1;
  } else if (a.label > b.label) {
    return -1;
  } else {
    return 0;
  }
}

function _sortByDisabledLast(a, b) {
  if (a.isDisabled > b.isDisabled) {
    return 1;
  } else if (a.isDisabled < b.isDisabled) {
    return -1;
  } else {
    return 0;
  }
}

/////////////
// EXPORTS //
/////////////

export default connect(mapStateToProps, mapDispatchToProps)(CustomerEntities);

// Exports for easy testing
export {
  Breadcrumbs,
  PageHeader,
  SaveAndCancelButtons,
  Table,
  WarningMessages,
};
