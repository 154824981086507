import camelcaseKeys from 'camelcase-keys';
import {
    SOLUTION_VERSION_DETAIL_FETCH_ERROR,
    SOLUTION_VERSION_DETAIL_FETCH_SUCCESS,
    SOLUTION_VERSION_DETAIL_FETCH_REQUEST,
    SOLUTION_VERSION_DETAIL_CLEAR,
} from "../actions/actionTypes";

const initialState = {
    solutionVersion: null,
    isLoading: false,
    error: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SOLUTION_VERSION_DETAIL_FETCH_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: action.error,
            };

        case SOLUTION_VERSION_DETAIL_FETCH_SUCCESS:
            const data = camelcaseKeys(action.data, {deep: true});
            return {
                ...state,
                solutionVersion: data,
                isLoading: false,
                error: action.error
            };

        case SOLUTION_VERSION_DETAIL_FETCH_ERROR:
            return {
                ...state,
                solutionVersion: null,
                isLoading: false,
                error: action.error,
            };

        case SOLUTION_VERSION_DETAIL_CLEAR:
            return {
                solutionVersion: null,
                isLoading: false,
                error: null
            };

        default:
            return state;
    }
};
