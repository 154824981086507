import React from 'react';
import { Switch, Route } from 'react-router-dom';
import BillingList from './BillingList';

const Billing = () => {
  return (
    <Switch>
      <Route key="1" path="/admin/billing" exact component={BillingList} />
    </Switch>
  );
};

export default Billing;
