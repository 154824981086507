import axios from "axios";
import {
  MARKETPLACE_RESOURCE_LIST_FETCH_REQUEST,
  MARKETPLACE_RESOURCE_LIST_FETCH_SUCCESS,
  MARKETPLACE_RESOURCE_LIST_FETCH_ERROR,
  MARKETPLACE_RESOURCE_CHANGE_ORDER_ERROR,
  MARKETPLACE_RESOURCE_CHANGE_ORDER_SUCCESS,
  MARKETPLACE_RESOURCE_CHANGE_ORDER_REQUEST,
  MARKETPLACE_RESOURCE_STATIC_FILES_FETCH_REQUEST,
  MARKETPLACE_RESOURCE_STATIC_FILES_LIST_FETCH_SUCCESS,
  MARKETPLACE_RESOURCE_STATIC_FILES_LIST_FETCH_ERROR,
  LEAD_ADD_ERROR,
} from "./actionTypes";
import { updateSpinner } from "./spinnerActions";
import snakeCaseKeys from "snakecase-keys";

export const fetchMarketplaceResourceList =
  (marketplace, limit = null) =>
  (dispatch, state) => {
    dispatch(updateSpinner(true));
    dispatch({ type: MARKETPLACE_RESOURCE_LIST_FETCH_REQUEST, request: true });
    let url = `/api/marketplace/marketplace_resources/`;

    const { mode } = state().account;
    url += `?mode=${mode}`;

    if (marketplace) url += `&marketplace=${marketplace}`;
    if (limit) url += `&limit=${limit}`;

    axios
      .get(url)
      .then(({ data }) => {
        if (data) {
          dispatch({ type: MARKETPLACE_RESOURCE_LIST_FETCH_SUCCESS, data });
        } else {
          dispatch({
            type: MARKETPLACE_RESOURCE_LIST_FETCH_ERROR,
            error: { mes: "" },
          });
        }
        dispatch(updateSpinner(false));
      })
      .catch((error) => {
        dispatch({ type: MARKETPLACE_RESOURCE_LIST_FETCH_ERROR, error });
        dispatch(updateSpinner(false));
      });
  };

export const changeMarketplaceResourceOrder =
  (filters) => (dispatch, state) => {
    dispatch(updateSpinner(true));
    dispatch({
      type: MARKETPLACE_RESOURCE_CHANGE_ORDER_REQUEST,
      request: true,
    });
    let url = `/api/marketplace/marketplace_resources/change_order/`;
    const { mode } = state().account;
    filters.mode = mode;

    axios
      .get(url, {
        params: {
          ...snakeCaseKeys(filters),
        },
      })
      .then(({ data }) => {
        if (data) {
          // dispatch({ type: MARKETPLACE_RESOURCE_CHANGE_ORDER_SUCCESS, data });
        } else {
          dispatch({
            type: MARKETPLACE_RESOURCE_CHANGE_ORDER_ERROR,
            error: { mes: "" },
          });
        }
        dispatch(updateSpinner(false));
      })
      .catch((error) => {
        dispatch({ type: MARKETPLACE_RESOURCE_CHANGE_ORDER_ERROR, error });
        dispatch(updateSpinner(false));
      });
  };

export const fetchMarketplaceResourceStaticFiles =
  (marketplace, prefix) => async (dispatch, state) => {
    dispatch(updateSpinner(true));
    dispatch({
      type: MARKETPLACE_RESOURCE_STATIC_FILES_FETCH_REQUEST,
      request: true,
    });
    let url = `/api/marketplace/marketplace_resources/static_files/`;

    const { mode } = state().account;
    url += `?mode=${mode}`;

    const params = {};
    params.prefix = prefix;

    if (marketplace) {
      params.marketplace = marketplace;
    }
    try {
      const res = await axios.get(url, { params });
      if (res.data) {
        dispatch({
          type: MARKETPLACE_RESOURCE_STATIC_FILES_LIST_FETCH_SUCCESS,
          data: res.data,
        });
        dispatch(updateSpinner(false));
        return res.data;
      } else {
        dispatch({
          type: MARKETPLACE_RESOURCE_STATIC_FILES_LIST_FETCH_ERROR,
          error: { mes: "" },
        });
        dispatch(updateSpinner(false));
      }
    } catch (error) {
      dispatch({
        type: MARKETPLACE_RESOURCE_STATIC_FILES_LIST_FETCH_ERROR,
        error,
      });
      dispatch(updateSpinner(false));
      throw error
    }
  };
