/* eslint-disable linebreak-style */
import axios from 'axios';
import {
  FLEET_ADD_REQUEST,
  FLEET_ADD_SUCCESS,
  FLEET_ADD_ERROR,
  FLEET_UPDATE_REQUEST,
  FLEET_UPDATE_SUCCESS,
  FLEET_UPDATE_ERROR,
  FLEET_CLEAR
} from './actionTypes';
import { updateSpinner } from "./spinnerActions";


// It's not used and it's not work properly
// export const addUpdateFleetDetail = (data) => (dispatch) => {
//   dispatch(updateSpinner(true));
//   dispatch({ type: CUSTOMER_ADD_UPDATE_REQUEST , request: true});
//   let url = '/api/core/fleets/create_or_update/';
//   return axios.post(url, data)
//   .then(({ data }) => {
//     if (data) {
//       dispatch({ type: CUSTOMER_ADD_UPDATE_SUCCESS, data });
//     } else {
//       dispatch({ type: CUSTOMER_ADD_UPDATE_ERROR, error: { mes: "" }});
//     }
//     dispatch(updateSpinner(false));
//     return data;
//   })
//   .catch((error) => {
//     dispatch({ type: CUSTOMER_ADD_UPDATE_ERROR, error });
//     dispatch(updateSpinner(false));
//   });
// };

export const addFleetDetail = (data) => async (dispatch) => {
  dispatch(updateSpinner(true));
  dispatch({ type: FLEET_ADD_REQUEST , request: true});
  let url = '/api/core/fleets/';
  try {
    const res = await axios.post(url, data);
    dispatch({ type: FLEET_ADD_SUCCESS, data: res.data });
    dispatch(updateSpinner(false));
    return {
      data: res.data,
      err: null
    };
  } catch (error) {
    dispatch({ type: FLEET_ADD_ERROR, error });
    dispatch(updateSpinner(false));
    return {
      data: null,
      err: error
    };
  }
};

export const updateFleetDetail = (fleetId, data) => async (dispatch) => {
  dispatch(updateSpinner(true));
  dispatch({ type: FLEET_UPDATE_REQUEST , request: true});
  let url = `/api/core/fleets/${fleetId}/`;
  try {
    const res = await axios.put(url, data);
    dispatch({ type: FLEET_UPDATE_SUCCESS, data: res.data });
    dispatch(updateSpinner(false));
  } catch (error) {
    dispatch(updateSpinner(false));
    dispatch({ type: FLEET_UPDATE_ERROR, error });
    return error;
  }
};

export const saveFleetDetail = (data) => async (dispatch) => {
  dispatch({ type: FLEET_ADD_SUCCESS, data });
};

export const clearFleetDetail = () => (dispatch) => {
  dispatch({ type: FLEET_CLEAR });
};
