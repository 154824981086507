import React from 'react';
import {FormGroup, Input, Label} from 'reactstrap';

const CheckboxGroup = ({label, required, name, options, input, meta}) => (
  <FormGroup>
    {options.map((option, index) => (
      <FormGroup check inline key={index}>
        <Label check>
          <Input type="checkbox"
             name={`${name}[${index}]`}
             value={option.name}
             checked={input.value.indexOf(option.name) !== -1}
             onChange={event => {
               const newValue = [...input.value];
               if (event.target.checked) {
                 newValue.push(option.name);
               } else {
                 newValue.splice(newValue.indexOf(option.name), 1);
               }

               return input.onChange(newValue);
             }}/>
          <span className={"form-check-sign"} />
          <p style={{color: "#252422", fontSize: "14px"}}>
            {option.value}
          </p>
        </Label>
      </FormGroup>))
    }
  </FormGroup>
);

export default CheckboxGroup;
