export const M_RESOURCE_CATEGORY_EVENTS_VALUE = 'EVENTS';
export const M_RESOURCE_CATEGORY_EVENTS_LABEL = 'Events';

export const M_RESOURCE_CATEGORY_REPORTS_VALUE = 'REPORTS';
export const M_RESOURCE_CATEGORY_REPORTS_LABEL = 'Reports';

export const M_RESOURCE_CATEGORY_STUDIES_VALUE = 'STUDIES';
export const M_RESOURCE_CATEGORY_STUDIES_LABEL = 'Studies';

export const M_RESOURCE_CATEGORY_WHITEPAPERS_VALUE = 'WHITEPAPERS';
export const M_RESOURCE_CATEGORY_WHITEPAPERS_LABEL = 'Whitepapers';

export const M_RESOURCE_CATEGORY_VIDEOS_VALUE = 'VIDEOS';
export const M_RESOURCE_CATEGORY_VIDEOS_LABEL = 'Videos';

export const M_RESOURCE_CATEGORY_E_BOOKS_VALUE = 'E_BOOKS';
export const M_RESOURCE_CATEGORY_E_BOOKS_LABEL = 'E-books';


export const M_RESOURCE_CATEGORY_OPTIONS = {
  [M_RESOURCE_CATEGORY_EVENTS_VALUE]: M_RESOURCE_CATEGORY_EVENTS_LABEL,
  [M_RESOURCE_CATEGORY_REPORTS_VALUE]: M_RESOURCE_CATEGORY_REPORTS_LABEL,
  [M_RESOURCE_CATEGORY_STUDIES_VALUE]: M_RESOURCE_CATEGORY_STUDIES_LABEL,
  [M_RESOURCE_CATEGORY_WHITEPAPERS_VALUE]: M_RESOURCE_CATEGORY_WHITEPAPERS_LABEL,
  [M_RESOURCE_CATEGORY_VIDEOS_VALUE]: M_RESOURCE_CATEGORY_VIDEOS_LABEL,
  [M_RESOURCE_CATEGORY_E_BOOKS_VALUE]: M_RESOURCE_CATEGORY_E_BOOKS_LABEL,
};
