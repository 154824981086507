import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useDropzone } from "react-dropzone";
import { Button } from "reactstrap";

const DropzoneImageUpload = ({ image, setImage, setImageError }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      setImage(null);
    };
  }, [dispatch]);

  const { getRootProps: getRootPropsImage, getInputProps: getInputPropsImage } =
    useDropzone({
      // accept: "image/*",
      onDrop: (acceptedFiles) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          setImage(reader.result);
          setImageError(null);
        };
        reader.readAsDataURL(acceptedFiles[0]);
      },
    });

  return (
    <div className="d-flex">
      <div {...getRootPropsImage({ className: "dropzone" })}>
        <input {...getInputPropsImage()} />

        {image && <img style={{ maxWidth: 250 }} src={image} alt="Picture" />}

        {!image && (
          <Button
            className="has-danger"
            color="outline-primary"
            onClick={(e) => e.preventDefault()}
          >
            Add Image
          </Button>
        )}
      </div>

      {image && (
        <div
          onClick={() => {
            setImage(null);
          }}
        >
          <i className="fa fa-remove" />
        </div>
      )}
    </div>
  );
};

export default DropzoneImageUpload;
