/* eslint-disable linebreak-style */
import axios from 'axios';
import {
  SOLUTION_LIST_FETCH_ERROR,
  SOLUTION_LIST_FETCH_REQUEST,
  SOLUTION_LIST_FETCH_SUCCESS
} from './actionTypes';
import {updateSpinner} from "./spinnerActions";


export const fetchSolutionList = (limit) => (dispatch, state) => {
  dispatch(updateSpinner(true));
  dispatch({ type: SOLUTION_LIST_FETCH_REQUEST , request: true});
  let url = `/api/solution/solutions/`;

  const { mode } = state().account;
  url += `?mode=${mode}`;

  if (limit) url += `&limit=${limit}`;

  axios.get(url)
  .then(({ data }) => {
    if (data) {
      dispatch({ type: SOLUTION_LIST_FETCH_SUCCESS, data });
    } else {
      dispatch({ type: SOLUTION_LIST_FETCH_ERROR, error: { mes: "" }});
    }
    dispatch(updateSpinner(false));
  })
  .catch((error) => {
    dispatch({ type: SOLUTION_LIST_FETCH_ERROR, error });
    dispatch(updateSpinner(false));
  });
};

export const validateSolutionFields = async (fields) => {
  let url = `/api/solution/solutions/validate_fields/`;
  try {
    return await axios.post(url, fields);
  } catch (error) {
  }
};
