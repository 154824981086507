import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm, formValueSelector } from "redux-form";

import {Col, FormGroup, Label, Row, UncontrolledTooltip} from "reactstrap";
import {nonNegativeNumbers, renderCheckboxField, renderInputField, renderSelectField} from "../../forms/utils";
import {
  maxNumber100Validation,
  maxLengthValidation120,
  maxNumber100000Validation,
  minLengthValidation5,
  notZeroValidation,
  positiveNumberValidation,
  requiredValidation,
  integerValidation
} from "../../forms/validation";
import HelpIcon from "../../../assets/icons/help-icon.svg";
import {
  SOLUTION_DISCOUNT_ENTITY_TYPE_NON_MONETARY_LABEL,
  SOLUTION_DISCOUNT_ENTITY_TYPE_NON_MONETARY_VALUE,
  SOLUTION_DISCOUNT_ENTITY_TYPE_OTC1_VALUE,
  SOLUTION_DISCOUNT_ENTITY_TYPE_OTC2_VALUE,
  SOLUTION_DISCOUNT_ENTITY_TYPE_SUBSCRIPTION_LABEL,
  SOLUTION_DISCOUNT_ENTITY_TYPE_SUBSCRIPTION_VALUE,
  SOLUTION_DISCOUNT_PRICE_TYPE_FIXED_AMOUNT_VALUE,
  SOLUTION_DISCOUNT_PRICE_TYPE_PERCENTAGE_VALUE
} from "../../../config/solutionDiscount";

class SolutionDiscountForm extends React.Component {
  componentDidMount() {
    const {discount} = this.props;

    if (discount.name) {
      this.props.initialize({
        ...discount,
        discountPriceType: discount.discountPriceType ? discount.discountPriceType : SOLUTION_DISCOUNT_PRICE_TYPE_PERCENTAGE_VALUE,
        entityType: discount.entityType ? discount.entityType : SOLUTION_DISCOUNT_ENTITY_TYPE_SUBSCRIPTION_VALUE,
      });
    } else {
      this.props.initialize(
        {
          discountPriceType: SOLUTION_DISCOUNT_PRICE_TYPE_PERCENTAGE_VALUE,
          entityType: SOLUTION_DISCOUNT_ENTITY_TYPE_SUBSCRIPTION_VALUE
        }
      );
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {discount} = this.props;
    if (prevProps.discount.name !== discount.name) {
      if (this.props.isEditing) {
        this.props.initialize({
          ...this.props.discount,
          discountPriceType: discount.discountPriceType ? discount.discountPriceType : SOLUTION_DISCOUNT_PRICE_TYPE_PERCENTAGE_VALUE,
          entityType: discount.entityType ? discount.entityType : SOLUTION_DISCOUNT_ENTITY_TYPE_SUBSCRIPTION_VALUE
        });
      }
    }
  }

  render() {
    const {handleSubmit, solutionListing, entityType, discountPriceType, settings} = this.props;
    const solutionListingFormValues = this.props.SolutionListing.values;
    const {currency} = solutionListingFormValues;

    const firstOneTimeSubDescriptionExists = solutionListing && solutionListing.firstOneTimeSubDescription
        ? solutionListing.firstOneTimeSubDescription
        : solutionListingFormValues.firstOneTimeSubDescription
            ? solutionListingFormValues.firstOneTimeSubDescription
            : false

    const secondOneTimeSubDescriptionExists = solutionListing && solutionListing.secondOneTimeSubDescription
        ? solutionListing.secondOneTimeSubDescription
        : solutionListingFormValues.secondOneTimeSubDescription
            ? solutionListingFormValues.secondOneTimeSubDescription
            : false

    return (
      <form onSubmit={handleSubmit}>
        <Row className="mt-3">
          <Col>
            <h5 className="font-weight-bold">
              Product
            </h5>
            <div className="align-items-start d-flex">
              <FormGroup inline className="ml-0">
                <Field
                  type="select"
                  name="entityType"
                  component={renderSelectField}
                  onChange={
                    (e) => {
                      if (e.target.value === SOLUTION_DISCOUNT_ENTITY_TYPE_NON_MONETARY_VALUE) {
                        this.props.change("amount", null);
                        this.props.change("percentage", null);
                      }
                    }
                  }
                >
                  <option value={SOLUTION_DISCOUNT_ENTITY_TYPE_SUBSCRIPTION_VALUE}>{SOLUTION_DISCOUNT_ENTITY_TYPE_SUBSCRIPTION_LABEL}</option>

                  {firstOneTimeSubDescriptionExists &&
                    <option value={SOLUTION_DISCOUNT_ENTITY_TYPE_OTC1_VALUE}>
                      {solutionListing && solutionListing.firstOneTimeSubDescription
                        ? solutionListing.firstOneTimeSubDescription
                        : solutionListingFormValues.firstOneTimeSubDescription
                          ? solutionListingFormValues.firstOneTimeSubDescription
                          : "First one-time charge"
                      }
                    </option>
                  }

                  {secondOneTimeSubDescriptionExists &&
                    <option value={SOLUTION_DISCOUNT_ENTITY_TYPE_OTC2_VALUE}>
                      {solutionListing && solutionListing.secondOneTimeSubDescription
                        ? solutionListing.secondOneTimeSubDescription
                        : solutionListingFormValues.secondOneTimeSubDescription
                          ? solutionListingFormValues.secondOneTimeSubDescription
                          : "Second one-time charge"
                      }
                    </option>
                  }

                  <option value={SOLUTION_DISCOUNT_ENTITY_TYPE_NON_MONETARY_VALUE}>
                    {SOLUTION_DISCOUNT_ENTITY_TYPE_NON_MONETARY_LABEL}
                  </option>
                </Field>
              </FormGroup>
            </div>
          </Col>
        </Row>
        <Row className="mt-1">
          <Col>
            <FormGroup>
              <label>
                <h5 className="font-weight-bold mb-2">
                  Discount Description
                  <img alt="" src={HelpIcon} className="help-icon" id="discount-name-tooltip" />
                  <UncontrolledTooltip placement="right" target="discount-name-tooltip">
                    <p style={{textAlign: 'left'}} className="m-0">
                      This will appear on customers' invoices.
                    </p>
                  </UncontrolledTooltip>
                </h5>
              </label>
              <Field
                name="name"
                type="text"
                component={renderInputField}
                placeholder="Enter Discount Description"
                validate={[requiredValidation, maxLengthValidation120, minLengthValidation5]}
              />
            </FormGroup>
          </Col>
        </Row>

        {entityType !== SOLUTION_DISCOUNT_ENTITY_TYPE_NON_MONETARY_VALUE &&
          <Row className="mt-1">
            <Col>
              <label>
                <h5 className="font-weight-bold mb-2">
                  Type
                </h5>
              </label>
              <div className="form-check-radio">
                <Label check>
                  <Field
                    type="radio"
                    name="discountPriceType"
                    id="discountPriceTypePercentage"
                    component={renderCheckboxField}
                    value={SOLUTION_DISCOUNT_PRICE_TYPE_PERCENTAGE_VALUE}
                    onChange={
                      () => {
                        this.props.change("amount", null);
                      }
                    }
                  />
                  <span className="custom-radio-label">Percentage discount</span>
                  <span className="form-check-sign" />
                </Label>
              </div>
              <div className="form-check-radio">
                <Label check>
                  <Field
                    type="radio"
                    name="discountPriceType"
                    id="discountPriceTypeFixed"
                    component={renderCheckboxField}
                    value={SOLUTION_DISCOUNT_PRICE_TYPE_FIXED_AMOUNT_VALUE}
                    onChange={
                      () => {
                        this.props.change("percentage", null);
                      }
                    }
                  />
                  <span className="custom-radio-label">Fixed amount discount</span>
                  <span className="form-check-sign" />
                </Label>
              </div>
            </Col>
          </Row>
        }
        {discountPriceType === SOLUTION_DISCOUNT_PRICE_TYPE_PERCENTAGE_VALUE && entityType !== SOLUTION_DISCOUNT_ENTITY_TYPE_NON_MONETARY_VALUE &&
          <Row className="mt-3">
            <Col md={6}>
              <FormGroup inline>
                <label>
                  <h5 className="font-weight-bold mb-2">
                    Percentage off
                  </h5>
                </label>
                <div className="align-items-center d-flex">
                  <Field
                    name="percentage"
                    type="number"
                    normalize={nonNegativeNumbers}
                    component={renderInputField}
                    validate={[requiredValidation, integerValidation, positiveNumberValidation, notZeroValidation, maxNumber100Validation]}
                  />
                  <div className="ml-2">%</div>
                </div>
              </FormGroup>
            </Col>
          </Row>
        }

        {discountPriceType === SOLUTION_DISCOUNT_PRICE_TYPE_FIXED_AMOUNT_VALUE && entityType !== SOLUTION_DISCOUNT_ENTITY_TYPE_NON_MONETARY_VALUE &&
          <Row className="mt-3">
            <Col md={6}>
              <FormGroup inline>
                <label>
                  <h5 className="font-weight-bold mb-2">
                    Discount amount
                  </h5>
                </label>
                <div className="align-items-center d-flex">
                  <div className="mr-2">{currency && settings.currencies[currency].symbol}</div>
                  <Field
                    name="amount"
                    type="number"
                    normalize={nonNegativeNumbers}
                    component={renderInputField}
                    validate={[requiredValidation, positiveNumberValidation, notZeroValidation, maxNumber100000Validation]}
                  />
                </div>
              </FormGroup>
            </Col>
          </Row>
        }

        {entityType === SOLUTION_DISCOUNT_ENTITY_TYPE_SUBSCRIPTION_VALUE &&
          <Row>
            <Col md={6}>
              <FormGroup check>

                  <h5 className="font-weight-bold mb-3">
                    View
                  </h5>

                <Label check>
                  <Field
                    type="checkbox"
                    name="isPrimary"
                    component={renderCheckboxField}
                  />
                  <p style={{color: "#252422", fontSize: "14px"}}>
                    Primary discount
                  </p>
                </Label>
              </FormGroup>
            </Col>
          </Row>
        }


      </form>
    );
  }
}

const selector = formValueSelector('SolutionDiscount');

SolutionDiscountForm = connect(
  state => ({
    discountPriceType: selector(state, 'discountPriceType'),
    entityType: selector(state, 'entityType'),
    SolutionListing: state.form.SolutionListing,
    settings: state.settings
  })
)(SolutionDiscountForm);

SolutionDiscountForm = reduxForm({
  form: 'SolutionDiscount'
})(
  SolutionDiscountForm
);

export default SolutionDiscountForm;
