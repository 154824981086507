import React, { useEffect, useState } from "react";
import { useTable, useSortBy } from "react-table";
import { formatDisplayDatetimeShort } from "../../../utils/datetime";
import {Link} from "react-router-dom";

const Table = ({ columns, data, history }) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
        initialState: {
          sortBy: [
            {
              id: "date",
              desc: false,
            },
          ],
        },
      },
      useSortBy
    );

  return (
    <div className="ReactTable7">
      <table {...getTableProps()} className="rt-table">
        <thead className="rt-thead">
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()} className="rt-rh rt-tr">
            {headerGroup.headers.map((column) => {
              const sortClass =
                column.isSorted && !column.isSortedDesc
                  ? "-sort-asc"
                  : column.isSorted && column.isSortedDesc
                    ? "-sort-desc"
                    : "no-sort";

              return (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  <div className={"rt-header rt-td " + sortClass}>
                    {column.render("Header")}
                  </div>
                </th>
              );
            })}
          </tr>
        ))}
        </thead>
        <tbody {...getTableBodyProps()}>
        {rows && rows.length > 0
          ? rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                className="rt-tr"
                onClick={() => {
                  history.push(`/admin/billing/`);
                }}
              >
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()} className="rt-td">
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })
          : [...Array(10).keys()].map((val) => (
            <tr className="rt-tr" key={val}>
              <td colSpan={10} className="rt-td empty-rows">
                {val === 4 ? "No rows found" : ""}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const BillingListTable = ({ data, history }) => {
  const columns = React.useMemo(
    () => [
      {
        id: "Item",
        Header: "Item",
        accessor: (row) => {
          return (
            <div>
              <h6 className="mb-0 text-capitalize">
                <Link to="/admin/billing/">{row.item.order}</Link>
              </h6>
              <small>{row.item.invoice}</small>
            </div>
          )
        },
      },
      {
        Header: "Amount",
        accessor: "amount",
      },
      {
        Header: "Customer",
        accessor: "customer",
      },
      {
        Header: "Solution",
        accessor: "solution",
      },
      {
        Header: "Marketplace",
        accessor: "marketplace",
      },
    ],
    []
  );

  return <Table columns={columns} data={data} history={history} />;
};

export default BillingListTable;
