import React from 'react'
import Datetime from 'react-datetime';
import countryList from 'react-select-country-list'

import {
  FormGroup,
  Input,
  Label,
} from "reactstrap";

import { DropdownSelectSearchField } from "../../components/ReduxFields/DropdownSelectSearchField";


export const renderField = ({ input, label, placeholder, type, meta: { touched,
  error, visited } }) => {
  let formGroupClass;
  if (visited && touched) {
    formGroupClass = error ? "has-danger" : "";
  } else {
    formGroupClass = "";
  }
  if (touched && error) {
    formGroupClass = "has-danger";
  }
  return (
    <FormGroup className={formGroupClass}>
      <Label>{label}</Label>
      <div>
        <Input {...input} placeholder={placeholder} type={type} />
        {touched && error && <Label className="error mt-1">{error}</Label>}
      </div>
    </FormGroup>
  );
};

class FeatureInputField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {val: this.props.initialVal.replace(/\n/g, ''), height: '4em'};
  }

  handleChange = (e) => {
    this.setState({
        val: e.target.value.replace(/\n/g, ''),
        height: (e.target.scrollHeight+2)+'px'
    });

    this.props.input.onChange(e.target.value.replace(/\n/g, ''))
  };

  render(){
    return (
      <div className={this.props.parentClass}>
        <textarea
          {...this.props.childProps}
          placeholder={this.props.placeholder}
          type={this.props.type}
          disabled={this.props.disabled}
          value={this.state.val.replace(/\n/g, '')}
          defaultValue={this.state.val.replace(/\n/g, '')}
          onChange={this.handleChange}
          className="form-control height-adjust"
          style={{display: 'inline',  minHeight: '4em', height: this.state.height}}
        />
        {this.props.touched && this.props.error && <Label className="error mt-1">{this.props.error}</Label>}
      </div>
    );
  }
}

export const renderFeatureInputField = ({input, childProps, placeholder, initialVal, className, type, disabled, meta:{ touched, error, visited } }) => {
  let parentClass;
  if (visited && touched) {
    parentClass = error ? " has-danger" : " has-success";
  } else {
    parentClass = "";
  }
  if (touched && error) {
    parentClass = " has-danger";
  }
  parentClass = className + parentClass;

  return (
    <FeatureInputField
      parentClass={parentClass}
      input={input}
      childProps={childProps}
      placeholder={placeholder}
      type={type}
      disabled={disabled}
      touched={touched}
      error={error}
      initialVal={initialVal}
    />
  );
};

export const renderDatetimeField = ({ input, childProps, placeholder, className, disabled, meta: { touched,
  error, visited } }) => {
  let parentClass = '';
  if (visited && touched) parentClass = error ? " has-danger" : " ";
  if (touched && error) parentClass = " has-danger";
  parentClass = className + parentClass;

  return (
    <div className={parentClass}>
      <Datetime
        {...childProps}
        {...input}
        timeFormat={false}
        closeOnSelect={true}
        inputProps={{placeholder: placeholder, disabled: disabled}}
      />
      {touched && error && <Label className="error mt-1">{error}</Label>}
    </div>
  );
};

export const renderDatetimeMinMaxField = ({ input, childProps, placeholder, className, disabled, meta: { touched,
  error, visited } }) => {
  let parentClass;
  if (visited && touched) {
    parentClass = error ? " has-danger" : " ";
  } else {
    parentClass = "";
  }
  if (touched && error) {
    parentClass = " has-danger";
  }
  parentClass = className + parentClass;

  const minDate = new Date().getTime();
  const maxDate = new Date().setDate(new Date().getDate()+90);

  return (
    <div className={parentClass}>
      <Datetime
        {...childProps}
        {...input}
        timeFormat={false}
        closeOnSelect={true}
        inputProps={{placeholder: placeholder, disabled: disabled}}
        isValidDate={function( current ) {
          return current >= minDate && current <= maxDate;
        }}
      />
      {touched && error && <Label className="error mt-1">{error}</Label>}
    </div>
  );
};

export const renderInputField = ({ input, childProps, placeholder, className, type, disabled, meta: { touched,
  error, visited } }) => {
  let parentClass;
  if (visited && touched) {
    parentClass = error ? " has-danger" : " ";
  } else {
    parentClass = "";
  }
  if (touched && error) {
    parentClass = " has-danger";
  }
  parentClass = className + parentClass;
  return (
    <div className={parentClass}>
      <Input
        {...childProps}
        {...input}
        placeholder={placeholder}
        type={type}
        disabled={disabled}
      />
      {touched && error && <Label className="error mt-1">{error}</Label>}
    </div>
  );
};

export const renderFloatNumberField = ({ input, childProps, placeholder, disabled, type, decimals = 2, meta: { touched,
  error, visited } }) => {
  let parentClass;
  if (visited && touched) {
    parentClass = error ? "has-danger" : "";
  } else {
    parentClass = "";
  }
  if (touched && error) {
    parentClass = "has-danger";
  }
  return (
    <div className={parentClass}>
      <Input
        {...childProps}
        {...input}
        onBlur={(event) => {
          if (type === "number") {
            if (event.target.value) {
              const value = parseFloat(event.target.value);
              input.onChange(value.toFixed(decimals));
            } else {
              const value = null;
              input.onChange(value);
            }
          }
          input.onBlur();
        }}
        disabled={disabled}
        placeholder={placeholder}
        type={type}
      />
      {touched && error && <Label className="error mt-1">{error}</Label>}
    </div>
  );
};

export const renderSelectField = ({ input, children, placeholder, disabled, type, meta: { touched, error, dirty } }) => {
  let parentClass = "";
  if (dirty && error) parentClass = " has-danger";
  return (
    <div className={parentClass}>
      <Input {...input} placeholder={placeholder} type={type} disabled={disabled} className="custom-select custom-select-xl" style={{marginRight: 18}}>
        {children}
      </Input>
      {touched && error && <Label className="error mt-1">{error}</Label>}
    </div>
  );
};

export const renderDropdownSelectSearchField = ({input, children, items, onItemClick, clearSolutionName, toggleClearSolutionName, clearSolutionField, disabled, meta: {error, dirty, touched} }) => {
  let parentClass;
  if (dirty) parentClass = error ? " has-danger" : " ";
  else parentClass = "";
  if (dirty && error) parentClass = " has-danger";
  return (
    <div className={parentClass}>
      <DropdownSelectSearchField
        input={input}
        items={items}
        disabled={disabled}
        clearSolutionField={clearSolutionField}
        toggleClearSolutionName={toggleClearSolutionName}
        onItemClick={onItemClick}
        clearSolutionName={clearSolutionName}
      >
        {children}
      </DropdownSelectSearchField>
      {(dirty || touched) && error && <Label className="error mt-1">{error}</Label>}
    </div>
  );
};

export const renderCheckboxField = ({ input, children, placeholder, disabled, type, meta: { touched,
  error, visited}}) => {
  let parentClass;
  if (visited && touched) {
    parentClass = error ? " has-danger" : " ";
  } else {
    parentClass = "";
  }
  if (touched && error) {
    parentClass = " has-danger";
  }
  return (
    <>
      <Input {...input} placeholder={placeholder} disabled={disabled} type={type}>
        {children}
      </Input>
      <span className={"form-check-sign" + parentClass} />
    </>
  );
};

export const renderSelectUSStatesField = ({ input, placeholder, meta: { touched,
  error, visited } }) => {
  let parentClass;
  if (visited && touched) {
    parentClass = error ? "has-danger" : "";
  } else {
    parentClass = "";
  }
  if (touched && error) {
    parentClass = "has-danger";
  }
  return (
    <div className={parentClass}>
      <Input {...input} placeholder={placeholder} type="select" className="custom-select custom-select-xl">
        <option value="" disabled hidden>Select state</option>
        <option value="AL">Alabama</option>
        <option value="AK">Alaska</option>
        <option value="AZ">Arizona</option>
        <option value="AR">Arkansas</option>
        <option value="CA">California</option>
        <option value="CO">Colorado</option>
        <option value="CT">Connecticut</option>
        <option value="DE">Delaware</option>
        <option value="DC">District of Columbia</option>
        <option value="FL">Florida</option>
        <option value="GA">Georgia</option>
        <option value="GU">Guam</option>
        <option value="HI">Hawaii</option>
        <option value="ID">Idaho</option>
        <option value="IL">Illinois</option>
        <option value="IN">Indiana</option>
        <option value="IA">Iowa</option>
        <option value="KS">Kansas</option>
        <option value="KY">Kentucky</option>
        <option value="LA">Louisiana</option>
        <option value="ME">Maine</option>
        <option value="MD">Maryland</option>
        <option value="MA">Massachusetts</option>
        <option value="MI">Michigan</option>
        <option value="MN">Minnesota</option>
        <option value="MS">Mississippi</option>
        <option value="MO">Missouri</option>
        <option value="MT">Montana</option>
        <option value="NE">Nebraska</option>
        <option value="NV">Nevada</option>
        <option value="NH">New Hampshire</option>
        <option value="NJ">New Jersey</option>
        <option value="NM">New Mexico</option>
        <option value="NY">New York</option>
        <option value="NC">North Carolina</option>
        <option value="ND">North Dakota</option>
        <option value="MP">Northern Marianas Islands</option>
        <option value="OH">Ohio</option>
        <option value="OK">Oklahoma</option>
        <option value="OR">Oregon</option>
        <option value="PA">Pennsylvania</option>
        <option value="PR">Puerto Rico</option>
        <option value="RI">Rhode Island</option>
        <option value="SC">South Carolina</option>
        <option value="SD">South Dakota</option>
        <option value="TN">Tennessee</option>
        <option value="TX">Texas</option>
        <option value="UT">Utah</option>
        <option value="VT">Vermont</option>
        <option value="VA">Virginia</option>
        <option value="VI">Virgin Islands</option>
        <option value="WA">Washington</option>
        <option value="WV">West Virginia</option>
        <option value="WI">Wisconsin</option>
        <option value="WY">Wyoming</option>
      </Input>
      {touched && error && <Label className="error mt-1">{error}</Label>}
    </div >
  );
};

export const renderSelectCountriesField = ({ input, placeholder, meta: { touched,
  error, visited } }) => {
  let parentClass;
  if (visited && touched) {
    parentClass = error ? "has-danger" : "";
  } else {
    parentClass = "";
  }
  if (touched && error) {
    parentClass = "has-danger";
  }

  const options = countryList().getData();

  return (
      <div className={parentClass}>
        <Input {...input} placeholder={placeholder} type="select" className="custom-select custom-select-xl">
          <option value="" disabled hidden>Select country</option>
          {options.map(country => {
            return <option value={country.value} key={country.value}>{country.label}</option>
          })}
        </Input>
        {touched && error && <Label className="error mt-1">{error}</Label>}
      </div >
  );
};


export const nonNegativeNumbers = value => {
  if (value < 0) return 0;
  else return value
};

export const nonNegativeIntNormalizer = value => {
  if (value) {
    if (parseInt(value) < 0) {
      return 0;
    }
    else {
      return parseInt(value).toString()
    }
  } else {
    return ''
  }
};
