/* eslint-disable linebreak-style */
import axios from 'axios';
import {
    SOLUTION_FETCH_REQUEST,
    SOLUTION_FETCH_SUCCESS,
    SOLUTION_FETCH_ERROR,
    SOLUTION_ADD_REQUEST,
    SOLUTION_ADD_SUCCESS,
    SOLUTION_ADD_ERROR,
    SOLUTION_DETAIL_CLEAR,
    SOLUTION_CLONE_REQUEST,
    SOLUTION_CLONE_SUCCESS,
    SOLUTION_CLONE_ERROR,
    SOLUTION_DELETE_REQUEST,
    SOLUTION_DELETE_SUCCESS, SOLUTION_DELETE_ERROR
} from './actionTypes';

import {updateSpinner} from "./spinnerActions"

export const fetchSolutionDetail = (solutionId, versionId = null) => async (dispatch, state) => {
    dispatch(updateSpinner(true));
    dispatch({type: SOLUTION_FETCH_REQUEST, request: true});
    let url = `/api/solution/solutions/${solutionId}/`;

    const {mode} = state().account;
    url += `?mode=${mode}`;

    if (versionId) {
        url += `&version_id=${versionId}`
    }

    try {
        const res = await axios.get(url);
        dispatch({type: SOLUTION_FETCH_SUCCESS, data: res.data});
        dispatch(updateSpinner(false));
        return res
    } catch (error) {
        dispatch({type: SOLUTION_FETCH_ERROR, error});
        dispatch(updateSpinner(false));
        return error
    }
};

export const addSolutionDetail = (data) => async (dispatch) => {
    dispatch(updateSpinner(true));
    dispatch({type: SOLUTION_ADD_REQUEST, request: true});
    let url = '/api/solution/solutions/';
    try {
        const res = await axios.post(url, data);
        dispatch({type: SOLUTION_ADD_SUCCESS, data: res.data});
        dispatch(updateSpinner(false));
        return res
    } catch (error) {
        dispatch({type: SOLUTION_ADD_ERROR, error});
        dispatch(updateSpinner(false));
        throw error
    }
};

export const updateSolutionDetail = (solutionId, data) => async (dispatch) => {
    dispatch(updateSpinner(true));
    dispatch({type: SOLUTION_ADD_REQUEST, request: true});
    let url = `/api/solution/solutions/${solutionId}/`;
    try {
        const res = await axios.patch(url, data);
        dispatch({type: SOLUTION_ADD_SUCCESS, data: res.data});
        dispatch(updateSpinner(false));
    } catch (error) {
        dispatch(updateSpinner(false));
        dispatch({type: SOLUTION_ADD_ERROR, error});
        return error;
    }
};

export const clearSolutionDetail = () => (dispatch) => {
    dispatch({type: SOLUTION_DETAIL_CLEAR, request: false});
};

export const cloneSolution = (solutionId) => async (dispatch) => {
    dispatch(updateSpinner(true));
    dispatch({type: SOLUTION_CLONE_REQUEST, request: true});
    let url = `/api/solution/solutions/${solutionId}/clone/`;
    try {
        const res = await axios.post(url);
        dispatch({type: SOLUTION_CLONE_SUCCESS, data: res.data});
        dispatch(updateSpinner(false));
        return res
    } catch (error) {
        dispatch(updateSpinner(false));
        dispatch({type: SOLUTION_CLONE_ERROR, error});
        return error;
    }
};

export const deleteSolutionDetail = (solutionId) => async (dispatch) => {
    dispatch(updateSpinner(true));
    dispatch({type: SOLUTION_DELETE_REQUEST, request: true});
    let url = `/api/solution/solutions/${solutionId}/`;
    try {
        const res = await axios.delete(url);
        if (res.status === 204) {
            dispatch({type: SOLUTION_DELETE_SUCCESS, data: solutionId});
            dispatch(updateSpinner(false));
            return res
        }
        dispatch(updateSpinner(false));
    } catch (error) {
        dispatch(updateSpinner(false));
        dispatch({type: SOLUTION_DELETE_ERROR, error});
        dispatch(updateSpinner(false));
        throw error;
    }
};
