import PropTypes from "prop-types";
import { LEAD_TYPE_TRIAL_VALUE } from "../../../config/lead";
import { Col, Row } from "reactstrap";
import React from "react";

const TrialMsg = (props) => {
  if (props.leadType === LEAD_TYPE_TRIAL_VALUE) {
    return (
      <Row>
        <Col>
          <p className="text-right" style={props.pStyle}>
            When the trial period is over, click Create Subscription to start a
            recurring subscription.
          </p>
        </Col>
      </Row>
    );
  } else {
    return null;
  }
};

TrialMsg.propTypes = {
  leadType: PropTypes.string,
  pStyle: PropTypes.object,
};

export default TrialMsg;
