/* eslint-disable linebreak-style */
import axios from 'axios';
import {
  WFE_BUYFLOW_DETAIL_FETCH_REQUEST,
  WFE_BUYFLOW_DETAIL_FETCH_SUCCESS,
  WFE_BUYFLOW_DETAIL_FETCH_ERROR,
  WFE_BUYFLOW_DETAIL_CLEAR
} from './actionTypes';
import { updateSpinner } from  "./spinnerActions"

export const fetchWfeBuyFlowDetail = (subscriptionId) => (dispatch) => {
  dispatch(updateSpinner(true));
  dispatch({ type: WFE_BUYFLOW_DETAIL_FETCH_REQUEST , request: true});
  let url = `/api/workflow/buyflows/${subscriptionId}/`;
  axios.get(url)
  .then(({ data }) => {
    if (data) {
      dispatch({ type: WFE_BUYFLOW_DETAIL_FETCH_SUCCESS, data });
    } else {
      dispatch({ type: WFE_BUYFLOW_DETAIL_FETCH_ERROR, error: { mes: "" }});
    }
    dispatch(updateSpinner(false));
  })
  .catch((error) => {
    dispatch({ type: WFE_BUYFLOW_DETAIL_FETCH_ERROR, error });
    dispatch(updateSpinner(false));
  });
};


export const clearWfeBuyFlowDetail = () => (dispatch) => {
  dispatch({ type: WFE_BUYFLOW_DETAIL_CLEAR , request: false});
};
