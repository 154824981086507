/* eslint-disable linebreak-style */
import camelcaseKeys from 'camelcase-keys';
import {
  SOLUTION_LIST_FETCH_REQUEST,
  SOLUTION_LIST_FETCH_SUCCESS,
  SOLUTION_LIST_FETCH_ERROR, SOLUTION_DELETE_REQUEST, SOLUTION_DELETE_ERROR, SOLUTION_DELETE_SUCCESS,
} from "../actions/actionTypes";

const initialState = {
  solutions: [],
  isLoading: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SOLUTION_LIST_FETCH_REQUEST:
    case SOLUTION_DELETE_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: action.error,
      };

    case SOLUTION_LIST_FETCH_SUCCESS:
      const data = camelcaseKeys(action.data, {deep: true});
      return {
        ...state,
        solutions: data,
        isLoading: false,
        error: action.error
      };

    case SOLUTION_LIST_FETCH_ERROR:
    case SOLUTION_DELETE_ERROR:
      return {
        ...state,
        solutions: [],
        isLoading: false,
        error: action.error,
      };

    case SOLUTION_DELETE_SUCCESS:
      return {
        ...state,
        solutions: state.solutions.filter((solution) => solution.id !== action.data.id),
        isLoading: false,
        error: action.error,
      };

    default:
      return state;
  }
};
