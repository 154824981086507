import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Card, CardHeader, CardBody, Row, Col } from "reactstrap";

import { fetchSolutionList } from "../../store/actions/solutionListActions";
import {clearSolutionDetail} from "../../store/actions/solutionDetailActions";
import EmbeddedAlert from "../../components/EmbeddedAlert";

class SolutionList extends React.Component {

  componentDidMount() {
    this.props.fetchSolutionList();
    this.props.clearSolutionDetail();
  }

  render() {
    const {
      solutionList: {
        solutions
      }
    } = this.props;
    const { account } = this.props;

    return (
      <>
        <div className="breadcrumb-nav">
          <Row>
            <Col>
              <ol className="breadcrumb-container">
                <li className="breadcrumb-container__item">
                  <h5>
                    <Link to="/admin/home">Home</Link>
                  </h5>
                </li>
                <li className="breadcrumb-container__item">
                  <h5>/</h5>
                </li>
                <li className="breadcrumb-container__item">
                  <h5>Solutions</h5>
                </li>
              </ol>
            </Col>
          </Row>
        </div>
        <div className="content solutions-list-page">
          <div className="pt-3 c-solution-alert">
            <EmbeddedAlert />
          </div>
          <Row>
            {solutions.map(({
              id,
              name,
              internalName,
              marketplaceCount,
              viewCount,
              leadCount,
              solutionLogo
            }) => (
              <Col lg="6" sm="6" key={id}>
                <Card
                  onClick={() => this.props.history.push(`/admin/solutions/${id}/`)}
                >
                  <CardHeader>
                  </CardHeader>
                  <CardBody>
                    <div className="d-flex m-4">
                      <div>
                        {
                          solutionLogo &&
                          <img alt="" className="solution-logo" src={solutionLogo} />
                        }
                      </div>
                      <div className="ml-4 text-truncate">
                        <h5>
                          <a href="#">
                            {internalName}
                          </a>
                        </h5>
                        <p className="card-description">
                          {name}
                        </p>
                      </div>
                    </div>
                    <div className="d-flex justify-content-around m-4">
                      <div>
                        <h4>
                          {marketplaceCount}
                        </h4>
                        <p className="card-solution__metrics-name">
                          Marketplaces
                        </p>
                      </div>
                      <div className="text-center">
                        <h4>
                          {viewCount}
                        </h4>
                        <p className="card-solution__metrics-name">
                          Views
                        </p>
                      </div>
                      <div className="text-center">
                        <h4>
                          {leadCount}
                        </h4>
                        <p className="card-solution__metrics-name">
                          Leads
                        </p>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            ))}

            {account.permissions.includes("write:solutions") &&
              <Col lg="6" sm="6">
                <Card className="card-add">
                  <CardBody className="p-0 d-flex align-items-center">
                    <Col className="text-center">
                      <Link to="/admin/solutions/listing-new">
                        <h1 className="font-weight-bold">
                          <i className="nc-icon nc-simple-add" />
                        </h1>
                      </Link>
                        <h5 className="card-solution__add-new-text">
                          Add New
                        </h5>
                    </Col>
                  </CardBody>
                </Card>
              </Col>
            }
          </Row>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  solutionList: state.solutionList,
  account: state.account
});

const mapDispatchToProps = {
  fetchSolutionList,
  clearSolutionDetail
};

export default connect(mapStateToProps, mapDispatchToProps)(SolutionList);
