/* eslint-disable linebreak-style */
import axios from 'axios';
import {
  REPORT_FETCH_REQUEST,
  REPORT_FETCH_SUCCESS,
  REPORT_FETCH_ERROR,
} from './actionTypes';
import { updateSpinner } from "./spinnerActions";


export const fetchReportLink = () => (dispatch, state) => {
  dispatch(updateSpinner(true));
  dispatch({ type: REPORT_FETCH_REQUEST , request: true});

  const url = `/api/host/report`;

  axios.get(url)
  .then(({ data }) => {
    if (data) {
      dispatch({ type: REPORT_FETCH_SUCCESS, data });
    } else {
      dispatch({ type: REPORT_FETCH_ERROR, error: { mes: "" }});
    }
    dispatch(updateSpinner(false));
  })
  .catch((error) => {
    dispatch({ type: REPORT_FETCH_ERROR, error });
    dispatch(updateSpinner(false));
  });
};
