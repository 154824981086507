/* eslint-disable linebreak-style */
import { UPDATE_SPINNER } from "../actions/actionTypes";

const initialState = {
  backgroundSpinner: false,
  displaySpinner: 0
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_SPINNER:
      return {
        ...state,
        backgroundSpinner: action.data.backgroundSpinner,
        displaySpinner: action.data.displaySpinner
      };

    default:
      return state;
  }
};
