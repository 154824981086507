import {
  ADD_MESSAGE,
  REMOVE_MESSAGES,
  REMOVE_ALL_MESSAGES,
} from "../actions/actionTypes";

const initialState = {
  messages: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_MESSAGE:
      return {
        ...state,
        messages: [...state.messages, action.data],
      };

    case REMOVE_ALL_MESSAGES:
      return {
        ...state,
        messages: [],
      };

    case REMOVE_MESSAGES:
      return {
        ...state,
        messages: state.messages.filter(
          (message) => message.id !== action.payload.id
        ),
      };

    default:
      return state;
  }
};
