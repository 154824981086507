import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm, formValueSelector } from "redux-form";

import {
  Button,
  Col,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  Table,
} from "reactstrap";
import {
  renderCheckboxField,
  renderDatetimeField,
  renderInputField,
  renderSelectField,
} from "../forms/utils";
import {
  maxLengthValidation120,
  requiredValidation,
  urlValidation,
  maxLengthValidation2048,
  isDateValidationMMDDYYYY,
  minLengthValidation5,
  maxLengthValidation256,
} from "../forms/validation";
import {
  M_RESOURCE_CATEGORY_EVENTS_VALUE,
  M_RESOURCE_CATEGORY_OPTIONS,
  M_RESOURCE_CATEGORY_VIDEOS_VALUE,
} from "../../config/marketplaceResource";
import moment from "moment";
import DropzoneImageUpload from "../../components/DropzoneImageUpload";
import { fetchMarketplaceResourceStaticFiles } from "../../store/actions/marketplaceResourceListActions";
import S3Browser from "./S3Browser";
import { Auth0Context } from "../../services/auth/react-auth0-wrapper";

const CREATE_MARKETPLACE_RESOURCE_FROM_SCRATCH =
  "CREATE_MARKETPLACE_RESOURCE_FROM_SCRATCH";
const CREATE_MARKETPLACE_RESOURCE_FROM_SOLUTION_RESOURCE =
  "CREATE_MARKETPLACE_RESOURCE_FROM_SOLUTION_RESOURCE";
const CREATE_MARKETPLACE_RESOURCE_FROM_STATIC_CONTENT =
  "CREATE_MARKETPLACE_RESOURCE_FROM_STATIC_CONTENT";

class MarketplaceResourceForm extends React.Component {
  static contextType = Auth0Context;

  constructor(props) {
    super(props);
    this.state = {
      isSpeakersOpened: false,
      selectedSpeakerId: null,
      modalDeleteConfirmation: false,
      speakerImage: null,
    };
    this.toggleModalDeleteConfirmation =
      this.toggleModalDeleteConfirmation.bind(this);
  }

  componentDidMount() {
    const { selectedMarketplaceResource } = this.props;
    if (selectedMarketplaceResource) {
      this.props.initialize({
        ...selectedMarketplaceResource,
        addType: CREATE_MARKETPLACE_RESOURCE_FROM_SCRATCH,
        date: selectedMarketplaceResource.date
          ? moment(selectedMarketplaceResource.date).format("MM/DD/YYYY")
          : null,
      });
    } else {
      this.props.initialize({
        addType: CREATE_MARKETPLACE_RESOURCE_FROM_SCRATCH,
        category: this.props.selectedCategory
          ? this.props.selectedCategory
          : null,
      });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.selectedMarketplaceResource !==
      this.props.selectedMarketplaceResource
    ) {
      const { selectedMarketplaceResource } = this.props;
      if (selectedMarketplaceResource) {
        this.props.initialize({
          ...selectedMarketplaceResource,
          addType: CREATE_MARKETPLACE_RESOURCE_FROM_SCRATCH,
          date: selectedMarketplaceResource.date
            ? moment(selectedMarketplaceResource.date).format("MM/DD/YYYY")
            : null,
        });
      }
    }
  }

  addSpeaker() {
    const {
      speakerName,
      speakerCompany,
      speakerTitle,
      speakerDescription,
      payload,
      setPayload,
    } = this.props;

    let speakers;
    if (this.state.selectedSpeakerId) {
      speakers = payload.speakers.map((speaker) =>
        speaker.id === this.state.selectedSpeakerId
          ? {
              id: this.state.selectedSpeakerId,
              name: speakerName,
              title: speakerTitle,
              company: speakerCompany,
              description: speakerDescription,
              photo: this.state.speakerImage,
            }
          : speaker
      );
    } else {
      const payloadSpeakers =
        payload && payload.speakers ? payload.speakers : [];
      speakers = [
        ...payloadSpeakers,
        {
          id: Math.floor(Math.random() * 1000000000) + 1,
          name: speakerName,
          title: speakerTitle,
          company: speakerCompany,
          description: speakerDescription,
          photo: this.state.speakerImage,
        },
      ];
    }

    this.setState({
      selectedSpeakerId: null,
      isSpeakersOpened: false,
      speakerImage: null,
    });

    setPayload({ ...payload, speakers });

    this.props.change("speakerName", null);
    this.props.change("speakerCompany", null);
    this.props.change("speakerTitle", null);
    this.props.change("speakerDescription", null);

    this.props.untouch("speakerName");
    this.props.untouch("speakerCompany");
    this.props.untouch("speakerTitle");
    this.props.untouch("speakerDescription");
  }

  editSpeaker(speakerId) {
    const { payload } = this.props;
    const speaker =
      payload && payload.speakers
        ? payload.speakers.find((speaker) => speaker.id === speakerId)
        : null;
    if (speaker) {
      this.props.change("speakerName", speaker.name);
      this.props.change("speakerCompany", speaker.company);
      this.props.change("speakerTitle", speaker.title);
      this.props.change("speakerDescription", speaker.description);
      this.setState({ speakerImage: speaker.photo });
    }

    this.setState({ selectedSpeakerId: speakerId, isSpeakersOpened: true });
  }

  deleteSpeaker(speakerId) {
    const { payload, setPayload } = this.props;
    const speakers = payload.speakers.filter(
      (speaker) => speaker.id !== speakerId
    );
    this.setState({
      selectedSpeakerId: null,
      modalDeleteConfirmation: false,
    });
    setPayload({ payload, speakers });
  }

  toggleModalDeleteConfirmation() {
    this.setState({
      modalDeleteConfirmation: !this.state.modalDeleteConfirmation,
    });
  }

  setSpeakerImage(speakerImage) {
    this.setState({
      speakerImage,
    });
  }

  render() {
    const {
      handleSubmit,
      addType,
      isEditing,
      solutionMarketplaces,
      fetchSolutionResourceList,
      solutionResources,
      category,
      image,
      setImage,
      payload,
      staticFile,
      imageError,
      setImageError,
    } = this.props;
    const { user } = this.context;

    return (
      <form onSubmit={handleSubmit}>
        {!isEditing && (
          <Row className="mt-1">
            <Col>
              <div className="form-check-radio">
                <Label check>
                  <Field
                    type="radio"
                    name="addType"
                    id="addType1"
                    component={renderCheckboxField}
                    value={CREATE_MARKETPLACE_RESOURCE_FROM_SCRATCH}
                  />
                  <span className="custom-radio-label">
                    Create New Resource
                  </span>
                  <span className="form-check-sign" />
                </Label>
              </div>

              <div className="form-check-radio">
                <Label check>
                  <Field
                    type="radio"
                    name="addType"
                    id="addType2"
                    component={renderCheckboxField}
                    value={CREATE_MARKETPLACE_RESOURCE_FROM_SOLUTION_RESOURCE}
                  />
                  <span className="custom-radio-label">
                    Copy from Solution Resource
                  </span>
                  <span className="form-check-sign" />
                </Label>
              </div>

              {user.scope.includes("SuperAdmin") && (
                <div className="form-check-radio">
                  <Label check>
                    <Field
                      type="radio"
                      name="addType"
                      id="addType3"
                      component={renderCheckboxField}
                      value={CREATE_MARKETPLACE_RESOURCE_FROM_STATIC_CONTENT}
                    />
                    <span className="custom-radio-label">
                      Copy from Static Content
                    </span>
                    <span className="form-check-sign" />
                  </Label>
                </div>
              )}
            </Col>
          </Row>
        )}

        {(addType === CREATE_MARKETPLACE_RESOURCE_FROM_STATIC_CONTENT ||
          staticFile) && (
          <Row className="mt-3">
            <Col>
              <div className="mb-3">
                <S3Browser
                  fetchMarketplaceResourceStaticFiles={
                    this.props.fetchMarketplaceResourceStaticFiles
                  }
                  marketplaceId={this.props.marketplaceId}
                  onSelectFile={this.props.onSelectFile.bind(this)}
                  staticFile={staticFile}
                />
              </div>
            </Col>
          </Row>
        )}

        {addType === CREATE_MARKETPLACE_RESOURCE_FROM_SOLUTION_RESOURCE && (
          <>
            <Row className="mt-3">
              <Col>
                <div className="align-items-start d-flex">
                  <FormGroup inline className="ml-0">
                    <Field
                      type="select"
                      name="solution"
                      component={renderSelectField}
                      onChange={(e) => {
                        this.props.change("label", null);
                        this.props.change("resourceLink", null);
                        fetchSolutionResourceList(e.target.value);
                      }}
                    >
                      <option value="">---Select Solution---</option>
                      {solutionMarketplaces.map(
                        (solutionMarketplace, index) => {
                          return (
                            <option
                              key={index}
                              value={solutionMarketplace.solution.id}
                            >
                              {solutionMarketplace.solution.name}
                            </option>
                          );
                        }
                      )}
                    </Field>
                  </FormGroup>
                </div>
              </Col>
            </Row>
            <Row className="mt-1">
              <Col>
                <div className="align-items-start d-flex">
                  <FormGroup inline className="ml-0">
                    <Field
                      type="select"
                      name="solutionResource"
                      component={renderSelectField}
                      onChange={(e) => {
                        const selectedSolutionResource = solutionResources.find(
                          (x) => x.id === parseInt(e.target.value)
                        );
                        this.props.change(
                          "label",
                          selectedSolutionResource.resourceTitle ||
                            selectedSolutionResource.resourceFileName
                        );
                        this.props.change(
                          "resourceLink",
                          selectedSolutionResource.resourceLink ||
                            selectedSolutionResource.resourceFileUrl
                        );
                      }}
                    >
                      <option value="">---Select Solution Resource---</option>
                      {solutionResources.map((solutionResource, index) => {
                        return (
                          <option key={index} value={solutionResource.id}>
                            {solutionResource.resourceTitle ||
                              solutionResource.resourceLink ||
                              solutionResource.resourceFileName}
                          </option>
                        );
                      })}
                    </Field>
                  </FormGroup>
                </div>
              </Col>
            </Row>
          </>
        )}
        <>
          <Row className="mt-3">
            <Col>
              <FormGroup inline className="ml-0">
                <label>
                  <h5 className="font-weight-bold mb-2">Resource Category *</h5>
                </label>
                <Field
                  type="select"
                  name="category"
                  validate={[requiredValidation]}
                  component={renderSelectField}
                >
                  <option value="">--- Select Resource Category ---</option>
                  {Object.keys(M_RESOURCE_CATEGORY_OPTIONS).map((key) => {
                    return (
                      <option key={key} value={key}>
                        {M_RESOURCE_CATEGORY_OPTIONS[key]}
                      </option>
                    );
                  })}
                </Field>
              </FormGroup>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col md={6}>
              <FormGroup>
                <label>
                  <h5 className="font-weight-bold mb-2">Title *</h5>
                </label>
                <Field
                  name="label"
                  type="text"
                  component={renderInputField}
                  validate={[requiredValidation, maxLengthValidation120]}
                />
              </FormGroup>
            </Col>

            {addType !== CREATE_MARKETPLACE_RESOURCE_FROM_STATIC_CONTENT && (
              <Col md={6}>
                <FormGroup>
                  <label>
                    <h5 className="font-weight-bold mb-2">
                      {category === M_RESOURCE_CATEGORY_VIDEOS_VALUE
                        ? "Video URL *"
                        : "URL"}
                    </h5>
                  </label>
                  <Field
                    name="resourceLink"
                    type="url"
                    placeholder="https://"
                    component={renderInputField}
                    validate={
                      category === M_RESOURCE_CATEGORY_VIDEOS_VALUE
                        ? [
                            requiredValidation,
                            urlValidation,
                            maxLengthValidation2048,
                          ]
                        : [urlValidation, maxLengthValidation2048]
                    }
                  />
                </FormGroup>
              </Col>
            )}
          </Row>

          {category === M_RESOURCE_CATEGORY_EVENTS_VALUE && (
            <Row className="mt-1">
              <Col>
                <FormGroup className="d-inline-block">
                  <Label>
                    <h5 className="font-weight-bold mb-2">Date</h5>
                  </Label>
                  <Field
                    type="date"
                    name="date"
                    placeholder="mm/dd/yyyy"
                    validate={[requiredValidation, isDateValidationMMDDYYYY]}
                    component={renderDatetimeField}
                  />
                </FormGroup>
              </Col>
            </Row>
          )}

          <Row className="mt-1">
            <Col>
              <FormGroup>
                <label>
                  <h5 className="font-weight-bold">Description</h5>
                </label>
                <Field
                  name="description"
                  type="textarea"
                  component={renderInputField}
                  validate={[minLengthValidation5]}
                  childProps={{
                    className: "height-adjust",
                    style: { minHeight: "4em" },
                  }}
                />
              </FormGroup>
            </Col>
          </Row>

          <Row className="mt-1">
            <Col>
              <FormGroup>
                <label>
                  <h5 className="font-weight-bold mb-2">Image *</h5>
                </label>
                <DropzoneImageUpload
                  image={image}
                  setImage={setImage}
                  setImageError={setImageError}
                />
                {imageError && <p className="text-danger">{imageError}</p>}
              </FormGroup>
            </Col>
          </Row>

          {category === M_RESOURCE_CATEGORY_EVENTS_VALUE && (
            <>
              <Row className="mt-1 mb-3">
                <Col>
                  <h4 className="font-weight-bold mb-2">Speakers</h4>
                </Col>
              </Row>

              {payload && payload.speakers && payload.speakers.length > 0 && (
                <Row>
                  <Col>
                    <Table responsive>
                      <thead>
                        <tr>
                          <th className="text-center">#</th>
                          <th>Photo</th>
                          <th>Name</th>
                          <th>Job Position</th>
                          <th>Company</th>
                          <th>Description</th>
                          <th className="text-right">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {payload.speakers.map((speaker, i) => {
                          return (
                            <tr key={speaker.id}>
                              <td className="text-center">{i + 1}</td>
                              <td>
                                {speaker.photo && (
                                  <img
                                    src={speaker.photo}
                                    alt=""
                                    style={{ maxHeight: 100 }}
                                  />
                                )}
                              </td>
                              <td>{speaker.name}</td>
                              <td>{speaker.title}</td>
                              <td>{speaker.company}</td>
                              <td>{speaker.description}</td>
                              <td className="text-right">
                                <Button
                                  onClick={() => this.editSpeaker(speaker.id)}
                                  className="btn-icon mr-2"
                                  color="success"
                                  size="sm"
                                >
                                  <i className="fa fa-edit"></i>
                                </Button>
                                <Button
                                  className="btn-icon"
                                  color="danger"
                                  size="sm"
                                  onClick={() => {
                                    this.toggleModalDeleteConfirmation();
                                    this.setState({
                                      selectedSpeakerId: speaker.id,
                                    });
                                  }}
                                >
                                  <i className="fa fa-times" />
                                </Button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              )}
              {!this.state.isSpeakersOpened && (
                <Row>
                  <Col>
                    <Button
                      color="outline-primary"
                      onClick={() => {
                        this.setState({
                          isSpeakersOpened: !this.state.isSpeakersOpened,
                        });
                      }}
                    >
                      Add Speaker
                    </Button>
                  </Col>
                </Row>
              )}

              {this.state.isSpeakersOpened && (
                <>
                  <Row className="mt-1">
                    <Col md={6}>
                      <FormGroup>
                        <label>
                          <h5 className="font-weight-bold mb-2">Name *</h5>
                        </label>
                        <Field
                          name="speakerName"
                          type="text"
                          component={renderInputField}
                          validate={
                            category === M_RESOURCE_CATEGORY_EVENTS_VALUE
                              ? [requiredValidation, maxLengthValidation256]
                              : [maxLengthValidation256]
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <label>
                          <h5 className="font-weight-bold mb-2">Company *</h5>
                        </label>
                        <Field
                          name="speakerCompany"
                          type="text"
                          component={renderInputField}
                          validate={
                            category === M_RESOURCE_CATEGORY_EVENTS_VALUE
                              ? [requiredValidation, maxLengthValidation256]
                              : [maxLengthValidation256]
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <label>
                          <h5 className="font-weight-bold mb-2">Title *</h5>
                        </label>
                        <Field
                          name="speakerTitle"
                          type="text"
                          component={renderInputField}
                          validate={
                            category === M_RESOURCE_CATEGORY_EVENTS_VALUE
                              ? [requiredValidation, maxLengthValidation256]
                              : [maxLengthValidation256]
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="mt-1">
                    <Col>
                      <FormGroup>
                        <label>
                          <h5 className="font-weight-bold">Description *</h5>
                        </label>
                        <Field
                          name="speakerDescription"
                          type="textarea"
                          component={renderInputField}
                          validate={
                            category === M_RESOURCE_CATEGORY_EVENTS_VALUE
                              ? [requiredValidation, minLengthValidation5]
                              : []
                          }
                          childProps={{
                            className: "height-adjust",
                            style: { minHeight: "4em" },
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="mt-1">
                    <Col>
                      <FormGroup>
                        <label>
                          <h5 className="font-weight-bold mb-2">Photo</h5>
                        </label>
                        <DropzoneImageUpload
                          image={this.state.speakerImage}
                          setImage={this.setSpeakerImage.bind(this)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Button
                        color="primary"
                        onClick={this.addSpeaker.bind(this)}
                      >
                        Save
                      </Button>

                      <Button
                        color="outline-primary"
                        onClick={() =>
                          this.setState({ isSpeakersOpened: false })
                        }
                      >
                        Cancel
                      </Button>
                    </Col>
                  </Row>
                </>
              )}
            </>
          )}
        </>
        <Modal
          isOpen={this.state.modalDeleteConfirmation}
          fade={false}
          toggle={this.toggleModalDeleteConfirmation}
          className="modal-dialog-centered"
        >
          <div className="modal-header justify-content-center">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={this.toggleModalDeleteConfirmation}
            >
              <span aria-hidden="true">×</span>
            </button>
            <h5 className="modal-title">Confirmation Required</h5>
          </div>
          <ModalBody>
            Are you sure, you want to delete the selected speaker?
          </ModalBody>
          <ModalFooter className="mr-3">
            <Button
              color="outline-primary"
              onClick={this.toggleModalDeleteConfirmation}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              onClick={() => {
                this.deleteSpeaker(this.state.selectedSpeakerId);
              }}
            >
              Delete
            </Button>
          </ModalFooter>
        </Modal>
      </form>
    );
  }
}

const selector = formValueSelector("MarketplaceResource");

const mapDispatchToProps = {
  fetchMarketplaceResourceStaticFiles,
};

const mapStateToProps = (state) => ({
  addType: selector(state, "addType"),
  category: selector(state, "category"),
  speakerName: selector(state, "speakerName"),
  speakerCompany: selector(state, "speakerCompany"),
  speakerTitle: selector(state, "speakerTitle"),
  speakerDescription: selector(state, "speakerDescription"),
  staticFile: selector(state, "staticFile"),
  solutionMarketplaces: state.solutionMarketplace.solutionMarketplaces,
  solutionResources: state.solutionResourceList.solutionResources,
});

MarketplaceResourceForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(MarketplaceResourceForm);

MarketplaceResourceForm = reduxForm({
  form: "MarketplaceResource",
})(MarketplaceResourceForm);

export default MarketplaceResourceForm;
