import React from "react";
import { Provider } from "react-redux";
import { ReduxRouter } from "@lagunovsky/redux-react-router";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import TagManager from "react-gtm-module";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { Auth0Context } from "./services/auth/react-auth0-wrapper";
import store, { history } from "./store";
import AuthLayout from "./layouts/Auth/Auth";
import AdminLayout from "./layouts/Admin/Admin";
import Loading from "./components/Loading";
import PrivateRoute from "./components/Auth/PrivateRoute";
import { setupAxios, handleAxiosErrors } from "./services/auth/axios";
import Notify from "./components/Notify";
import ScrollToTop from "./components/ScrollToTop";
import { reCaptchaConfig } from "./config/reCaptchaConfig";
import CookieConsent from "react-cookie-consent";

class App extends React.Component {
  static contextType = Auth0Context;

  constructor(props) {
    super(props);
    this.state = { appIsRendered: false };
    this.renderApp = this.renderApp.bind(this);
  }

  renderApp() {
    this.setState({ appIsRendered: true });
  }

  getToken() {
    const { getTokenSilently } = this.context;
    try {
      getTokenSilently()
        .then((token) => {
          setupAxios(token);
          this.renderApp();
        })
        .catch((err) => {
          this.renderApp();
        });
    } catch (error) {
      console.log(error);
      this.renderApp();
    }

    // Refresh the token every hour
    setTimeout(this.getToken.bind(this), 3600000); // 1 hour
  }

  render() {
    const { loading, isAuthenticated, logout, loginWithRedirect, user } =
      this.context;

    if (loading) {
      return <Loading />;
    }

    setupAxios(); // Setup only baseURL for the BACKEND
    handleAxiosErrors(logout, loginWithRedirect);

    if (this.state.appIsRendered) {
      const dataLayer = {};
      if (user) {
        dataLayer.userId = user.sub;
        dataLayer.email = user.email;
        dataLayer.name = user.name;
        dataLayer.nickname = user.nickname;
        if (user.partners && user.partners.length > 0) {
          dataLayer.userPartnerId = user.partners[0].partner_id;
          dataLayer.userPartnerName = user.partners[0].partner_name;
        }
        if (user.hosts && user.hosts.length > 0) {
          dataLayer.userHostId = user.hosts[0].host_id;
          dataLayer.userHostName = user.hosts[0].host_name;
        }
      }
      const tagManagerArgs = {
        gtmId: process.env.REACT_APP_GTM_ID || "GTM-TS5X463", // by default automatedtests-partner.teamroadz.com
        dataLayer,
      };

      TagManager.initialize(tagManagerArgs);

      return (
        <Provider store={store}>
          <GoogleReCaptchaProvider
            reCaptchaKey={reCaptchaConfig.sitekey}
            scriptProps={{}}
          >
            <BrowserRouter>
              <ReduxRouter history={history}>
                <ScrollToTop>
                  <Notify />
                  {isAuthenticated ? (
                    <Switch>
                      <PrivateRoute
                        path="/admin"
                        render={(props) => <AdminLayout {...props} />}
                      />
                      <Redirect from="/" to="/admin/home" />
                    </Switch>
                  ) : (
                    <Switch>
                      <Route
                        path="/auth"
                        render={(props) => <AuthLayout {...props} />}
                      />
                      <Redirect from="/" to="/auth/login" />
                    </Switch>
                  )}
                  <CookieConsent
                    location="bottom"
                    buttonText="ACCEPT COOKIES"
                    cookieName="Cookie"
                    style={{
                      backgroundColor: "#ffffff",
                      color: "#000000",
                      padding: "30px 100px 30px 100px",
                    }}
                    buttonStyle={{
                      color: "#000000",
                      fontSize: "13px",
                      padding: "15px 20px 15px 20px",
                      backgroundColor: "#ffffff",
                      border: "1px solid #000000",
                    }}
                    expires={150}
                  >
                    This website uses cookies to enhance user experience and to
                    analyse performance and traffic on our website. We also
                    share information about your use of our site with our social
                    media, advertising and analytics partners.{" "}
                    <a target="_blank" href="https://roadz.com/privacy-policy/">
                      Please see our Privacy Policy.
                    </a>
                  </CookieConsent>
                </ScrollToTop>
              </ReduxRouter>
            </BrowserRouter>
          </GoogleReCaptchaProvider>
        </Provider>
      );
    }

    // First we get a token for axios and then render the app
    this.getToken();

    return <Loading />;
  }
}

export default App;
