import axios from 'axios';
import {
  SUBSCRIPTION_DISCOUNT_LIST_FETCH_REQUEST,
  SUBSCRIPTION_DISCOUNT_LIST_FETCH_SUCCESS,
  SUBSCRIPTION_DISCOUNT_LIST_FETCH_ERROR,
  SUBSCRIPTION_DISCOUNT_LIST_CLEAR,
} from './actionTypes';
import { updateSpinner } from "./spinnerActions";
import camelcaseKeys from "camelcase-keys";

export const fetchSubscriptionDiscountList = (subscriptionId) => (dispatch, state) => {
  dispatch(updateSpinner(true));
  dispatch({ type: SUBSCRIPTION_DISCOUNT_LIST_FETCH_REQUEST , request: true});
  let url = `/api/core/subscription_discounts/`;

  const { mode } = state().account;
  url += `?mode=${mode}`;
  url += `&subscription=${subscriptionId}`;

  axios.get(url)
    .then(({ data }) => {
      if (data) {
        const payload = camelcaseKeys(data, {deep: true});
        dispatch({ type: SUBSCRIPTION_DISCOUNT_LIST_FETCH_SUCCESS, data: payload });
      } else {
        dispatch({ type: SUBSCRIPTION_DISCOUNT_LIST_FETCH_ERROR, error: { mes: "" }});
      }
      dispatch(updateSpinner(false));
    })
    .catch((error) => {
      dispatch({ type: SUBSCRIPTION_DISCOUNT_LIST_FETCH_ERROR, error });
      dispatch(updateSpinner(false));
    });
};

export const clearSubscriptionDiscountList = () => (dispatch) => {
  dispatch({ type: SUBSCRIPTION_DISCOUNT_LIST_CLEAR , request: false});
};
