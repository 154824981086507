import React from "react";
import { connect } from "react-redux";
import {Field, reduxForm, formValueSelector, FieldArray} from "redux-form";
import { isEmpty } from 'lodash'
import {
  Card,
  Col,
  FormGroup, Label,
  Row,
  UncontrolledTooltip
} from "reactstrap";
import Pluralize from "pluralize";

import ImageUpload from "../../components/CustomUpload/ImageUpload.jsx";
import SolutionAdditionalResourcesUpload from "../../components/CustomUpload/SolutionAdditionalResourcesUpload";
import SolutionMediaResourcesUpload from "../../components/CustomUpload/SolutionMediaResourcesUpload.jsx";
import HelpIcon from "../../assets/icons/help-icon.svg";
import WarningIcon from "../../assets/icons/warning-icon.svg";

import {
  integerValidation,
  minLengthValidation5,
  positiveNumberValidation,
  requiredValidation,
  notZeroValidation,
  maxLengthValidation120,
  maxNumber500000000Validation,
  maxLengthValidation500, freeSolutionValidation, minNumber1Validation, maxLengthValidation256, maxLengthValidation20
} from "./validation";

import {
  renderInputField,
  renderSelectField,
  renderFloatNumberField,
  nonNegativeNumbers,
  renderFeatureInputField, renderCheckboxField, nonNegativeIntNormalizer
} from "./utils";
import {
  ENTITY_TYPE_OPTIONS,
  SOLUTION_1_OTC_SHIPPING_TYPE_NO_SHIPPING_VALUE, SOLUTION_1_OTC_SHIPPING_TYPE_OPTIONS,
  SOLUTION_2_OTC_SHIPPING_TYPE_NO_SHIPPING_VALUE, SOLUTION_2_OTC_SHIPPING_TYPE_OPTIONS
} from "../../config/solution";
import {
  SUBSCRIPTION_PAYMENT_INTERVAL_MONTH_VALUE,
  SUBSCRIPTION_PAYMENT_METHOD_INTERVALS
} from "../../config/subscription";
import SolutionDiscount from "../solutions/discounts/SolutionDiscount";
import Currency from "../../components/Currency";
import FleetIcon from "../../assets/icons/icon-fleet-size.svg";
import FillIcon from "../../assets/icons/fill-1.svg";
import VehiclesIcon from "../../assets/icons/icon-vehicles.svg";
import CircleBorder from "../../components/CircleBorder";
import SolutionPromotionResourceUpload from "../../components/CustomUpload/SolutionPromotionResourceUpload";

class SolutionListingEditNewForm extends React.Component {
  constructor(props){
    super(props);
    this.renderFeatureFields = this.renderFeatureFields.bind(this);
  }

  renderFeatureFields = ({fields, meta:{touched, error, submitFailed}}) => {
    if (!fields.length) {
      fields.push('');
    }

    return (
      <>
        <ul className="solution-edit-feature-form">
        {fields.map((feature, index) => {
          return (<li key={`${index}_${fields.length}`} className="solution-feature-input">
            <div className="d-flex">
              <div className="flex-grow-1">
                <Field
                  name={feature}
                  type="textarea"
                  component={renderFeatureInputField}
                  initialVal={fields.get(index)}
                  childProps={{'rows': 1}}
                  validate={[requiredValidation, minLengthValidation5]}/>
                {(() => {fields.getAll()[index] && this.props.change(feature, fields.getAll()[index])})()}
              </div>
              <div>
                <button
                  className="btn btn-primary btn-icon close-button"
                  onClick={(e) => {
                    e.preventDefault();
                    if(fields.length >= 1){
                      fields.remove(index);
                    }
                  }}
                >X</button>
              </div>
            </div>
          </li>);
        })}
      </ul>
      <div>
        <button className="has-danger btn btn-outline-primary add-button" onClick={(e) => {e.preventDefault();fields.push('')}}>add item</button>
        {(touched || submitFailed) && error && <span>{error}</span>}
      </div>
     </>
        );
  };

  componentDidMount() {
    const {
      solutionListing,
      settings
    } = this.props;
    if (solutionListing.name) {
      this.props.initialize({
        ...this.props.solutionListing,
        featureAndBenefits: this.props.featureAndBenefits,
        anchorDayRadio: solutionListing.anchorDay ? "1" : "0"
      });
    } else {
      this.props.initialize(
        {
          unit: "",
          anchorDay: 1,
          anchorDayRadio: "1",
          currency: settings.defaultCurrency.id,
        }
      );
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.solutionListing.name !== this.props.solutionListing.name) {
      if (this.props.isEditing) {
        this.props.initialize({
          ...this.props.solutionListing,
          featureAndBenefits: this.props.featureAndBenefits,
          anchorDayRadio: this.props.solutionListing.anchorDay ? "1" : "0"
        });
      }
    }
  }

  validateStartSolutionPrice = () => {
    const {startSubscriptionPrice} = this.props;
    if (startSubscriptionPrice === '0.00' || startSubscriptionPrice === '0') {
      this.props.change("startSubscriptionPrice", 0);
    }
  }

  render() {
    const {
      handleSubmit,
      setLogo,
      solutionLogo,
      addRemovedAdditionalResource,
      removeAddedAdditionalResource,
      setAddedAdditionalResources,
      solutionAdditionalResources,
      setAddedMediaResources,
      solutionMediaResources,
      addRemovedMediaResource,
      removeAddedMediaResource,
      setAddedPromotionResources,
      promotionResources,
      addRemovedPromotionResource,
      removeAddedPromotionResource,
      valid,
      pristine,
      firstOneTimeShippingType,
      secondOneTimeShippingType,
      firstOneTimePrice,
      secondOneTimePrice,
      startSubscriptionPrice,
      unit,
      submitFailed,
      solution,
      settings,
      solutionId,
      solutionListing,
      firstOneTimeSubDescription,
      secondOneTimeSubDescription,
      anchorDayRadio,
      isEditing,
      currency,
      customerSizeFrom,
      setFilesSize,
      promotionName,
      promotionDescription,
    } = this.props;

    if (!settings || isEmpty(settings) || !settings.defaultCurrency) {
      return ''
    }
    const disableFieldOnEditing = settings.editSolutionPricing ? false : isEditing;

    const {featureAndBenefits} = this.props;

    return (
      <form onSubmit={handleSubmit}>
        {!valid && !pristine && submitFailed &&
        <div className="d-flex justify-content-end pr-5">
          <p className="text-danger">
            <img alt="" src={WarningIcon} className="mr-1" />
            Complete all required fields or fix invalid fields before continuing.
          </p>
        </div>
        }
        <Card>
          <div className="section">
            <Row>
              <Col md="2">
                <div className="mr-3">
                  <Field
                    name="logo"
                    type="file"
                    component={ImageUpload}
                    setFilesSize={setFilesSize}
                    previewImage={solutionLogo}
                    setLogo={setLogo}
                    // Temporary solution. When you click "Back" on "Info" form, you will come back
                    // with "solutionLogo" URL, but "file" attribute used to populate "value"
                    // attribute of Redux Form field will be empty. In result checking <input> element
                    // for logo image will fail, as required fields are expected to have non-empty "value".
                    validate={solutionLogo ? [] : [requiredValidation]}
                  />
                </div>
              </Col>
              <Col md="5" className="col-border d-flex">
                <div className="w-100">
                  <FormGroup>
                    <label>
                      <h5 className="font-weight-bold">
                        Solution Name
                        <img alt="" src={HelpIcon} className="help-icon" id="solution-name-tooltip" />
                        <UncontrolledTooltip placement="bottom" target="solution-name-tooltip">
                          <p style={{textAlign: 'left'}}>
                            This is your solution name as it will appear in the marketplace.
                          </p>
                        </UncontrolledTooltip>
                      </h5>
                    </label>
                    <Field
                      name="name"
                      type="text"
                      defaultValue="test"
                      component={renderInputField}
                      placeholder="Enter Solution Name"
                      validate={[requiredValidation, maxLengthValidation120, minLengthValidation5]}
                    />
                  </FormGroup>
                  <FormGroup>
                    <label>
                      <h5 className="font-weight-bold">
                        Short Description
                        <img alt="" src={HelpIcon} className="help-icon" id="solution-short-description-tooltip" />
                        <UncontrolledTooltip placement="bottom" target="solution-short-description-tooltip">
                          <p style={{textAlign: 'left'}}>
                            This description will be shown on the solution card in the marketplace. Max 500 characters.
                          </p>
                        </UncontrolledTooltip>
                      </h5>
                    </label>
                    <Field
                      name="summary"
                      type="textarea"
                      component={renderInputField}
                      validate={[requiredValidation, maxLengthValidation500, minLengthValidation5]}
                      childProps={{'className': 'height-adjust', 'style': {'minHeight': '4em'}}}
                    />
                  </FormGroup>
                </div>
              </Col>
              <Col md="5">
                <FormGroup>
                  <label>
                    <h5 className="font-weight-bold">
                      Company Name
                    </h5>
                  </label>
                  <Field
                    name="companyName"
                    type="text"
                    placeholder="Enter Company Name"
                    component={renderInputField}
                    validate={[requiredValidation, minLengthValidation5, maxLengthValidation120]}
                  />
                </FormGroup>
                <FormGroup>
                  <label>
                    <h5 className="font-weight-bold">
                      Cost (preview)
                      <img alt="" src={HelpIcon} className="help-icon" id="solution-cost-preview-tooltip" />
                      <UncontrolledTooltip placement="bottom" target="solution-cost-preview-tooltip">
                        <p style={{textAlign: 'left'}}>
                          Enter price info below. Use minimum customer size to set the minimum # of units sold online.
                          Enter subscription and any one-time costs.
                        </p>
                      </UncontrolledTooltip>
                    </h5>
                  </label>
                  <p>
                    <b>Starting at <Currency currency={currency} value={startSubscriptionPrice} /></b> <b>per </b>
                    {
                      unit === 'D' && "Driver"
                    }
                    {
                      unit === 'A' && "Asset"
                    }
                    {
                      unit === 'V' && "Vehicle"
                    }
                  </p>
                </FormGroup>
              </Col>
            </Row>
          </div>
          <div className="section mt-4">
            <Row>
              <Col md="2" className="d-flex align-items-center">
                <h4>
                  Who It's For
                </h4>
              </Col>
              <Col md="1" className="d-flex align-items-center">
                <div className="divider" />
              </Col>
              <Col md="3">
                <div className="d-flex align-items-center py-3">
                  <CircleBorder size={35} backgroundColor={"#000000"}>
                    <img alt="Fleet Size" src={FleetIcon} />
                  </CircleBorder>
                  <h4 className="m-0" style={{paddingLeft: "10px"}}>
                    Fleet Size
                  </h4>
                </div>
                <p className="pl-5">
                  {unit && customerSizeFrom &&
                    <>
                      {Pluralize(ENTITY_TYPE_OPTIONS[unit], customerSizeFrom, true)} and Larger
                    </>
                  }
                </p>
              </Col>
              <Col md="3">
                <div className="d-flex align-items-center py-3">
                  <CircleBorder size={35} backgroundColor={"#000000"}>
                    <img alt="Fleet Segments" src={FillIcon} />
                  </CircleBorder>
                  <h4 className="m-0" style={{paddingLeft: "10px"}}>
                    Fleet Segments
                  </h4>
                </div>
                <div className="pl-5">
                  <FormGroup>
                    <Field
                      name="fleetSegments"
                      type="textarea"
                      component={renderInputField}
                      validate={[requiredValidation, maxLengthValidation256]}
                      childProps={{'className': 'height-adjust', 'style': {'minHeight': '4em'}}}
                    />
                  </FormGroup>
                </div>
              </Col>
              <Col md="3">
                <div className="d-flex align-items-center py-3">
                  <CircleBorder size={35} backgroundColor={"#000000"}>
                    <img alt="Vehicle Classes" src={VehiclesIcon} />
                  </CircleBorder>
                  <h4 className="m-0" style={{paddingLeft: "10px"}}>
                    Vehicle Classes
                  </h4>
                </div>
                <div className="pl-5">
                  <FormGroup>
                    <Field
                      name="vehicleClasses"
                      type="textarea"
                      component={renderInputField}
                      validate={[requiredValidation, maxLengthValidation256]}
                      childProps={{'className': 'height-adjust', 'style': {'minHeight': '4em'}}}
                    />
                  </FormGroup>
                </div>
              </Col>
            </Row>
          </div>
          <div className="section">
            <Row>
              <Col>
                <h4 className="font-weight-bold">
                  Price
                </h4>
                <p>
                  Input solution price info, then
                  <a href="#"> update the preview</a> above.
                </p>
                <div>
                  <div className="mb-2 cost-options-panel">
                    <h5 className="font-weight-bold">
                      Minimum customer size
                    </h5>
                    <div className="mt-3 d-flex">
                      <p className="mt-2">Starting prices below apply to customers with</p>

                      <FormGroup inline>
                        <Field
                          name="customerSizeFrom"
                          type="number"
                          component={renderInputField}
                          normalize={nonNegativeIntNormalizer}
                          validate={[requiredValidation, integerValidation, positiveNumberValidation, maxNumber500000000Validation]}
                        />
                      </FormGroup>

                      <p className="mt-2">to</p>

                      <FormGroup inline>
                        <Field
                          name="customerSizeTo"
                          type="number"
                          validate={[requiredValidation, integerValidation, positiveNumberValidation, notZeroValidation, maxNumber500000000Validation]}
                          component={renderInputField}
                          normalize={nonNegativeIntNormalizer}
                        />
                      </FormGroup>
                      <FormGroup inline>
                        <Field
                          name="unit"
                          component={renderSelectField}
                          type="select"
                          validate={[requiredValidation]}
                          defaultValue=""
                        >
                          <option value="">Select units</option>
                          <option value="D">Drivers</option>
                          <option value="V">Vehicles</option>
                          <option value="A">Assets</option>
                        </Field>
                      </FormGroup>
                    </div>
                  </div>

                  <div className="mb-2 cost-options-panel">
                    <Row>
                      <Col md="3">
                        <h5 className="font-weight-bold">
                          Subscription
                        </h5>
                        <p>
                          Price per unit
                        </p>
                      </Col>
                      <Col md="4">
                        <h5 className="font-weight-bold">
                          Starting at price
                        </h5>
                        <div className="d-flex mt-3">
                          <p className="mt-2">{currency && settings.currencies[currency].symbol}</p>
                          <FormGroup inline className="pl-2">
                            <Field
                              name="startSubscriptionPrice"
                              type="number"
                              decimals={2}
                              disabled={disableFieldOnEditing}
                              validate={[requiredValidation, positiveNumberValidation, freeSolutionValidation]}
                              component={renderFloatNumberField}
                              normalize={nonNegativeNumbers}
                            />
                          </FormGroup>
                            {settings.enableSelectCurrency &&
                              <FormGroup inline className="m-0 p-0">
                                <Field
                                  type="select"
                                  name="currency"
                                  disabled={disableFieldOnEditing}
                                  defaultValue={settings.defaultCurrency.id}
                                  component={renderSelectField}
                                  validate={[]}
                                >
                                  {Object.keys(settings.currencies).map((key) => (
                                    <option
                                      key={settings.currencies[key].id}
                                      value={settings.currencies[key].id}
                                    >
                                      {settings.currencies[key].code}
                                    </option>
                                  ))}
                                </Field>
                              </FormGroup>
                            }
                        </div>
                      </Col>
                      {settings.paymentInterval &&
                        <Col md="3">
                          <h5 className="font-weight-bold">
                            Payment interval
                          </h5>
                          <div className="align-items-start d-flex">
                            <FormGroup inline className="ml-0">
                              <Field
                                type="select"
                                name="paymentInterval"
                                disabled={disableFieldOnEditing}
                                defaultValue={SUBSCRIPTION_PAYMENT_INTERVAL_MONTH_VALUE}
                                component={renderSelectField}
                                validate={[]}
                              >
                                {Object.keys(SUBSCRIPTION_PAYMENT_METHOD_INTERVALS).map((key, index) => (
                                  <option key={index} value={key}>{SUBSCRIPTION_PAYMENT_METHOD_INTERVALS[key]}</option>
                                ))}
                              </Field>
                            </FormGroup>
                          </div>
                        </Col>
                      }
                    </Row>

                    {settings.anchorDay &&
                      <Row className="mt-3">
                        <Col>
                          <h5 className="font-weight-bold">
                            Billing Cycle Anchor Day
                          </h5>
                          <div className="d-flex">
                            <div className="form-check-radio mr-4 mt-2">
                              <Label check>
                                <Field
                                  type="radio"
                                  name="anchorDayRadio"
                                  disabled={disableFieldOnEditing}
                                  id="anchorDayRadioSubscribed"
                                  component={renderCheckboxField}
                                  value="0"
                                  onChange={
                                    () => {
                                      this.props.change("anchorDay", 0);
                                    }
                                  }
                                />
                                <span className="custom-radio-label">On subscription activation date</span> <span className="form-check-sign" />
                              </Label>
                            </div>
                            <div className="form-check-radio mt-2">
                              <Label check>
                                <Field
                                  type="radio"
                                  name="anchorDayRadio"
                                  disabled={disableFieldOnEditing}
                                  id="anchorDayRadioDayOfMonth"
                                  component={renderCheckboxField}
                                  value="1"
                                />
                                <span className="custom-radio-label">On this day of month: </span>
                                <span className="form-check-sign" />
                              </Label>
                            </div>
                            {anchorDayRadio === "1" &&
                              <div className="ml-2 mt-1">
                                <Field
                                  type="select"
                                  name="anchorDay"
                                  disabled={disableFieldOnEditing}
                                  component={renderSelectField}
                                  validate={[]}
                                  onFocus={
                                    () => {
                                      this.props.change("anchorDayRadio", "1");
                                    }
                                  }
                                >
                                  {Array.from(Array(32), (e, i) => {
                                    const value = i === 0 ? "-- Select --" : i;
                                    return <option key={i} value={i}>{value}</option>
                                  })}
                                </Field>
                              </div>
                            }
                          </div>
                        </Col>
                      </Row>
                    }
                  </div>

                  <div className="mb-2 cost-options-panel">
                    <Row>
                      <Col xl="12">
                        <h5 className="font-weight-bold">
                          One-time costs
                        </h5>
                        <p>
                          Enter one-time costs such as hardware or shipping, priced per unit.
                          These charges are collected during checkout.
                        </p>
                      </Col>
                      <Col xl="12">
                        <Row>
                          <Col md="2"><h5 className="font-weight-bold">Price per unit</h5></Col>
                          <Col md="3"><h5 className="font-weight-bold">Brief description</h5></Col>
                          <Col md="2"><h5 className="font-weight-bold">Shipping type</h5></Col>
                          <Col md="2"><h5 className="font-weight-bold">Shipping price</h5></Col>
                          {settings.taxCodes && settings.taxCodes.length > 0 && (currency && settings.currencies[currency].code.toLowerCase() === 'usd') &&
                            <Col md="3"><h5 className="font-weight-bold">Tax code</h5></Col>
                          }
                        </Row>
                        <Row>
                          {/*Price per unit*/}
                          <Col md="2">
                            <div className="align-items-center d-flex mb-3">
                              {currency && settings.currencies[currency].symbol}
                              <FormGroup>
                                <Field
                                  name="firstOneTimePrice"
                                  type="number"
                                  validate={[positiveNumberValidation, notZeroValidation, minNumber1Validation]}
                                  disabled={disableFieldOnEditing}
                                  component={renderFloatNumberField}
                                  normalize={nonNegativeNumbers}
                                  onChange={
                                    (event) => {
                                      if (event && event.target && !event.target.value) {
                                        this.props.change("firstOneTimeSubDescription", "");
                                        this.props.change("firstOneTimeShippingType", SOLUTION_1_OTC_SHIPPING_TYPE_NO_SHIPPING_VALUE);
                                        this.props.change("firstOneTimeShippingPrice", null);
                                        this.props.change("firstOneTimeTaxCode", null);
                                        this.props.change("secondOneTimePrice", null);
                                        this.props.change("secondOneTimeSubDescription", "");
                                        this.props.change("secondOneTimeShippingType", SOLUTION_2_OTC_SHIPPING_TYPE_NO_SHIPPING_VALUE);
                                        this.props.change("secondOneTimeShippingPrice", null);
                                        this.props.change("secondOneTimeTaxCode", null);
                                      }
                                    }
                                  }
                                />
                              </FormGroup>
                            </div>
                          </Col>
                          {/*Brief Description*/}
                          <Col md="3">
                            <FormGroup className="m-0 mb-3">
                              <Field
                                name="firstOneTimeSubDescription"
                                type="text"
                                component={renderInputField}
                                disabled={!parseFloat(firstOneTimePrice) || disableFieldOnEditing}
                                validate={parseFloat(firstOneTimePrice) ? [requiredValidation, minLengthValidation5, maxLengthValidation120] : []}
                              />
                            </FormGroup>
                          </Col>
                          {/*Shipping type*/}
                          <Col md="2">
                            <FormGroup inline className="mb-3">
                              <Field
                                name="firstOneTimeShippingType"
                                component={renderSelectField}
                                type="select"
                                disabled={!parseFloat(firstOneTimePrice) || disableFieldOnEditing}
                                validate={parseFloat(firstOneTimePrice) ? [requiredValidation] : []}
                                onChange={
                                  (event) => {
                                    if (event.target.value === SOLUTION_1_OTC_SHIPPING_TYPE_NO_SHIPPING_VALUE) {
                                      this.props.change("firstOneTimeShippingPrice", null);
                                    }
                                  }
                                }
                              >
                                <option value="" hidden />
                                {Object.keys(SOLUTION_1_OTC_SHIPPING_TYPE_OPTIONS).map((key, index) => (
                                  <option key={index} value={key}>{SOLUTION_1_OTC_SHIPPING_TYPE_OPTIONS[key]}</option>
                                ))}
                              </Field>
                            </FormGroup>
                          </Col>
                          {/*Shipping price per unit*/}
                          <Col md="2">
                            <div className="align-items-center d-flex mb-3">
                              {currency && settings.currencies[currency].symbol}
                              <FormGroup>
                                <Field
                                  name="firstOneTimeShippingPrice"
                                  type="number"
                                  disabled={!firstOneTimeShippingType || firstOneTimeShippingType === SOLUTION_1_OTC_SHIPPING_TYPE_NO_SHIPPING_VALUE || disableFieldOnEditing}
                                  validate={firstOneTimeShippingType && firstOneTimeShippingType !== SOLUTION_1_OTC_SHIPPING_TYPE_NO_SHIPPING_VALUE ? [requiredValidation, positiveNumberValidation, notZeroValidation] : []}
                                  component={renderFloatNumberField}
                                  normalize={nonNegativeNumbers}
                                />
                              </FormGroup>
                            </div>
                          </Col>
                          {/*Tax code*/}
                          <Col md="3">
                            {settings.taxCodes && settings.taxCodes.length > 0 && (currency && settings.currencies[currency].code.toLowerCase() === 'usd') &&
                              <FormGroup inline className="mb-3">
                                <Field
                                  name="firstOneTimeTaxCode"
                                  component={renderSelectField}
                                  type="select"
                                  disabled={!parseFloat(firstOneTimePrice) || disableFieldOnEditing}
                                >
                                  <option value=""/>
                                  {settings.taxCodes && settings.taxCodes.map((taxCode, index) => (
                                    <option key={index} value={taxCode.id}>{taxCode.category}</option>
                                  ))}
                                </Field>
                              </FormGroup>
                            }
                          </Col>
                        </Row>

                        <Row>
                          {/*Price per unit (2)*/}
                          <Col md="2">
                            <div className="align-items-center d-flex">
                              {currency && settings.currencies[currency].symbol}
                              <FormGroup>
                                <Field
                                  name="secondOneTimePrice"
                                  type="number"
                                  validate={[positiveNumberValidation, notZeroValidation, minNumber1Validation]}
                                  component={renderFloatNumberField}
                                  normalize={nonNegativeNumbers}
                                  disabled={!parseFloat(firstOneTimePrice) || disableFieldOnEditing}
                                  onChange={
                                    (event) => {
                                      if (event && event.target && !event.target.value) {
                                        this.props.change("secondOneTimeSubDescription", "");
                                        this.props.change("secondOneTimeShippingType", SOLUTION_2_OTC_SHIPPING_TYPE_NO_SHIPPING_VALUE);
                                        this.props.change("secondOneTimeShippingPrice", null);
                                        this.props.change("secondOneTimeTaxCode", null);
                                      }
                                    }
                                  }
                                />
                              </FormGroup>
                            </div>
                          </Col>
                          {/*Brief Description (2)*/}
                          <Col md="3">
                            <FormGroup className="m-0">
                              <Field
                                name="secondOneTimeSubDescription"
                                type="text"
                                disabled={(!(parseFloat(firstOneTimePrice) && parseFloat(secondOneTimePrice))) || disableFieldOnEditing}
                                validate={parseFloat(secondOneTimePrice) ? [requiredValidation, minLengthValidation5, maxLengthValidation120] : []}
                                component={renderInputField}
                              />
                            </FormGroup>
                          </Col>
                          {/*Shipping type (2)*/}
                          <Col md="2">
                            <FormGroup inline className="mb-3">
                              <Field
                                name="secondOneTimeShippingType"
                                component={renderSelectField}
                                type="select"
                                disabled={!parseFloat(secondOneTimePrice) || disableFieldOnEditing}
                                validate={parseFloat(secondOneTimePrice) ? [requiredValidation] : []}
                                onChange={
                                  (event) => {
                                    if (event.target.value === SOLUTION_2_OTC_SHIPPING_TYPE_NO_SHIPPING_VALUE) {
                                      this.props.change("secondOneTimeShippingPrice", null);
                                    }
                                  }
                                }
                              >
                                <option value="" hidden />
                                {Object.keys(SOLUTION_2_OTC_SHIPPING_TYPE_OPTIONS).map((key, index) => (
                                  <option key={index} value={key}>{SOLUTION_2_OTC_SHIPPING_TYPE_OPTIONS[key]}</option>
                                ))}
                              </Field>
                            </FormGroup>
                          </Col>
                          {/*Shipping price per unit (2)*/}
                          <Col md="2">
                            <div className="align-items-center d-flex mb-3">
                              {currency && settings.currencies[currency].symbol}
                              <FormGroup>
                                <Field
                                  name="secondOneTimeShippingPrice"
                                  type="number"
                                  disabled={!secondOneTimeShippingType || secondOneTimeShippingType === SOLUTION_2_OTC_SHIPPING_TYPE_NO_SHIPPING_VALUE || disableFieldOnEditing}
                                  validate={secondOneTimeShippingType && secondOneTimeShippingType !== SOLUTION_2_OTC_SHIPPING_TYPE_NO_SHIPPING_VALUE ? [requiredValidation, positiveNumberValidation, notZeroValidation] : []}
                                  component={renderFloatNumberField}
                                  normalize={nonNegativeNumbers}
                                />
                              </FormGroup>
                            </div>
                          </Col>
                          {/*Tax code (2)*/}
                          <Col md="3">
                            {settings.taxCodes && settings.taxCodes.length > 0 && (currency && settings.currencies[currency].code.toLowerCase() === 'usd') &&
                              <FormGroup inline className="mb-3">
                                <Field
                                  name="secondOneTimeTaxCode"
                                  component={renderSelectField}
                                  type="select"
                                  disabled={!parseFloat(secondOneTimePrice) || disableFieldOnEditing}
                                >
                                  <option value=""/>
                                  {settings.taxCodes && settings.taxCodes.map((taxCode, index) => (
                                    <option key={index} value={taxCode.id}>{taxCode.category}</option>
                                  ))}
                                </Field>
                              </FormGroup>
                            }
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                  {settings.discount &&
                    <div className="mb-2 cost-options-panel">
                      <SolutionDiscount
                        solutionId={solutionId}
                        solutionListing={solutionListing}
                        startSubscriptionPrice={startSubscriptionPrice}
                        firstOneTimeSubDescription={firstOneTimeSubDescription}
                        secondOneTimeSubDescription={secondOneTimeSubDescription}
                        disableFieldOnEditing={disableFieldOnEditing}
                        validateStartSolutionPrice={this.validateStartSolutionPrice}
                      />
                    </div>
                  }
                  {settings.promotions &&
                    <div className="mb-2 cost-options-panel">
                      <Row className="mt-2">
                        <Col>
                          <h5 className="font-weight-bold mb-2">
                            Promotion
                          </h5>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Row className="mt-1">
                            <Col xl={8}>
                              <FormGroup>
                                <label>
                                  <h5 className="font-weight-bold mb-2">
                                    Title
                                  </h5>
                                </label>
                                <Field
                                  name="promotionName"
                                  type="text"
                                  component={renderInputField}
                                  validate={[maxLengthValidation120, minLengthValidation5]}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row className="mt-3">
                            <Col xl={8}>
                              <FormGroup inline>
                                <label>
                                  <h5 className="font-weight-bold mb-2">
                                    Description
                                  </h5>
                                </label>
                                <Field
                                  name="promotionDescription"
                                  type="textarea"
                                  component={renderInputField}
                                  validate={[maxLengthValidation500, minLengthValidation5]}
                                  childProps={{'className': 'height-adjust', 'style': {'minHeight': '4em'}}}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row className="mt-3">
                            <Col xl={6}>
                              <FormGroup>
                                <label>
                                  <h5 className="font-weight-bold mb-2">
                                    Badge Main Text
                                  </h5>
                                </label>
                                <Field
                                  name="promotionBadgeMain"
                                  type="text"
                                  component={renderInputField}
                                  validate={[maxLengthValidation20, minLengthValidation5]}
                                />
                              </FormGroup>
                            </Col>
                            <Col xl={6}>
                              <FormGroup>
                                <label>
                                  <h5 className="font-weight-bold mb-2">
                                    Badge Small Text
                                  </h5>
                                </label>
                                <Field
                                  name="promotionBadgeSmall"
                                  type="text"
                                  component={renderInputField}
                                  validate={[maxLengthValidation20, minLengthValidation5]}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row className="mt-3">
                            <Col xl={8}>
                              <FormGroup inline>
                                <label>
                                  <h5 className="font-weight-bold mb-2">
                                    Promotion Image
                                  </h5>
                                </label>
                                {(!solution || (solution && !solution.id) || (solution && promotionResources !== null)) &&
                                  <SolutionPromotionResourceUpload
                                      setFilesSize={setFilesSize}
                                      setAddedResources={setAddedPromotionResources}
                                      resources={promotionResources}
                                      addRemovedResource={addRemovedPromotionResource}
                                      removeAddedResource={removeAddedPromotionResource}
                                      submitFailed={submitFailed}
                                      imageRequired={promotionName || promotionDescription}
                                      change={this.props.change}
                                  />
                                }
                              </FormGroup>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  }
                </div>
              </Col>
            </Row>
          </div>
          <div className="section">
            <Row>
              <Col>
                <h4 className="font-weight-bold">
                  Description
                </h4>
                <FormGroup>
                  <Field
                    name="description"
                    type="textarea"
                    component={renderInputField}
                    validate={[requiredValidation]}
                    childProps={{'className': 'height-adjust', 'style': {'minHeight': '4em'}}}
                  />
                </FormGroup>
              </Col>
            </Row>
          </div>
          <div className="section">
            <SolutionMediaResourcesUpload
              setFilesSize={setFilesSize}
              setAddedMediaResources={setAddedMediaResources}
              solutionMediaResources={solutionMediaResources}
              addRemovedMediaResource={addRemovedMediaResource}
              removeAddedMediaResource={removeAddedMediaResource}
              manageSolutionMediaUrlResources={this.props.manageSolutionMediaUrlResources}
              submitFailed={submitFailed}
            />
          </div>
          {!solution.isLoading &&
          <div className="section">
            <Row>
              <Col>
                <h4>
                  Features & Benefits
                </h4>
                <FormGroup>
                  {(!isEditing || (isEditing && featureAndBenefits)) &&
                    <FieldArray
                      name="featureAndBenefits"
                      component={this.renderFeatureFields}
                      rerenderOnEveryChange={true}
                    />
                  }
                </FormGroup>
              </Col>
            </Row>
          </div>
          }
          <div className="section">
            <Row className="section">
              <Col md="6">
                <h4>
                  About the company
                </h4>
                <FormGroup>
                  <Field
                    name="companyDescription"
                    type="textarea"
                    component={renderInputField}
                    validate={[requiredValidation, minLengthValidation5]}
                    childProps={{'className': 'height-adjust', 'style': {'minHeight': '4em'}}}
                  />
                </FormGroup>
              </Col>
              <Col md="6">
                <SolutionAdditionalResourcesUpload
                  setFilesSize={setFilesSize}
                  addedAdditionalResources={this.props.addedAdditionalResources}
                  setAddedAdditionalResources={setAddedAdditionalResources}
                  solutionAdditionalResources={solutionAdditionalResources}
                  addRemovedAdditionalResource={addRemovedAdditionalResource}
                  removeAddedAdditionalResource={removeAddedAdditionalResource}
                />
              </Col>
            </Row>
          </div>
        </ Card>
        {!valid && !pristine && submitFailed &&
        <div className="d-flex justify-content-end pr-5 mt-2">
          <p className="text-danger">
            <img alt="" src={WarningIcon} className="mr-1" />
            Complete all required fields or fix invalid fields before continuing.
          </p>
        </div>
        }
      </form>
    );
  }
}

const validate = (values) => {
  const errors = {};
  const {
    customerSizeFrom,
    customerSizeTo,
    promotionName,
    promotionDescription,
  } = values;

  if (promotionName && !promotionDescription) {
    errors.promotionDescription = "Required"
  }
  if (!promotionName && promotionDescription) {
    errors.promotionName = "Required"
  }
  if (parseInt(customerSizeFrom) > parseInt(customerSizeTo)) {
    errors.customerSizeFrom = "Lower bound size should be less than upper bound size"
  }
  return errors;
};

const selector = formValueSelector('SolutionListing');

SolutionListingEditNewForm = connect(
  state => ({
    firstOneTimePrice: selector(state, 'firstOneTimePrice'),
    firstOneTimeShippingType: selector(state, 'firstOneTimeShippingType'),
    firstOneTimeSubDescription: selector(state, 'firstOneTimeSubDescription'),
    secondOneTimePrice: selector(state, 'secondOneTimePrice'),
    secondOneTimeShippingType: selector(state, 'secondOneTimeShippingType'),
    secondOneTimeSubDescription: selector(state, 'secondOneTimeSubDescription'),
    startSubscriptionPrice: selector(state, 'startSubscriptionPrice'),
    anchorDayRadio: selector(state, 'anchorDayRadio'),
    promotionName: selector(state, 'promotionName'),
    customerSizeFrom: selector(state, 'customerSizeFrom'),
    promotionDescription: selector(state, 'promotionDescription'),
    currency: selector(state, 'currency'),
    unit: selector(state, 'unit'),
    solution: state.solutionDetail,
    settings: state.settings
  })
)(SolutionListingEditNewForm);

SolutionListingEditNewForm = reduxForm({
  form: 'SolutionListing',
  validate
})(
  SolutionListingEditNewForm
);

export default SolutionListingEditNewForm;
