/* eslint-disable linebreak-style */
import axios from 'axios';
import {
  SETTINGS_FETCH_REQUEST,
  SETTINGS_FETCH_SUCCESS,
  SETTINGS_FETCH_ERROR,
} from './actionTypes';
import { updateSpinner } from "./spinnerActions";


export const fetchSettings = () => (dispatch, state) => {
  dispatch(updateSpinner(true));
  dispatch({ type: SETTINGS_FETCH_REQUEST , request: true});

  const { mode } = state().account;
  const url = `/api/core/settings/?mode=${mode}`;

  axios.get(url)
    .then(({ data }) => {
      if (data) {
        dispatch({ type: SETTINGS_FETCH_SUCCESS, data });
      } else {
        dispatch({ type: SETTINGS_FETCH_ERROR, error: { mes: "" }});
      }
      dispatch(updateSpinner(false));
    })
    .catch((error) => {
      dispatch({ type: SETTINGS_FETCH_ERROR, error });
      dispatch(updateSpinner(false));
    });
};
