/*eslint-disable*/
import React from "react";
import { Container, Row, Col } from "reactstrap";
// used for making the prop types of this component
import PropTypes from "prop-types";

class Footer extends React.Component {
  render() {
    return (
      <footer
        className={"footer footer-default"}
      >
        <Container fluid={this.props.fluid ? true : false}>
          <Row>
            <div className="credits ml-auto mr-auto">
              <div className="copyright">
                &copy; {1900 + new Date().getYear()} Roadz, Inc.
                All Rights Reserved | 
                Contact us at <a href="mailto:info@roadz.com">info@roadz.com</a>
              </div>
            </div>
          </Row>
        </Container>
      </footer>
    );
  }
}

Footer.propTypes = {
  default: PropTypes.bool,
  fluid: PropTypes.bool
};

export default Footer;
