import React from "react";
import { Field, reduxForm } from "redux-form";

import {
  Col,
  FormGroup,
  Row
} from "reactstrap";

import {
  minLengthValidation5,
  requiredValidation,
  maxLengthValidation120,
  urlValidation,
  maxLengthValidation256
} from "../../../views/forms/validation";

import {
  renderInputField,
} from "../../../views/forms/utils";

class SolutionAdditionalResourcesUploadForm extends React.Component {
  render() {
    const {handleSubmit} = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <div>
          <Row className="mt-3">
            <Col md={3}>
              <h5 className="font-weight-bold">
                Name
              </h5>
            </Col>
            <Col>
              <FormGroup>
                <Field
                  name="name"
                  type="text"
                  component={renderInputField}
                  placeholder="Resource Name"
                  validate={[requiredValidation, maxLengthValidation120, minLengthValidation5]}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <h5 className="font-weight-bold">
                URL
              </h5>
            </Col>
            <Col>
              <FormGroup>
                <Field
                  name="url"
                  type="url"
                  component={renderInputField}
                  placeholder="https://example.com/"
                  validate={[requiredValidation, urlValidation, maxLengthValidation256]}
                />
              </FormGroup>
            </Col>
          </Row>
        </div>
      </form>
    );
  }
}

SolutionAdditionalResourcesUploadForm = reduxForm({
  form: 'SolutionAdditionalResourcesUploadForm'
})(
  SolutionAdditionalResourcesUploadForm
);

export default SolutionAdditionalResourcesUploadForm;
