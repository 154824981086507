import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import snakeCaseKeys from "snakecase-keys";
import { Button, Col, Row } from "reactstrap";

import { LEAD_TYPE_TRIAL_VALUE } from "../../config/lead";
import { fetchMarketplaceList } from "../../store/actions/marketplaceListActions";
import {
  fetchLeadDetail,
  addLeadDetail,
  updateLeadDetail,
  clearLeadDetail,
} from "../../store/actions/leadDetailActions";
import LeadNewDetailForm from "../forms/LeadNewDetailForm";
import { addMessage } from "../../store/actions/messageActions";
import { Auth0Context } from "../../services/auth/react-auth0-wrapper";
import { saveFleetDetail } from "../../store/actions/customerDetailActions";
import { SOLUTION_TYPE_FREE_VALUE } from "../../config/solution";
import TrialMsg from "../../components/Leads/TrialMsg";
import { ActiveSubscriptionsByHostFleetIdAndHost } from "../../store/actions/subscriptionListActions";
import camelcaseKeys from "camelcase-keys";

const { updateSyncErrors } = require("redux-form/lib/actions").default;

class LeadNewDetail extends React.Component {
  static contextType = Auth0Context;

  constructor(props) {
    super(props);
    this.form = React.createRef();
    this.saveFormButton = React.createRef();
    this.state = {
      saveLeadOnSubscriptionClick: false,
      subscriptionFromOtherLeadExists: null,
      subscriptionFromOtherLeadRequestSent: false,
    };
  }

  componentDidMount() {
    const {
      fetchLeadDetail,
      fetchMarketplaceList,
      match: {
        params: { leadId },
      },
    } = this.props;

    fetchMarketplaceList();
    if (leadId) {
      fetchLeadDetail(leadId);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      leadDetail: { isUpdating: isPrevUpdating, isAdding: isPrevAdding },
    } = prevProps;
    const {
      leadDetail: { error, isUpdating, isAdding, lead },
      ActiveSubscriptionsByHostFleetIdAndHost,
    } = this.props;
    const { subscriptionFromOtherLeadRequestSent } = this.state;

    if (
      !error &&
      ((isPrevUpdating === true && isUpdating === false) ||
        (isPrevAdding === true && isAdding === false))
    ) {
      this.props.history.push("/admin/leads/");
    }

    if (
      subscriptionFromOtherLeadRequestSent === false &&
      lead.hostFleetId &&
      lead.solution.id &&
      lead.marketplace &&
      lead.marketplace.host &&
      lead.marketplace.host.id
    ) {
      ActiveSubscriptionsByHostFleetIdAndHost(
        lead.hostFleetId,
        lead.solution.id,
        lead.marketplace.host.id
      ).then((res) => {
        if (res.data) {
          if (res.data.length > 0) {
            this.setState({ subscriptionFromOtherLeadExists: true });
          } else {
            this.setState({ subscriptionFromOtherLeadExists: false });
          }
        }
      });

      this.setState({ subscriptionFromOtherLeadRequestSent: true });
    }
  }

  componentWillUnmount() {
    if (!this.state.saveLeadOnSubscriptionClick) this.props.clearLeadDetail();
  }

  submitLeadForm(event) {
    this.form.current.submit();
    event.preventDefault();
  }

  onSubmit(values, dispatch) {
    const {
      addLeadDetail,
      updateLeadDetail,
      match: {
        params: { leadId },
      },
    } = this.props;

    values["solution"] = parseInt(values["solution"]);

    const data = snakeCaseKeys(values, { deep: true });
    if (!leadId) {
      addLeadDetail(data).then((err) => {
        if (err) {
          this.setState({ saveLeadOnSubscriptionClick: false });
          try {
            const fieldErrors = camelcaseKeys(err.response.data, {
              deep: true,
            });
            if (fieldErrors) {
              for (const [key, value] of Object.entries(fieldErrors)) {
                dispatch(
                  updateSyncErrors("LeadNewDetail", {
                    [key]: value,
                  })
                );
              }
            }
          } catch (e) {}
        } else {
          this.props.addMessage("Lead created");
        }
      });
    } else {
      updateLeadDetail(leadId, data).then((err) => {
        // When we click "Create Subscription" button we automatically update the lead
        // and set saveLeadOnSubscriptionClick to true.
        // In this case we don't want to show "Lead saved" message
        if (err) {
          this.setState({ saveLeadOnSubscriptionClick: false });
          try {
            const fieldErrors = camelcaseKeys(err.response.data, {
              deep: true,
            });
            if (fieldErrors) {
              for (const [key, value] of Object.entries(fieldErrors)) {
                dispatch(
                  updateSyncErrors("LeadNewDetail", {
                    [key]: value,
                  })
                );
              }
            }
          } catch (e) {}
        } else {
          if (!this.state.saveLeadOnSubscriptionClick)
            this.props.addMessage("Lead saved");
          if (this.state.saveLeadOnSubscriptionClick) {
            this.props.history.push("/admin/customers/new");
          }
        }
      });
    }
  }

  render() {
    const { account } = this.props;

    const {
      match: {
        params: { leadId },
      },
      marketplaces,
      leadDetail: {
        lead,
        lead: { subscriptionSet },
        isLoading,
      },
    } = this.props;

    const { subscriptionFromOtherLeadExists } = this.state;

    return (
      <>
        <div
          className="breadcrumb-nav"
          style={
            lead && lead.type === LEAD_TYPE_TRIAL_VALUE
              ? { paddingBottom: "6px" }
              : null
          }
        >
          <div>
            <ol className="breadcrumb-container">
              <li className="breadcrumb-container__item">
                <h5>
                  <Link to="/admin/leads">Leads</Link>
                </h5>
              </li>
              <li className="breadcrumb-container__item">
                <h5>/</h5>
              </li>
              <li className="breadcrumb-container__item">
                <h5>{leadId ? "Lead Details" : "Lead New"}</h5>
              </li>
            </ol>
          </div>

          {leadId && (
            <div className="pull-right m-0">
              <Button
                color="outline-primary"
                className="mr-3"
                onClick={() => this.props.history.push("/admin/leads")}
              >
                Cancel
              </Button>
              {account.permissions.includes("write:leads") && (
                <Button
                  innerRef={this.saveFormButton}
                  color="outline-primary"
                  className="mr-3"
                  onClick={this.submitLeadForm.bind(this)}
                >
                  Save
                </Button>
              )}
              {subscriptionFromOtherLeadExists ? null : subscriptionSet &&
                subscriptionSet[0] ? (
                <Button
                  color="primary"
                  onClick={() => {
                    this.props.history.push(
                      `/admin/customers/${subscriptionSet[0].id}/`
                    );
                  }}
                >
                  Review Subscription
                </Button>
              ) : lead &&
                lead.solution &&
                lead.solution.solutionType !== SOLUTION_TYPE_FREE_VALUE ? (
                <>
                  {account.permissions.includes("write:customers") && (
                    <Button
                      color="primary"
                      onClick={() => {
                        this.setState(
                          { saveLeadOnSubscriptionClick: true },
                          () => {
                            this.saveFormButton.current.click();
                          }
                        );
                      }}
                    >
                      Create Subscription
                    </Button>
                  )}
                </>
              ) : null}
            </div>
          )}
        </div>

        <TrialMsg
          leadType={lead && lead.type}
          pStyle={{ paddingRight: "30px" }}
        />

        <div className="content lead-edit-new-page">
          <LeadNewDetailForm
            ref={this.form}
            onSubmit={this.onSubmit.bind(this)}
            marketplaces={marketplaces}
            lead={lead}
            isEditing={!!leadId}
            isLoading={isLoading}
            hasSubscription={subscriptionSet && subscriptionSet[0]}
          />
          <div className="page-content-footer">
            <TrialMsg leadType={lead && lead.type} />

            <Row>
              <Col className="text-right">
                <Button
                  color="outline-primary"
                  className="mr-3"
                  onClick={() => this.props.history.push("/admin/leads")}
                >
                  Cancel
                </Button>
                {account.permissions.includes("write:leads") && (
                  <Button
                    color="outline-primary"
                    className="mr-3"
                    onClick={this.submitLeadForm.bind(this)}
                  >
                    Save
                  </Button>
                )}
                {leadId && (
                  <>
                    {subscriptionFromOtherLeadExists ? null : subscriptionSet &&
                      subscriptionSet[0] ? (
                      <Button
                        color="primary"
                        onClick={() => {
                          this.props.history.push(
                            `/admin/customers/${subscriptionSet[0].id}/`
                          );
                        }}
                      >
                        Review Subscription
                      </Button>
                    ) : lead &&
                      lead.solution &&
                      lead.solution.solutionType !==
                        SOLUTION_TYPE_FREE_VALUE ? (
                      <>
                        {account.permissions.includes("write:customers") && (
                          <Button
                            color="primary"
                            onClick={() => {
                              this.setState(
                                { saveLeadOnSubscriptionClick: true },
                                () => {
                                  this.saveFormButton.current.click();
                                }
                              );
                            }}
                          >
                            Create Subscription
                          </Button>
                        )}
                      </>
                    ) : null}
                  </>
                )}
              </Col>
            </Row>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  marketplaces: state.marketplaceList.marketplaces,
  leadDetail: state.leadDetail,
  account: state.account,
});

const mapDispatchToProps = {
  fetchMarketplaceList,
  fetchLeadDetail,
  addLeadDetail,
  clearLeadDetail,
  updateLeadDetail,
  addMessage,
  saveFleetDetail,
  ActiveSubscriptionsByHostFleetIdAndHost,
};

export default connect(mapStateToProps, mapDispatchToProps)(LeadNewDetail);

// Exports for easy testing
export { LeadNewDetail };
