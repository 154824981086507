import React from "react";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input } from "reactstrap";

export class DropdownSelectSearchField extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      open: false,
      value: "",
      searchString: ""
    };
  }

  toggleOpen = () => {
    this.setState(prevState => ({
      open: !prevState.open
    }));
  };

  openMenu = () => {
    this.setState({open: true});
  };

  setSearchString() {
    const foundItems = this.props.items.filter(item => item.value === this.props.input.value);
    if (foundItems && foundItems.length > 0) {
      this.setState({searchString: foundItems[0].name});
    }
  }

  componentDidMount() {
    this.setSearchString()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const newValue = this.props.input.value;
    const prevValue = prevProps.input.value;
    if (newValue !== prevValue) this.setSearchString()
    if (this.props.clearSolutionName) {
      this.setState({searchString: "" });
      this.props.toggleClearSolutionName();
    }
    if (prevProps.items !== this.props.items) {
      this.setSearchString();
    }
  }

  render() {
    const normalizedSearchValue = this.state.searchString.trim().toLowerCase();
    const filteredItems = this.props.items.filter(item => item.name.toLowerCase().startsWith(normalizedSearchValue));

    return (
      <Dropdown
        isOpen={this.state.open}
        toggle={this.toggleOpen}
      >
        <DropdownToggle
          className="opacity-100-important"
          style={{padding: '0', margin: '0', width: '100%'}}
          disabled={this.props.disabled}
        >
          <Input
            disabled={this.props.disabled}
            type="text"
            value={this.state.searchString}
            placeholder="Select a solution"
            style={{width: '100%', padding: '10px'}}
            className="form-control"
            onChange={(e) => {
              this.props.clearSolutionField();
              this.setState({
                value: null,
                searchString: e.target.value
              });
              const item = filteredItems.find(item => item.name === e.target.value);
              if (item) this.props.onItemClick(item.value);
              this.openMenu();
            }}
          />
          <Input
            {...this.props.input}
            type="number"
            className="hidden"
          />
        </DropdownToggle>
        <DropdownMenu style={{width: '100%', maxHeight: "400px", overflowY: "auto", paddingTop: "0", marginTop: "0"}} x-placement="none">
          {
            filteredItems.map((item, i) => (
              <DropdownItem key={i} onClick={() => {
                this.props.clearSolutionField();
                this.setState({searchString: item.name});
                this.props.onItemClick(item.value);
              }}>
                {item.name}
              </DropdownItem>
            ))
          }
        </DropdownMenu>
      </Dropdown>
    )
  }
}
