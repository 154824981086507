import axios from 'axios';
import {
  SOLUTION_PROMOTION_UPDATE_FETCH_REQUEST,
  SOLUTION_PROMOTION_UPDATE_FETCH_SUCCESS,
  SOLUTION_PROMOTION_UPDATE_FETCH_ERROR,
} from './actionTypes';
import { updateSpinner } from "./spinnerActions";
import camelcaseKeys from "camelcase-keys";

export const updateSolutionPromotion = (promotion, data) => async (dispatch, state) => {
  dispatch(updateSpinner(true));
  dispatch({ type: SOLUTION_PROMOTION_UPDATE_FETCH_REQUEST , request: true});

  if (promotion && promotion.id) {
    let url = `/api/solution/solution_promotions/${promotion.id}/`;
    const { mode } = state().account;
    url += `?mode=${mode}`;
    try {
      const res = await axios.patch(url, data);
      const payload = camelcaseKeys(res.data, {deep: true});
      dispatch({ type: SOLUTION_PROMOTION_UPDATE_FETCH_SUCCESS, data: payload });
      dispatch(updateSpinner(false));
    } catch (error) {
      dispatch(updateSpinner(false));
      dispatch({ type: SOLUTION_PROMOTION_UPDATE_FETCH_ERROR, error });
      return error;
    }
  } else {
    let url = `/api/solution/solution_promotions/`;
    const { mode } = state().account;
    url += `?mode=${mode}`;
    try {
      const res = await axios.post(url, data);
      const payload = camelcaseKeys(res.data, {deep: true});
      dispatch({ type: SOLUTION_PROMOTION_UPDATE_FETCH_SUCCESS, data: payload });
      dispatch(updateSpinner(false));
    } catch (error) {
      dispatch(updateSpinner(false));
      dispatch({ type: SOLUTION_PROMOTION_UPDATE_FETCH_ERROR, error });
      return error;
    }
  }
};
